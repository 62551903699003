import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Table, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import no_image from '../../assets/img/brand/no_image.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const moment = require('moment');

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};


class ClientOnBoardingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      occupation: this.props.admin ? this.props.admin.occupation : "",
      investment_strategy_product: "",
      mode_of_investment: "",
      amount_of_investment: "",
      security: "",
      investment_amount: "",
      client_category: "",
      clientCat: "",
      finance_first_year: "",
      finance_second_year: "",
      finance_third_year: "",
      firsttillDate: "",
      first_net_worth_inr: "",
      secondTillDate: "",
      second_net_worth_inr: "",
      thirdTillDate: "",
      third_net_worth_inr: "",
      politically_exposed_person: "",
      money_being_invested: "",
      money_being_invested_other: "",
      investment_experience: "",
      investment_objective: "",
      time_horizon_of_investment: "",
      is_nominee: "",
      nominee_name: "",
      nominee_relationship: "",
      nominee_pan_no: "",
      nominee_dob: "",
      nominee_contact_no: "",
      same_address_as_investor: "",
      nominee_address: "",
      nominee_guardian_name: "",
      nominee_guardian_contact: "",
      nominee_guardian_address: "",

      name_of_security_first: "",
      isin_code_first: "",
      quantity_first: "",
      original_date_of_aquisition_first: "",
      original_per_share_rate_of_aquisition_first: "",

      name_of_security_second: "",
      isin_code_second: "",
      quantity_second: "",
      original_date_of_aquisition_second: "",
      original_per_share_rate_of_aquisition_second: "",

      name_of_security_third: "",
      isin_code_third: "",
      quantity_third: "",
      original_date_of_aquisition_third: "",
      original_per_share_rate_of_aquisition_third: "",

      name_of_security_fourth: "",
      isin_code_fourth: "",
      quantity_fourth: "",
      original_date_of_aquisition_fourth: "",
      original_per_share_rate_of_aquisition_fourth: "",

      name_of_security_fifth: "",
      isin_code_fifth: "",
      quantity_fifth: "",
      original_date_of_aquisition_fifth: "",
      original_per_share_rate_of_aquisition_fifth: "",

      name_of_security_sixth: "",
      isin_code_sixth: "",
      quantity_sixth: "",
      original_date_of_aquisition_sixth: "",
      original_per_share_rate_of_aquisition_sixth: "",

      name_of_security_seventh: "",
      isin_code_seventh: "",
      quantity_seventh: "",
      original_date_of_aquisition_seventh: "",
      original_per_share_rate_of_aquisition_seventh: "",

      name_of_security_eighth: "",
      isin_code_eighth: "",
      quantity_eighth: "",
      original_date_of_aquisition_eighth: "",
      original_per_share_rate_of_aquisition_eighth: "",

      name_of_security_ninth: "",
      isin_code_ninth: "",
      quantity_ninth: "",
      original_date_of_aquisition_ninth: "",
      original_per_share_rate_of_aquisition_ninth: "",

      name_of_security_ten: "",
      isin_code_ten: "",
      quantity_ten: "",
      original_date_of_aquisition_ten: "",
      original_per_share_rate_of_aquisition_ten: "",

      name_of_security_eleven: "",
      isin_code_eleven: "",
      quantity_eleven: "",
      original_date_of_aquisition_eleven: "",
      original_per_share_rate_of_aquisition_eleven: "",

      name_of_security_twelve: "",
      isin_code_twelve: "",
      quantity_twelve: "",
      original_date_of_aquisition_twelve: "",
      original_per_share_rate_of_aquisition_twelve: "",

      name_of_security_thirteen: "",
      isin_code_thirteen: "",
      quantity_thirteen: "",
      original_date_of_aquisition_thirteen: "",
      original_per_share_rate_of_aquisition_thirteen: "",

      name_of_security_fourteen: "",
      isin_code_fourteen: "",
      quantity_fourteen: "",
      original_date_of_aquisition_fourteen: "",
      original_per_share_rate_of_aquisition_fourteen: "",

      name_of_security_fifteen: "",
      isin_code_fifteen: "",
      quantity_fifteen: "",
      original_date_of_aquisition_fifteen: "",
      original_per_share_rate_of_aquisition_fifteen: "",

      name_of_security_sixteen: "",
      isin_code_sixteen: "",
      quantity_sixteen: "",
      original_date_of_aquisition_sixteen: "",
      original_per_share_rate_of_aquisition_sixteen: "",

      name_of_security_seventeen: "",
      isin_code_seventeen: "",
      quantity_seventeen: "",
      original_date_of_aquisition_seventeen: "",
      original_per_share_rate_of_aquisition_seventeen: "",

      name_of_security_eighteen: "",
      isin_code_eighteen: "",
      quantity_eighteen: "",
      original_date_of_aquisition_eighteen: "",
      original_per_share_rate_of_aquisition_eighteen: "",

      name_of_security_ninteen: "",
      isin_code_ninteen: "",
      quantity_ninteen: "",
      original_date_of_aquisition_ninteen: "",
      original_per_share_rate_of_aquisition_ninteen: "",

      name_of_security_twenty: "",
      isin_code_twenty: "",
      quantity_twenty: "",
      original_date_of_aquisition_twenty: "",
      original_per_share_rate_of_aquisition_twenty: "",

      name_of_security_tone: "",
      isin_code_tone: "",
      quantity_tone: "",
      original_date_of_aquisition_tone: "",
      original_per_share_rate_of_aquisition_tone: "",

      name_of_security_ttwo: "",
      isin_code_ttwo: "",
      quantity_ttwo: "",
      original_date_of_aquisition_ttwo: "",
      original_per_share_rate_of_aquisition_ttwo: "",

      name_of_security_tthree: "",
      isin_code_tthree: "",
      quantity_tthree: "",
      original_date_of_aquisition_tthree: "",
      original_per_share_rate_of_aquisition_tthree: "",

      name_of_security_tfour: "",
      isin_code_tfour: "",
      quantity_tfour: "",
      original_date_of_aquisition_tfour: "",
      original_per_share_rate_of_aquisition_tfour: "",

      name_of_security_tfive: "",
      isin_code_tfive: "",
      quantity_tfive: "",
      original_date_of_aquisition_tfive: "",
      original_per_share_rate_of_aquisition_tfive: "",

      fee_structure_plan : "",
      sr_no_body_corporate_one : "",
      name_of_body_corporate_one : "",
      nature_of_interest_one : "",
      sr_no_body_corporate_two : "",
      name_of_body_corporate_two : "",
      nature_of_interest_two : "",
      sr_no_body_corporate_three : "",
      name_of_body_corporate_three : "",
      nature_of_interest_three : "",
      sr_no_company_one : "",
      name_of_company_one : "",
      sr_no_company_two : "",
      name_of_company_two : "",
      sr_no_company_three : "",
      name_of_company_three : "",
      willingness_to_take_financial_risk : "",
      adapt_things_go_wrong : "",
      risky_investment : "",
      major_financial_decision : "",
      feel_about_major_financial_decision : "",
      financial_decision_in_past : "",
      currently_financial_decision : "",
      investment_in_portfolio : "",
      chance_of_loss : "",
      risk_above_average : "",


      non_individual_type : "",
      applicant_name : "",
      incorporation_date : "",
      incorporation_place : "",
      date_of_commencement_of_business : "",
      registeration_no : "",
      risk_tolerance : "",
      onBoardingStatus : "",
      
      id: this.props.admin._id,
      profileDataForStore: {},
      clientCategory: "",
      s2 : "shide",
      s3 : "shide",
      s4 : "shide",
      s5 : "shide",
      s6 : "shide",
      s7 : "shide",
      s8 : "shide",
      s9 : "shide",
      s10 : "shide",
      s11 : "shide",
      s12 : "shide",
      s13 : "shide",
      s14 : "shide",
      s15 : "shide",
      s16 : "shide",
      s17 : "shide",
      s18 : "shide",
      s19 : "shide",
      s20 : "shide",
      s21 : "shide",
      s22 : "shide",
      s23 : "shide",
      s24 : "shide",
      s25 : "shide",
      b1 : "bshow",
      b2 : "bshow",
      b3 : "bshow",
      b4 : "bshow",
      b5 : "bshow",
      b6 : "bshow",
      b7 : "bshow",
      b8 : "bshow",
      b9 : "bshow",
      b10 : "bshow",
      b11 : "bshow",
      b12 : "bshow",
      b13 : "bshow",
      b14 : "bshow",
      b15 : "bshow",
      b16 : "bshow",
      b17 : "bshow",
      b18 : "bshow",
      b19 : "bshow",
      b20 : "bshow",
      b21 : "bshow",
      b22 : "bshow",
      b23 : "bshow",
      b24 : "bshow",
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    if(e.target.name == "client_category"){
      var clientCategory = this.state.client_category;
      if(clientCategory != e.target.value && clientCategory != ""){
        this.setState({
          finance_first_year: "",
          finance_second_year: "",
          finance_third_year: "",
          firsttillDate: "",
          first_net_worth_inr: "",
          secondTillDate: "",
          second_net_worth_inr: "",
          thirdTillDate: "",
          third_net_worth_inr: "",
          politically_exposed_person: "",
          money_being_invested: "",
          money_being_invested_other: "",
          investment_experience: "",
          investment_objective: "",
          time_horizon_of_investment: "",
          is_nominee: "",
          nominee_name: "",
          nominee_relationship: "",
          nominee_pan_no: "",
          nominee_dob: "",
          nominee_contact_no: "",
          same_address_as_investor: "",
          nominee_address: "",
          nominee_guardian_name: "",
          nominee_guardian_contact: "",
          nominee_guardian_address: "",

          name_of_security_first: "",
          isin_code_first: "",
          quantity_first: "",
          original_date_of_aquisition_first: "",
          original_per_share_rate_of_aquisition_first: "",

          name_of_security_second: "",
          isin_code_second: "",
          quantity_second: "",
          original_date_of_aquisition_second: "",
          original_per_share_rate_of_aquisition_second: "",

          name_of_security_third: "",
          isin_code_third: "",
          quantity_third: "",
          original_date_of_aquisition_third: "",
          original_per_share_rate_of_aquisition_third: "",

          name_of_security_fourth: "",
          isin_code_fourth: "",
          quantity_fourth: "",
          original_date_of_aquisition_fourth: "",
          original_per_share_rate_of_aquisition_fourth: "",

          name_of_security_fifth: "",
          isin_code_fifth: "",
          quantity_fifth: "",
          original_date_of_aquisition_fifth: "",
          original_per_share_rate_of_aquisition_fifth: "",

          name_of_security_sixth: "",
          isin_code_sixth: "",
          quantity_sixth: "",
          original_date_of_aquisition_sixth: "",
          original_per_share_rate_of_aquisition_sixth: "",

          name_of_security_seventh: "",
          isin_code_seventh: "",
          quantity_seventh: "",
          original_date_of_aquisition_seventh: "",
          original_per_share_rate_of_aquisition_seventh: "",

          name_of_security_eighth: "",
          isin_code_eighth: "",
          quantity_eighth: "",
          original_date_of_aquisition_eighth: "",
          original_per_share_rate_of_aquisition_eighth: "",

          name_of_security_ninth: "",
          isin_code_ninth: "",
          quantity_ninth: "",
          original_date_of_aquisition_ninth: "",
          original_per_share_rate_of_aquisition_ninth: "",

          name_of_security_ten: "",
          isin_code_ten: "",
          quantity_ten: "",
          original_date_of_aquisition_ten: "",
          original_per_share_rate_of_aquisition_ten: "",

          name_of_security_eleven: "",
          isin_code_eleven: "",
          quantity_eleven: "",
          original_date_of_aquisition_eleven: "",
          original_per_share_rate_of_aquisition_eleven: "",

          name_of_security_twelve: "",
          isin_code_twelve: "",
          quantity_twelve: "",
          original_date_of_aquisition_twelve: "",
          original_per_share_rate_of_aquisition_twelve: "",

          name_of_security_thirteen: "",
          isin_code_thirteen: "",
          quantity_thirteen: "",
          original_date_of_aquisition_thirteen: "",
          original_per_share_rate_of_aquisition_thirteen: "",

          name_of_security_fourteen: "",
          isin_code_fourteen: "",
          quantity_fourteen: "",
          original_date_of_aquisition_fourteen: "",
          original_per_share_rate_of_aquisition_fourteen: "",

          name_of_security_fifteen: "",
          isin_code_fifteen: "",
          quantity_fifteen: "",
          original_date_of_aquisition_fifteen: "",
          original_per_share_rate_of_aquisition_fifteen: "",

          name_of_security_sixteen: "",
          isin_code_sixteen: "",
          quantity_sixteen: "",
          original_date_of_aquisition_sixteen: "",
          original_per_share_rate_of_aquisition_sixteen: "",

          name_of_security_seventeen: "",
          isin_code_seventeen: "",
          quantity_seventeen: "",
          original_date_of_aquisition_seventeen: "",
          original_per_share_rate_of_aquisition_seventeen: "",

          name_of_security_eighteen: "",
          isin_code_eighteen: "",
          quantity_eighteen: "",
          original_date_of_aquisition_eighteen: "",
          original_per_share_rate_of_aquisition_eighteen: "",

          name_of_security_ninteen: "",
          isin_code_ninteen: "",
          quantity_ninteen: "",
          original_date_of_aquisition_ninteen: "",
          original_per_share_rate_of_aquisition_ninteen: "",

          name_of_security_twenty: "",
          isin_code_twenty: "",
          quantity_twenty: "",
          original_date_of_aquisition_twenty: "",
          original_per_share_rate_of_aquisition_twenty: "",

          name_of_security_tone: "",
          isin_code_tone: "",
          quantity_tone: "",
          original_date_of_aquisition_tone: "",
          original_per_share_rate_of_aquisition_tone: "",

          name_of_security_ttwo: "",
          isin_code_ttwo: "",
          quantity_ttwo: "",
          original_date_of_aquisition_ttwo: "",
          original_per_share_rate_of_aquisition_ttwo: "",

          name_of_security_tthree: "",
          isin_code_tthree: "",
          quantity_tthree: "",
          original_date_of_aquisition_tthree: "",
          original_per_share_rate_of_aquisition_tthree: "",

          name_of_security_tfour: "",
          isin_code_tfour: "",
          quantity_tfour: "",
          original_date_of_aquisition_tfour: "",
          original_per_share_rate_of_aquisition_tfour: "",

          name_of_security_tfive: "",
          isin_code_tfive: "",
          quantity_tfive: "",
          original_date_of_aquisition_tfive: "",
          original_per_share_rate_of_aquisition_tfive: "",

          fee_structure_plan : "",
          sr_no_body_corporate_one : "",
          name_of_body_corporate_one : "",
          nature_of_interest_one : "",
          sr_no_body_corporate_two : "",
          name_of_body_corporate_two : "",
          nature_of_interest_two : "",
          sr_no_body_corporate_three : "",
          name_of_body_corporate_three : "",
          nature_of_interest_three : "",
          sr_no_company_one : "",
          name_of_company_one : "",
          sr_no_company_two : "",
          name_of_company_two : "",
          sr_no_company_three : "",
          name_of_company_three : "",
          willingness_to_take_financial_risk : "",
          adapt_things_go_wrong : "",
          risky_investment : "",
          major_financial_decision : "",
          feel_about_major_financial_decision : "",
          financial_decision_in_past : "",
          currently_financial_decision : "",
          investment_in_portfolio : "",
          chance_of_loss : "",
          risk_above_average : "",


          non_individual_type : "",
          applicant_name : "",
          incorporation_date : "",
          incorporation_place : "",
          date_of_commencement_of_business : "",
          registeration_no : "",
          risk_tolerance : ""
        })
      }
    }

    this.setState({
      [e.target.name]: e.target.value
    })

    if (e.target.name == "mode_of_investment") {
      if(e.target.value == 'Funds'){
        this.state.security = ""
      }
      if(e.target.value == 'Securities'){
        this.state.amount_of_investment = ""
      }
      this.setState({
        investment_amount : (this.state.security != '' && this.state.security != null && this.state.security != undefined ? parseFloat(this.state.security) : 0) + (this.state.amount_of_investment != '' && this.state.amount_of_investment != null && this.state.amount_of_investment != undefined ? parseFloat(this.state.amount_of_investment) : 0)
      })
    }

    if (e.target.name == "amount_of_investment") {
      this.setState({
        investment_amount : (this.state.security != '' && this.state.security != null && this.state.security != undefined ? parseFloat(this.state.security) : 0) + (e.target.value != '' ? parseFloat(e.target.value) : 0)
      })
    }
    if (e.target.name == "security") {
      this.setState({
        investment_amount : (this.state.amount_of_investment != '' && this.state.amount_of_investment != null && this.state.amount_of_investment != undefined ? parseFloat(this.state.amount_of_investment) : 0) + (e.target.value != '' ? parseFloat(e.target.value) : 0)
      })
    }
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.clientOnBoarding(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  handleButtonChange= (e) =>{
    let stat = e.target.getAttribute('data_type');
    var ele = document.getElementsByClassName(stat);
    ele[0].classList.remove("shide");
    e.target.classList.remove("bshow");
    e.target.classList.add("shide");
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async clientOnBoarding(postData) {
    let path = apiPath.clientOnBoarding;
    this.setState({ loading: true });
    postData.user_id = this.state.id
    postData.mode_of_investment = this.state.mode_of_investment != "" ? this.state.mode_of_investment : null;
    postData.client_category = this.state.client_category != "" ? this.state.client_category : null;
    postData.finance_first_year = this.state.finance_first_year != "" ? this.state.finance_first_year : null;
    postData.finance_second_year = this.state.finance_second_year != "" ? this.state.finance_second_year : null;
    postData.finance_third_year = this.state.finance_third_year != "" ? this.state.finance_third_year : null;
    postData.politically_exposed_person = this.state.politically_exposed_person != "" ? this.state.politically_exposed_person : null;
    postData.money_being_invested = this.state.money_being_invested != "" ? this.state.money_being_invested : null;
    postData.investment_experience = this.state.investment_experience != "" ? this.state.investment_experience : null;
    postData.investment_objective = this.state.investment_objective != "" ? this.state.investment_objective : null;
    postData.time_horizon_of_investment = this.state.time_horizon_of_investment != "" ? this.state.time_horizon_of_investment : null;
    postData.risk_tolerance = this.state.risk_tolerance != "" ? this.state.risk_tolerance : null;
    postData.is_nominee = this.state.is_nominee != "" ? this.state.is_nominee : null;
    postData.same_address_as_investor = this.state.same_address_as_investor != "" ? this.state.same_address_as_investor : null;
    postData.fee_structure_plan = this.state.fee_structure_plan != "" ? this.state.fee_structure_plan : null;
    postData.willingness_to_take_financial_risk = this.state.willingness_to_take_financial_risk != "" ? this.state.willingness_to_take_financial_risk : null;
    postData.adapt_things_go_wrong = this.state.adapt_things_go_wrong != "" ? this.state.adapt_things_go_wrong : null;
    postData.risky_investment = this.state.risky_investment != "" ? this.state.risky_investment : null;
    postData.major_financial_decision = this.state.major_financial_decision != "" ? this.state.major_financial_decision : null;
    postData.feel_about_major_financial_decision = this.state.feel_about_major_financial_decision != "" ? this.state.feel_about_major_financial_decision : null;
    postData.financial_decision_in_past = this.state.financial_decision_in_past != "" ? this.state.financial_decision_in_past : null;
    postData.currently_financial_decision = this.state.currently_financial_decision != "" ? this.state.currently_financial_decision : null;
    postData.investment_in_portfolio = this.state.investment_in_portfolio != "" ? this.state.investment_in_portfolio : null;
    postData.chance_of_loss = this.state.chance_of_loss != "" ? this.state.chance_of_loss : null;
    postData.risk_above_average = this.state.risk_above_average != "" ? this.state.risk_above_average : null;
    
    postData.firsttillDate = this.state.firsttillDate != "" ? moment(this.state.firsttillDate).format('YYYY-MM-DD') : null;
    postData.secondTillDate = this.state.secondTillDate != "" ? moment(this.state.secondTillDate).format('YYYY-MM-DD') : null;
    postData.thirdTillDate = this.state.thirdTillDate != "" ? moment(this.state.thirdTillDate).format('YYYY-MM-DD') : null;
    postData.nominee_dob = this.state.nominee_dob != "" ? moment(this.state.nominee_dob).format('YYYY-MM-DD') : null;
    postData.date_of_commencement_of_business = this.state.date_of_commencement_of_business != "" ? moment(this.state.date_of_commencement_of_business).format('YYYY-MM-DD') : null;
    postData.incorporation_date = this.state.incorporation_date != "" ? moment(this.state.incorporation_date).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_first = this.state.original_date_of_aquisition_first != "" ? moment(this.state.original_date_of_aquisition_first).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_second = this.state.original_date_of_aquisition_second != "" ? moment(this.state.original_date_of_aquisition_second).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_third = this.state.original_date_of_aquisition_third != "" ? moment(this.state.original_date_of_aquisition_third).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_fourth = this.state.original_date_of_aquisition_fourth != "" ? moment(this.state.original_date_of_aquisition_fourth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_fifth = this.state.original_date_of_aquisition_fifth != "" ? moment(this.state.original_date_of_aquisition_fifth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_sixth = this.state.original_date_of_aquisition_sixth != "" ? moment(this.state.original_date_of_aquisition_sixth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_seventh = this.state.original_date_of_aquisition_seventh != "" ? moment(this.state.original_date_of_aquisition_seventh).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_eighth = this.state.original_date_of_aquisition_eighth != "" ? moment(this.state.original_date_of_aquisition_eighth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_ninth = this.state.original_date_of_aquisition_ninth != "" ? moment(this.state.original_date_of_aquisition_ninth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_ten = this.state.original_date_of_aquisition_ten != "" ? moment(this.state.original_date_of_aquisition_ten).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_eleven = this.state.original_date_of_aquisition_eleven != "" ? moment(this.state.original_date_of_aquisition_eleven).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_twelve = this.state.original_date_of_aquisition_twelve != "" ? moment(this.state.original_date_of_aquisition_twelve).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_thirteen = this.state.original_date_of_aquisition_thirteen != "" ? moment(this.state.original_date_of_aquisition_thirteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_fourteen = this.state.original_date_of_aquisition_fourteen != "" ? moment(this.state.original_date_of_aquisition_fourteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_fifteen = this.state.original_date_of_aquisition_fifteen != "" ? moment(this.state.original_date_of_aquisition_fifteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_sixteen = this.state.original_date_of_aquisition_sixteen != "" ? moment(this.state.original_date_of_aquisition_sixteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_seventeen = this.state.original_date_of_aquisition_seventeen != "" ? moment(this.state.original_date_of_aquisition_seventeen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_eighteen = this.state.original_date_of_aquisition_eighteen != "" ? moment(this.state.original_date_of_aquisition_eighteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_ninteen = this.state.original_date_of_aquisition_ninteen != "" ? moment(this.state.original_date_of_aquisition_ninteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_twenty = this.state.original_date_of_aquisition_twenty != "" ? moment(this.state.original_date_of_aquisition_twenty).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_tone = this.state.original_date_of_aquisition_tone != "" ? moment(this.state.original_date_of_aquisition_tone).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_ttwo = this.state.original_date_of_aquisition_ttwo != "" ? moment(this.state.original_date_of_aquisition_ttwo).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_tthree = this.state.original_date_of_aquisition_tthree != "" ? moment(this.state.original_date_of_aquisition_tthree).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_tfour = this.state.original_date_of_aquisition_tfour != "" ? moment(this.state.original_date_of_aquisition_tfour).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_tfive = this.state.original_date_of_aquisition_tfive != "" ? moment(this.state.original_date_of_aquisition_tfive).format('YYYY-MM-DD') : null;
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(postData)
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            this.setState({redirectTo: true});
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProducts() {
    let path = apiPath.products;
    this.setState({ loading: true });
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData);
          this.setState({ products: resultData.result })
          await this.getProcessData('Client-On-Boarding');
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProcessData(processName) {
    let path = apiPath.getProcessData;
    this.setState({ loading: true });
    var data = {}
    data.user_id = this.state.id
    data.process = processName
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData.result,'resultData.result');
          let processData = resultData.result;
          this.setState({  
            occupation: processData && processData.occupation ? processData.occupation : "",
            investment_strategy_product: processData && processData.investment_strategy_product ? processData.investment_strategy_product : "",
            mode_of_investment: processData && processData.mode_of_investment ? processData.mode_of_investment : "",
            amount_of_investment: processData && processData.amount_of_investment ? processData.amount_of_investment : "",
            security: processData && processData.security ? processData.security : "",
            investment_amount: processData && processData.investment_amount ? processData.investment_amount : "",
            client_category: processData && processData.client_category ? processData.client_category : "",
            clientCat: processData && processData.client_category ? processData.client_category : "",
            finance_first_year: processData && processData.finance_first_year ? processData.finance_first_year : "",
            finance_second_year: processData && processData.finance_second_year ? processData.finance_second_year : "",
            finance_third_year: processData && processData.finance_third_year ? processData.finance_third_year : "",
            firsttillDate: processData && processData.firsttillDate && processData.firsttillDate != '' ? new Date(moment(processData.firsttillDate)) : "",
            first_net_worth_inr: processData && processData.first_net_worth_inr ? processData.first_net_worth_inr : "",
            secondTillDate: processData && processData.secondTillDate && processData.secondTillDate != '' ? new Date(moment(processData.secondTillDate)) : "",
            second_net_worth_inr: processData && processData.second_net_worth_inr ? processData.second_net_worth_inr : "",
            thirdTillDate: processData && processData.thirdTillDate && processData.thirdTillDate != '' ? new Date(moment(processData.thirdTillDate)) : "",
            third_net_worth_inr: processData && processData.third_net_worth_inr ? processData.third_net_worth_inr : "",
            politically_exposed_person: processData && processData.politically_exposed_person ? processData.politically_exposed_person : "",
            money_being_invested: processData && processData.money_being_invested ? processData.money_being_invested : "",
            money_being_invested_other: processData && processData.money_being_invested_other ? processData.money_being_invested_other : "",
            investment_experience: processData && processData.investment_experience ? processData.investment_experience : "",
            investment_objective: processData && processData.investment_objective ? processData.investment_objective : "",
            time_horizon_of_investment: processData && processData.time_horizon_of_investment ? processData.time_horizon_of_investment : "",
            is_nominee: processData && processData.is_nominee ? processData.is_nominee : "",
            nominee_name: processData && processData.nominee_name ? processData.nominee_name : "",
            nominee_relationship: processData && processData.nominee_relationship ? processData.nominee_relationship : "",
            nominee_pan_no: processData && processData.nominee_pan_no ? processData.nominee_pan_no : "",
            nominee_dob: processData && processData.nominee_dob && processData.nominee_dob != '' ? new Date(moment(processData.nominee_dob)) : "",
            nominee_contact_no: processData && processData.nominee_contact_no ? processData.nominee_contact_no : "",
            same_address_as_investor: processData && processData.same_address_as_investor ? processData.same_address_as_investor : "",
            nominee_address: processData && processData.nominee_address ? processData.nominee_address : "",
            nominee_guardian_name: processData && processData.nominee_guardian_name ? processData.nominee_guardian_name : "",
            nominee_guardian_contact: processData && processData.nominee_guardian_contact ? processData.nominee_guardian_contact : "",
            nominee_guardian_address: processData && processData.nominee_guardian_address ? processData.nominee_guardian_address : "",

            name_of_security_first: processData && processData.name_of_security_first ? processData.name_of_security_first : "",
            isin_code_first: processData && processData.isin_code_first ? processData.isin_code_first : "",
            quantity_first: processData && processData.quantity_first ? processData.quantity_first : "",
            original_date_of_aquisition_first: processData && processData.original_date_of_aquisition_first && processData.original_date_of_aquisition_first != '' ? new Date(moment(processData.original_date_of_aquisition_first)) : "",
            original_per_share_rate_of_aquisition_first: processData && processData.original_per_share_rate_of_aquisition_first ? processData.original_per_share_rate_of_aquisition_first : "",

            name_of_security_second: processData && processData.name_of_security_second ? processData.name_of_security_second : "",
            isin_code_second: processData && processData.isin_code_second ? processData.isin_code_second : "",
            quantity_second: processData && processData.quantity_second ? processData.quantity_second : "",
            original_date_of_aquisition_second: processData && processData.original_date_of_aquisition_second && processData.original_date_of_aquisition_second != '' ? new Date(moment(processData.original_date_of_aquisition_second)) : "",
            original_per_share_rate_of_aquisition_second: processData && processData.original_per_share_rate_of_aquisition_second ? processData.original_per_share_rate_of_aquisition_second : "",

            name_of_security_third: processData && processData.name_of_security_third ? processData.name_of_security_third : "",
            isin_code_third: processData && processData.isin_code_third ? processData.isin_code_third : "",
            quantity_third: processData && processData.quantity_third ? processData.quantity_third : "",
            original_date_of_aquisition_third: processData && processData.original_date_of_aquisition_third && processData.original_date_of_aquisition_third != '' ? new Date(moment(processData.original_date_of_aquisition_third)) : "",
            original_per_share_rate_of_aquisition_third: processData && processData.original_per_share_rate_of_aquisition_third ? processData.original_per_share_rate_of_aquisition_third : "",

            name_of_security_fourth: processData && processData.name_of_security_fourth ? processData.name_of_security_fourth : "",
            isin_code_fourth: processData && processData.isin_code_fourth ? processData.isin_code_fourth : "",
            quantity_fourth: processData && processData.quantity_fourth ? processData.quantity_fourth : "",
            original_date_of_aquisition_fourth: processData && processData.original_date_of_aquisition_fourth && processData.original_date_of_aquisition_fourth != '' ? new Date(moment(processData.original_date_of_aquisition_fourth)) : "",
            original_per_share_rate_of_aquisition_fourth: processData && processData.original_per_share_rate_of_aquisition_fourth ? processData.original_per_share_rate_of_aquisition_fourth : "",

            name_of_security_fifth: processData && processData.name_of_security_fifth ? processData.name_of_security_fifth : "",
            isin_code_fifth: processData && processData.isin_code_fifth ? processData.isin_code_fifth : "",
            quantity_fifth: processData && processData.quantity_fifth ? processData.quantity_fifth : "",
            original_date_of_aquisition_fifth: processData && processData.original_date_of_aquisition_fifth && processData.original_date_of_aquisition_fifth != '' ? new Date(moment(processData.original_date_of_aquisition_fifth)) : "",
            original_per_share_rate_of_aquisition_fifth: processData && processData.original_per_share_rate_of_aquisition_fifth ? processData.original_per_share_rate_of_aquisition_fifth : "",

            name_of_security_sixth: processData && processData.name_of_security_sixth ? processData.name_of_security_sixth : "",
            isin_code_sixth: processData && processData.isin_code_sixth ? processData.isin_code_sixth : "",
            quantity_sixth: processData && processData.quantity_sixth ? processData.quantity_sixth : "",
            original_date_of_aquisition_sixth: processData && processData.original_date_of_aquisition_sixth && processData.original_date_of_aquisition_sixth != '' ? new Date(moment(processData.original_date_of_aquisition_sixth)) : "",
            original_per_share_rate_of_aquisition_sixth: processData && processData.original_per_share_rate_of_aquisition_sixth ? processData.original_per_share_rate_of_aquisition_sixth : "",

            name_of_security_seventh: processData && processData.name_of_security_seventh ? processData.name_of_security_seventh : "",
            isin_code_seventh: processData && processData.isin_code_seventh ? processData.isin_code_seventh : "",
            quantity_seventh: processData && processData.quantity_seventh ? processData.quantity_seventh : "",
            original_date_of_aquisition_seventh: processData && processData.original_date_of_aquisition_seventh && processData.original_date_of_aquisition_seventh != '' ? new Date(moment(processData.original_date_of_aquisition_seventh)) : "",
            original_per_share_rate_of_aquisition_seventh: processData && processData.original_per_share_rate_of_aquisition_seventh ? processData.original_per_share_rate_of_aquisition_seventh : "",

            name_of_security_eighth: processData && processData.name_of_security_eighth ? processData.name_of_security_eighth : "",
            isin_code_eighth: processData && processData.isin_code_eighth ? processData.isin_code_eighth : "",
            quantity_eighth: processData && processData.quantity_eighth ? processData.quantity_eighth : "",
            original_date_of_aquisition_eighth: processData && processData.original_date_of_aquisition_eighth && processData.original_date_of_aquisition_eighth != '' ? new Date(moment(processData.original_date_of_aquisition_eighth)) : "",
            original_per_share_rate_of_aquisition_eighth: processData && processData.original_per_share_rate_of_aquisition_eighth ? processData.original_per_share_rate_of_aquisition_eighth : "",

            name_of_security_ninth: processData && processData.name_of_security_ninth ? processData.name_of_security_ninth : "",
            isin_code_ninth: processData && processData.isin_code_ninth ? processData.isin_code_ninth : "",
            quantity_ninth: processData && processData.quantity_ninth ? processData.quantity_ninth : "",
            original_date_of_aquisition_ninth: processData && processData.original_date_of_aquisition_ninth && processData.original_date_of_aquisition_ninth != '' ? new Date(moment(processData.original_date_of_aquisition_ninth)) : "",
            original_per_share_rate_of_aquisition_ninth: processData && processData.original_per_share_rate_of_aquisition_ninth ? processData.original_per_share_rate_of_aquisition_ninth : "",

            name_of_security_ten: processData && processData.name_of_security_ten ? processData.name_of_security_ten : "",
            isin_code_ten: processData && processData.isin_code_ten ? processData.isin_code_ten : "",
            quantity_ten: processData && processData.quantity_ten ? processData.quantity_ten : "",
            original_date_of_aquisition_ten: processData && processData.original_date_of_aquisition_ten && processData.original_date_of_aquisition_ten != '' ? new Date(moment(processData.original_date_of_aquisition_ten)) : "",
            original_per_share_rate_of_aquisition_ten: processData && processData.original_per_share_rate_of_aquisition_ten ? processData.original_per_share_rate_of_aquisition_ten : "",

            name_of_security_eleven: processData && processData.name_of_security_eleven ? processData.name_of_security_eleven : "",
            isin_code_eleven: processData && processData.isin_code_eleven ? processData.isin_code_eleven : "",
            quantity_eleven: processData && processData.quantity_eleven ? processData.quantity_eleven : "",
            original_date_of_aquisition_eleven: processData && processData.original_date_of_aquisition_eleven && processData.original_date_of_aquisition_eleven != '' ? new Date(moment(processData.original_date_of_aquisition_eleven)) : "",
            original_per_share_rate_of_aquisition_eleven: processData && processData.original_per_share_rate_of_aquisition_eleven ? processData.original_per_share_rate_of_aquisition_eleven : "",

            name_of_security_twelve: processData && processData.name_of_security_twelve ? processData.name_of_security_twelve : "",
            isin_code_twelve: processData && processData.isin_code_twelve ? processData.isin_code_twelve : "",
            quantity_twelve: processData && processData.quantity_twelve ? processData.quantity_twelve : "",
            original_date_of_aquisition_twelve: processData && processData.original_date_of_aquisition_twelve && processData.original_date_of_aquisition_twelve != '' ? new Date(moment(processData.original_date_of_aquisition_twelve)) : "",
            original_per_share_rate_of_aquisition_twelve: processData && processData.original_per_share_rate_of_aquisition_twelve ? processData.original_per_share_rate_of_aquisition_twelve : "",

            name_of_security_thirteen: processData && processData.name_of_security_thirteen ? processData.name_of_security_thirteen : "",
            isin_code_thirteen: processData && processData.isin_code_thirteen ? processData.isin_code_thirteen : "",
            quantity_thirteen: processData && processData.quantity_thirteen ? processData.quantity_thirteen : "",
            original_date_of_aquisition_thirteen: processData && processData.original_date_of_aquisition_thirteen && processData.original_date_of_aquisition_thirteen != '' ? new Date(moment(processData.original_date_of_aquisition_thirteen)) : "",
            original_per_share_rate_of_aquisition_thirteen: processData && processData.original_per_share_rate_of_aquisition_thirteen ? processData.original_per_share_rate_of_aquisition_thirteen : "",

            name_of_security_fourteen: processData && processData.name_of_security_fourteen ? processData.name_of_security_fourteen : "",
            isin_code_fourteen: processData && processData.isin_code_fourteen ? processData.isin_code_fourteen : "",
            quantity_fourteen: processData && processData.quantity_fourteen ? processData.quantity_fourteen : "",
            original_date_of_aquisition_fourteen: processData && processData.original_date_of_aquisition_fourteen && processData.original_date_of_aquisition_fourteen != '' ? new Date(moment(processData.original_date_of_aquisition_fourteen)) : "",
            original_per_share_rate_of_aquisition_fourteen: processData && processData.original_per_share_rate_of_aquisition_fourteen ? processData.original_per_share_rate_of_aquisition_fourteen : "",

            name_of_security_fifteen: processData && processData.name_of_security_fifteen ? processData.name_of_security_fifteen : "",
            isin_code_fifteen: processData && processData.isin_code_fifteen ? processData.isin_code_fifteen : "",
            quantity_fifteen: processData && processData.quantity_fifteen ? processData.quantity_fifteen : "",
            original_date_of_aquisition_fifteen: processData && processData.original_date_of_aquisition_fifteen && processData.original_date_of_aquisition_fifteen != '' ? new Date(moment(processData.original_date_of_aquisition_fifteen)) : "",
            original_per_share_rate_of_aquisition_fifteen: processData && processData.original_per_share_rate_of_aquisition_fifteen ? processData.original_per_share_rate_of_aquisition_fifteen : "",

            name_of_security_sixteen: processData && processData.name_of_security_sixteen ? processData.name_of_security_sixteen : "",
            isin_code_sixteen: processData && processData.isin_code_sixteen ? processData.isin_code_sixteen : "",
            quantity_sixteen: processData && processData.quantity_sixteen ? processData.quantity_sixteen : "",
            original_date_of_aquisition_sixteen: processData && processData.original_date_of_aquisition_sixteen && processData.original_date_of_aquisition_sixteen != '' ? new Date(moment(processData.original_date_of_aquisition_sixteen)) : "",
            original_per_share_rate_of_aquisition_sixteen: processData && processData.original_per_share_rate_of_aquisition_sixteen ? processData.original_per_share_rate_of_aquisition_sixteen : "",

            name_of_security_seventeen: processData && processData.name_of_security_seventeen ? processData.name_of_security_seventeen : "",
            isin_code_seventeen: processData && processData.isin_code_seventeen ? processData.isin_code_seventeen : "",
            quantity_seventeen: processData && processData.quantity_seventeen ? processData.quantity_seventeen : "",
            original_date_of_aquisition_seventeen: processData && processData.original_date_of_aquisition_seventeen && processData.original_date_of_aquisition_seventeen != '' ? new Date(moment(processData.original_date_of_aquisition_seventeen)) : "",
            original_per_share_rate_of_aquisition_seventeen: processData && processData.original_per_share_rate_of_aquisition_seventeen ? processData.original_per_share_rate_of_aquisition_seventeen : "",

            name_of_security_eighteen: processData && processData.name_of_security_eighteen ? processData.name_of_security_eighteen : "",
            isin_code_eighteen: processData && processData.isin_code_eighteen ? processData.isin_code_eighteen : "",
            quantity_eighteen: processData && processData.quantity_eighteen ? processData.quantity_eighteen : "",
            original_date_of_aquisition_eighteen: processData && processData.original_date_of_aquisition_eighteen && processData.original_date_of_aquisition_eighteen != '' ? new Date(moment(processData.original_date_of_aquisition_eighteen)) : "",
            original_per_share_rate_of_aquisition_eighteen: processData && processData.original_per_share_rate_of_aquisition_eighteen ? processData.original_per_share_rate_of_aquisition_eighteen : "",

            name_of_security_ninteen: processData && processData.name_of_security_ninteen ? processData.name_of_security_ninteen : "",
            isin_code_ninteen: processData && processData.isin_code_ninteen ? processData.isin_code_ninteen : "",
            quantity_ninteen: processData && processData.quantity_ninteen ? processData.quantity_ninteen : "",
            original_date_of_aquisition_ninteen: processData && processData.original_date_of_aquisition_ninteen && processData.original_date_of_aquisition_ninteen != '' ? new Date(moment(processData.original_date_of_aquisition_ninteen)) : "",
            original_per_share_rate_of_aquisition_ninteen: processData && processData.original_per_share_rate_of_aquisition_ninteen ? processData.original_per_share_rate_of_aquisition_ninteen : "",

            name_of_security_twenty: processData && processData.name_of_security_twenty ? processData.name_of_security_twenty : "",
            isin_code_twenty: processData && processData.isin_code_twenty ? processData.isin_code_twenty : "",
            quantity_twenty: processData && processData.quantity_twenty ? processData.quantity_twenty : "",
            original_date_of_aquisition_twenty: processData && processData.original_date_of_aquisition_twenty && processData.original_date_of_aquisition_twenty != '' ? new Date(moment(processData.original_date_of_aquisition_twenty)) : "",
            original_per_share_rate_of_aquisition_twenty: processData && processData.original_per_share_rate_of_aquisition_twenty ? processData.original_per_share_rate_of_aquisition_twenty : "",

            name_of_security_tone: processData && processData.name_of_security_tone ? processData.name_of_security_tone : "",
            isin_code_tone: processData && processData.isin_code_tone ? processData.isin_code_tone : "",
            quantity_tone: processData && processData.quantity_tone ? processData.quantity_tone : "",
            original_date_of_aquisition_tone: processData && processData.original_date_of_aquisition_tone && processData.original_date_of_aquisition_tone != '' ? new Date(moment(processData.original_date_of_aquisition_tone)) : "",
            original_per_share_rate_of_aquisition_tone: processData && processData.original_per_share_rate_of_aquisition_tone ? processData.original_per_share_rate_of_aquisition_tone : "",

            name_of_security_ttwo: processData && processData.name_of_security_ttwo ? processData.name_of_security_ttwo : "",
            isin_code_ttwo: processData && processData.isin_code_ttwo ? processData.isin_code_ttwo : "",
            quantity_ttwo: processData && processData.quantity_ttwo ? processData.quantity_ttwo : "",
            original_date_of_aquisition_ttwo: processData && processData.original_date_of_aquisition_ttwo && processData.original_date_of_aquisition_ttwo != '' ? new Date(moment(processData.original_date_of_aquisition_ttwo)) : "",
            original_per_share_rate_of_aquisition_ttwo: processData && processData.original_per_share_rate_of_aquisition_ttwo ? processData.original_per_share_rate_of_aquisition_ttwo : "",

            name_of_security_tthree: processData && processData.name_of_security_tthree ? processData.name_of_security_tthree : "",
            isin_code_tthree: processData && processData.isin_code_tthree ? processData.isin_code_tthree : "",
            quantity_tthree: processData && processData.quantity_tthree ? processData.quantity_tthree : "",
            original_date_of_aquisition_tthree: processData && processData.original_date_of_aquisition_tthree && processData.original_date_of_aquisition_tthree != '' ? new Date(moment(processData.original_date_of_aquisition_tthree)) : "",
            original_per_share_rate_of_aquisition_tthree: processData && processData.original_per_share_rate_of_aquisition_tthree ? processData.original_per_share_rate_of_aquisition_tthree : "",

            name_of_security_tfour: processData && processData.name_of_security_tfour ? processData.name_of_security_tfour : "",
            isin_code_tfour: processData && processData.isin_code_tfour ? processData.isin_code_tfour : "",
            quantity_tfour: processData && processData.quantity_tfour ? processData.quantity_tfour : "",
            original_date_of_aquisition_tfour: processData && processData.original_date_of_aquisition_tfour && processData.original_date_of_aquisition_tfour != '' ? new Date(moment(processData.original_date_of_aquisition_tfour)) : "",
            original_per_share_rate_of_aquisition_tfour: processData && processData.original_per_share_rate_of_aquisition_tfour ? processData.original_per_share_rate_of_aquisition_tfour : "",

            name_of_security_tfive: processData && processData.name_of_security_tfive ? processData.name_of_security_tfive : "",
            isin_code_tfive: processData && processData.isin_code_tfive ? processData.isin_code_tfive : "",
            quantity_tfive: processData && processData.quantity_tfive ? processData.quantity_tfive : "",
            original_date_of_aquisition_tfive: processData && processData.original_date_of_aquisition_tfive && processData.original_date_of_aquisition_tfive != '' ? new Date(moment(processData.original_date_of_aquisition_tfive)) : "",
            original_per_share_rate_of_aquisition_tfive: processData && processData.original_per_share_rate_of_aquisition_tfive ? processData.original_per_share_rate_of_aquisition_tfive : "",

            fee_structure_plan : processData && processData.fee_structure_plan ? processData.fee_structure_plan : "",
            sr_no_body_corporate_one : processData && processData.sr_no_body_corporate_one ? processData.sr_no_body_corporate_one : "",
            name_of_body_corporate_one : processData && processData.name_of_body_corporate_one ? processData.name_of_body_corporate_one : "",
            nature_of_interest_one : processData && processData.nature_of_interest_one ? processData.nature_of_interest_one : "",
            sr_no_body_corporate_two : processData && processData.sr_no_body_corporate_two ? processData.sr_no_body_corporate_two : "",
            name_of_body_corporate_two : processData && processData.name_of_body_corporate_two ? processData.name_of_body_corporate_two : "",
            nature_of_interest_two : processData && processData.nature_of_interest_two ? processData.nature_of_interest_two : "",
            sr_no_body_corporate_three : processData && processData.sr_no_body_corporate_three ? processData.sr_no_body_corporate_three : "",
            name_of_body_corporate_three : processData && processData.name_of_body_corporate_three ? processData.name_of_body_corporate_three : "",
            nature_of_interest_three : processData && processData.nature_of_interest_three ? processData.nature_of_interest_three : "",
            sr_no_company_one : processData && processData.sr_no_company_one ? processData.sr_no_company_one : "",
            name_of_company_one : processData && processData.name_of_company_one ? processData.name_of_company_one : "",
            sr_no_company_two : processData && processData.sr_no_company_two ? processData.sr_no_company_two : "",
            name_of_company_two : processData && processData.name_of_company_two ? processData.name_of_company_two : "",
            sr_no_company_three : processData && processData.sr_no_company_three ? processData.sr_no_company_three : "",
            name_of_company_three : processData && processData.name_of_company_three ? processData.name_of_company_three : "",
            willingness_to_take_financial_risk : processData && processData.willingness_to_take_financial_risk ? processData.willingness_to_take_financial_risk : "",
            adapt_things_go_wrong : processData && processData.adapt_things_go_wrong ? processData.adapt_things_go_wrong : "",
            risky_investment : processData && processData.risky_investment ? processData.risky_investment : "",
            major_financial_decision : processData && processData.major_financial_decision ? processData.major_financial_decision : "",
            feel_about_major_financial_decision : processData && processData.feel_about_major_financial_decision ? processData.feel_about_major_financial_decision : "",
            financial_decision_in_past : processData && processData.financial_decision_in_past ? processData.financial_decision_in_past : "",
            currently_financial_decision : processData && processData.currently_financial_decision ? processData.currently_financial_decision : "",
            investment_in_portfolio : processData && processData.investment_in_portfolio ? processData.investment_in_portfolio : "",
            chance_of_loss : processData && processData.chance_of_loss ? processData.chance_of_loss : "",
            risk_above_average : processData && processData.risk_above_average ? processData.risk_above_average : "",


            non_individual_type : processData && processData.non_individual_type ? processData.non_individual_type : "",
            applicant_name : processData && processData.applicant_name ? processData.applicant_name : "",
            incorporation_date : processData && processData.incorporation_date && processData.incorporation_date != '' ? new Date(moment(processData.incorporation_date)) : "",
            incorporation_place : processData && processData.incorporation_place ? processData.incorporation_place : "",
            date_of_commencement_of_business : processData && processData.date_of_commencement_of_business && processData.date_of_commencement_of_business != '' ? new Date(moment(processData.date_of_commencement_of_business)) : "",
            registeration_no : processData && processData.registeration_no ? processData.registeration_no : "",
            risk_tolerance : processData && processData.risk_tolerance ? processData.risk_tolerance : "",
            onBoardingStatus : processData && processData.status ? processData.status : "",
          })
          if(!_.isEmpty(this.state.name_of_security_second)){
            this.setState({
              s2:"",
              b1:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_third)){
            this.setState({
              s3:"",
              b2:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fourth)){
            this.setState({
              s4:"",
              b3:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fifth)){
            this.setState({
              s5:"",
              b4:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_sixth)){
            this.setState({
              s6:"",
              b5:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_seventh)){
            this.setState({
              s7:"",
              b6:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_eighth)){
            this.setState({
              s8:"",
              b7:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ninth)){
            this.setState({
              s9:"",
              b8:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ten)){
            this.setState({
              s10:"",
              b9:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_eleven)){
            this.setState({
              s11:"",
              b10:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_twelve)){
            this.setState({
              s12:"",
              b11:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_thirteen)){
            this.setState({
              s13:"",
              b12:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fourteen)){
            this.setState({
              s14:"",
              b13:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fifteen)){
            this.setState({
              s15:"",
              b14:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_sixteen)){
            this.setState({
              s16:"",
              b15:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_seventeen)){
            this.setState({
              s17:"",
              b16:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_eighteen)){
            this.setState({
              s18:"",
              b17:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ninteen)){
            this.setState({
              s19:"",
              b18:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_twenty)){
            this.setState({
              s20:"",
              b19:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tone)){
            this.setState({
              s21:"",
              b20:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ttwo)){
            this.setState({
              s22:"",
              b21:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tthree)){
            this.setState({
              s23:"",
              b22:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tfour)){
            this.setState({
              s24:"",
              b23:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tfive)){
            this.setState({
              s25:"",
              b24:"shide"
            })
          }
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  products() {
    if (!_.isEmpty(this.state.products)) {
      return this.state.products.map((make, index) => {
        return <option key={index} id={index} value={make._id}>{make.title}</option>
      })
    }
  }

  notSameAddressAsInvestor(){
    return <div className="">
        <div className="form-group">
          <TextInput value={this.state.nominee_addressnominee_address} onChange={this.handleChange} name="nominee_address" id="nominee_address" 
          placeholder={"Address of Nominee"} />
        </div>
      </div>
  }

  isNominee(){
    return <div>
      <h6 className="title mt-3 text-left">Please fill the below details  </h6>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <TextInput value={this.state.nominee_name} onChange={this.handleChange} name="nominee_name" id="nominee_name" placeholder={"Name of the nominee"} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <TextInput value={this.state.nominee_relationship} onChange={this.handleChange} name="nominee_relationship" id="nominee_relationship" placeholder={"Relationship with Nominee"} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <TextInput value={this.state.nominee_pan_no} onChange={this.handleChange} name="nominee_pan_no" id="nominee_pan_no" placeholder={"Pan No of Nominee"} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
          {/* <TextInput value={this.state.nominee_dob} onChange={this.handleChange} name="nominee_dob" id="nominee_dob" placeholder={"Date of Birth of Nominee"} /> */}

          <DatePicker
              selected={this.state.nominee_dob}
              name="nominee_dob"
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeNomineeDate}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              placeholderText="Date of Birth of Nominee"
              /*isClearable*/
              dateFormat="yyyy-MM-dd"
            />

          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <TextInput value={this.state.nominee_contact_no} onChange={this.handleChange} name="nominee_contact_no" id="nominee_contact_no" 
            placeholder={"Contact No of Nominee"}
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Contact number of nominee must be numeric."
            }}  />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">    
          <h6 className="title mt-3 text-left">Address of Nominee </h6>
          <h6 className="title mt-3 text-left">Same as investor Address </h6>
          <div className="row">
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="same_address_as_investor" id="same_address_yes" value="Yes" checked={this.state.same_address_as_investor == "Yes" ? true : false} />
              <label className="form-check-label">
                Yes
              </label>
            </div>
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="same_address_as_investor" id="same_address_no" value="No" checked={this.state.same_address_as_investor == "No" ? true : false} />
              <label className="form-check-label">
                No
              </label>
            </div>
          </div>
          {this.state.same_address_as_investor == 'No' ? this.notSameAddressAsInvestor() : ""}
        </div>
      </div>
      <div className="row">
        <h6 className="title mt-3 text-left">If Nominee is a minor, details of guardian </h6>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <TextInput value={this.state.nominee_guardian_name} onChange={this.handleChange} name="nominee_guardian_name" id="nominee_guardian_name" 
                placeholder={"Name of Guardian"} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <TextInput value={this.state.nominee_guardian_contact} onChange={this.handleChange} name="nominee_guardian_contact" 
                id="nominee_guardian_contact" placeholder={"Contact No of Guardian"}
                validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                errorMessage={{
                  validator: "Contact number of guardian must be numeric."
                }} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <TextInput value={this.state.nominee_guardian_address} onChange={this.handleChange} name="nominee_guardian_address" 
                id="nominee_guardian_address" placeholder={"Address of Guardian"} />
              </div>
            </div>
          </div>

      </div>

    </div>
  }

  securityDetailsIndividual(){
    return <tbody>
      <tr>
        <td>
          <TextInput type="text" value={this.state.name_of_security_first} onChange={this.handleChange} 
            name="name_of_security_first" id="name_of_security_first" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_first} onChange={this.handleChange} 
            name="isin_code_first" id="isin_code_first" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_first} onChange={this.handleChange} 
            name="quantity_first" id="quantity_first" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_first}
              name="original_date_of_aquisition_first"
              required={this.state.name_of_security_first != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate1}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_first} onChange={this.handleChange} required={this.state.name_of_security_first != "" ? true : false}
            name="original_per_share_rate_of_aquisition_first" id="original_per_share_rate_of_aquisition_first"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} data_type="second-section" className={this.state.b1} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>

      <tr className={"second-section "+this.state.s2 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_second} onChange={this.handleChange} 
            name="name_of_security_second" id="name_of_security_second" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_second} onChange={this.handleChange} 
            name="isin_code_second" id="isin_code_second" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_second} onChange={this.handleChange} 
            name="quantity_second" id="quantity_second" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_second}
              name="original_date_of_aquisition_second"
              yearDropdownItemNumber={10}
              required={this.state.name_of_security_second != "" ? true : false}
              showYearDropdown
              onChange={this.handleChangeOriginalDate2}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_second} onChange={this.handleChange} 
            required={this.state.name_of_security_second != "" ? true : false}
            name="original_per_share_rate_of_aquisition_second" id="original_per_share_rate_of_aquisition_second"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="third-section" className={this.state.b2}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"third-section "+this.state.s3 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_third} onChange={this.handleChange} 
            name="name_of_security_third" id="name_of_security_third" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_third} onChange={this.handleChange} 
            name="isin_code_third" id="isin_code_third" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_third} onChange={this.handleChange} 
            name="quantity_third" id="quantity_third" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_third}
              name="original_date_of_aquisition_third"
              showYearDropdown
              required={this.state.name_of_security_third != "" ? true : false}
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate3}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_third} onChange={this.handleChange} 
            required={this.state.name_of_security_third != "" ? true : false}
            name="original_per_share_rate_of_aquisition_third" id="original_per_share_rate_of_aquisition_third"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fourth-section" className={this.state.b3}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fourth-section "+this.state.s4 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fourth} onChange={this.handleChange} 
            name="name_of_security_fourth" id="name_of_security_fourth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_fourth} onChange={this.handleChange} 
            name="isin_code_fourth" id="isin_code_fourth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fourth} onChange={this.handleChange} 
            name="quantity_fourth" id="quantity_fourth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_fourth}
              name="original_date_of_aquisition_fourth"
              showYearDropdown
              required={this.state.name_of_security_fourth != "" ? true : false}
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate4}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_fourth} onChange={this.handleChange} 
            required={this.state.name_of_security_fourth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_fourth" id="original_per_share_rate_of_aquisition_fourth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fifth-section" className={this.state.b4}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fifth-section "+this.state.s5 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fifth} onChange={this.handleChange} 
            name="name_of_security_fifth" id="name_of_security_fifth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_fifth} onChange={this.handleChange} 
            name="isin_code_fifth" id="isin_code_fifth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fifth} onChange={this.handleChange} 
            name="quantity_fifth" id="quantity_fifth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_fifth}
              name="original_date_of_aquisition_fifth"
              showYearDropdown
              required={this.state.name_of_security_fifth != "" ? true : false}
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate5}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_fifth} onChange={this.handleChange} 
            required={this.state.name_of_security_fifth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_fifth" id="original_per_share_rate_of_aquisition_fifth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="sixth-section" className={this.state.b5}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"sixth-section "+this.state.s6 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_sixth} onChange={this.handleChange} 
            name="name_of_security_sixth" id="name_of_security_sixth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_sixth} onChange={this.handleChange} 
            name="isin_code_sixth" id="isin_code_sixth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_sixth} onChange={this.handleChange} 
            name="quantity_sixth" id="quantity_sixth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_sixth}
              name="original_date_of_aquisition_sixth"
              required={this.state.name_of_security_sixth != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate6}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_sixth} onChange={this.handleChange} 
            required={this.state.name_of_security_sixth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_sixth" id="original_per_share_rate_of_aquisition_sixth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="seventh-section" className={this.state.b6}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"seventh-section "+this.state.s7 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_seventh} onChange={this.handleChange} 
            name="name_of_security_seventh" id="name_of_security_seventh" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_seventh} onChange={this.handleChange} 
            name="isin_code_seventh" id="isin_code_seventh" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_seventh} onChange={this.handleChange} 
            name="quantity_seventh" id="quantity_seventh" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_seventh}
              name="original_date_of_aquisition_seventh"
              required={this.state.name_of_security_seventh != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate7}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_seventh} onChange={this.handleChange} 
            required={this.state.name_of_security_seventh != "" ? true : false}
            name="original_per_share_rate_of_aquisition_seventh" id="original_per_share_rate_of_aquisition_seventh"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="eighth-section" className={this.state.b7}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"eighth-section "+this.state.s8 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_eighth} onChange={this.handleChange} 
            name="name_of_security_eighth" id="name_of_security_eighth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_eighth} onChange={this.handleChange} 
            name="isin_code_eighth" id="isin_code_eighth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_eighth} onChange={this.handleChange} 
            name="quantity_eighth" id="quantity_eighth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_eighth}
              name="original_date_of_aquisition_eighth"
              required={this.state.name_of_security_eighth != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate8}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_eighth} onChange={this.handleChange} 
            required={this.state.name_of_security_eighth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_eighth" id="original_per_share_rate_of_aquisition_eighth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ninth-section" className={this.state.b8}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ninth-section "+this.state.s9 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ninth} onChange={this.handleChange} 
            name="name_of_security_ninth" id="name_of_security_ninth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_ninth} onChange={this.handleChange} 
            name="isin_code_ninth" id="isin_code_ninth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ninth} onChange={this.handleChange} 
            name="quantity_ninth" id="quantity_ninth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_ninth}
              name="original_date_of_aquisition_ninth"
              required={this.state.name_of_security_ninth != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate9}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_ninth} onChange={this.handleChange} 
            required={this.state.name_of_security_ninth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_ninth" id="original_per_share_rate_of_aquisition_ninth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ten-section" className={this.state.b9}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ten-section "+this.state.s10 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ten} onChange={this.handleChange} 
            name="name_of_security_ten" id="name_of_security_ten" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_ten} onChange={this.handleChange} 
            name="isin_code_ten" id="isin_code_ten" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ten} onChange={this.handleChange} 
            name="quantity_ten" id="quantity_ten" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_ten}
              name="original_date_of_aquisition_ten"
              required={this.state.name_of_security_ten != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate10}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_ten} onChange={this.handleChange} 
            required={this.state.name_of_security_ten != "" ? true : false}
            name="original_per_share_rate_of_aquisition_ten" id="original_per_share_rate_of_aquisition_ten"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="eleven-section" className={this.state.b10}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"eleven-section "+this.state.s11 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_eleven} onChange={this.handleChange} 
            name="name_of_security_eleven" id="name_of_security_eleven" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_eleven} onChange={this.handleChange} 
            name="isin_code_eleven" id="isin_code_eleven" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_eleven} onChange={this.handleChange} 
            name="quantity_eleven" id="quantity_eleven" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_eleven}
              name="original_date_of_aquisition_eleven"
              required={this.state.name_of_security_eleven != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate11}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_eleven} onChange={this.handleChange} 
            required={this.state.name_of_security_eleven != "" ? true : false}
            name="original_per_share_rate_of_aquisition_eleven" id="original_per_share_rate_of_aquisition_eleven"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="twelve-section" className={this.state.b11}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"twelve-section "+this.state.s12 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_twelve} onChange={this.handleChange} 
            name="name_of_security_twelve" id="name_of_security_twelve" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_twelve} onChange={this.handleChange} 
            name="isin_code_twelve" id="isin_code_twelve" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_twelve} onChange={this.handleChange} 
            name="quantity_twelve" id="quantity_twelve" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_twelve}
              name="original_date_of_aquisition_twelve"
              required={this.state.name_of_security_twelve != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate12}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_twelve} onChange={this.handleChange} 
            required={this.state.name_of_security_twelve != "" ? true : false}
            name="original_per_share_rate_of_aquisition_twelve" id="original_per_share_rate_of_aquisition_twelve"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="thirteen-section" className={this.state.b12}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"thirteen-section "+this.state.s13 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_thirteen} onChange={this.handleChange} 
            name="name_of_security_thirteen" id="name_of_security_thirteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_thirteen} onChange={this.handleChange} 
            name="isin_code_thirteen" id="isin_code_thirteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_thirteen} onChange={this.handleChange} 
            name="quantity_thirteen" id="quantity_thirteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_thirteen}
              name="original_date_of_aquisition_thirteen"
              required={this.state.name_of_security_thirteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate13}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_thirteen} onChange={this.handleChange} 
            required={this.state.name_of_security_thirteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_thirteen" id="original_per_share_rate_of_aquisition_thirteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fourteen-section" className={this.state.b13}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fourteen-section "+this.state.s14 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fourteen} onChange={this.handleChange} 
            name="name_of_security_fourteen" id="name_of_security_fourteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_fourteen} onChange={this.handleChange} 
            name="isin_code_fourteen" id="isin_code_fourteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fourteen} onChange={this.handleChange} 
            name="quantity_fourteen" id="quantity_fourteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_fourteen}
              name="original_date_of_aquisition_fourteen"
              required={this.state.name_of_security_fourteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate14}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_fourteen} onChange={this.handleChange} 
            required={this.state.name_of_security_fourteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_fourteen" id="original_per_share_rate_of_aquisition_fourteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fifteen-section" className={this.state.b14}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fifteen-section "+this.state.s15 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fifteen} onChange={this.handleChange} 
            name="name_of_security_fifteen" id="name_of_security_fifteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_fifteen} onChange={this.handleChange} 
            name="isin_code_fifteen" id="isin_code_fifteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fifteen} onChange={this.handleChange} 
            name="quantity_fifteen" id="quantity_fifteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_fifteen}
              name="original_date_of_aquisition_fifteen"
              required={this.state.name_of_security_fifteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate15}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_fifteen} onChange={this.handleChange} 
            required={this.state.name_of_security_fifteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_fifteen" id="original_per_share_rate_of_aquisition_fifteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="sixteen-section" className={this.state.b15}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"sixteen-section "+this.state.s16 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_sixteen} onChange={this.handleChange} 
            name="name_of_security_sixteen" id="name_of_security_sixteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_sixteen} onChange={this.handleChange} 
            name="isin_code_sixteen" id="isin_code_sixteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_sixteen} onChange={this.handleChange} 
            name="quantity_sixteen" id="quantity_sixteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_sixteen}
              name="original_date_of_aquisition_sixteen"
              required={this.state.name_of_security_sixteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate16}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_sixteen} onChange={this.handleChange} 
            required={this.state.name_of_security_sixteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_sixteen" id="original_per_share_rate_of_aquisition_sixteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="seventeen-section" className={this.state.b16}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"seventeen-section "+this.state.s17 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_seventeen} onChange={this.handleChange} 
            name="name_of_security_seventeen" id="name_of_security_seventeen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_seventeen} onChange={this.handleChange} 
            name="isin_code_seventeen" id="isin_code_seventeen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_seventeen} onChange={this.handleChange} 
            name="quantity_seventeen" id="quantity_seventeen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_seventeen}
              name="original_date_of_aquisition_seventeen"
              required={this.state.name_of_security_seventeen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate17}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_seventeen} onChange={this.handleChange} 
            required={this.state.name_of_security_seventeen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_seventeen" id="original_per_share_rate_of_aquisition_seventeen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="eighteen-section" className={this.state.b17}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"eighteen-section "+this.state.s18 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_eighteen} onChange={this.handleChange} 
            name="name_of_security_eighteen" id="name_of_security_eighteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_eighteen} onChange={this.handleChange} 
            name="isin_code_eighteen" id="isin_code_eighteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_eighteen} onChange={this.handleChange} 
            name="quantity_eighteen" id="quantity_eighteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_eighteen}
              name="original_date_of_aquisition_eighteen"
              required={this.state.name_of_security_eighteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate18}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_eighteen} onChange={this.handleChange} 
            required={this.state.name_of_security_eighteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_eighteen" id="original_per_share_rate_of_aquisition_eighteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ninteen-section" className={this.state.b18}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ninteen-section "+this.state.s19 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ninteen} onChange={this.handleChange} 
            name="name_of_security_ninteen" id="name_of_security_ninteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_ninteen} onChange={this.handleChange} 
            name="isin_code_ninteen" id="isin_code_ninteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ninteen} onChange={this.handleChange} 
            name="quantity_ninteen" id="quantity_ninteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_ninteen}
              name="original_date_of_aquisition_ninteen"
              required={this.state.name_of_security_ninteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate19}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_ninteen} onChange={this.handleChange} 
            required={this.state.name_of_security_ninteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_ninteen" id="original_per_share_rate_of_aquisition_ninteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="twenty-section" className={this.state.b19}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"twenty-section "+this.state.s20 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_twenty} onChange={this.handleChange} 
            name="name_of_security_twenty" id="name_of_security_twenty" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_twenty} onChange={this.handleChange} 
            name="isin_code_twenty" id="isin_code_twenty" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_twenty} onChange={this.handleChange} 
            name="quantity_twenty" id="quantity_twenty" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_twenty}
              name="original_date_of_aquisition_twenty"
              required={this.state.name_of_security_twenty != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate20}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_twenty} onChange={this.handleChange} 
            required={this.state.name_of_security_twenty != "" ? true : false}
            name="original_per_share_rate_of_aquisition_twenty" id="original_per_share_rate_of_aquisition_twenty"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tone-section" className={this.state.b20}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tone-section "+this.state.s21 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tone} onChange={this.handleChange} 
            name="name_of_security_tone" id="name_of_security_tone" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_tone} onChange={this.handleChange} 
            name="isin_code_tone" id="isin_code_tone" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tone} onChange={this.handleChange} 
            name="quantity_tone" id="quantity_tone" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_tone}
              name="original_date_of_aquisition_tone"
              required={this.state.name_of_security_tone != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate21}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_tone} onChange={this.handleChange} 
            required={this.state.name_of_security_tone != "" ? true : false}
            name="original_per_share_rate_of_aquisition_tone" id="original_per_share_rate_of_aquisition_tone"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ttwo-section" className={this.state.b21}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ttwo-section "+this.state.s22 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ttwo} onChange={this.handleChange} 
            name="name_of_security_ttwo" id="name_of_security_ttwo" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_ttwo} onChange={this.handleChange} 
            name="isin_code_ttwo" id="isin_code_ttwo" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ttwo} onChange={this.handleChange} 
            name="quantity_ttwo" id="quantity_ttwo" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_ttwo}
              name="original_date_of_aquisition_ttwo"
              required={this.state.name_of_security_ttwo != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate22}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_ttwo} onChange={this.handleChange} 
            required={this.state.name_of_security_ttwo != "" ? true : false}
            name="original_per_share_rate_of_aquisition_ttwo" id="original_per_share_rate_of_aquisition_ttwo"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tthree-section" className={this.state.b22}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tthree-section "+this.state.s23 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tthree} onChange={this.handleChange} 
            name="name_of_security_tthree" id="name_of_security_tthree" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_tthree} onChange={this.handleChange} 
            name="isin_code_tthree" id="isin_code_tthree" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tthree} onChange={this.handleChange} 
            name="quantity_tthree" id="quantity_tthree" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_tthree}
              name="original_date_of_aquisition_tthree"
              required={this.state.name_of_security_tthree != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate23}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_tthree} onChange={this.handleChange} 
            required={this.state.name_of_security_tthree != "" ? true : false}
            name="original_per_share_rate_of_aquisition_tthree" id="original_per_share_rate_of_aquisition_tthree"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tfour-section" className={this.state.b23}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tfour-section "+this.state.s24 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tfour} onChange={this.handleChange} 
            name="name_of_security_tfour" id="name_of_security_tfour" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_tfour} onChange={this.handleChange} 
            name="isin_code_tfour" id="isin_code_tfour" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tfour} onChange={this.handleChange} 
            name="quantity_tfour" id="quantity_tfour" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_tfour}
              name="original_date_of_aquisition_tfour"
              required={this.state.name_of_security_tfour != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate24}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_tfour} onChange={this.handleChange} 
            required={this.state.name_of_security_tfour != "" ? true : false}
            name="original_per_share_rate_of_aquisition_tfour" id="original_per_share_rate_of_aquisition_tfour"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tfive-section" className={this.state.b24}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tfive-section "+this.state.s25 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tfive} onChange={this.handleChange} 
            name="name_of_security_tfive" id="name_of_security_tfive" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_tfive} onChange={this.handleChange} 
            name="isin_code_tfive" id="isin_code_tfive" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tfive} onChange={this.handleChange} 
            name="quantity_tfive" id="quantity_tfive" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_tfive}
              name="original_date_of_aquisition_tfive"
              required={this.state.name_of_security_tfive != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate25}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_tfive} onChange={this.handleChange} 
            required={this.state.name_of_security_tfive != "" ? true : false}
            name="original_per_share_rate_of_aquisition_tfive" id="original_per_share_rate_of_aquisition_tfive"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td></td>
      </tr>
    </tbody>
  }

  financial_details(){
    return <div>
      <h6 className="title text-left m-0">1. Annual Income for the last 3 Financial Years </h6>
      <h6 className="title mb-3 text-left">(Income Range per annum -please choose the suitable option) </h6>
      <div className="row">
        <div className="col-lg-6 text-left mb-3">
          <h6 className="title text-left">1st FY</h6>

          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" onChange={this.handleChange} name="finance_first_year" id="ffy1" value="Below-Rs-1-Lakh" checked={this.state.finance_first_year == "Below-Rs-1-Lakh" ? true : false} />
            <label className="form-check-label">
              Below Rs 1 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" onChange={this.handleChange} type="radio" name="finance_first_year" id="ffy2" value="1-5-Lakh" checked={this.state.finance_first_year == "1-5-Lakh" ? true : false} />
            <label className="form-check-label">
              1-5 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" onChange={this.handleChange} type="radio" name="finance_first_year" id="ffy3" value="5-10-Lakh" checked={this.state.finance_first_year == "5-10-Lakh" ? true : false} />
            <label className="form-check-label">
              5-10 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" onChange={this.handleChange} type="radio" name="finance_first_year" id="ffy4" value="10-25-Lakh" checked={this.state.finance_first_year == "10-25-Lakh" ? true : false} />
            <label className="form-check-label">
              10-25 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" onChange={this.handleChange} type="radio" name="finance_first_year" id="ffy5" value="Above-25-Lakh" checked={this.state.finance_first_year == "Above-25-Lakh" ? true : false} />
            <label className="form-check-label">
              Above 25 Lakh
            </label>
          </div>

        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-3 text-left">
          <h6 className="title text-left">2nd FY</h6>

          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_second_year" id="sfy1" onChange={this.handleChange} value="Below-Rs-1-Lakh" checked={this.state.finance_second_year == "Below-Rs-1-Lakh" ? true : false} />
            <label className="form-check-label">
              Below Rs 1 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_second_year" id="sfy2" onChange={this.handleChange} value="1-5-Lakh" checked={this.state.finance_second_year == "1-5-Lakh" ? true : false} />
            <label className="form-check-label">
              1-5 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_second_year" id="sfy3" onChange={this.handleChange} value="5-10-Lakh" checked={this.state.finance_second_year == "5-10-Lakh" ? true : false} />
            <label className="form-check-label">
              5-10 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_second_year" id="sfy4" onChange={this.handleChange} value="10-25-Lakh" checked={this.state.finance_second_year == "10-25-Lakh" ? true : false} />
            <label className="form-check-label">
              10-25 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_second_year" id="sfy5" onChange={this.handleChange} value="Above-25-Lakh" checked={this.state.finance_second_year == "Above-25-Lakh" ? true : false} />
            <label className="form-check-label">
              Above 25 Lakh
            </label>
          </div>

        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mb-3 text-left">
          <h6 className="title text-left">3rd FY</h6>

          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_third_year" id="tfy1" onChange={this.handleChange} value="Below-Rs-1-Lakh" checked={this.state.finance_third_year == "Below-Rs-1-Lakh" ? true : false} />
            <label className="form-check-label">
              Below Rs 1 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_third_year" id="tfy2" onChange={this.handleChange} value="1-5-Lakh" checked={this.state.finance_third_year == "1-5-Lakh" ? true : false} />
            <label className="form-check-label">
              1-5 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_third_year" id="tfy3" onChange={this.handleChange} value="5-10-Lakh" checked={this.state.finance_third_year == "5-10-Lakh" ? true : false} />
            <label className="form-check-label">
              5-10 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_third_year" id="tfy4" onChange={this.handleChange} value="10-25-Lakh" checked={this.state.finance_third_year == "10-25-Lakh" ? true : false} />
            <label className="form-check-label">
              10-25 Lakh
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input financial-radio" type="radio" name="finance_third_year" id="tfy5" onChange={this.handleChange} value="Above-25-Lakh" checked={this.state.finance_third_year == "Above-25-Lakh" ? true : false} />
            <label className="form-check-label">
              Above 25 Lakh
            </label>
          </div>

        </div>
      </div>

      <h6 className="title text-left">2. Net Worth Till Date </h6>
      <div className="row">
        <div className="col-12 text-left">
          <h6 className="title mb-3 text-left">1st FY</h6>
            <div className="row">
              <div className="col-sm-2 text-left">As on</div>
              <div className="col-sm-5 text-left">
              <FormGroup className="datepic">
                  <DatePicker
                    selected={this.state.firsttillDate}
                    name="firsttillDate"
                    showYearDropdown
                    yearDropdownItemNumber={10}
                    onChange={this.handleChangeDate1}
                    disabledKeyboardNavigation
                    maxDate={this.state.max_date}
                    className="form-control"
                    selectsStart
                    placeholderText="Date"
                    /*isClearable*/
                    dateFormat="yyyy-MM-dd"
                  />
                </FormGroup>
              </div>
              <div className="col-sm-5 text-left">
                <div className="form-group">
                  <TextInput
                    name="first_net_worth_inr" id="first_net_worth_inr" placeholder={"INR"}
                    onChange={this.handleChange}
                    value={this.state.first_net_worth_inr}
                    validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                    errorMessage={{
                      validator: "First net worth must be numeric."
                    }} 
                  />
                </div>
              </div>
            </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-left">
          <h6 className="title mb-3 text-left">2nd FY</h6>
            <div className="row">
              <div className="col-sm-2 text-left">As on</div>
              <div className="col-sm-5 text-left">
              <FormGroup className="datepic">
                  <DatePicker
                    selected={this.state.secondTillDate}
                    name="secondTillDate"
                    showYearDropdown
                    yearDropdownItemNumber={10}
                    onChange={this.handleChangeDate2}
                    disabledKeyboardNavigation
                    maxDate={this.state.max_date}
                    className="form-control"
                    selectsStart
                    placeholderText="Date"
                    /*isClearable*/
                    dateFormat="yyyy-MM-dd"
                    errorMessage= {{ required: "Please enter password."}}
                  />
                </FormGroup>
              </div>
              <div className="col-sm-5 text-left">
                <div className="form-group">
                  <TextInput
                    name="second_net_worth_inr" id="second_net_worth_inr" placeholder={"INR"}
                    onChange={this.handleChange}
                    value={this.state.second_net_worth_inr} 
                    validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                    errorMessage={{
                      validator: "Second net worth must be numeric."
                    }}
                  />
                </div>
              </div>
            </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-left">
          <h6 className="title mb-3 text-left">3rd FY</h6>
            <div className="row">
              <div className="col-sm-2 text-left">As on</div>
              <div className="col-sm-5 text-left">
              <FormGroup className="datepic"> 
                  <DatePicker
                    selected={this.state.thirdTillDate}
                    name="thirdTillDate" 
                    showYearDropdown
                    yearDropdownItemNumber={10}
                    onChange={this.handleChangeDate3}
                    disabledKeyboardNavigation
                    maxDate={this.state.max_date}
                    className="form-control"
                    selectsStart
                    placeholderText="Date"
                    /*isClearable*/
                    dateFormat="yyyy-MM-dd"
                  />
                </FormGroup>
              </div>
              <div className="col-sm-5 text-left">
                <div className="form-group">
                  <TextInput
                    name="third_net_worth_inr" id="third_net_worth_inr" placeholder={"INR"}
                    onChange={this.handleChange}
                    value={this.state.third_net_worth_inr} 
                    validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                    errorMessage={{
                      validator: "Third net worth must be numeric."
                    }}
                  />
                </div>
              </div>
            </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-left">
          <h6 className="title mb-3 text-left">3. Occupation</h6>
          <div class="row">
           <div className="col-lg-6">
              <div className="form-group">
                <TextInput value={this.state.occupation} onChange={this.handleChange} name="occupation" id="occupation" placeholder={"Occupation"} 
                  maxLength={200} />
              </div>
            </div>
        </div></div>
      </div>

      <div className="row">
        <div className="col-12 text-left">
          <h6 className="title mb-3 text-left">4. Please tick if applicable</h6>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                <Input type="checkbox" value="Politically_Exposed_Person" 
                  onChange={this.handleChange} 
                  name="politically_exposed_person" id="politically_exposed_person" className="exposed_person" checked={this.state.politically_exposed_person == "Politically_Exposed_Person" ? true : false}
                />{' '}
                <label className="form-check-label">Politically exposed person</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                <Input type="checkbox" value="Relative_Of_Politically_Exposed_Person" onChange={this.handleChange} 
                  name="politically_exposed_person" id="politically_exposed_person" className="exposed_person" checked={this.state.politically_exposed_person == "Relative_Of_Politically_Exposed_Person" ? true : false}
                />{' '}
                <label className="form-check-label">Relative of Politically exposed person</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                <Input type="checkbox" value="Not_Applicable" onChange={this.handleChange} 
                  name="politically_exposed_person" id="politically_exposed_person" className="exposed_person" checked={this.state.politically_exposed_person == "Not_Applicable" ? true : false}
                />{' '}
                <label className="form-check-label">Not Applicable</label>
              </div>
            </FormGroup>
          </div>

        </div>
      </div>
    </div>
  }

  investment_details(){
    return <div>
      <div className="row">
        <div className="col-12 text-left">
          <h6 className="title text-left">1. Source of Money Being Invested</h6>

          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Savings" 
                  onChange={this.handleChange} 
                  name="money_being_invested" id="money_being_invested" className="exposed_person" checked={this.state.money_being_invested == "Savings" ? true : false}
                  />{' '}
                <label className="form-check-label">Savings</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Business" onChange={this.handleChange} 
                  name="money_being_invested" id="money_being_invested" className="exposed_person" checked={this.state.money_being_invested == "Business" ? true : false}
                  />{' '}
                <label className="form-check-label">Business</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Ancestral" onChange={this.handleChange} 
                  name="money_being_invested" id="money_being_invested" className="exposed_person" checked={this.state.money_being_invested == "Ancestral" ? true : false}
                  />{' '}
                <label className="form-check-label">Ancestral/Inheritance</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Gift_by_Relative" onChange={this.handleChange} 
                  name="money_being_invested" id="money_being_invested" className="exposed_person" checked={this.state.money_being_invested == "Gift_by_Relative" ? true : false}
                  />{' '}
                <label className="form-check-label">Gift by Relative/Friend</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Other" onChange={this.handleChange} 
                  name="money_being_invested" id="money_being_investedmoney_being_invested" className="exposed_person" checked={this.state.money_being_invested == "Other" ? true : false}
                  />{' '}
                <label className="form-check-label">Other (Please Specify)</label>
              </div>
            </FormGroup>
          </div>
          {this.state.money_being_invested == "Other" ? <div className="col-lg-6">
            <FormGroup>
              <TextInput value={this.state.money_being_invested_other} onChange={this.handleChange} name="money_being_invested_other" id="money_being_invested_other" placeholder={"Please Specify"} 
                  maxLength={200} />
            </FormGroup>
          </div> : null }

        </div>
      </div>

      <div className="row">
        <div className="col-12 text-left">
          <h6 className="title text-left">2. Investment experience</h6>

          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                <Input type="checkbox" value="Less-Than-3-Years" 
                onChange={this.handleChange} 
                name="investment_experience" id="investment_experience" className="exposed_person" checked={this.state.investment_experience == "Less-Than-3-Years" ? true : false}
                />{' '}
                <label className="form-check-label">Less Than 3 Years</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="3-5-Years" onChange={this.handleChange} 
                  name="investment_experience" id="investment_experience" className="exposed_person" checked={this.state.investment_experience == "3-5-Years" ? true : false}
                  />{' '}
                <label className="form-check-label">3-5 years</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Greater-Than-5-years" onChange={this.handleChange} 
                  name="investment_experience" id="investment_experience" className="exposed_person" checked={this.state.investment_experience == "Greater-Than-5-years" ? true : false}
                  />{' '}
                <label className="form-check-label">Greater Than 5 Years</label>
              </div>
            </FormGroup>
          </div>
          

        </div>
      </div>

      <div className="row">
        <div className="col-12 text-left">
          <h6 className="title text-left">3. Investment Objective</h6>

          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Capital-Appreciation" 
                  onChange={this.handleChange} 
                  name="investment_objective" id="investment_objective" className="exposed_person" checked={this.state.investment_objective == "Capital-Appreciation" ? true : false}
                  />{' '}
                <label className="form-check-label">Capital Appreciation</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Regular-Income" onChange={this.handleChange} 
                  name="investment_objective" id="investment_objective" className="exposed_person" checked={this.state.investment_objective == "Regular-Income" ? true : false}
                  />{' '}
                <label className="form-check-label">Regular Income </label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Capital-Appreciation-Regular-Income" onChange={this.handleChange} 
                  name="investment_objective" id="investment_objective" className="exposed_person" checked={this.state.investment_objective == "Capital-Appreciation-Regular-Income" ? true : false}
                  />{' '}
                <label className="form-check-label">Capital Appreciation + Regular Income</label>
              </div>
            </FormGroup>
          </div>
          
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-left">
          <h6 className="title text-left">4. Time Horizon of Investment</h6>

          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Less-than-2-Years" 
                  onChange={this.handleChange} 
                  name="time_horizon_of_investment" id="time_horizon_of_investment" className="exposed_person" checked={this.state.time_horizon_of_investment == "Less-than-2-Years" ? true : false}
                  />{' '}
                <label className="form-check-label">Less than 2 Years</label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="2-3-Years" onChange={this.handleChange} 
                  name="time_horizon_of_investment" id="time_horizon_of_investment" className="exposed_person" checked={this.state.time_horizon_of_investment == "2-3-Years" ? true : false}
                  />{' '}
                <label className="form-check-label">2-3 Years </label>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup>
              <div className="form-check">
                  <Input type="checkbox" value="Greater-than-3-Years" onChange={this.handleChange} 
                  name="time_horizon_of_investment" id="time_horizon_of_investment" className="exposed_person" checked={this.state.time_horizon_of_investment == "Greater-than-3-Years" ? true : false}
                  />{' '}
                <label className="form-check-label">Greater than 3 Years</label>
              </div>
            </FormGroup>
          </div>
          
        </div>
      </div>
    </div>
  }

  fee_structure(){
    return <div>
     
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">Please Tick</th>
              <th scope="col">Plan</th>
              <th scope="col">Fixed Fee</th>
              <th scope="col">Performance Fee</th>
              <th scope="col">Hurdle Rate</th>
              <th scope="col">Minimum Investment</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">
                <FormGroup>
                  <div className="form-check">
                    <Input type="checkbox" value="A" onChange={this.handleChange} name="fee_structure_plan" id="fee_structure_plan" className="checkbox-left-allign" checked={this.state.fee_structure_plan == "A" ? true : false} />{' '}
                  </div>
                </FormGroup>
              </td>
              <td>A</td>
              <td>1.75%</td>
              <td>0%</td>
              <td>0</td>
              <td>50 Lakh</td>
            </tr>
            <tr>
            <td align="center">
                <FormGroup>
                  <div className="form-check">
                    <Input type="checkbox" value="B" onChange={this.handleChange} name="fee_structure_plan" id="fee_structure_plan" className="checkbox-left-allign" checked={this.state.fee_structure_plan == "B" ? true : false} />{' '}
                  </div>
                </FormGroup>
              </td>
              <td>B</td>
              <td>1.25%</td>
              <td>15%</td>
              <td>12%</td>
              <td>1 crore</td>
            </tr>
            <tr>
            <td align="center">
                <FormGroup>  
                  <div className="form-check">
                    <Input type="checkbox" value="C" onChange={this.handleChange} name="fee_structure_plan" id="fee_structure_plan" className="checkbox-left-allign" checked={this.state.fee_structure_plan == "C" ? true : false} />{' '}
                  </div>
                </FormGroup>
              </td>
              <td>C</td>
              <td>1%</td>
              <td>10%</td>
              <td>10%</td>
              <td>5 crore</td>
            </tr>
            <tr>
            <td align="center">
                <FormGroup>
                  <div className="form-check">
                    <Input type="checkbox" value="D" onChange={this.handleChange} name="fee_structure_plan" id="fee_structure_plan" className="checkbox-left-allign" checked={this.state.fee_structure_plan == "D" ? true : false} />{' '}
                  </div>
                </FormGroup>
              </td>
              <td>D</td>
              <td>0%</td>
              <td>20%</td>
              <td>8%</td>
              <td>50 Lakh</td>
            </tr>
          
          </tbody>
        </Table>
   
      <div className="text-left">
        <p>Note:  Fixed Fee would be charged on a monthly bases.</p>                   
        <p>In plan B, C & D Performance fees would be applicable at the end of 3rd year (No Catch up) and post that on yearly basis, subject to a high watermark.</p>    
        <p>Our Exit Load Structure for all the clients </p>                      
        <p>The exit load will be charged as under:   </p>                      
        <p>2% chargeable if Exit in 18 months </p>                       
        <p>1.5% chargeable if Exit Between 18 to 24 months </p>                        
        <p>0.5% if chargeable Exit Between 24 to 36 months</p>                       
        <p>Our other costs and expenses would be as actuals, not exceeding 0.5% per annum of the client’s average daily Assets under Management (AUM) excluding brokerage.</p>
      </div>
    </div>
  }

  disclosure_list(){
    return <div>
      <h6 className="title text-left">Please fill in the below disclosure requirements if any </h6>
      <h6 className="title text-left">Companies in respect of which client has access to price-sensitive information </h6>
      
    
        <Table responsive hover>
          <thead>
            <tr>
              <th width="12%" scope="col">Sr No.</th>
              <th scope="col">Name of Body Corporate</th>
              <th scope="col">Nature of Interest</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TextInput value={this.state.sr_no_body_corporate_one} onChange={this.handleChange} name="sr_no_body_corporate_one" 
                id="sr_no_body_corporate_one" placeholder={"Sr No"} />
              </td>
              <td>
              <TextInput value={this.state.name_of_body_corporate_one} onChange={this.handleChange} name="name_of_body_corporate_one" 
                id="name_of_body_corporate_one" placeholder={"Name of Body Corporate"} />
              </td>
              <td>
              <TextInput value={this.state.nature_of_interest_one} onChange={this.handleChange} name="nature_of_interest_one" 
                id="nature_of_interest_one" placeholder={"Nature of Interest"} />
              </td>
            </tr>
            <tr>
              <td>
                <TextInput value={this.state.sr_no_body_corporate_two} onChange={this.handleChange} name="sr_no_body_corporate_two" 
                id="sr_no_body_corporate_two" placeholder={"Sr No"} />
              </td>
              <td>
              <TextInput value={this.state.name_of_body_corporate_two} onChange={this.handleChange} name="name_of_body_corporate_two" 
                id="name_of_body_corporate_two" placeholder={"Name of Body Corporate"} />
              </td>
              <td>
              <TextInput value={this.state.nature_of_interest_two} onChange={this.handleChange} name="nature_of_interest_two" 
                id="nature_of_interest_two" placeholder={"Nature of Interest"} />
              </td>
            </tr>
            <tr>
              <td>
                <TextInput value={this.state.sr_no_body_corporate_three} onChange={this.handleChange} name="sr_no_body_corporate_three" 
                id="sr_no_body_corporate_three" placeholder={"Sr No"} />
              </td>
              <td>
              <TextInput value={this.state.name_of_body_corporate_three} onChange={this.handleChange} name="name_of_body_corporate_three" 
                id="name_of_body_corporate_three" placeholder={"Name of Body Corporate"} />
              </td>
              <td>
              <TextInput value={this.state.nature_of_interest_three} onChange={this.handleChange} name="nature_of_interest_three" 
                id="nature_of_interest_three" placeholder={"Nature of Interest"} />
              </td>
            </tr>
          </tbody>
        </Table>
    

      <h6 className="title text-left">Companies in respect of which client has access to price-sensitive information </h6>
      
    
        <Table responsive hover>
          <thead>
            <tr>
              <th width="12%" scope="col">Sr No.</th>
              <th scope="col">Name of the company </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TextInput value={this.state.sr_no_company_one} onChange={this.handleChange} name="sr_no_company_one" 
                id="sr_no_company_one" placeholder={"Sr No"} />
              </td>
              <td>
              <TextInput value={this.state.name_of_company_one} onChange={this.handleChange} name="name_of_company_one" 
                id="name_of_company_one" placeholder={"Name of the company "} />
              </td>
            </tr>
            <tr>
              <td>
                <TextInput value={this.state.sr_no_company_two} onChange={this.handleChange} name="sr_no_company_two" 
                id="sr_no_company_two" placeholder={"Sr No"} />
              </td>
              <td>
              <TextInput value={this.state.name_of_company_two} onChange={this.handleChange} name="name_of_company_two" 
                id="name_of_company_two" placeholder={"Name of the company "} />
              </td>
            </tr>
            <tr>
              <td>
                <TextInput value={this.state.sr_no_company_three} onChange={this.handleChange} name="sr_no_company_three" 
                id="sr_no_company_three" placeholder={"Sr No"} />
              </td>
              <td>
              <TextInput value={this.state.name_of_company_three} onChange={this.handleChange} name="name_of_company_three" 
                id="name_of_company_three" placeholder={"Name of the company "} />
              </td>
            </tr>
          </tbody>
        </Table>
    
    </div>
  }

  individual(){
    return <div>
      <div className="individual mt-3">

        <div className="background-white">
          <h5 className="title mb-3 text-left">III. Financial Detail </h5>
          {this.financial_details()}
        </div>

        <div className="mt-3 client-category">
          <h5 className="title mb-3 text-left">IV. Investment Details </h5>
          {this.investment_details()}
        </div>

        <div className="background-white">
          <h5 className="title mb-3 text-left">V. Nomination Details </h5>

          <div className="row">
            <div className="col-12 text-left">
              <h6 className="title text-left">Do you Wish to have a nominee for your PMS account </h6>
              <div className="d-flex flex-wrap">
                <div className="form-check col-lg-6">
                  <input className="form-check-input financial-radio" type="radio"
                  onChange={this.handleChange} name="is_nominee" id="is_nominee_yes" value="Yes" checked={this.state.is_nominee == "Yes" ? true : false} />
                  <label className="form-check-label">
                    Yes
                  </label>
                </div>
                <div className="form-check col-lg-6">
                  <input className="form-check-input financial-radio" type="radio"
                  onChange={this.handleChange} name="is_nominee" id="is_nominee_no" value="No"  checked={this.state.is_nominee == "No" ? true : false} />
                  <label className="form-check-label">
                    No
                  </label>
                </div>
              </div>

              {this.state.is_nominee == 'Yes' ? this.isNominee() : ""}
              
            </div>
          </div>

        </div>

        <div className="mt-3 client-category">
            <h5 className="title mb-3 text-left">VI. Please fill in the below details if you would be making an Investment by transfering securities </h5>

           
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">Name of Security</th>
                    <th scope="col">ISIN Code</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Original Date of Acquisition</th>
                    <th scope="col">Original Per Share Rate of Acquisition</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {this.securityDetailsIndividual()}
              </Table>
            
        
        </div>

        <div className="background-white">
            <h5 className="title mb-3 text-left">VII. Fee Structure 
              <span className="float-right"><a href="#" target="_blank" className="custom-button btn-sm">Fee Calculator</a></span>
            </h5>
            <h6 className="title mb-3 text-left">Please choose a suitable fee structure for your portfolio </h6>
            {this.fee_structure()}
        </div>

        <div className="mt-3 client-category">
            <h5 className="title mb-3 text-left">VIII. Disclosure List </h5>
            {this.disclosure_list()}
        </div>

        <div className="background-white">
            <h5 className="title mb-3 text-left">IX. Risk Profiling </h5>
            <h6 className="title text-left">Please choose the most appropriate option, it will help us analyze your risk appetite and suggest appropriate investments. </h6>

            <h6 className="title text-left">1. Compared to others, how do you rate your willingness to take financial risk?</h6>
            <div className="row">
              <div className="col-lg-6 text-left">
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="willingness_to_take_financial_risk" onChange={this.handleChange} id="exampleRadios1" value="Extremely-low-risk-taker" checked={this.state.willingness_to_take_financial_risk == "Extremely-low-risk-taker" ? true : false}  />
                  <label className="form-check-label">
                    1. Extremely low-risk taker.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="willingness_to_take_financial_risk" onChange={this.handleChange} id="exampleRadios2"
                   value="Very-low-risk-taker" checked={this.state.willingness_to_take_financial_risk == "Very-low-risk-taker" ? true : false}  />
                  <label className="form-check-label">
                    2. Very low-risk taker.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="willingness_to_take_financial_risk" id="exampleRadios2" onChange={this.handleChange}
                   value="Low-risk-taker" checked={this.state.willingness_to_take_financial_risk == "Low-risk-taker" ? true : false}  />
                  <label className="form-check-label">
                    3. Low-risk taker.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="willingness_to_take_financial_risk" id="exampleRadios2" onChange={this.handleChange}
                   value="Average-risk-taker" checked={this.state.willingness_to_take_financial_risk == "Average-risk-taker" ? true : false} />
                  <label className="form-check-label">
                    4. Average risk-taker.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="willingness_to_take_financial_risk" id="exampleRadios2" onChange={this.handleChange}
                   value="High-risk-taker" checked={this.state.willingness_to_take_financial_risk == "High-risk-taker" ? true : false} />
                  <label className="form-check-label">
                    5. High-risk taker.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="willingness_to_take_financial_risk" id="exampleRadios2" onChange={this.handleChange}
                   value="Very-high-risk-taker" checked={this.state.willingness_to_take_financial_risk == "Very-high-risk-taker" ? true : false} />
                  <label className="form-check-label">
                    6. Very high-risk taker.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="willingness_to_take_financial_risk" id="exampleRadios2" onChange={this.handleChange}
                   value="Extremely-high-risk-taker" checked={this.state.willingness_to_take_financial_risk == "Extremely-high-risk-taker" ? true : false} />
                  <label className="form-check-label">
                    7. Extremely high-risk taker.
                  </label>
                </div>

              </div>
            </div>

            <h6 className="title text-left mt-3">2. How easily do you adapt when things go wrong financially?</h6>
            <div className="row">
              <div className="col-lg-6 text-left">
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="adapt_things_go_wrong" id="exampleRadios1"  onChange={this.handleChange}
                  value="Very-uneasily" checked={this.state.adapt_things_go_wrong == "Very-uneasily" ? true : false}  />
                  <label className="form-check-label">
                    1. Very uneasily.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="adapt_things_go_wrong" id="exampleRadios2" onChange={this.handleChange}
                   value="Somewhat-uneasily" checked={this.state.adapt_things_go_wrong == "Somewhat-uneasily" ? true : false} />
                  <label className="form-check-label">
                  2. Somewhat uneasily.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="adapt_things_go_wrong" id="exampleRadios2" onChange={this.handleChange}
                   value="Somewhat-easily" checked={this.state.adapt_things_go_wrong == "Somewhat-easily" ? true : false} />
                  <label className="form-check-label">
                  3. Somewhat easily.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="adapt_things_go_wrong" id="exampleRadios2" onChange={this.handleChange}
                   value="Very-easily" checked={this.state.adapt_things_go_wrong == "Very-easily" ? true : false} />
                  <label className="form-check-label">
                  4. Very easily.
                  </label>
                </div>

              </div>
            </div>

            <h6 className="title text-left mt-3">3. Have you ever invested a large sum in a risky investment mainly for the "thrill" of seeing whether it went up or down in value?</h6>
            <div className="row">
              <div className="col-lg-6 text-left">
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risky_investment" id="exampleRadios1"  onChange={this.handleChange}
                  value="No" checked={this.state.risky_investment == "No" ? true : false} />
                  <label className="form-check-label">
                    1. No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risky_investment" id="exampleRadios2" onChange={this.handleChange}
                   value="Yes-very-rarely" checked={this.state.risky_investment == "Yes-very-rarely" ? true : false} />
                  <label className="form-check-label">
                  2. Yes, very rarely.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risky_investment" id="exampleRadios2" onChange={this.handleChange}
                   value="Yes-somewhat-rarely" checked={this.state.risky_investment == "Yes-somewhat-rarely" ? true : false} />
                  <label className="form-check-label">
                  3. Yes, somewhat rarely.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risky_investment" id="exampleRadios2" onChange={this.handleChange}
                   value="Yes-somewhat-frequently" checked={this.state.risky_investment == "Yes-somewhat-frequently" ? true : false} />
                  <label className="form-check-label">
                  4. Yes, somewhat frequently.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risky_investment" id="exampleRadios2" onChange={this.handleChange}
                   value="Yes-very-frequently" checked={this.state.risky_investment == "Yes-very-frequently" ? true : false} />
                  <label className="form-check-label">
                  5. Yes, very frequently.
                  </label>
                </div>

              </div>
            </div>

            <h6 className="title text-left mt-3">4. When faced with a major financial decision, are you more concerned about the possible losses or the possible gains?</h6>
            <div className="row">
              <div className="col-lg-6 text-left">
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="major_financial_decision" id="exampleRadios1"  onChange={this.handleChange}
                  value="Always-the-possible-losses" checked={this.state.major_financial_decision == "Always-the-possible-losses" ? true : false} />
                  <label className="form-check-label">
                  1. Always the possible losses.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="major_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Usually-the-possible-losses" checked={this.state.major_financial_decision == "Usually-the-possible-losses" ? true : false} />
                  <label className="form-check-label">
                  2. Usually the possible losses.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="major_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Usually-the-possible-gains" checked={this.state.major_financial_decision == "Usually-the-possible-gains" ? true : false} />
                  <label className="form-check-label">
                  3. Usually the possible gains.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="major_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Always-the-possible-gains" checked={this.state.major_financial_decision == "Always-the-possible-gains" ? true : false} />
                  <label className="form-check-label">
                  4. Always the possible gains.
                  </label>
                </div>

              </div>
            </div>

            <h6 className="title text-left mt-3">5. How do you usually feel about your major financial decisions after you make them?</h6>
            <div className="row">
              <div className="col-lg-6 text-left">
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="feel_about_major_financial_decision" id="exampleRadios1" onChange={this.handleChange} 
                  value="Very-pessimistic" checked={this.state.feel_about_major_financial_decision == "Very-pessimistic" ? true : false} />
                  <label className="form-check-label">
                  1. Very pessimistic.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="feel_about_major_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Somewhat-pessimistic" checked={this.state.feel_about_major_financial_decision == "Somewhat-pessimistic" ? true : false} />
                  <label className="form-check-label">
                  2. Somewhat pessimistic.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="feel_about_major_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Somewhat-optimistic" checked={this.state.feel_about_major_financial_decision == "Somewhat-optimistic" ? true : false} />
                  <label className="form-check-label">
                  3. Somewhat optimistic.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="feel_about_major_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Very-optimistic" checked={this.state.feel_about_major_financial_decision == "Very-optimistic" ? true : false} />
                  <label className="form-check-label">
                  4. Very optimistic.
                  </label>
                </div>

              </div>
            </div>

            <h6 className="title text-left mt-3">6. What degree of risk have you taken with your financial decisions in the past?</h6>
            <div className="row">
              <div className="col-lg-6 text-left">
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="financial_decision_in_past" id="exampleRadios1"  onChange={this.handleChange}
                  value="Very-small" checked={this.state.financial_decision_in_past == "Very-small" ? true : false} />
                  <label className="form-check-label">
                  1. Very small.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="financial_decision_in_past" id="exampleRadios2" onChange={this.handleChange}
                   value="Small" checked={this.state.financial_decision_in_past == "Small" ? true : false} />
                  <label className="form-check-label">
                  2. Small.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="financial_decision_in_past" id="exampleRadios2" onChange={this.handleChange}
                   value="Medium" checked={this.state.financial_decision_in_past == "Medium" ? true : false} />
                  <label className="form-check-label">
                  3. Medium.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="financial_decision_in_past" id="exampleRadios2" onChange={this.handleChange}
                   value="Large" checked={this.state.financial_decision_in_past == "Large" ? true : false} />
                  <label className="form-check-label">
                  4. Large.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="financial_decision_in_past" id="exampleRadios2" onChange={this.handleChange}
                   value="Very-large" checked={this.state.financial_decision_in_past == "Very-large" ? true : false} />
                  <label className="form-check-label">
                  5. Very large.
                  </label>
                </div>

              </div>
            </div>

            <h6 className="title text-left mt-3">7. What degree of risk are you currently prepared to take with your financial decisions?</h6>
            <div className="row">
              <div className="col-lg-6 text-left">
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="currently_financial_decision" id="exampleRadios1" onChange={this.handleChange} 
                  value="Very-small" checked={this.state.currently_financial_decision == "Very-small" ? true : false} />
                  <label className="form-check-label">
                  1. Very small.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="currently_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Small" checked={this.state.currently_financial_decision == "Small" ? true : false} />
                  <label className="form-check-label">
                  2. Small.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="currently_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Medium" checked={this.state.currently_financial_decision == "Medium" ? true : false} />
                  <label className="form-check-label">
                  3. Medium.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="currently_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Large" checked={this.state.currently_financial_decision == "Large" ? true : false} />
                  <label className="form-check-label">
                  4. Large.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="currently_financial_decision" id="exampleRadios2" onChange={this.handleChange}
                   value="Very-large" checked={this.state.currently_financial_decision == "Very-large" ? true : false} />
                  <label className="form-check-label">
                  5. Very large.
                  </label>
                </div>

              </div>
            </div>

            <h6 className="title text-left mt-3">When making an investment, return and risk usually go hand-in-hand. Investments that produce above-average returns are usually of above-average risk and vice versa.</h6>
            <h6 className="title text-left mt-3">Dividing risk under three parameters namely, High, medium and low risk, please answer the below questions</h6>

            <h6 className="title text-left">8. Which mix of investments do you find most appealing? </h6>
            <h6 className="title text-left mt-3">Please select one of the seven portfolios listed below.</h6>

           
              <Table responsive hover>
                <thead>
                  <tr><th colspan="4">Mix of Investment in Portfolio</th></tr>
                  <tr>
                    <th width="10%" scope="col">Portfolio</th>
                    <th scope="col">High Risk/Return</th>
                    <th scope="col">Medium Risk/Return</th>
                    <th scope="col">Low Risk/Return</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="center">
                      <Input type="checkbox" value="0-30-70" onChange={this.handleChange} 
                      name="investment_in_portfolio" id="investment_in_portfolio" className="checkbox-left-allign" checked={this.state.investment_in_portfolio == "0-30-70" ? true : false} />{' '}
                    </td>
                    <td>0%</td>
                    <td>30%</td>
                    <td>70%</td>
                  </tr>
                  <tr>
                  <td align="center">
                      <Input type="checkbox" value="10-40-50" onChange={this.handleChange} 
                      name="investment_in_portfolio" id="investment_in_portfolio" className="checkbox-left-allign" checked={this.state.investment_in_portfolio == "10-40-50" ? true : false}  />{' '}
                    </td>
                    <td>10%</td>
                    <td>40%</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                  <td align="center">
                      <Input type="checkbox" value="30-40-30" onChange={this.handleChange} 
                      name="investment_in_portfolio" id="investment_in_portfolio" className="checkbox-left-allign" checked={this.state.investment_in_portfolio == "30-40-30" ? true : false} />{' '}
                    </td>
                    <td>30%</td>
                    <td>40%</td>
                    <td>30%</td>
                  </tr>
                  <tr>
                  <td align="center">
                      <Input type="checkbox" value="50-40-10" onChange={this.handleChange} 
                      name="investment_in_portfolio" id="investment_in_portfolio" className="checkbox-left-allign" checked={this.state.investment_in_portfolio == "50-40-10" ? true : false} />{' '}
                    </td>
                    <td>50%</td>
                    <td>40%</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                  <td align="center">
                      <Input type="checkbox" value="70-30-0" onChange={this.handleChange} 
                      name="investment_in_portfolio" id="investment_in_portfolio" className="checkbox-left-allign" checked={this.state.investment_in_portfolio == "70-30-0" ? true : false} />{' '}
                    </td>
                    <td>70%</td>
                    <td>30%</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                  <td align="center">
                      <Input type="checkbox" value="100-0-0" onChange={this.handleChange} 
                      name="investment_in_portfolio" id="investment_in_portfolio" className="checkbox-left-allign" checked={this.state.investment_in_portfolio == "100-0-0" ? true : false} />{' '}
                    </td>
                    <td>100%</td>
                    <td>0%</td>
                    <td>0%</td>
                  </tr>
                  
                </tbody>
              </Table>
           

            <h6 className="title text-left">9. How low would the chance of a loss have to be for you to make the investment?</h6>
            <div className="row">
              <div className="col-lg-6 text-left">
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="chance_of_loss" id="exampleRadios1"  onChange={this.handleChange}
                  value="Zero-i-e-no-chance-of-loss" checked={this.state.chance_of_loss == "Zero-i-e-no-chance-of-loss" ? true : false} />
                  <label className="form-check-label">
                  1. Zero, i.e. no chance of loss.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="chance_of_loss" id="exampleRadios2" onChange={this.handleChange}
                   value="Very-low-chance-of-loss" checked={this.state.chance_of_loss == "Very-low-chance-of-loss" ? true : false} />
                  <label className="form-check-label">
                  2. Very low chance of loss.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="chance_of_loss" id="exampleRadios2" onChange={this.handleChange}
                   value="Moderately-low-chance-of-loss" checked={this.state.chance_of_loss == "Moderately-low-chance-of-loss" ? true : false} />
                  <label className="form-check-label">
                  3. Moderately low chance of loss.
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="chance_of_loss" id="exampleRadios2" onChange={this.handleChange}
                   value="50-percent-chance-of-loss" checked={this.state.chance_of_loss == "50-percent-chance-of-loss" ? true : false} />
                  <label className="form-check-label">
                  4. 50% chance of loss.
                  </label>
                </div>
              </div>
            </div>

            <h6 className="title text-left mt-3">10. How much of the funds you have available to invest would you be willing to place in investments where both returns and risks are expected to be above average?</h6>
            <div className="row">
              <div className="col-lg-6 text-left">
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios1"  onChange={this.handleChange}
                  value="None" checked={this.state.risk_above_average == "None" ? true : false} />
                  <label className="form-check-label">
                  1. None
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="10" checked={this.state.risk_above_average == "10" ? true : false} />
                  <label className="form-check-label">
                  2. 10%
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="20" checked={this.state.risk_above_average == "20" ? true : false} />
                  <label className="form-check-label">
                  3. 20%
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="30" checked={this.state.risk_above_average == "30" ? true : false} />
                  <label className="form-check-label">
                  4. 30%
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="40" checked={this.state.risk_above_average == "40" ? true : false} />
                  <label className="form-check-label">
                  5. 40%
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="50" checked={this.state.risk_above_average == "50" ? true : false} />
                  <label className="form-check-label">
                  6. 50%
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="60" checked={this.state.risk_above_average == "60" ? true : false} />
                  <label className="form-check-label">
                  7. 60%
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="70" checked={this.state.risk_above_average == "70" ? true : false} />
                  <label className="form-check-label">
                  8. 70%
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="80" checked={this.state.risk_above_average == "80" ? true : false} />
                  <label className="form-check-label">
                  9. 80%
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="90" checked={this.state.risk_above_average == "90" ? true : false} />
                  <label className="form-check-label">
                  10. 90%
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input radio-left-align" type="radio" name="risk_above_average" id="exampleRadios2" onChange={this.handleChange}
                   value="100" checked={this.state.risk_above_average == "100" ? true : false} />
                  <label className="form-check-label">
                  11. 100%
                  </label>
                </div>
              </div>
            </div>

        </div>

      </div>
    </div>
  }

  nonIndividual(){
    return <div>
      <div className="non-individual mt-3">
        <div className="row">
          <Col lg="6">
            <FormGroup>
              <SelectGroup name="non_individual_type" id="non_individual_type" onChange={this.handleChange} value={this.state.non_individual_type} required={this.state.client_category == "Non-Individual" ? true : false} errorMessage={{ required: "Please enter non individual type" }} >
                <option value="">Select Non Individual</option>  
                <option value="HUF">HUF</option>
                <option value="Corporate">Corporate</option>
                <option value="Trust">Trust or Registered Society</option>
                <option value="Partnership">Partnership </option>
                <option value="Unincorporated-Association-or-Body-of-Individuals">Unincorporated Association or Body of Individuals</option>
                <option value="Bank-Institutional-Investor">Bank/ Institutional Investor</option>
                <option value="Foreign-Institutional-Investors">Foreign Institutional Investors</option>
                <option value="Army-Government-Bodies">Army/Government Bodies</option>
              </SelectGroup>
            </FormGroup>
          </Col>
        </div>

        <h6 className="title text-left">Please answer the below Questions to process your request </h6>

        <div className="background-white">
            <h5 className="title text-left mt-3 mb-3">I. Please fill in the below details for a non individual client </h5>

          
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <TextInput value={this.state.applicant_name} onChange={this.handleChange} name="applicant_name" id="applicant_name"
                    placeholder={"Name of the Applicant"} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <FormGroup className="datepic">
                      <DatePicker
                        selected={this.state.incorporation_date}
                        name="incorporation_date"
                        required 
                        showYearDropdown
                        yearDropdownItemNumber={10}
                        onChange={this.handleChangeIncorporationDate}
                        disabledKeyboardNavigation
                        maxDate={this.state.max_date}
                        className="form-control"
                        selectsStart
                        placeholderText="Date of Incorporation"
                        /*isClearable*/
                        dateFormat="yyyy-MM-dd"
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <TextInput value={this.state.incorporation_place} onChange={this.handleChange} name="incorporation_place" id="incorporation_place" 
                    placeholder={"Place of Incorporation"}  />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <FormGroup className="datepic">
                      <DatePicker
                        selected={this.state.date_of_commencement_of_business}
                        name="date_of_commencement_of_business"
                        required 
                        showYearDropdown
                        yearDropdownItemNumber={10}
                        onChange={this.handleChangeBusinessCommencementDate}
                        disabledKeyboardNavigation
                        maxDate={this.state.max_date}
                        className="form-control"
                        selectsStart
                        placeholderText="Date of Commencement of Business"
                        /*isClearable*/
                        dateFormat="yyyy-MM-dd"
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <TextInput value={this.state.registeration_no} onChange={this.handleChange} name="registeration_no" id="registeration_no" 
                    placeholder={"Registeration No(Eg: CIN no)"}  />
                  </div>
                </div>
              </div>
            

        </div>

        <div className="mt-3 client-category">
          {this.financial_details()}
        </div>

        <div className="background-white">
            <h5 className="title mb-3 text-left">III. Investment Details of Non Individual Client </h5>
            {this.investment_details()}
            <div className="row">
              <div className="col-12 text-left">
                <h6 className="title text-left">5. Risk Tolerance</h6>

                <div className="col-lg-6">
                  <FormGroup>
                    <div className="form-check">
                        <Input type="checkbox" value="Low" checked={this.state.risk_tolerance == "Low" ? true : false}
                        onChange={this.handleChange} 
                        name="risk_tolerance" id="risk_tolerance" className="exposed_person"
                        />{' '}
                      <label className="form-check-label">Low</label>
                    </div>
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <div className="form-check">
                        <Input type="checkbox" value="Medium" checked={this.state.risk_tolerance == "Medium" ? true : false} onChange={this.handleChange} 
                        name="risk_tolerance" id="risk_tolerance" className="exposed_person"
                        />{' '}
                      <label className="form-check-label">Medium</label>
                    </div>
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup>
                    <div className="form-check">
                        <Input type="checkbox" value="High" checked={this.state.risk_tolerance == "High" ? true : false} onChange={this.handleChange} 
                        name="risk_tolerance" id="risk_tolerance" className="exposed_person"
                        />{' '}
                      <label className="form-check-label">High</label>
                    </div>
                  </FormGroup>
                </div>
                
              </div>
            </div>

        </div>

        <div className="mt-3 client-category">
            <h5 className="title mb-3 text-left">IV. Please fill in the below details if you would be making an Investment by transfering securities </h5>

         
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">Name of Security</th>
                    <th scope="col">ISIN Code</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Original Date of Acquisition</th>
                    <th scope="col">Original Per Share Rate of Acquisition</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {this.securityDetailsIndividual()}
              </Table>
           
          
        </div>

        <div className="background-white">
          <h5 className="title mb-3 text-left">V. Fee Structure </h5>
          <h6 className="title mb-3 text-left">Please choose a suitable fee structure for your portfolio </h6>
          {this.fee_structure()}
        </div>

        <div className="mt-3 client-category">
          <h5 className="title mb-3 text-left">VI. Disclosure List </h5>
          {this.disclosure_list()}
        </div>

      </div>

    </div>
  }

  componentDidMount() {
    this.getProducts();
  }

  // Date change functions
    handleChangeDate1 = date => {
      this.setState({
        firsttillDate: date
      })
    }

    handleChangeDate2 = date => {
      this.setState({
        secondTillDate: date
      })
    }

    handleChangeDate3 = date => {
      this.setState({
        thirdTillDate: date
      })
    }

    handleChangeNomineeDate = date => {
      this.setState({
        nominee_dob: date
      })
    }

    handleChangeOriginalDate1 = date => {
      this.setState({
        original_date_of_aquisition_first: date
      })
    }

    handleChangeOriginalDate2 = date => {
      this.setState({
        original_date_of_aquisition_second: date
      })
    }

    handleChangeOriginalDate3 = date => {
      this.setState({
        original_date_of_aquisition_third: date
      })
    }

    handleChangeOriginalDate4 = date => {
      this.setState({
        original_date_of_aquisition_fourth: date
      })
    }

    handleChangeOriginalDate5 = date => {
      this.setState({
        original_date_of_aquisition_fifth: date
      })
    }

    handleChangeOriginalDate6 = date => {
      this.setState({
        original_date_of_aquisition_sixth: date
      })
    }

    handleChangeOriginalDate7 = date => {
      this.setState({
        original_date_of_aquisition_seventh: date
      })
    }

    handleChangeOriginalDate8 = date => {
      this.setState({
        original_date_of_aquisition_eighth: date
      })
    }

    handleChangeOriginalDate9 = date => {
      this.setState({
        original_date_of_aquisition_ninth: date
      })
    }

    handleChangeOriginalDate10 = date => {
      this.setState({
        original_date_of_aquisition_ten: date
      })
    }

    handleChangeOriginalDate11 = date => {
      this.setState({
        original_date_of_aquisition_eleven: date
      })
    }

    handleChangeOriginalDate12 = date => {
      this.setState({
        original_date_of_aquisition_twelve: date
      })
    }

    handleChangeOriginalDate13 = date => {
      this.setState({
        original_date_of_aquisition_thirteen: date
      })
    }

    handleChangeOriginalDate14 = date => {
      this.setState({
        original_date_of_aquisition_fourteen: date
      })
    }

    handleChangeOriginalDate15 = date => {
      this.setState({
        original_date_of_aquisition_fifteen: date
      })
    }

    handleChangeOriginalDate16 = date => {
      this.setState({
        original_date_of_aquisition_sixteen: date
      })
    }

    handleChangeOriginalDate17 = date => {
      this.setState({
        original_date_of_aquisition_seventeen: date
      })
    }

    handleChangeOriginalDate18 = date => {
      this.setState({
        original_date_of_aquisition_eighteen: date
      })
    }

    handleChangeOriginalDate19 = date => {
      this.setState({
        original_date_of_aquisition_ninteen: date
      })
    }

    handleChangeOriginalDate20 = date => {
      this.setState({
        original_date_of_aquisition_twenty: date
      })
    }

    handleChangeOriginalDate21 = date => {
      this.setState({
        original_date_of_aquisition_tone: date
      })
    }

    handleChangeOriginalDate22 = date => {
      this.setState({
        original_date_of_aquisition_ttwo: date
      })
    }

    handleChangeOriginalDate23 = date => {
      this.setState({
        original_date_of_aquisition_tthree: date
      })
    }

    handleChangeOriginalDate24 = date => {
      this.setState({
        original_date_of_aquisition_tfour: date
      })
    }

    handleChangeOriginalDate25 = date => {
      this.setState({
        original_date_of_aquisition_tfive: date
      })
    }

    handleChangeBusinessCommencementDate = date => {
      this.setState({
        date_of_commencement_of_business: date
      })
    }

    handleChangeIncorporationDate = date => {
      this.setState({
        incorporation_date: date
      })
    }

  // Date change functions

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/vallum-tracker" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
          <section className="profile-section section-bg padding-top padding-bottom">
            <div className="container container-inner">
             <div className="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                <div className="row justify-content-center after-login-pages no-gutters">
                  {/*Client On-Boarding Form */}
                   <Sidebar />
                   <div class="col-md-8 col-lg-9">
                     
                        <div className="p-3 p-md-3"> 
                        {this.message()}
                              <h4 className="title mb-3 text-left">Client On-Boarding Form </h4>
                              <h6 className="title mb-3 text-left">Please answer the below Questions to process your request </h6>
                             
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter required field." }} >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <Col lg="6">
                                      <h5 className="title mb-2 text-left">I. Investment Strategy</h5>
                                        <FormGroup>
                                          <SelectGroup name="investment_strategy_product" id="investment_strategy_product" 
                                              onChange={this.handleChange} value={this.state.investment_strategy_product} required errorMessage={{
                                              required: "Please select investment strategy product" }} >
                                            <option value="">Select Investment Strategy Product</option>  
                                            {this.products()}
                                          </SelectGroup>
                                        </FormGroup>
                                      </Col>
                                    </div>
                                    <div className="row">
                                      <Col xs="12">
                                        <h6 className="title text-left">Mode of Investment</h6>
                                        <div className="row">
                                          <Col xs="4">
                                            <div className="form-check form-group">
                                                <Input type="radio" value="Funds" onChange={this.handleChange} 
                                                name="mode_of_investment" id="mode_of_investment"
                                                required
                                                checked={this.state.mode_of_investment == "Funds" ? true : false}
                                                />{' '}
                                              <label className="form-check-label">Funds</label>
                                            </div>
                                            </Col>

                                            <Col xs="4">
                                            <div className="form-check form-group">
                                              <Input type="radio" value="Securities" onChange={this.handleChange} 
                                                name="mode_of_investment" id="mode_of_investment" required
                                                checked={this.state.mode_of_investment == "Securities" ? true : false}
                                                />{' '}
                                              <label className="form-check-label">Securities</label>
                                            </div>
                                            </Col>
                                          
                                            <Col xs="4">
                                            <div className="form-check form-group ">
                                                <Input type="radio" value="Both" onChange={this.handleChange} required
                                                name="mode_of_investment" id="mode_of_investment" checked={this.state.mode_of_investment == "Both" ? true : false}
                                                />{' '}
                                              <label className="form-check-label">Both</label>
                                            </div>
                                           
                                            </Col>
                                        
                                        </div>
                                      </Col>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <h6 className="title text-left">Amount of Investment</h6>
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <FormGroup>
                                              <TextInput type="text" value={this.state.amount_of_investment} onChange={this.handleChange} name="amount_of_investment" id="amount_of_investment" placeholder={"Funds in INR"}
                                                validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                                                errorMessage={{
                                                  validator: "Amount of investment must be numeric."
                                                }}
                                                disabled={this.state.mode_of_investment == "Securities" ? true : false}
                                              />
                                            </FormGroup>
                                          </div>
                                          <div className="col-lg-6">
                                            <FormGroup>
                                              <TextInput type="text" value={this.state.security} onChange={this.handleChange} 
                                                name="security" id="security" placeholder={"Securities (approximate latest value)"}
                                                validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                                                errorMessage={{
                                                  validator: "Security must be numeric."
                                                }}
                                                disabled={this.state.mode_of_investment == "Funds" ? true : false}
                                              />
                                            </FormGroup>
                                          </div>
                                          <div className="col-lg-6">
                                            <FormGroup>
                                              <TextInput type="text" value={this.state.investment_amount} onChange={this.handleChange} readOnly
                                              name="investment_amount" id="investment_amount" placeholder={"Investment Amount (INR)*"}
                                                required
                                                errorMessage={{ required: "Investment amount is required." }}
                                              />
                                            </FormGroup>
                                            <p className="text-left small-text">(Minimum Investment INR 50 Lakh) </p>
                                          </div>
                                        
                                        </div>
                                      </div>
                                    </div>

                                    <div className="client-category pt-4">
                                      <h5 className="title mb-3 text-left">II. Please Select Client Category </h5>

                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="form-check">
                                            <input className="form-check-input" type="radio" name="client_category" id="radios1" 
                                              onChange={this.handleChange}
                                              value="Individual" checked={this.state.client_category == "Individual" ? true : false} disabled = {this.state.clientCat == 'Non-Individual' ? true : false}/>
                                            <label className="form-check-label">
                                              Individual
                                            </label>
                                          </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="form-check">
                                              <input className="form-check-input" type="radio" name="client_category" id="radios2" 
                                                onChange={this.handleChange}
                                                value="Non-Individual" checked={this.state.client_category == "Non-Individual" ? true : false} disabled = {this.state.clientCat == 'Individual' ? true : false}/>
                                              <label className="form-check-label">
                                                Non Individual
                                              </label>
                                            </div>
                                          </div>
                                    </div>
                                      {this.state.client_category == 'Individual' ? this.individual() : ""}
                                      {this.state.client_category == 'Non-Individual' ? this.nonIndividual() : ""}

                                    </div>

                                    {this.state.onBoardingStatus == '' || this.state.onBoardingStatus == 'form-filled' || this.state.onBoardingStatus == 'document-uploaded' || this.state.onBoardingStatus == 'under-document-verification' ?<div className="row mt-4">
                                      <div className="col-md-12"><button type="submit">Submit</button></div>
                                    </div> : null }
                                  
                                  </div>
                                </div>
                              </ValidationForm>
                              {this.state.clientCategory}
                           
                        </div>
                      </div>
                    </div>
                  {/*Client On Boarding Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientOnBoardingForm);
