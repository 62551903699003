import React, { Component } from 'react';
//import { Route, Switch, HashRouter } from 'react-router-dom';
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import './App.scss';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500,Register } from './views/Pages';
import Home from './views/Home';
import ForgotPassword from './views/Pages/Login/ForgotPassword';
import UserChangePassword from './views/Pages/Login/UserChangePassword';

import TermsAndConditions from './views/StaticPages/TermsAndConditions';
import GetInTouch from './views/StaticPages/GetInTouch';
import PrivacyPolicy from './views/StaticPages/PrivacyPolicy';
import Advisory from './views/StaticPages/Advisory';
import Career from './views/StaticPages/Career';
import Faqs from './views/StaticPages/Faqs';
import InvestmentPhilosophy from './views/StaticPages/InvestmentPhilosophy';
import InvestmentStrategy from './views/StaticPages/InvestmentStrategy';
import OurClientCentricApproach from './views/StaticPages/OurClientCentricApproach';
import TrackRecordAndPortfolioStatistics from './views/StaticPages/TrackRecordAndPortfolioStatistics';
import AccreditedInvestorsAndLargeValueAccreditedInvestors from './views/StaticPages/AccreditedInvestorsAndLargeValueAccreditedInvestors';
import DisclosureDocument from './views/StaticPages/DisclosureDocument';
import Valuenomics from './views/StaticPages/Valuenomics';
import Media from './views/StaticPages/Media';
import WhoWeAre from './views/StaticPages/WhoWeAre';
import TheArtOfGivingBack from './views/StaticPages/TheArtOfGivingBack';
import OurTeam from './views/OurTeam/OurTeam';
import Timeline from './views/Timeline/Timeline';
import PMSList from './views/PMS/List';
import PMSView from './views/PMS/DetailPage';
import Insight from './views/Insights/Insight';
import ValuenomicsView from './views/Insights/InsightValuenomics';
import InsightMedia from './views/Insights/Media';
import MediaView from './views/Insights/InsightMedia';
import WhatVallumReads from './views/Insights/WhatVallumReads';
import WhatVallumReadsView from './views/Insights/WhatVallumReadsView';
import WhatVallumReadsDetail from './views/Insights/WhatVallumReadsDetail';
import ShareholdersLetters from './views/Insights/ShareholdersLetters';
import ShareholdersLettersView from './views/Insights/ShareholdersLettersView';
import Profile from './views/Profile';
import ChangePassword from './views/Profile/ChangePassword';
import ChangeEmail from './views/Profile/ChangeEmail';
import ChangeMobile from './views/Profile/ChangeMobile';
import TalkToTheTeam from './views/Profile/TalkToTheTeam';
import Reports from './views/Profile/Reports';
import Documents from './views/Profile/Documents';
import VallumTracker from './views/VallumTracker/VallumTracker';
import ClientOnBoardingForm from './views/VallumTracker/ClientOnBoardingForm';
import ClientOnBoardingDocumentsIndividual from './views/VallumTracker/ClientOnBoardingDocumentsIndividual';
import ClientOnBoardingDocumentsNonIndividual from './views/VallumTracker/ClientOnBoardingDocumentsNonIndividual';
import ClientOnBoardingUploadSignaturedForm from './views/VallumTracker/ClientOnBoardingUploadSignaturedForm'; 
import AdditionalCorpusForm from './views/VallumTracker/AdditionalCorpusForm';
import AdditionalCorpusUploadSignatureForm from './views/VallumTracker/AdditionalCorpusUploadSignatureForm'; 
import PartialWithdrawalForm from './views/VallumTracker/PartialWithdrawalForm';
import PartialWithdrawalDocuments from './views/VallumTracker/PartialWithdrawalDocuments';
import PartialWithdrawalUploadSignatureForm from './views/VallumTracker/PartialWithdrawalUploadSignatureForm';
import CompleteRedemptionForm from './views/VallumTracker/CompleteRedemptionForm';
import CompleteRedemptionDocuments from './views/VallumTracker/CompleteRedemptionDocuments';
import CompleteRedemptionUploadSignatureForm from './views/VallumTracker/CompleteRedemptionUploadSignatureForm';

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <BrowserRouter basename={'/'}>
        <Switch>
          <Route exact path="/login" name="Login" component={Login} />
          <Route exact path="/sign-up" name="Sign Up" component={Register} />
          <Route exact path="/forgot-password" name="Forgot Password" component={ForgotPassword} />
          <Route exact path="/user-account" name="Password Reset Page" component={UserChangePassword} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route exact path="/terms-and-conditions" name="Terms And Conditions" component={TermsAndConditions} />
          <Route exact path="/get-in-touch" name="Get In Touch" component={GetInTouch} />
          <Route exact path="/privacy-policy" name="Privacy Policy" component={PrivacyPolicy} />
          <Route exact path="/advisory" name="Advisory" component={Advisory} />
          <Route exact path="/career" name="Career" component={Career} />
          <Route exact path="/faqs" name="FAQ's" component={Faqs} />
          <Route exact path="/investment-philosophy" name="Investment Philosophy" component={InvestmentPhilosophy} />
          <Route exact path="/investment-strategy" name="Investment Strategy" component={InvestmentStrategy} />
          <Route exact path="/our-client-centric-approach" name="Our Client Centric Approach" component={OurClientCentricApproach} />
          {/*<Route exact path="/track-record-and-portfolio-statistics" name="Track Record And Portfolio Statistics" component={TrackRecordAndPortfolioStatistics} />*/}
          <Route exact path="/track-record-and-portfolio-statistics" name="Track Record And Portfolio Statistics" render={() => <Redirect to="/" />} />
          <Route exact path="/accredited-investors-and-large-value-accredited-investors" name="Accredited Investors and Large Value Accredited Investors" component={AccreditedInvestorsAndLargeValueAccreditedInvestors} />
          <Route exact path="/disclosure-document" name="Disclosure Document" component={DisclosureDocument} />
          <Route exact path="/valuenomics" name="Valuenomics" component={Valuenomics} />
          <Route exact path="/media" name="Media" component={Media} />
          <Route exact path="/who-we-are" name="Who We Are" component={WhoWeAre} />
          <Route exact path="/the-art-of-giving-back" name="The Art Of Giving Back" component={TheArtOfGivingBack} />
          <Route exact path="/our-team" name="Our Team" component={OurTeam} />
          <Route exact path="/timeline" name="Timeline" component={Timeline}/>
          <Route exact path="/pms" name="PMS" component={PMSList} />
          <Route exact path="/pms-view" name="PMS View" component={PMSView} />
          <Route exact path="/blogs" name="Blogs" component={Insight} />
          <Route exact path="/blog-view" name="Blog View" component={ValuenomicsView} />
          <Route exact path="/insight-media" name="Media" component={InsightMedia} />
          <Route exact path="/media-view" name="Media View" component={MediaView} />
          <Route exact path="/insight-what-vallum-reads/:id" name="What Vallum Reads View" component={WhatVallumReadsDetail} />
          <Route exact path="/insight-what-vallum-reads" name="What Vallum Reads" component={WhatVallumReads} />
          <Route exact path="/what-vallum-reads-view" name="What Vallum Reads View" component={WhatVallumReadsView} />
          <Route exact path="/insight-shareholders-letters" name="Shareholders Letters" component={ShareholdersLetters} />
          <Route exact path="/shareholders-letters-view" name="Shareholders LettersView View" component={ShareholdersLettersView} />
          <Route exact path="/my-account" name="My Account" component={Profile} />
          <Route exact path="/change-password" name="Change Password" component={ChangePassword} />
          <Route exact path="/change-mobile" name="Change Mobile Number" component={ChangeMobile} />
          <Route exact path="/change-email" name="Change Email" component={ChangeEmail} />
          <Route exact path="/talk-to-the-team" name="Talk To The Team" component={TalkToTheTeam} />
          <Route exact path="/reports" name="Reports" component={Reports} />
          <Route exact path="/documents" name="Documents" component={Documents} />
          <Route exact path="/vallum-tracker" name="Vallum Tracker" component={VallumTracker} />
          <Route exact path="/client-on-boarding-form" name="Client On Boarding Form" component={ClientOnBoardingForm} />
          <Route exact path="/client-on-boarding-document-individual" name="Client On Boarding Document Individual" component={ClientOnBoardingDocumentsIndividual} />
          <Route exact path="/client-on-boarding-document-non-individual" name="Client On Boarding Document Non Individual" component={ClientOnBoardingDocumentsNonIndividual} />
          <Route exact path="/client-on-boarding-upload-signatured-document" name="Client On Boarding Upload Signatured Form" component={ClientOnBoardingUploadSignaturedForm} />
          <Route exact path="/additional-corpus-form" name="Additional Corpus Form" component={AdditionalCorpusForm} />
          <Route exact path="/additional-corpus-upload-signature-form" name="Additional Corpus Form" component={AdditionalCorpusUploadSignatureForm} />
          <Route exact path="/partial-withdrawal-form" name="Partial Withdrawal Form" component={PartialWithdrawalForm} />
          <Route exact path="/partial-withdrawal-document" name="Partial Withdrawal Documents" component={PartialWithdrawalDocuments} />
          <Route exact path="/partial-withdrawal-upload-signature-form" name="Partial Withdrawal Form" component={PartialWithdrawalUploadSignatureForm} />
          <Route exact path="/complete-redemption-form" name="Complete Redemption Form" component={CompleteRedemptionForm} />
          <Route exact path="/complete-redemption-document" name="Complete Redemption Documents" component={CompleteRedemptionDocuments} />
          <Route exact path="/complete-redemption-upload-signature-form" name="Complete Redemption Upload Signature Form" component={CompleteRedemptionUploadSignatureForm} />
          <Route path="/" name="Home" component={Home}  />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
