import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import no_image from '../../assets/img/brand/no_image.png';

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      oldPassword: "",
      password: "",
      confirm_password: "",
      id: this.props.admin._id,
      profileDataForStore: {}
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.changePassword(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async changePassword(postData) {
    let path = apiPath.changePassword;
    this.setState({ loading: true });
    postData.userId = this.state.id
    let options = {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(postData)
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            this.setState({redirectTo: true});
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/my-account" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
        <section class="profile-section section-bg padding-top padding-bottom" id="my-profile">
            <div class="container container-inner">
            {this.message()}
            <div class="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                  {/*Change Password Form*/}
                  <div className="row justify-content-center after-login-pages no-gutters">
                      <Sidebar />
                          <div className="col-md-8 col-lg-9">
                            <div className="p-3 p-md-3">
                             <h4 className="title mb-4 text-left">Change Password</h4>
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter something." }} >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <Col lg="4">
                                        <FormGroup>
                                          <TextInput type="password" value={this.state.oldPassword} onChange={this.handleChange} name="oldPassword" id="oldPassword" placeholder={"Old Password*"}
                                            required 
                                            maxLength={10}
                                            pattern="^(?=.*\d)(?=.*[a-zA-Z]).{6,10}$"
                                            errorMessage={{ required: "Please enter old password.", pattern: "Old password must be 6 to 10 characters long and should include letters and numbers." }}
                                          />
                                        </FormGroup>
                                      </Col>
                                  
                                   
                                      <Col lg="4">
                                        <FormGroup>
                                          <TextInput type="password" value={this.state.password} onChange={this.handleChange} name="password" id="password" placeholder={"Password*"}
                                            required disabled={this.state.showOTP ? "disabled" : false}
                                            maxLength={10}
                                            pattern="^(?=.*\d)(?=.*[a-zA-Z]).{6,10}$"
                                            errorMessage={{ required: "Please enter password.", pattern: "Password must be 6 to 10 characters long and should include letters and numbers." }}
                                          />
                                        </FormGroup>
                                      </Col>
                                   
                                   
                                      <Col lg="4">
                                        <FormGroup>
                                          <TextInput type="password" value={this.state.confirm_password} onChange={this.handleChange} name="confirm_password" id="confirm_password" placeholder={"Confirm Password*"}
                                            required disabled={this.state.showOTP ? "disabled" : false}
                                            maxLength={10}
                                            validator={(value) => validator.equals(value, this.state.password)}
                                            errorMessage={{ required: "Please enter confirm password.", validator: "Password and confirm password does not match." }}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6"><button type="submit">Submit</button></div>
                                    </div>
                                  </div>
                                </div>
                              </ValidationForm>
                            </div>
                            </div>
                        
                    </div>
                  {/*Change Password Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
