import React, { Component } from 'react';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";

import GetInTouch from '../Elements/GetInTouch';
import bannerImage from '../../assets/images/banner/banner-inner.png';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      timelineData: ""
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async getTimelineData() {
    let path = apiPath.timeline;
    this.setState({loading: true});
    let options = {
      method: 'GET',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
    }
    let result = await Http(path, options);
    this.setState({loading: false});
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({
            timelineData: resultUsersJson,
          });
          this.generate('success');

        } else {
          this.generate('danger');
          this.setState({message: resultJson.msg});
          setTimeout(() => {
            this.setState({message: null});
          }, 5000);
        }
      }
    } else {
      this.generate('danger');
      this.setState({message: result.statusText});
      setTimeout(() => {
        this.setState({message: null});
      }, 5000);
    }
  }

  componentDidMount(){
    this.getTimelineData();
  }

  timelineList(){
    if(!_.isEmpty(this.state.timelineData)){
      var i = 0;
      return this.state.timelineData.map((value,index)=>{
        i++;
        return <div className={ i%2 == 0 ? "timeline-child right" : " timeline-child left"} key={index}>
          <div className="content">
            <h3>{value.year}</h3>
            <h5>{value.title}</h5>
            <p>{value.description}</p>
          </div>
        </div>
      })
    }
  }

  render() {
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
        <section className="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div className="container">
                  <div className="hero-content">
                      <h1 className="title text-white">Timeline</h1>
                      <ul className="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Timeline
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        <section className="timeline-pg-section padding-top padding-bottom" id="timeline">
            <div className="container">
                <div className="row justify-content-center feature-wrapper">
                        <div className="aos-item" data-aos="fade-up">
                            <div className="timeline">
                              {this.timelineList()}
                            </div>
                        </div>
                </div>
            </div>
        </section>

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default Timeline;
