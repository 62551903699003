import React, { Component } from 'react';
import { Button, Input,CardImg  } from 'reactstrap';
import _ from "lodash";
import { Redirect } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import { AlertList } from "react-bs-notifier";

import DateFormat from "../../constants/dateformat";
import DateFormatLocal from "../../constants/dateformatlocal";
import helper from '../../constants/helper';
import no_image from '../../assets/img/brand/no_image.png';
import { saveAs } from 'file-saver'
const FileSaver = require('file-saver');

class DocumentRow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userEdit: {},
      selected: '',
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: ""
    }
  }

  Edit(user) {

    this.setState({ userEdit: user })
  }

  changeStatus(e) {
    let user_id = e.target.getAttribute("user_id");
    let status = e.target.value;
    this.setState({ selected: status });
    this.updateDocumentStatus(status, user_id);
  }
  generate(type) {

    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }
  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  fileDwonload(e) {
    let url = e.target.getAttribute("data_url");
    let fileName = url.split('/');
    let len = fileName.length
    let fName = fileName[len-1];
    FileSaver.saveAs(url, fName)
  }

  async updateDocumentStatus(status, user_id) {

    let path = apiPath.updateDocumentStatus;
    if (!_.isEmpty(status) && !_.isEmpty(user_id)) {
      this.setState({ loading: true });
      let options = {
        method: 'PUT',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ "status": status })
      }
      let result = await Http(path + "?id=" + user_id, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {
          let resultData = await result.json();

          if (resultData.success) {
            this.setState({ message: resultData.msg });
            this.generate('success');
          } else {
            this.setState({ message: resultData.msg });
            this.generate('danger');
          }
        }
      } else {
        console.log(result);
        this.setState({ message: result.statusText });
        this.generate('danger');
      }
    } else {
      console.log('No Response');
    }
  }

  render() {

    if (!_.isEmpty(this.state.userEdit)) {
      return <Redirect to={{
        pathname: '/documents/edit',
        userData: this.state.userEdit
      }} />
    }
    const user = this.props.user;
    return (
      <tr key={user.id.toString()}>
        {/*<td>{this.props.id + 1}</td>*/}
        <td>{(user && user.title) || ''}</td>
        <td>{ user.photo != '' ? <a href={user.photo} target="_blank">Preview Uploaded File</a> :null}</td>
        <td><i className="fa fa-download" data_url = {user.photo} onClick={this.fileDwonload.bind(this)}></i></td>
      </tr>
    )
  }
}
export default DocumentRow;