import React, { Component } from "react";
import {
  Label,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  CardHeader,
  FormGroup,
  CardFooter,
} from "reactstrap";
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link, NavLink, Redirect } from "react-router-dom";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import * as Scroll from "react-scroll";

import PropTypes from "prop-types";
import validator from "validator";

import { connect } from "react-redux";
import { adminLogin } from "../../actions/index";
import logo from "../../assets/img/brand/login_logo.png";
var scroll = Scroll.animateScroll;

const mapStateToProps = (state) => {
  return { admin: state.admin };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminLogin: (admin) => dispatch(adminLogin(admin)),
  };
};

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};
class Header extends Component {
  constructor(props) {
    super(props);
    console.log(localStorage.getItem("oneTimeModelOpen"), "sdfsfdsdfsdf");
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      logoutRedirect: false,
      showingLoader: false,
      headerClass: "",
      scrollTop: "",
      settings: "",
      activeMenu: false,
      activeAboutMenu: false,
      activeInvestorMenu: false,
      activeInsightMenu: false,
      activeProductMenu: false,
      activeRegulatory_information: false,
      firstColName: "",
      firstColValue: "",
      secondColName: "",
      secondColValue: "",
      thirdColName: "",
      thirdColValue: "",
      fourthColName: "",
      fourthColValue: "",
      fifthColName: "",
      fifthColValue: "",
      popUpHeading: "",
      profileDataForStore: {},
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.toggleClass = this.toggleClass.bind(this);
    this.aboutToggleClass = this.aboutToggleClass.bind(this);
    this.productToggleClass = this.productToggleClass.bind(this);
    this.regulatory_informationToggleClass =
      this.regulatory_informationToggleClass.bind(this);
    this.insightsToggleClass = this.insightsToggleClass.bind(this);
    this.investorToggleClass = this.investorToggleClass.bind(this);
    this.onLogoutHandle = this.onLogoutHandle.bind(this);
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: new Date().getTime(),
      type: type,
      headline: "",
      message: this.state.message,
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert],
    });
  }

  clearAlerts() {
    this.setState({
      alerts: [],
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)],
      });
    }
  }

  toggleClass() {
    const currentState = this.state.activeMenu;
    if (currentState == "active") {
      this.setState({ activeMenu: "" });
    } else {
      this.setState({ activeMenu: "active" });
    }
  }

  aboutToggleClass() {
    const currentAboutState = this.state.activeAboutMenu;
    if (currentAboutState == "active-submenu") {
      this.setState({ activeAboutMenu: "" });
    } else {
      this.setState({ activeAboutMenu: "active-submenu" });
    }
  }

  productToggleClass() {
    const currentProductState = this.state.activeProductMenu;
    if (currentProductState == "active-submenu") {
      this.setState({ activeProductMenu: "" });
    } else {
      this.setState({ activeProductMenu: "active-submenu" });
    }
  }

  regulatory_informationToggleClass() {
    const currentRegulatory_information =
      this.state.activeRegulatory_information;
    if (currentRegulatory_information == "active-submenu") {
      this.setState({ activeRegulatory_information: "" });
    } else {
      this.setState({ activeRegulatory_information: "active-submenu" });
    }
  }

  insightsToggleClass() {
    const currentInsightState = this.state.activeInsightMenu;
    if (currentInsightState == "active-submenu") {
      this.setState({ activeInsightMenu: "" });
    } else {
      this.setState({ activeInsightMenu: "active-submenu" });
    }
  }

  investorToggleClass() {
    const currentInvestorState = this.state.activeInvestorMenu;
    if (currentInvestorState == "active-submenu") {
      this.setState({ activeInvestorMenu: "" });
    } else {
      this.setState({ activeInvestorMenu: "active-submenu" });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
  };

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  };

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  };

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      );
    }
  }

  onLogoutHandle() {
    if (!window.confirm("Are you sure to logout?")) {
      return false;
    } else {
      localStorage.removeItem("admin");
      localStorage.removeItem("redirect");
      localStorage.setItem("redirect", "/");
      //this.setState({logoutRedirect : true});
      //this.props.adminLogin({});
      setTimeout(() => {
        window.location.href = "/";
      }, 100);
    }
  }

  async getSettingData() {
    let path = apiPath.settings;
    this.setState({ loading: true });
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({
            settings: resultUsersJson,
          });
          if (resultUsersJson.support_email) {
            localStorage.setItem(
              "local_support_email",
              resultUsersJson.support_email
            );
            localStorage.setItem(
              "local_support_mobile",
              resultUsersJson.support_mobile
            );
            localStorage.setItem(
              "local_whatsapp_mobile",
              resultUsersJson.whatsapp_mobile
            );
            localStorage.setItem("local_copyright", resultUsersJson.copyright);
            localStorage.setItem("local_web_url", resultUsersJson.web_url);
            localStorage.setItem("local_fb_url", resultUsersJson.fb_url);
            localStorage.setItem(
              "local_twitter_url",
              resultUsersJson.twitter_url
            );
            localStorage.setItem(
              "local_linkedin_url",
              resultUsersJson.linkedin_url
            );
            localStorage.setItem("local_address", resultUsersJson.address);
            localStorage.setItem(
              "local_bank_details",
              resultUsersJson.bank_details
            );
            localStorage.setItem("local_logo", resultUsersJson.logo);
            localStorage.setItem(
              "local_footer_data",
              resultUsersJson.footer_data
            );
            localStorage.setItem(
              "disclosure_document",
              resultUsersJson.disclosure_document
            );
            localStorage.setItem(
              "score_document",
              resultUsersJson.score_document
            );
          }
          this.generate("success");
        } else {
          this.generate("danger");
          this.setState({ message: resultJson.msg });
          setTimeout(() => {
            this.setState({ message: null });
          }, 5000);
        }
      }
    } else {
      this.generate("danger");
      this.setState({ message: result.statusText });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  }

  async getPMSData() {
    let path = apiPath.products;
    this.setState({ loading: true });
    let options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          //console.log(resultUsersJson);
          this.setState({
            PMSData: resultUsersJson,
            // category_id: resultUsersJson.category_id,
            // team_id: resultUsersJson.team_id,
          });
          this.generate("success");
          let categoryIds = resultUsersJson.map((item) => item.category_id.pdf);
          console.log(categoryIds);
        } else {
          this.generate("danger");
          this.setState({ message: resultJson.msg });
          setTimeout(() => {
            this.setState({ message: null });
          }, 5000);
        }
      }
    } else {
      this.generate("danger");
      this.setState({ message: result.statusText });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  }

  componentDidMount() {
    this.getSettingData();
    this.getPMSData();
    window.addEventListener("load", this.handleLoad);
    window.addEventListener("scroll", () => {
      let headerClass = "";
      let scrollTop = "";
      if (window.scrollY > 1) {
        headerClass = "active";
      }
      if (window.scrollY > 500) {
        scrollTop = "active";
      }
      this.setState({ headerClass: headerClass, scrollTop: scrollTop });
    });
    /*setTimeout(() => {
      this.setState({
        showingLoader: false
      });
    }, 400);*/
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }

  handleLoad() {
    /*setTimeout(() => {
      this.setState({
        showingLoader: false
      });
    }, 100);*/
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  render() {
    /*if(this.state.logoutRedirect){
      return <Redirect to="/" />
    }*/
    const { children, ...attributes } = this.props;
    return (
      <div>
        {/*Preloader*/}
        <div className={this.state.showingLoader ? " preloader" : "fadeout"}>
          <div className="preloader-wrapper">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <a
          href=""
          className={`scrollToTop ${this.state.scrollTop}`}
          onClick={this.scrollToTop}
        >
          <i className="fa fa-angle-up"></i>
        </a>
        <div className="overlay"></div>
        {/*Preloader*/}

        {/*Header*/}
        <header className={`header-section ${this.state.headerClass}`}>
          <div className="header-top">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-5 d-lg-block d-md-block d-none">
                  <div className="cart-area d-flex justify-content-md-start">
                    <ul className="mr-4">
                      <li className="list-inline-item">
                        <a
                          href="https://forms.zohopublic.com/connectvallum/form/AccountOpeningForm/formperma/p1KsS8e6gvtSmEjbQeNeQoMesmrrIC5KBkqVaUJHBMw"
                          target="_blank"
                        >
                          For Direct Onboarding{" "}
                          <span className="text-warning">Click Here</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-12">
                  <div className="cart-area d-flex justify-content-md-end justify-content-between">
                    <div className="top-social-icon">
                      <ul>
                        <li>
                          <a
                            href={
                              this.state.settings.fb_url
                                ? this.state.settings.fb_url
                                : "https://www.facebook.com/VallumCapitalAdvisors"
                            }
                            target="_blank"
                          >
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              this.state.settings.twitter_url
                                ? this.state.settings.twitter_url
                                : "https://twitter.com/VallumConnect"
                            }
                            target="_blank"
                          >
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              this.state.settings.linkedin_url
                                ? this.state.settings.linkedin_url
                                : "https://www.linkedin.com/company/vallum-capital-ad"
                            }
                            target="_blank"
                          >
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="top-account-section">
                      {this.props.admin && this.props.admin.email ? (
                        <ul>
                          <li>
                            <NavLink to="my-account" activeClassName="active">
                              <i className="fa fa-user"></i>My Account
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="#"
                              onClick={this.onLogoutHandle}
                              activeClassName="active"
                            >
                              <i className="fa fa-lock"></i>Logout
                            </NavLink>
                          </li>
                        </ul>
                      ) : (
                        <ul>
                          {/*<li><NavLink to="/login" activeClassName="active"><i className="fa fa-lock"></i>Login</NavLink></li>
                                    <li><NavLink to="/sign-up" activeClassName="active"><i className="fa fa-user"></i>Sign Up</NavLink></li>*/}
                          {/*<li><a href="https://webfa.axisbank.co.in/wealthspectrum/app/loginWith" target="_blank"><i className="fa fa-lock"></i>Axis Login</a></li>*/}
                          <li>
                            <a
                              href="https://onlinefa.icicibank.com/wealthspectrum/app/login"
                              target="_blank"
                            >
                              <i className="fa fa-lock"></i>Client Login
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bottom">
            <div className="container-fluid">
              <div className="header-area">
                <div className="logo">
                  <NavLink to="/">
                    <img src={logo} alt="logo" />
                  </NavLink>
                </div>
                <ul
                  className={"menu " + this.state.activeMenu}
                  id="myCustomMenu"
                >
                  <li className="nav-link active">
                    <NavLink to="/" activeClassName="active">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-link menu-item-has-children">
                    <a
                      href="javascript:void(0)"
                      onClick={this.productToggleClass}
                    >
                      Our Products
                    </a>
                    <ul className={"submenu " + this.state.activeProductMenu}>
                      <li>
                        <NavLink to="/pms" activeClassName="active">
                          PMS
                        </NavLink>
                      </li>
                      {/*<li><NavLink to="/advisory" activeClassName="active">Advisory</NavLink></li>*/}
                    </ul>
                  </li>
                  <li className="nav-link menu-item-has-children">
                    <a
                      href="javascript:void(0)"
                      onClick={this.regulatory_informationToggleClass}
                    >
                      Regulatory Information
                    </a>
                    <ul
                      className={
                        "submenu " + this.state.activeRegulatory_information
                      }
                    >
                      <li>
                        <a
                          href={
                            this.state.settings.disclosure_document
                              ? this.state.settings.disclosure_document
                              : "#"
                          }
                          target="_blank"
                          activeClassName="active"
                        >
                          Disclosure Document{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://vallum.in/api/img/documents/INVESTOR-CHARTER-OF-PORTFOLIO-MANAGEMENT-SERVICES.pdf"
                          target="_blank"
                          activeClassName="active"
                        >
                          Invester Charter{" "}
                        </a>
                      </li>

                      <li>
                        {this.state.PMSData && this.state.PMSData.length > 0 ? (
                          this.state.PMSData.map((item) => (
                            <a
                              key={item._id} // Assuming item._id is a unique identifier
                              href={
                                item.category_id.pdf
                                  ? item.category_id.pdf
                                  : "#"
                              }
                              target="_blank"
                              activeClassName="active"
                            >
                              SCORES
                            </a>
                          ))
                        ) : (
                          <span>No data available</span>
                        )}
                      </li>
                      <li>
                        <a
                          href="https://vallum.in/api/img/documents/SEBI-Master-Circular-for-Online-Resolution-of-Disputes.pdf"
                          target="_blank"
                          activeClassName="active"
                        >
                          SEBI Master Circular for Online Resolution of Disputes
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://vallum.in/api/img/documents/Vallum-Capital-Advisors-Fee-Illustration.xlsx"
                          activeClassName="active"
                        >
                          Fee Calculator{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-link menu-item-has-children">
                    <a
                      href="javascript:void(0)"
                      onClick={this.investorToggleClass}
                    >
                      Investors Corner
                    </a>
                    <ul className={"submenu " + this.state.activeInvestorMenu}>
                      {/*<li><NavLink to="/investment-philosophy" activeClassName="active">Investment Philosophy</NavLink></li>*/}
                      <li>
                        <NavLink
                          to="/investment-strategy"
                          activeClassName="active"
                        >
                          Investment Strategy
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/our-client-centric-approach"
                          activeClassName="active"
                        >
                          Our Client Centric Approach
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/accredited-investors-and-large-value-accredited-investors"
                          activeClassName="active"
                        >
                          Accredited Investors and Large Value Accredited
                          Investors
                        </NavLink>
                      </li>
                      {/*<li><NavLink to="/track-record-and-portfolio-statistics" activeClassName="active">Track Record and Portfolio Statistics</NavLink></li>*/}
                    </ul>
                  </li>
                  <li className="nav-link menu-item-has-children">
                    <a
                      href="javascript:void(0)"
                      onClick={this.insightsToggleClass}
                    >
                      Insights
                    </a>
                    <ul className={"submenu " + this.state.activeInsightMenu}>
                      <li>
                        <NavLink
                          to="/insight-shareholders-letters"
                          activeClassName="active"
                        >
                          Shareholders Letters
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/insight-what-vallum-reads"
                          activeClassName="active"
                        >
                          Vallum's Weekend Reading
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/blogs" activeClassName="active">
                          Blog
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/insight-media" activeClassName="active">
                          Media
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-link menu-item-has-children">
                    <a
                      href="javascript:void(0)"
                      onClick={this.aboutToggleClass}
                    >
                      About Us
                    </a>
                    <ul className={"submenu " + this.state.activeAboutMenu}>
                      <li>
                        <NavLink to="/who-we-are" activeClassName="active">
                          Who we are
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/our-team" activeClassName="active">
                          Our Team
                        </NavLink>
                      </li>
                      {/*<li><NavLink to="/timeline" activeClassName="active">Timeline</NavLink></li>*/}
                      {/*<li><NavLink to="/the-art-of-giving-back" activeClassName="active">The Art of Giving Back</NavLink></li>
                                      <li><NavLink to="/career" activeClassName="active">Careers</NavLink></li>
                                      <li><NavLink to="/faqs" activeClassName="active">FAQ's</NavLink></li>*/}
                    </ul>
                  </li>
                  <li className="list-inline-item d-lg-none d-md-none d-block">
                    <a
                      className="d-block"
                      href="https://forms.zohopublic.com/connectvallum/form/AccountOpeningForm/formperma/p1KsS8e6gvtSmEjbQeNeQoMesmrrIC5KBkqVaUJHBMw"
                      target="_blank"
                    >
                      For Direct Onboarding{" "}
                      <span className="text-warning">Click Here</span>
                    </a>
                  </li>
                  <li className="pr-1">
                    <NavLink to="/get-in-touch" className="custom-button">
                      FOR DIRECT ONBOARDING
                    </NavLink>
                  </li>
                </ul>
                <div
                  className={"header-bar d-lg-none " + this.state.activeMenu}
                  onClick={this.toggleClass}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/*Header*/}
      </div>
    );
  }
}
Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(Header);
