import React, { Component } from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from "lodash";
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/admin_logo.png'
import sygnet from '../../assets/img/brand/admin_logo.png'
import PubNubReact from 'pubnub-react';
import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";
import Sound from 'react-sound'

const pubnubPublishKey = "";
const pubnubSubscribeKey=  "";

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.pubnub = new PubNubReact({
      publishKey: pubnubPublishKey,
      subscribeKey: pubnubSubscribeKey
    });
    this.state = {
      unreadNoti: 0
    }
    this.pubnub.init(this);
    this.onLogoutHandle = this.onLogoutHandle.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentWillMount() {

    this.pubnub.subscribe({
      channels: ["DemoAdmin1234"],
      withPresence: true
    });

    this.pubnub.getMessage("DemoAdmin1234", (resP) => {
      const location = window.location.hash;
      if (!_.isEmpty(resP) && location !== '#/messages') {
        this.setState({ unreadNoti: (this.state.unreadNoti + 1) })
      }
      if (!_.isEmpty(resP) && location === '#/messages') {
        this.setState({ unreadNoti: 0 })
      }
    });
  }
  closePopup = event => {
    console.log(event.currentTarget);
    var cT = event.currentTarget;
    cT.parentNode.remove();
  }
  componentWillUnmount() {
      this.pubnub.unsubscribe({
          channels: ["DemoAdmin1234"]
      });
  }

  onLogoutHandle() {
    if (!window.confirm("Are you sure to logout?")) {
      return false;
    } else {
      localStorage.removeItem("admin");
      localStorage.setItem("redirect", (this.props.admin.account_type === 'admin') ? "/login" : '/login');
      this.props.adminLogin({});
    }
  }
  render() {
    // eslint-disable-next-line
    //console.log(this.props,'this.props');
    const { children, ...attributes } = this.props;
    const messages = this.pubnub.getMessage("DemoAdmin1234");
    console.log(messages,this.state.alertSound);
    return (
      <React.Fragment>
        <div style={{position:'absolute', right:'5px', top:'5px', zIndex:'1000'}}>
            {messages.map((m, index) => <p style={{position:'relative', boxShadow: '0px 6px 24px 0px rgba(0,0,0,0.2)', backgroundColor: '#ff8117', border: '0 solid #ff8117', padding:'15px', borderRadius:'5px'}} key={'message' + index}><span style={{position: 'absolute', right: '5px', top: '0', color: '#fff', cursor:'pointer'}} onClick={this.closePopup}>x</span>{m.message.message}</p>)}
        </div>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 70, alt: 'Demo Logo' }}
          minimized={{ src: sygnet, width: 70, alt: 'Demo Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto bell-icon-custom" navbar>
          {/*this.props.admin && <NavItem className="d-md-down-none">
            <Link to="/dashboard" color="light"><NavLink><i className="fa fa-refresh" aria-hidden="true"></i></NavLink></Link>
          </NavItem>*/}
          {/*this.props.admin && <NavItem className="d-md-down-none">
            <Link to="/admin-notifications" color="light"><NavLink><i className="fa fa-bell"></i></NavLink></Link>
          </NavItem>*/}
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <i className="fa fa-cog" aria-hidden="true"></i>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem header tag="div" className="text-center"><strong>{_.upperFirst(this.props.admin.username)}</strong></DropdownItem>
              <DropdownItem tag={Link} to={(this.props.admin.account_type === 'admin') ? "profile" : 'profile'}><i className="fa fa-user"></i> Profile</DropdownItem>
              <DropdownItem tag={Link} to={"logout"} onClick={this.onLogoutHandle}><i className="fa fa-lock"></i>Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);
