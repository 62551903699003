let appMode = 'production';
//let newlive = 'https://vallumcapital.24livehost.com/'
//let newlive = 'http://13.234.233.22:3001/'
let newlive = 'https://vallum.in/'
//let newlive = 'http://localhost:3001/'
let stage = 'http://192.168.0.237:3001/'
let URL = (appMode === 'production') ? newlive : stage;
let pathObj = {
    baseURL: URL,
    home: 'api/home',
    settings: 'api/settings',
    sendInformationRequest: 'api/sendInformationRequest',
    getStaticPage: 'api/static-pages',
    sendSubscription: 'api/sendSubscription',
    sendContactForm: 'api/sendContactForm',
    ourTeam: 'api/ourTeam',
    timeline: 'api/timeline',
    products: 'api/products',
    insights: 'api/insights',
    getInsightDetails: 'api/getInsightDetails',
    getAllCountries: 'api/getAllCountries',
    register: 'api/register',
    verifyOTPSignup: 'api/verifyOTPSignup',
    resendMobileOtp: 'api/resendMobileOtp',
    resendEmailOtp: 'api/resendEmailOtp',
    login: 'api/login',
    forgotPassword: 'api/forgotPassword',
    userResetPassword: 'api/userResetPassword',
    getProfile: 'api/getProfile',
    updateProfile: 'api/updateProfile',
    changePassword: 'api/change-password',
    changeEmail: 'api/changeEmail',
    changeMobile: 'api/changeMobile',
    verifyOTPMobile: 'api/verifyOTPMobile',
    verifyOTPEmail: 'api/verifyOTPEmail',
    getDocument: 'api/documents',
    clientOnBoarding: 'api/clientOnBoarding',
    getProcessData: 'api/getProcessData',
    clientOnBoardingDocument: 'api/clientOnBoardingDocument',
    clientOnBoardingDocumentsNonIndividual: 'api/clientOnBoardingDocumentsNonIndividual',
    clientOnBoardingUploadSignaturedForm: 'api/clientOnBoardingUploadSignaturedForm',
    additionalCorpus: 'api/additionalCorpus',
    additionalCorpusUploadSignaturedForm: 'api/additionalCorpusUploadSignaturedForm',
    partialWithdrawal: 'api/partialWithdrawal',
    partialWithdrawalDocument: 'api/partialWithdrawalDocument',
    partialWithdrawalUploadSignaturedForm: 'api/partialWithdrawalUploadSignaturedForm',
    completeRedemption: 'api/completeRedemption',
    completeRedemptionDocument: 'api/completeRedemptionDocument',
    completeRedemptionUploadSignaturedForm: 'api/completeRedemptionUploadSignaturedForm',
}
export default pathObj;
