import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap';
import { ValidationForm, TextInput} from "react-bootstrap4-form-validation";
import _ from "lodash";
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Http from '../../../services/Http';
import apiPath from '../../../constants/apiPath';
import { adminLogin } from "../../../actions/index";
import { connect } from "react-redux";

import Header from '../../Elements/Header'; 
import Footer from '../../Elements/Footer';
import GetInTouch from '../../Elements/GetInTouch';
import bannerImage from '../../../assets/images/banner/banner-inner.png';

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class Login extends Component {
  constructor(props){
    super(props);
    // If you want to use the reset state function, you need to have a reference to the ValidationForm component
    //If your React < 16.3, check https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate:true,
      setFocusOnError:true,
      clearInputOnReset:false,
      account_type: 'admin',
      isRememberMe: (!_.isEmpty(localStorage.getItem('username')) && !_.isEmpty(localStorage.getItem('userPassword'))) ? true: false,
      username: localStorage.getItem('username') || '',
      password: localStorage.getItem('userPassword') || '',
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: (e.target.name === 'isRememberMe') ? e.target.checked : e.target.value
    });
    if((e.target.name === 'isRememberMe') && (e.target.checked === false)) {
      localStorage.removeItem('username');
      localStorage.removeItem('userPassword');
    }
  };

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.login(formData);
  };

  handleErrorSubmit = (e,formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  };

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  };

  async login(postData){
    let path = apiPath.login;
    if(!_.isEmpty(postData)) {
      this.setState({loading: true});
      let options = {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({loading: false});
      if(result.ok) {
        if(result.status === 200) {
          let resultData = await result.json();

          if(resultData.success) {
            let adminData = resultData.result;
            adminData.token = resultData.token;
            adminData.account_type = resultData.account_type;
            if(this.state.isRememberMe){
              localStorage.setItem('username', this.state.username);
              localStorage.setItem('userPassword', this.state.password);
            }
            this.props.adminLogin(adminData);
          } else {
            this.setState({submitFormError: resultData.msg});
            setTimeout(() => {
              this.setState({submitFormError: null});
            }, 5000);
          }
        }
      } else {
        
        this.setState({submitFormError: result.statusText});
        setTimeout(() => {
          this.setState({submitFormError: null});
        }, 5000);
      }
    } else {
      console.log('No Response');
    }
  }

  response(){
    if(this.state.submitFormError) {
      return <Alert color="danger">
        {this.state.submitFormError}
      </Alert>
    }
  }

  showLoading(){
    if(this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }

  render() {

    if(!_.isEmpty(this.props.admin)) {
      return <Redirect to='/my-account' />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
          <section class="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div class="container">
                  <div class="hero-content">
                      <h1 class="title text-white">Login</h1>
                      <ul class="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Login
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        {/* Middle Section*/}
          <section class="feature-section padding-top padding-bottom" id="feature">
            <div class="container">
                <div class="row justify-content-center feature-wrapper">
                  {/*Login Form*/}
                    <div class="col-md-7">
                      {this.response()}
                      <div class="box-block bg_img white-bg box-shadow bg-shape aos-item" data-aos="fade-up">
                        <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                          ref={this.formRef}
                          immediate={this.state.immediate}
                          setFocusOnError={this.state.setFocusOnError}
                          defaultErrorMessage={{ required: "Please enter something."}} >
                          <div className="row justify-content-center pt-5 pb-5">
                          <div className="col-lg-7">
                            <div className="text-center">
                              <h4 className="title mb-4 text-uppercase">Vallum Client Login</h4>
                            </div>
                            <div className="justify-content-center d-flex flex-wrap">
                              <div className="col-md-12">
                                <TextInput type={"hidden"} name="account_type" id="account_type" value={this.state.account_type} onChange={this.handleChange} placeholder={"Email/Mobile/Client Code"} required errorMessage="Please enter Email/Mobile/Client Code"/>
                                <InputGroup className="mb-3">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-user"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <TextInput name="username" id="username" value={this.state.username} onChange={this.handleChange} placeholder={"Email/Mobile/Client Code"} required errorMessage="Please enter Email/Mobile/Client Code"/>
                                </InputGroup>
                              </div>
                              <div className="col-md-12">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-lock"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <TextInput type={"password"} name="password" value={this.state.password} onChange={this.handleChange} id="password" placeholder={"Password"} required errorMessage="Please enter password"/>
                                </InputGroup>
                              </div>
                              <div className="col-md-12 mb-20" >
                                <FormGroup check className="pl-0">
                                  <div className="custom-control custom-checkbox text-left">
                                    <Input className="custom-control-input" type="checkbox" name="isRememberMe" id="isRememberMe" checked={this.state.isRememberMe}
                                      onChange={this.handleChange}/>
                                    <label className="custom-control-label small-text" for="isRememberMe">
                                      Remember Me
                                    </label>
                                  </div>
                                </FormGroup>
                              </div>
                              <div className="col-md-12 mb-10">
                                  <Button color="primary" type={"submit"} className="px-4">Login {this.showLoading()}</Button>
                              </div>
                              <div className="col-md-6 text-left">
                                <Link to="/forgot-password"><b><u>Forgot password?</u></b></Link>
                              </div>
                              <div className="col-md-6 text-right">
                                <b>New User?</b> <Link to="/sign-up"><b><u>Sign Up</u></b></Link>
                              </div>
                            </div>
                          </div>
                          </div>
                        </ValidationForm>
                    </div>
                    </div>
                  {/*Login Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
