import React, { Component } from 'react';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import _ from "lodash";

import { Card, CardBody, Col, Row, Table, Button, FormGroup } from 'reactstrap';
// import { transform, isString, isEmpty } from 'lodash';
import { Link,Redirect } from 'react-router-dom';
import helper from "../../constants/helper";

import { AlertList } from "react-bs-notifier";

import GetInTouch from '../Elements/GetInTouch';
import bannerImage from '../../assets/images/banner/banner-inner.png';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const MAX_LENGTH = 160;
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width         : '40%'
  }
};

class PMSRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      ourTeam: "",
      PMSData: "",
      productView: {},
    };
  }

  View(product) {
    this.setState({ productView: product })
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async getPMSData() {
    let path = apiPath.products;
    this.setState({loading: true});
    let options = {
      method: 'GET',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
    }
    let result = await Http(path, options);
    this.setState({loading: false});
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          console.log(resultUsersJson);
          this.setState({
            PMSData: resultUsersJson,
          });
          this.generate('success');

        } else {
          this.generate('danger');
          this.setState({message: resultJson.msg});
          setTimeout(() => {
            this.setState({message: null});
          }, 5000);
        }
      }
    } else {
      this.generate('danger');
      this.setState({message: result.statusText});
      setTimeout(() => {
        this.setState({message: null});
      }, 5000);
    }
  }

  componentDidMount(){
    this.getPMSData();
  }


  ourTeamList(){
    if(!_.isEmpty(this.state.PMSData)){
      return this.state.PMSData.map((value,index)=>{
        return <div className="item" key={index}>
          
          <div className="card">              
              <span className="title" dangerouslySetInnerHTML={{__html : value.title}}></span>
              <img src={value.photo} alt="image"></img>
          </div>

        </div>
      })
    }
  }

  render() {
    if (!_.isEmpty(this.state.productView)) {
      return <Redirect to={{
        pathname: '/contacts/view',
        productData: this.state.productView
      }} />
    }
    const product = this.props.product;
    return (
      <tr>
        <td>1</td>
        <td>
          <span className="title" dangerouslySetInnerHTML={{__html : product.title}}></span>
        {(product && product.title) || ''}</td>
        <td>{(product && product.createdAt) || ''}</td>
       
      </tr>
    )
  }

}
export default PMSRow;
