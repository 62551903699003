import React, { Component } from 'react';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";

import GetInTouch from '../Elements/GetInTouch';
import bannerImage from '../../assets/images/banner/banner-inner.png';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import Modal from "react-modal";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const MAX_LENGTH = 450;
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '0',
    right                 : '0',
    margin                : 'auto',
    transform             : 'translateY(-50%)',
    maxWidth              : '700px',
    width                 : 'calc(100% - 30px)'
  }
};



class OurTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      ourTeam: "",
      hoverClass : false,
      ourTeamData: "",
      readMoreModalOpen : false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async getOurTeamData() {
    let path = apiPath.ourTeam;
    this.setState({loading: true});
    let options = {
      method: 'GET',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
    }
    let result = await Http(path, options);
    this.setState({loading: false});
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({
            ourTeamData: resultUsersJson,
          });
          this.generate('success');

        } else {
          this.generate('danger');
          this.setState({message: resultJson.msg});
          setTimeout(() => {
            this.setState({message: null});
          }, 5000);
        }
      }
    } else {
      this.generate('danger');
      this.setState({message: result.statusText});
      setTimeout(() => {
        this.setState({message: null});
      }, 5000);
    }
  }

  componentDidMount(){
    this.getOurTeamData();
  }

  openModal = (value) => {
    console.log(value);
    
    this.setState({ 
      readMoreModalOpen: true,
      activeItemName: value.name,
      activeItemTitle: value.title,
      activeItemDesignation: value.designation,
      activeItemDescription: value.description,
      activeItemFb_url: value.fb_url,
      activeItemTwitter_url: value.twitter_url,
      activeItemPhoto: value.photo,
     });
 };

  closeModal(){
    this.setState({readMoreModalOpen:false});
  }

  ourTeamList(){
    if(!_.isEmpty(this.state.ourTeamData)){
      return this.state.ourTeamData.map((value,index)=>{
        return <div className={"col-md-6 col-lg-5 team-col"} key={index}>
          
          <div className="flip-card relative"> 
             <div class="front-back-face">
             <div className="front-face">
              <h4 className="team-title mb-1">{value.title}</h4>
              <div className="member-name" dangerouslySetInnerHTML={{__html : value.name}}></div>
              <div className="title-designation">{value.title}, {value.designation}</div>
              <div className="team-social-icon mt-2">
                <ul className="d-flex">
  				        <li><a href={value.fb_url ? value.fb_url : ""} target="_blank" className="circle"><i className="fa fa-facebook"></i></a></li>
                  <li><a href={value.twitter_url ? value.twitter_url : ""} target="_blank" className="circle"><i className="fa fa-twitter"></i></a></li>
				        </ul>                 
              </div>            
              <div className="team-photo"><img src={value.photo} alt="image"></img></div>
              </div>
              <div className="back-face">
              <h4 className="team-title mb-2">{value.title}</h4>
				     <div className="description" dangerouslySetInnerHTML={{__html : `${value.description ? value.description.substring(0, MAX_LENGTH) : ''}...`}}></div>
             <a className="click-effect custom-button white btn-sm" href="javascript:void(0)" key={value.id} onClick={() => this.openModal(value)}>Read More</a>
             </div>
             </div>
          </div>

        </div>

      })
    }
  }

  ourTeamDetail(){
      return (
          <div className="modal-content">
             
                  <a className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                    <i className="fa fa-plus-circle"></i>
                  </a>
               
                
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-3">
                        <div class="team-popup-img"><img className="our-team-modal-img" src={this.state.activeItemPhoto} alt="image"></img></div>
                     </div>
                     <div className="col-md-9">
                       <div className="modal-custom-content">
                     <div className="member-name" dangerouslySetInnerHTML={{__html : this.state.activeItemName}}></div>
                     
                     <div className="modal-title title-designation" id="exampleModalLongTitle">{this.state.activeItemTitle}, {this.state.activeItemDesignation}</div>
                     <div className="team-social-icon mt-2 mb-2">
                       <ul className="d-flex">
                         <li><a href={this.state.activeItemFb_url ? this.state.activeItemFb_url : ""} target="_blank" className="circle"><i className="fa fa-facebook circle"></i></a></li>
                         <li><a href={this.state.activeItemTwitter_url ? this.state.activeItemTwitter_url : ""} target="_blank" className="circle"><i className="fa fa-twitter circle"></i></a></li>                 
                        </ul>
                     </div>
                     <div className="description" dangerouslySetInnerHTML={{__html : this.state.activeItemDescription}}></div>
                     </div>
                     </div>
                     </div>
                 </div>
              
          </div>
      );
  }

  render() {
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
        <section className="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div className="container">
                  <div className="hero-content">
                      <h1 className="title text-white">Our Team</h1>
                      <ul className="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Our Team
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        <section className="feature-section padding-top padding-bottom" id="feature">
            <div className="container container-inner">
                <div className="row justify-content-center feature-wrapper">
                    <div className="col-md-10 col-sm-10 col-lg-8">
                        <div className="feature-item aos-item" data-aos="fade-up">
                            <div className="feature-content">
                                <h2 className="title">Meet Our Experts</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center feature-wrapper our-team-card">
                    {this.ourTeamList()}
                </div>
            </div>
        </section>

        
        { this.state.readMoreModalOpen ? 
            <Modal isOpen={this.state.readMoreModalOpen} onRequestClose={this.closeModal} style={customStyles} contentLabel="Subscribe Modal">
              {this.ourTeamDetail()}
            </Modal>
        : null}

        {/*GetInTouch*/}
        <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default OurTeam;
