import React, { Component } from 'react';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import _ from "lodash";

import { Card, CardBody, Col, Row, Table, Button, FormGroup } from 'reactstrap';
// import { transform, isString, isEmpty } from 'lodash';
import { Link,Redirect } from 'react-router-dom';
import helper from "../../constants/helper";

import PMSRow from './PMSRow';

import { AlertList } from "react-bs-notifier";

import GetInTouch from '../Elements/GetInTouch';
import bannerImage from '../../assets/images/banner/banner-inner.png';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import DateFormatMonthName from "../../constants/dateformatmonthname";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const MAX_LENGTH = 160;
class PMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      ourTeam: "",
      PMSData: {
      },
      // PMSData: []
      //   category_id: [],
      //   team_id: [],
      // }
      // users: {
      //   docs: []
      // }
    };
  }

  View(product) {
    this.setState({ productView: product })
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async getPMSData() {
    let path = apiPath.products;
    this.setState({loading: true});
    let options = {
      method: 'GET',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
    }
    let result = await Http(path, options);
    this.setState({loading: false});
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          console.log(resultUsersJson);
          this.setState({
            PMSData: resultUsersJson,
            // category_id: resultUsersJson.category_id,
            // team_id: resultUsersJson.team_id,
          });
          this.generate('success');

        } else {
          this.generate('danger');
          this.setState({message: resultJson.msg});
          setTimeout(() => {
            this.setState({message: null});
          }, 5000);
        }
      }
    } else {
      this.generate('danger');
      this.setState({message: result.statusText});
      setTimeout(() => {
        this.setState({message: null});
      }, 5000);
    }
  }

  componentDidMount(){
    this.getPMSData();
  }

  productList(){
    if(!_.isEmpty(this.state.PMSData)){
      return this.state.PMSData.map((value,index)=>{
        return <div className="item product-list row" key={index}>
          <div className="col-md-4">
            <img src={value.performance_photo} alt="image"></img>
          </div>
          <div className="col-md-8">
            <h4 className="view-more modal-title title" onClick={this.View.bind(this, value)} outline color="info" value={JSON.stringify(value)} dangerouslySetInnerHTML={{__html : value.title}}></h4>
            <div className="description" dangerouslySetInnerHTML={{__html : `${window.atob(value.content) ? window.atob(value.content).substring(0, MAX_LENGTH) : ''}...`}}></div>
            {/* <div className="d-flex justify-content-md-start" style={{ marginBottom : '10px'}}>
              <a className="view-more custom-button" href={value.category_id.pdf} target="_blank">Download fact Sheet</a>
          </div> */}
            <div className="grid-item-footer">  
            <div className="view-more custom-button" onClick={this.View.bind(this, value)} outline color="info" value={JSON.stringify(value)}>View More</div>
           </div>
            </div>
        </div>
      })
    }
  }

  render() {
    if (!_.isEmpty(this.state.productView)) {
      return <Redirect to={{
        pathname: '/pms-view',
        productData: this.state.productView
      }} />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
          <section class="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div class="container">
                  <div class="hero-content">
                      <h1 class="title text-white">Portfolio Management Services</h1>
                      <ul class="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Portfolio Management Services
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        <section className="pms-section padding-top padding-bottom">
            <div className="container container-inner">
               <div className="box-shadow box-block p-3 p-md-4 aos-item product-list-card" data-aos="fade-up">
                   {this.productList()} 
               </div>
            </div>
        </section>

        {/*GetInTouch*/}
        <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default PMS;
