import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import no_image from '../../assets/img/brand/no_image.png';

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class ChangeMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      mobileotp1 : '',
      mobileotp2 : '',
      mobileotp3 : '',
      mobileotp4 : '',
      showOTP: false,
      id: this.props.admin._id,
      name: this.props.admin.first_name ? this.props.admin.first_name : '',
      email: this.props.admin.email ? this.props.admin.email : '',
      mobile: this.props.admin.mobile ? this.props.admin.mobile : '',
      old_mobile: this.props.admin.mobile ? this.props.admin.mobile : '',
      profileDataForStore: {}
    };
    this.resendMobileOtp = this.resendMobileOtp.bind(this);
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    if(e.target.name == 'mobileotp1'){
      if(e.target.value != ''){
        document.getElementById("mobileotp2").focus();
      }
    }
    if(e.target.name == 'mobileotp2'){
      if(e.target.value != ''){
        document.getElementById("mobileotp3").focus();
      }else{
        document.getElementById("mobileotp1").focus();
      }
    }
    if(e.target.name == 'mobileotp3'){
      if(e.target.value != ''){
        document.getElementById("mobileotp4").focus();
      }else{
        document.getElementById("mobileotp2").focus();
      }
    }
    if(e.target.name == 'mobileotp4'){
      if(e.target.value == ''){
        document.getElementById("mobileotp3").focus();
      }
    }
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    if(this.state.mobile != this.state.old_mobile){
      this.changeMobile(formData);
    }else{
      this.setState({ message: "You haven't updated mobile number." });
      setTimeout(() => {
        this.generate('danger');
      },100);
    }
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async changeMobile(postData) {
    let path = apiPath.changeMobile;
    this.setState({ loading: true });
    postData.userId = this.state.id
    let options = {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(postData)
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg,showOTP : true});
          this.generate('success');
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async resendMobileOtp() {
    let path = apiPath.resendMobileOtp;
    let sendRequestData = {}
    sendRequestData.name = this.state.name
    sendRequestData.email = this.state.email
    sendRequestData.mobile = this.state.mobile
    let options = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(sendRequestData)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          this.setState({ message: resultData.msg });
          this.generate('success');
        } else {
          this.setState({ message: resultData.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  handleOTPSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.verifyOTPMobile(formData);
  }

  handleErrorOTPSubmit = (e, formData, errorInputs) => {

  }

  async verifyOTPMobile(postData) {
    let path = apiPath.verifyOTPMobile;
    if (!_.isEmpty(postData)) {
      this.setState({ loading: true });
      postData.userId = this.state.id
      postData.mobile = this.state.mobile
      let options = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {
          let resultData = await result.json();
          if (resultData.success) {
            let resultUsersJson = resultData.result;
            resultUsersJson.account_type = this.props.admin.account_type;
            resultUsersJson.token = this.props.admin.token;
            this.props.adminLogin(resultUsersJson);
            this.setState({ message: resultData.msg });
            this.generate('success');
            setTimeout(() => {
              this.setState({redirectTo : true})
            }, 1500);
          } else {
            this.setState({ message: resultData.msg });
            this.generate('danger');
          }
        }
      } else {
        this.setState({ message: result.statusText });
        this.generate('danger');
      }
    }
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/my-account" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
        <section class="profile-section section-bg padding-top padding-bottom">
             <div class="container container-inner">
             {this.message()}
             <div class="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                  {/*Change Mobile Number Form*/}
                  <div className="row justify-content-center after-login-pages no-gutters">
                      <Sidebar />
                            <div className="col-md-8 col-lg-9">
                            <div className="p-3 p-md-3">
                             <h4 className="title mb-4 text-left">Change Mobile Number</h4>
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                ref={this.formRef}
                                immediate={this.state.immediate}
                                setFocusOnError={this.state.setFocusOnError}
                                defaultErrorMessage={{ required: "Please enter something." }} >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="form-group">
                                          <TextInput
                                            name="mobile" id="mobile" placeholder={"Mobile Number*"} 
                                            onChange={this.handleChange} required
                                            value={this.state.mobile} disabled={this.state.showOTP ? "disabled" : false}
                                            maxLength={10}
                                            validator={(value) => validator.isNumeric(validator.trim(value))}
                                            errorMessage={{
                                               required: "Please enter Mobile Number",
                                              validator: "Mobile number must be a numeric only."
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6"><button type="submit" disabled={this.state.showOTP ? "disabled" : false}>Verify</button></div>
                                    </div>
                                  </div>
                                </div>
                              </ValidationForm>
                              {/* OTP Section*/}
                                {this.state.showOTP ? <div class="col-md-10 col-sm-10 col-lg-10 mt-2">
                                  <div class="feature-item aos-item" data-aos="fade-up">
                                    <ValidationForm onSubmit={this.handleOTPSubmit} onErrorSubmit={this.handleErrorOTPSubmit}
                                      ref={this.formRef}
                                      immediate={this.state.immediate}
                                      setFocusOnError={this.state.setFocusOnError}
                                      defaultErrorMessage={{ required: "Please enter something." }} >
                                      <Row>
                                        <div className="col-md-6">
                                          <h3>Validate Mobile</h3>
                                          <p>Please enter a validation code below that was sent to your mobile number.</p>
                                          <div><i className="fa fa-check-circle"></i></div>
                                          <Row className="justify-content-center">
                                            <div className="col-md-3">
                                              <div className="form-group otp-block">
                                                <TextInput value={this.state.mobileotp1} onChange={this.handleChange} name="mobileotp1" id="mobileotp1" required
                                                  maxLength={1}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group otp-block">
                                                <TextInput value={this.state.mobileotp2} onChange={this.handleChange} name="mobileotp2" id="mobileotp2" required
                                                  maxLength={1}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group otp-block">
                                                <TextInput value={this.state.mobileotp3} onChange={this.handleChange} name="mobileotp3" id="mobileotp3" required
                                                  maxLength={1}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group otp-block">
                                                <TextInput value={this.state.mobileotp4} onChange={this.handleChange} name="mobileotp4" id="mobileotp4" required
                                                  maxLength={1}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <a href="javascript:void(0)" onClick={this.resendMobileOtp}>Resend Code</a>
                                            </div>
                                          </Row>
                                        </div>
                                      </Row>
                                      <div className="row">
                                        <div className="col-md-6"><button type="submit">Submit</button></div>
                                      </div> 
                                    </ValidationForm>
                                  </div>
                                </div> : null }
                              {/* OTP Section*/}
                            </div>
                         </div>
                    </div>
                  {/*Change Mobile Number Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeMobile);
