import React, { Component } from 'react';
import {Label,Card,CardBody,Col,Row,Button, CardHeader, FormGroup, CardFooter} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import Modal from "react-modal";
import InformationRequest from '../Elements/InformationRequest';
import { adminLogin } from "../../actions/index";
import bannerImage from '../../assets/images/banner/banner-inner.png';
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
class GetInTouch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      subscribeModalOpen : false,
      name: "",
      email: "",
      mobile: "",
      city: "",
      state: "",
      country: "",
      work_profile: "",
      nature_of_business: "",
      redirectTo: "",
      contactSettings: "",
      message: ""
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.sendContactForm(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  async sendContactForm(postData) {
    let path = apiPath.sendContactForm;
    this.setState({ loading: true });
    let options = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          /*this.setState({
            name:"",email:"",mobile:"",city:"",state:"",country:"",work_profile:"",nature_of_business:""
          })*/
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async settingContacts() {
    let path = apiPath.settings;
    this.setState({loading: true});
    let options = {
      method: 'GET',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
    }
    let result = await Http(path, options);
    this.setState({loading: false});
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({
            contactSettings : resultUsersJson
          });
          this.generate('success');

        } else {
          this.generate('danger');
          this.setState({message: resultJson.msg});
          setTimeout(() => {
            this.setState({message: null});
          }, 5000);
        }
      }
    } else {
      this.generate('danger');
      this.setState({message: result.statusText});
      setTimeout(() => {
        this.setState({message: null});
      }, 5000);
    }
  }

  componentDidMount(){
    this.settingContacts();
  }

  render() {
    /*if(this.state.redirectTo){
      return <Redirect to="/get-in-touch" />
    }*/
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
          <section class="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div class="container">
                  <div class="hero-content">
                      <h1 class="title text-white">Get In Touch</h1>
                      <ul class="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Get In Touch
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        {/* Middle Section*/}
          <section class="contact-section padding-top padding-bottom" id="contact">
            <div class="container">
                <div class="row justify-content-center feature-wrapper">
                  {/*Contact Form*/}
                    <div class="col-lg-8 mb-4">
                      <div class="box-shadow box-block aos-item" data-aos="fade-up">
                        <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                          ref={this.formRef}
                          immediate={this.state.immediate}
                          setFocusOnError={this.state.setFocusOnError}
                          defaultErrorMessage={{ required: "Please enter something." }} >
                          <div className="p-3 p-md-4">
                            {this.message()}
                            <div className="text-center">
                              <h3 className="title mb-4">Please share your details, We shall contact you.</h3>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput value={this.state.name} onChange={this.handleChange} name="name" id="name" placeholder={"Name*"} required
                                    maxLength={50}
                                    errorMessage={{
                                      required: "Please enter name"
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput name="email" id="email" type="email" placeholder="Email*" required
                                    validator={(value) => validator.isEmail(value) && !validator.contains(value, '+')}
                                    maxLength={100}
                                    errorMessage={{  required: "Please enter email", validator: "Please enter a valid email" }}
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput
                                    name="mobile" id="mobile" placeholder={"Contact Number"} 
                                    onChange={this.handleChange}
                                    value={this.state.mobile}
                                    maxLength={10}
                                    validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                                    errorMessage={{
                                      validator: "Contact number must be a numeric only."
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                  <FormGroup>
                                    <TextInput value={this.state.nature_of_business} onChange={this.handleChange} name="nature_of_business" id="nature_of_business" placeholder={"Nature of Business*"} 
                                      maxLength={200}
                                      required
                                      errorMessage={{
                                        required: "Please enter nature of business"
                                      }}
                                    />
                                  </FormGroup>
                              </div>
                              <div className="col-md-6">
                                  <FormGroup>
                                    <SelectGroup name="work_profile" id="work_profile" onChange={this.handleChange} value={this.state.work_profile} required errorMessage={{
                                        required: "Please enter work profile" }} >
                                      <option value="">Select Work Profile</option>  
                                      <option value="Business">Business</option>
                                      <option value="Working-Professional">Working Professional</option>
                                    </SelectGroup>
                                  </FormGroup>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput value={this.state.city} onChange={this.handleChange} name="city" id="city" placeholder={"City*"} required 
                                    maxLength={50}
                                    errorMessage={{
                                      required: "Please enter city"
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput value={this.state.state} onChange={this.handleChange} name="state" id="state" placeholder={"State*"} required 
                                    maxLength={50}
                                    errorMessage={{
                                      required: "Please enter state"
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput value={this.state.country} onChange={this.handleChange} name="country" id="country" placeholder={"Country*"} required
                                    maxLength={15}
                                    errorMessage={{
                                      required: "Please enter country"
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-md-12"><button type="submit">Submit</button></div>
                            </div>  
                          </div>
                        </ValidationForm>
                      </div>
                    </div>
                  {/*Contact Form*/}

                  {/*Contact Details*/}
                    <div class="col-lg-4 mb-4">
                      <div class="box-shadow box-block p-3 p-md-4 aos-item height-full" data-aos="fade-up">
                      
                          <h3 class="title text-center">Contact Details</h3>
                          <div className="contact-info">
                            <p>
                              <i className="fa fa-map-marker mr-2"></i>
                              <span  dangerouslySetInnerHTML={{__html : this.state.contactSettings.address ? this.state.contactSettings.address : "36, Kalanagar, Near MHADA office, Bandra <br/> (East), Mumbai 400 051, India"}}></span>
                            </p>
                            <br />
                            <p><i className="fa fa-phone mr-2"></i>{this.state.contactSettings.support_mobile ? this.state.contactSettings.support_mobile :  "+91-22-26590841/42/44/46"}</p>
                            <p><i className="fa fa-envelope mr-2"></i><a href={this.state.contactSettings.support_email ? "mailto:"+this.state.contactSettings.support_email : "mailto:connect.vallum@vallum.in"}>{this.state.contactSettings.support_email ? this.state.contactSettings.support_email : "connect.vallum@vallum.in"}</a></p>
                            <p><i className="fa fa-laptop mr-2"></i>{this.state.contactSettings.web_url ? this.state.contactSettings.web_url :  "www.vallum.in"}</p>
                            <p><i className="fa fa-whatsapp mr-2"></i>{this.state.contactSettings.whatsapp_mobile ? this.state.contactSettings.whatsapp_mobile :  "+91-9876543210"}</p>
                            <ul className="list-inline">
                              <li className="list-inline-item"><a href={this.state.contactSettings.fb_url ? this.state.contactSettings.fb_url : "https://www.facebook.com/VallumCapitalAdvisors"} target="_blank"><i className="fa fa-facebook"></i></a></li>
                              <li className="list-inline-item"><a href={this.state.contactSettings.twitter_url ? this.state.contactSettings.twitter_url : "https://twitter.com/VallumConnect"} target="_blank"><i className="fa fa-twitter"></i></a></li>
                              <li className="list-inline-item"><a href={this.state.contactSettings.linkedin_url ? this.state.contactSettings.linkedin_url : "https://www.linkedin.com/company/vallum-capital-ad"} target="_blank"><i className="fa fa-linkedin"></i></a></li>
                            </ul>
                          </div>
                      
                      </div>
                    </div>
                  {/*Contact Details*/}
                  </div>
                  </div>
                  </section>
                  <section class="contact-right padding-bottom">
                  <div className="row">
                  {/* Googlemap */}
                    <div class="col-md-12 col-sm-12 col-lg-12">
                      <div class="aos-item">
                        <h3 className="mb-3 text-center">Mumbai Office</h3>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.2121535639444!2d72.84617171438617!3d19.05440765760353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c92126514ae3%3A0x39b0138830cba053!2sKala+Nagar+Society%2C+Bandra+Kurla+Complex+Road%2C+Kala+Nagar%2C+Bandra+East%2C+Mumbai%2C+Maharashtra+400051!5e0!3m2!1sen!2sin!4v1476451507022" frameborder="0" style={{border: "0px", pointerEvents: "none", width:"100%", height:"400px"}} allowfullscreen=""></iframe>
                      </div>
                    </div>
                  {/* Googlemap */}
               </div>
            
          </section>
        {/* Middle Section*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default GetInTouch;
