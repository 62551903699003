import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import no_image from '../../assets/img/brand/no_image.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const moment = require('moment');

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      max_date: new Date(),
      id: this.props.admin._id,
      username: this.props.admin.username ? this.props.admin.username : '',
      password: '',
      token: this.props.admin.token ? this.props.admin.token : '',
      first_name: this.props.admin.first_name ? this.props.admin.first_name : '',
      last_name: this.props.admin.last_name ? this.props.admin.last_name : '',
      email: this.props.admin.email ? this.props.admin.email : '',
      mobile: this.props.admin.mobile ? this.props.admin.mobile : '',
      dob:this.props.admin && this.props.admin.dob != '' ? new Date(moment(this.props.admin.dob)) : '',
      occupation: this.props.admin.occupation ? this.props.admin.occupation : '',
      company_name: this.props.admin.company_name ? this.props.admin.company_name : '',
      city: this.props.admin.city ? this.props.admin.city : '',
      country: this.props.admin.country ? this.props.admin.country : '',
      client_code: this.props.admin.client_code ? this.props.admin.client_code : '',
      work_profile: this.props.admin.work_profile ? this.props.admin.work_profile : '',
      nature_of_business: this.props.admin.nature_of_business ? this.props.admin.nature_of_business : '',
      photo: this.props.admin.photo ? this.props.admin.photo : '',
      profileDataForStore: {}
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangeDOB = date => {
    this.setState({
      dob: date
    })
  }

  handleChangeImg = (name, e) => {
    helper.getBase64(e.target.files[0], (result) => {
      this.setState({
        [name]: result
      });
    });
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.updateProfile(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async updateProfile(postData) {
    let path = apiPath.updateProfile;
    this.setState({ loading: true });
    let data = new FormData();
    data.append('photo', postData.photo);
    postData.user_id = this.state.id
    postData.dob = moment(this.state.dob).format('YYYY-MM-DD');
    data.append('userData', JSON.stringify(postData));
    let options = {
      method: 'PUT',
      headers: {},
      body: data
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();

        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          resultUsersJson.account_type = this.props.admin.account_type;
          resultUsersJson.token = this.props.admin.token;
          this.props.adminLogin(resultUsersJson);
          this.setState({ message: resultJson.msg, profileDataForStore: resultUsersJson });
          this.generate('success');
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getCountries() {
    let path = apiPath.getAllCountries;
    this.setState({ loading: true });
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData);
          this.setState({ countries: resultData.result })
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentWillUnmount() {
    if (!isEmpty(this.state.profileDataForStore)) {
      this.props.adminLogin(this.state.profileDataForStore);
    }
  }

  componentDidMount() {
    this.getCountries();
  }

  countries() {
    if (!_.isEmpty(this.state.countries)) {
      return this.state.countries.map((make, index) => {
        return <option key={index} id={index} value={make._id}>{make.name}</option>
      })
    }
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
          <section class="profile-section section-bg padding-top padding-bottom" id="my-profile">
            <div class="container container-inner">
                  {/*Login Form*/}
                      {this.message()}
                      <div class="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                        <div className="row justify-content-center after-login-pages no-gutters">
                            <Sidebar />
                            <div className="col-md-8 col-lg-9">
                              <div className="p-3 p-md-3">
                              <h4 className="title mb-4 text-left">My Account {this.state.client_code != '' ? "( Client Code : "+this.state.client_code+")" : ""}</h4>
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter something." }} >
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="profile-pic"><CardImg top width="50%" src={this.state.photo ? this.state.photo : no_image} alt="" /> </div>
                                    <div className="custom-file custom-profile-pic mb-4">
                                      <FileInput className={"custom-file-input"} name="photo" id="photo"
                                        errorMessage={{ required: "Please upload an image.", fileType: 'only Image files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                                        fileType={["jpeg", "png", "jpg"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'photo')} />
                                      <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Picture</label>
                                    </div>
                                  </div>
                                  <div className="col-md-9">
                                    <div className="row">
                                    <Col lg="6">
                                      <FormGroup>
                                          <TextInput value={this.state.first_name} onChange={this.handleChange} name="first_name" id="first_name" placeholder={"First Name*"} required
                                            maxLength={100}
                                            errorMessage={{
                                              required: "Please enter first name"
                                            }}
                                          />
                                       </FormGroup>
                                     </Col>
                                      <Col lg="6">
                                      <FormGroup>
                                          <TextInput value={this.state.last_name} onChange={this.handleChange} name="last_name" id="last_name" placeholder={"last Name*"} required
                                            maxLength={100}
                                            errorMessage={{
                                              required: "Please enter last name"
                                            }}
                                          />
                                      </FormGroup>
                                    </Col>
                                      <Col lg="6">
                                      <FormGroup>
                                          <TextInput name="email" id="email" type="email" placeholder="Email*" readOnly
                                            validator={(value) => validator.isEmail(value) && !validator.contains(value, '+')}
                                            maxLength={100}
                                            errorMessage={{  required: "Please enter email", validator: "Please enter a valid email" }}
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                          />
                                        </FormGroup>
                                        </Col>
                                      <Col lg="6">
                                      <FormGroup>
                                          <TextInput
                                            name="mobile" id="mobile" placeholder={"Mobile Number*"} readOnly
                                            onChange={this.handleChange}
                                            value={this.state.mobile} 
                                            maxLength={10}
                                            validator={(value) => validator.isNumeric(validator.trim(value))}
                                            errorMessage={{
                                               required: "Please enter Mobile Number",
                                              validator: "Mobile number must be a numeric only."
                                            }}
                                          />
                                      </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="datepic">
                                          <DatePicker
                                            selected={this.state.dob}
                                            name="dob"
                                            required 
                                            showYearDropdown
                                            yearDropdownItemNumber={10}
                                            onChange={this.handleChangeDOB}
                                            disabledKeyboardNavigation
                                            maxDate={this.state.max_date}
                                            className="form-control"
                                            selectsStart
                                            placeholderText="DOB*"
                                            /*isClearable*/
                                            dateFormat="yyyy-MM-dd"
                                            errorMessage= {{ required: "Please enter password."}}
                                          />
                                        </FormGroup>
                                        </Col>
                                      <Col lg="6">
                                          <FormGroup>
                                            <SelectGroup name="work_profile" id="work_profile" onChange={this.handleChange} value={this.state.work_profile} required errorMessage={{
                                                required: "Please enter work profile" }} disabled={this.state.showOTP ? "disabled" : false}disabled={this.state.showOTP ? "disabled" : false}>
                                              <option value="">Select Work Profile</option>  
                                              <option value="Business">Business</option>
                                              <option value="Working-Professional">Working Professional</option>
                                            </SelectGroup>
                                          </FormGroup>
                                          </Col>
                                      <Col lg="6">
                                          <FormGroup>
                                            <TextInput value={this.state.nature_of_business} onChange={this.handleChange} name="nature_of_business" id="nature_of_business" placeholder={"Nature of Business*"} 
                                              maxLength={200} disabled={this.state.showOTP ? "disabled" : false}
                                              required
                                              errorMessage={{
                                                required: "Please enter nature of business"
                                              }}
                                            />
                                          </FormGroup>
                                          </Col>
                                      <Col lg="6">
                                       <FormGroup>
                                          <TextInput value={this.state.city} onChange={this.handleChange} name="city" id="city" placeholder={"City*"} required 
                                            maxLength={50}
                                            errorMessage={{
                                              required: "Please enter city"
                                            }}
                                          />
                                       </FormGroup>
                                     </Col>
                                      <Col lg="6">
                                        
                                          <FormGroup>
                                            <SelectGroup name="country" id="country" onChange={this.handleChange} required errorMessage="Please select country" value={this.state.country} disabled={this.state.showOTP ? "disabled" : false}>
                                              <option value="">Select Country</option>  
                                              {this.countries()}
                                            </SelectGroup>
                                          </FormGroup>
                                        
                                          </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                          <TextInput value={this.state.company_name} onChange={this.handleChange} name="company_name" id="company_name" placeholder={"Company Name"} 
                                            maxLength={200}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup>
                                          <TextInput value={this.state.occupation} onChange={this.handleChange} name="occupation" id="occupation" placeholder={"Occupation"} 
                                            maxLength={200}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </div>
                                    <div className="row justify-content-center">
                                      <div className="col-md-12"><button type="submit">Submit</button></div>
                                    </div>
                                  </div>
                                </div>
                              </ValidationForm>
                            </div></div>
                          </div>
                      </div>
                  {/*Login Form*/}
              
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
