import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';

import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

import { adminLogin } from "../../actions/index";

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: admin => dispatch(adminLogin(admin))
  };
};

class DefaultLayout extends Component {
  render() {

    if (_.isEmpty(this.props.admin)) {

      return <Redirect from="/" to={(localStorage.getItem("redirect")? localStorage.getItem("redirect"): "/login")} />
    }


    function ShowAppNav(props) {
      var showBreads =navigation;
      return (
        <AppSidebarNav navConfig={showBreads} {...props} />
      );
    }

    function ShowBreadcrumb(props) {
      var showBreads = routes;
      return (
        <AppBreadcrumb appRoutes={showBreads}/>
      );
    }

    function AppRoutes(props) {
      var AccountRoutes = routes;
      return (
        <Switch>
          {AccountRoutes.map((route, idx) => {
              return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                  <route.component {...props} />
                )} />)
                : (null);
            },
          )}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      );
    }

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <ShowAppNav account_type={this.props.admin.account_type} {...this.props}/>
            {/*<AppSidebarNav navConfig={navigation} {...this.props} />*/}
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <ShowBreadcrumb account_type={this.props.admin.account_type} />
            <Container fluid>
              <AppRoutes account_type={this.props.admin.account_type} />
            </Container>
          </main>
          <AppAside fixed>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
