import React, { Component } from 'react';
import { Link , Redirect} from 'react-router-dom';
import { UncontrolledTooltip, Button, Card, CardBody, CardFooter, Col, Container, InputGroup, InputGroupAddon, InputGroupText, Row,FormGroup } from 'reactstrap';
import { ValidationForm, TextInput,SelectGroup } from "react-bootstrap4-form-validation";
import apiPath from "../../../constants/apiPath";
import _ from "lodash";
import Http from "../../../services/Http";
import validator from "validator";
import { AlertList } from "react-bs-notifier";
import helper from '../../../constants/helper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../../Elements/Header';
import Footer from '../../Elements/Footer';
import GetInTouch from '../../Elements/GetInTouch';
import bannerImage from '../../../assets/images/banner/banner-inner.png';
import { adminLogin } from "../../../actions/index";
import { connect } from "react-redux";
const moment = require('moment');

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class Register extends Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: true,
      position: "top",
      redirectTo : false,
      alerts: [],
      timeout: 500000,
      message: "",
      max_date: new Date(),
      name: '',
      mobile: '',
      email: '',
      password: '',
      confirm_password: '',
      city: '',
      work_profile: '',
      nature_of_business: '',
      acceptCheckbox: '',
      dob: '',
      country: '',
      emailotp1 : '',
      emailotp2 : '',
      emailotp3 : '',
      emailotp4 : '',
      mobileotp1 : '',
      mobileotp2 : '',
      mobileotp3 : '',
      mobileotp4 : '',
      showOTP: false,
      countries: []
    }
    this.resendEmailOtp = this.resendEmailOtp.bind(this);
    this.resendMobileOtp = this.resendMobileOtp.bind(this);
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;
    const idx = alerts.indexOf(alert);
    if (idx >= 0) {
      this.setState({
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle=""
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  handleChange = (e) => {
    if(e.target.name == 'emailotp1'){
      if(e.target.value != ''){
        document.getElementById("emailotp2").focus();
      }
    }
    if(e.target.name == 'emailotp2'){
      if(e.target.value != ''){
        document.getElementById("emailotp3").focus();
      }else{
        document.getElementById("emailotp1").focus();
      }
    }
    if(e.target.name == 'emailotp3'){
      if(e.target.value != ''){
        document.getElementById("emailotp4").focus();
      }else{
        document.getElementById("emailotp2").focus();
      }
    }
    if(e.target.name == 'emailotp4'){
      if(e.target.value == ''){
        document.getElementById("emailotp3").focus();
      }
    }
    if(e.target.name == 'mobileotp1'){
      if(e.target.value != ''){
        document.getElementById("mobileotp2").focus();
      }
    }
    if(e.target.name == 'mobileotp2'){
      if(e.target.value != ''){
        document.getElementById("mobileotp3").focus();
      }else{
        document.getElementById("mobileotp1").focus();
      }
    }
    if(e.target.name == 'mobileotp3'){
      if(e.target.value != ''){
        document.getElementById("mobileotp4").focus();
      }else{
        document.getElementById("mobileotp2").focus();
      }
    }
    if(e.target.name == 'mobileotp4'){
      if(e.target.value == ''){
        document.getElementById("mobileotp3").focus();
      }
    }
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangeDOB = date => {
    this.setState({
      dob: date
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.register(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {

  }

  handleOTPSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.verifyOTPSignup(formData);
  }

  handleErrorOTPSubmit = (e, formData, errorInputs) => {

  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
    this.setState({
      name: '',
      mobile: '',
      email: '',
      password: '',
      confirm_password: '',
      city: '',
      work_profile: '',
      nature_of_business: '',
      acceptCheckbox: '',
      dob: '',
      country: '',
      emailotp1 : '',
      emailotp2 : '',
      emailotp3 : '',
      emailotp4 : '',
      mobileotp1 : '',
      mobileotp2 : '',
      mobileotp3 : '',
      mobileotp4 : '',
      showOTP: false
    });
  }

  async register(postData) {
    let path = apiPath.register;
    if (!_.isEmpty(postData)) {
      this.setState({ loading: true });
      postData.dob = moment(this.state.dob).format('YYYY-MM-DD');
      let options = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {
          let resultData = await result.json();
          if (resultData.success) {
            this.setState({ message: resultData.msg,showOTP:true });
            this.generate('success');
            //this.resetForm();
          } else {
            this.setState({ message: resultData.msg });
            this.generate('danger');
          }
        }
      } else {
        this.setState({ message: result.statusText });
        this.generate('danger');
      }
    }
  }

  async verifyOTPSignup(postData) {
    let path = apiPath.verifyOTPSignup;
    if (!_.isEmpty(postData)) {
      this.setState({ loading: true });
      postData.dob = moment(this.state.dob).format('YYYY-MM-DD');
      postData.name = this.state.name
      postData.email = this.state.email
      postData.mobile = this.state.mobile
      postData.work_profile = this.state.work_profile
      postData.nature_of_business = this.state.nature_of_business
      postData.password = this.state.password
      postData.city = this.state.city
      postData.country = this.state.country
      let options = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {
          let resultData = await result.json();
          if (resultData.success) {
            this.setState({ message: resultData.msg });
            this.generate('success');
            this.resetForm();
            setTimeout(() => {
              this.setState({redirectTo : true})
            }, 1500);
          } else {
            this.setState({ message: resultData.msg });
            this.generate('danger');
          }
        }
      } else {
        this.setState({ message: result.statusText });
        this.generate('danger');
      }
    }
  }

  async resendMobileOtp() {
    let path = apiPath.resendMobileOtp;
    let sendRequestData = {}
    sendRequestData.name = this.state.name
    sendRequestData.email = this.state.email
    sendRequestData.mobile = this.state.mobile
    let options = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(sendRequestData)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          this.setState({ message: resultData.msg });
          this.generate('success');
        } else {
          this.setState({ message: resultData.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async resendEmailOtp() {
    let path = apiPath.resendEmailOtp;
    let sendRequestData = {}
    sendRequestData.name = this.state.name
    sendRequestData.email = this.state.email
    sendRequestData.mobile = this.state.mobile
    let options = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(sendRequestData)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          this.setState({ message: resultData.msg });
          this.generate('success');
        } else {
          this.setState({ message: resultData.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getCountries() {
    let path = apiPath.getAllCountries;
    this.setState({ loading: true });
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData);
          this.setState({ countries: resultData.result })
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentDidMount() {
    this.getCountries();
  }

  countries() {
    if (!_.isEmpty(this.state.countries)) {
      return this.state.countries.map((make, index) => {
        return <option key={index} id={index} value={make._id}>{make.name}</option>
      })
    }
  }

  showLoading() {
    if (this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }

  render() {
    if(this.state.redirectTo){
      return <Redirect to="/my-account" />
    }
    if(!_.isEmpty(this.props.admin)) {
      return <Redirect to='/my-account' />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
          <section className="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div className="container">
                  <div className="hero-content">
                      <h1 className="title text-white">Sign Up</h1>
                      <ul className="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Sign Up
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        {/* Middle Section*/}
          <section className="feature-section padding-top padding-bottom" id="feature">
            <div class="custom-message">{this.message()}</div> 
            <div className="container">
                <div className="row justify-content-center feature-wrapper">
                  {/*Registration Form*/}
                    <div className="col-md-10 col-sm-10 col-lg-10">
                      <div className="box-block bg_img white-bg box-shadow bg-shape aos-item aos-init aos-animate" data-aos="fade-up">
                        <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                          ref={this.formRef}
                          immediate={this.state.immediate}
                          setFocusOnError={this.state.setFocusOnError}
                          defaultErrorMessage={{ required: "Please enter something." }} >
                          <div className="p-3 p-lg-5">
                            <div className="text-center">
                              <h4 className="title mb-4 text-uppercase">Vallum Client Sign Up</h4>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput value={this.state.name} onChange={this.handleChange} name="name" id="name" placeholder={"Full Name*"} required
                                    maxLength={100} disabled={this.state.showOTP ? "disabled" : false}
                                    errorMessage={{
                                      required: "Please enter full name"
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <FormGroup className="datepic">
                                  <DatePicker
                                    selected={this.state.dob}
                                    name="dob"
                                    required 
                                    disabled={this.state.showOTP ? "disabled" : false}
                                    showYearDropdown
                                    yearDropdownItemNumber={40}
                                    scrollableYearDropdown
                                    onChange={this.handleChangeDOB}
                                    disabledKeyboardNavigation
                                    maxDate={this.state.max_date}
                                    className="form-control"
                                    selectsStart
                                    placeholderText="DOB*"
                                    /*isClearable*/
                                    dateFormat="yyyy-MM-dd"
                                    errorMessage= {{ required: "Please enter password."}}
                                  />
                                </FormGroup>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput name="email" id="email" type="email" placeholder="Email*" required
                                    disabled={this.state.showOTP ? "disabled" : false}
                                    validator={(value) => validator.isEmail(value) && !validator.contains(value, '+')}
                                    maxLength={100}
                                    errorMessage={{  required: "Please enter email", validator: "Please enter a valid email" }}
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput
                                    name="mobile" id="mobile" placeholder={"Mobile Number*"} 
                                    onChange={this.handleChange} required
                                    value={this.state.mobile} disabled={this.state.showOTP ? "disabled" : false}
                                    maxLength={10}
                                    validator={(value) => validator.isNumeric(validator.trim(value))}
                                    errorMessage={{
                                       required: "Please enter Mobile Number",
                                      validator: "Mobile number must be a numeric only."
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                  <FormGroup>
                                    <SelectGroup className="custom-select" name="work_profile" id="work_profile" onChange={this.handleChange} value={this.state.work_profile} required errorMessage={{
                                        required: "Please enter work profile" }} disabled={this.state.showOTP ? "disabled" : false}disabled={this.state.showOTP ? "disabled" : false}>
                                      <option value="">Select Work Profile</option>  
                                      <option value="Business">Business</option>
                                      <option value="Working-Professional">Working Professional</option>
                                    </SelectGroup>
                                  </FormGroup>
                              </div>
                              <div className="col-md-6">
                                  <FormGroup>
                                    <TextInput value={this.state.nature_of_business} onChange={this.handleChange} name="nature_of_business" id="nature_of_business" placeholder={"Nature of Business*"} 
                                      maxLength={200} disabled={this.state.showOTP ? "disabled" : false}
                                      required
                                      errorMessage={{
                                        required: "Please enter nature of business"
                                      }}
                                    />
                                  </FormGroup>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <TextInput value={this.state.city} onChange={this.handleChange} name="city" id="city" placeholder={"City*"} required 
                                    maxLength={50}
                                    errorMessage={{
                                      required: "Please enter city"
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <FormGroup>
                                    <SelectGroup className="custom-select" name="country" id="country" onChange={this.handleChange} required errorMessage="Please select country" value={this.state.country} disabled={this.state.showOTP ? "disabled" : false}>
                                      <option value="">Select Country</option>  
                                      {this.countries()}
                                    </SelectGroup>
                                  </FormGroup>
                                </div>
                              </div>
                              <Col xs="6">
                                <FormGroup>
                                  <TextInput type="password" value={this.state.password} onChange={this.handleChange} name="password" id="password" placeholder={"Password*"}
                                    required disabled={this.state.showOTP ? "disabled" : false}
                                    maxLength={10}
                                    pattern="^(?=.*\d)(?=.*[a-zA-Z]).{6,10}$"
                                    errorMessage={{ required: "Please enter password.", pattern: "Password must be 6 to 10 characters long and should include letters and numbers." }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs="6">
                                <FormGroup>
                                  <TextInput type="password" value={this.state.confirm_password} onChange={this.handleChange} name="confirm_password" id="confirm_password" placeholder={"Confirm Password*"}
                                    required disabled={this.state.showOTP ? "disabled" : false}
                                    maxLength={10}
                                    validator={(value) => validator.equals(value, this.state.password)}
                                    errorMessage={{ required: "Please enter confirm password.", validator: "Password and confirm password does not match." }}
                                  />
                                </FormGroup>
                              </Col>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="custom-control custom-checkbox text-left">
                                    <input className="custom-control-input" type="checkbox" value="1" name="acceptCheckbox" id="acceptCheckbox" required  disabled={this.state.showOTP ? "disabled" : false}/>
                                    <label className="custom-control-label small-text" htmlFor="acceptCheckbox">
                                      I have read and accept the Vallum <a href="/terms-and-conditions" target="_blank"><u>Terms & Conditions</u></a> and <a href="/privacy-policy" target="_blank"><u>Privacy Policy</u></a>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-md-12"><button type="submit" disabled={this.state.showOTP ? "disabled" : false}>Create Account</button></div>
                            </div>  
                          </div>
                        </ValidationForm>
                      </div>
                     
                    </div>
                   
                  {/*Registration Form*/}
              
                
                </div> 
                <div className="row justify-content-center feature-wrapper">
                  {/* OTP Section*/}
                    {this.state.showOTP ? <div class="col-md-10 col-sm-10 col-lg-10">
                     
                        <ValidationForm onSubmit={this.handleOTPSubmit} onErrorSubmit={this.handleErrorOTPSubmit}
                          ref={this.formRef}
                          immediate={this.state.immediate}
                          setFocusOnError={this.state.setFocusOnError}
                          defaultErrorMessage={{ required: "Please enter something." }} >
                          <Row className="justify-content-center">
                          
                            <div className="col-md-6">
                            <div className="box-block white-bg box-shadow aos-item aos-init mt-4 aos-animate" data-aos="fade-up">
                              <div className="p-3 p-lg-5 text-center">
                                <h5 className="text-uppercase mb-2">Validate Email</h5>
                                <p class="small-text">Please enter a validation code below that was sent to your email address.</p>
                                <div className="check-icon mb-4"><i className="icon-check icon-green fa-2x"></i></div>
                              
                                <div className="d-flex otp-block justify-content-center">
                                      <TextInput value={this.state.emailotp1} onChange={this.handleChange} name="emailotp1" id="emailotp1" required
                                        maxLength={1}
                                      />
                                      <TextInput value={this.state.emailotp2} onChange={this.handleChange} name="emailotp2" id="emailotp2" required
                                        maxLength={1}
                                      />
                                      <TextInput value={this.state.emailotp3} onChange={this.handleChange} name="emailotp3" id="emailotp3" required
                                        maxLength={1}
                                      />
                                      <TextInput value={this.state.emailotp4} onChange={this.handleChange} name="emailotp4" id="emailotp4" required
                                        maxLength={1}
                                      />
                                  </div>
                                  <div className="d-flex justify-content-center mt-1">
                                    <a class="small-text" href="javascript:void(0)" onClick={this.resendEmailOtp}><u>Resend Code</u></a>
                                  </div>
                                
                              </div></div>
                            
                            </div>
                            <div className="col-md-6">
                            <div className="box-block white-bg box-shadow aos-item aos-init mt-4 aos-animate" data-aos="fade-up">
                              <div className="p-3 p-lg-5 text-center">
                              <h5 className="text-uppercase mb-2">Validate Mobile</h5>
                              <p class="small-text">Please enter a validation code below that was sent to your mobile number.</p>
                              <div className="check-icon mb-4"><i className="icon-check icon-green fa-2x"></i></div>
                              <div className="d-flex otp-block justify-content-center">
                                   <TextInput value={this.state.mobileotp1} onChange={this.handleChange} name="mobileotp1" id="mobileotp1" required
                                      maxLength={1}
                                    />
                                   <TextInput value={this.state.mobileotp2} onChange={this.handleChange} name="mobileotp2" id="mobileotp2" required
                                      maxLength={1}
                                    />
                                    <TextInput value={this.state.mobileotp3} onChange={this.handleChange} name="mobileotp3" id="mobileotp3" required
                                      maxLength={1}
                                    />
                                   <TextInput value={this.state.mobileotp4} onChange={this.handleChange} name="mobileotp4" id="mobileotp4" required
                                      maxLength={1}
                                    />
                                 </div>
                                <div className="d-flex justify-content-center mt-1">
                                  <a class="small-text" href="javascript:void(0)" onClick={this.resendMobileOtp}><u>Resend Code</u></a>
                                </div>
                              
                            </div> </div></div>
                          </Row>
                         
                          <div className="row justify-content-center mt-4">
                            <div className="col-md-2"><button type="submit">Submit</button></div>
                          </div> 
                        </ValidationForm>
                     
                    </div> : null }
                  {/* OTP Section*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}

export default  connect(mapStateToProps, mapDispatchToProps)(Register);
