import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Table, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import no_image from '../../assets/img/brand/no_image.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const moment = require('moment');

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};


class PartialWithdrawalForm extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.admin)
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      occupation: this.props.admin ? this.props.admin.occupation : "",
      holder_name_one: this.props.admin && this.props.admin.first_name ? this.props.admin.first_name+' '+this.props.admin.last_name : "",
      pms_account_number: this.props.admin && this.props.admin.client_code ? this.props.admin.client_code : "",
      investment_strategy_product: "604085283f1e5e227853d121",
      mode_of_redemption: "",
      amount_of_capital_redemption_inr: "",

      name_of_security_first: "",
      quantity_first: "",

      name_of_security_second: "",
      quantity_second: "",

      name_of_security_third: "",
      quantity_third: "",

      name_of_security_fourth: "",
      quantity_fourth: "",

      name_of_security_fifth: "",
      quantity_fifth: "",

      name_of_security_sixth: "",
      quantity_sixth: "",

      name_of_security_seventh: "",
      quantity_seventh: "",

      name_of_security_eighth: "",
      quantity_eighth: "",

      name_of_security_ninth: "",
      quantity_ninth: "",

      name_of_security_ten: "",
      quantity_ten: "",

      name_of_security_eleven: "",
      quantity_eleven: "",

      name_of_security_twelve: "",
      quantity_twelve: "",

      name_of_security_thirteen: "",
      quantity_thirteen: "",

      name_of_security_fourteen: "",
      quantity_fourteen: "",

      name_of_security_fifteen: "",
      quantity_fifteen: "",

      name_of_security_sixteen: "",
      quantity_sixteen: "",

      name_of_security_seventeen: "",
      quantity_seventeen: "",

      name_of_security_eighteen: "",
      quantity_eighteen: "",

      name_of_security_ninteen: "",
      quantity_ninteen: "",

      name_of_security_twenty: "",
      quantity_twenty: "",

      name_of_security_tone: "",
      quantity_tone: "",

      name_of_security_ttwo: "",
      quantity_ttwo: "",

      name_of_security_tthree: "",
      quantity_tthree: "",

      name_of_security_tfour: "",
      quantity_tfour: "",

      name_of_security_tfive: "",
      quantity_tfive: "",
      partilWithdrawalStatus : "",

      id: this.props.admin._id,
      profileDataForStore: {},
      clientCategory: "",
      s2 : "shide",
      s3 : "shide",
      s4 : "shide",
      s5 : "shide",
      s6 : "shide",
      s7 : "shide",
      s8 : "shide",
      s9 : "shide",
      s10 : "shide",
      s11 : "shide",
      s12 : "shide",
      s13 : "shide",
      s14 : "shide",
      s15 : "shide",
      s16 : "shide",
      s17 : "shide",
      s18 : "shide",
      s19 : "shide",
      s20 : "shide",
      s21 : "shide",
      s22 : "shide",
      s23 : "shide",
      s24 : "shide",
      s25 : "shide",
      b1 : "bshow",
      b2 : "bshow",
      b3 : "bshow",
      b4 : "bshow",
      b5 : "bshow",
      b6 : "bshow",
      b7 : "bshow",
      b8 : "bshow",
      b9 : "bshow",
      b10 : "bshow",
      b11 : "bshow",
      b12 : "bshow",
      b13 : "bshow",
      b14 : "bshow",
      b15 : "bshow",
      b16 : "bshow",
      b17 : "bshow",
      b18 : "bshow",
      b19 : "bshow",
      b20 : "bshow",
      b21 : "bshow",
      b22 : "bshow",
      b23 : "bshow",
      b24 : "bshow",
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.partialWithdrawal(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  handleButtonChange= (e) =>{
    let stat = e.target.getAttribute('data_type');
    var ele = document.getElementsByClassName(stat);
    ele[0].classList.remove("shide");
    e.target.classList.remove("bshow");
    e.target.classList.add("shide");
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async partialWithdrawal(postData) {
    let path = apiPath.partialWithdrawal;
    this.setState({ loading: true });
    postData.user_id = this.state.id
    postData.mode_of_redemption = this.state.mode_of_redemption != "" ? this.state.mode_of_redemption : null;
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(postData)
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            this.setState({redirectTo: true});
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProducts() {
    let path = apiPath.products;
    this.setState({ loading: true });
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData);
          this.setState({ products: resultData.result })
          await this.getProcessData('Partial-Withdrawal');
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProcessData(processName) {
    let path = apiPath.getProcessData;
    this.setState({ loading: true });
    var data = {}
    data.user_id = this.state.id
    data.process = processName
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData.result,'resultData.result');
          let processData = resultData.result;
          if(processData){
            this.setState({  
              investment_strategy_product: processData && processData.investment_strategy_product ? processData.investment_strategy_product : "",
              mode_of_redemption: processData && processData.mode_of_redemption ? processData.mode_of_redemption : "",
              holder_name_one: processData && processData.holder_name_one ? processData.holder_name_one : "",
              pms_account_number: processData && processData.pms_account_number ? processData.pms_account_number : "",
              amount_of_capital_redemption_inr: processData && processData.amount_of_capital_redemption_inr ? processData.amount_of_capital_redemption_inr : "",

              name_of_security_first: processData && processData.name_of_security_first ? processData.name_of_security_first : "",
              quantity_first: processData && processData.quantity_first ? processData.quantity_first : "",

              name_of_security_second: processData && processData.name_of_security_second ? processData.name_of_security_second : "",
              quantity_second: processData && processData.quantity_second ? processData.quantity_second : "",

              name_of_security_third: processData && processData.name_of_security_third ? processData.name_of_security_third : "",
              quantity_third: processData && processData.quantity_third ? processData.quantity_third : "",

              name_of_security_fourth: processData && processData.name_of_security_fourth ? processData.name_of_security_fourth : "",
              quantity_fourth: processData && processData.quantity_fourth ? processData.quantity_fourth : "",

              name_of_security_fifth: processData && processData.name_of_security_fifth ? processData.name_of_security_fifth : "",
              quantity_fifth: processData && processData.quantity_fifth ? processData.quantity_fifth : "",

              name_of_security_sixth: processData && processData.name_of_security_sixth ? processData.name_of_security_sixth : "",
              quantity_sixth: processData && processData.quantity_sixth ? processData.quantity_sixth : "",

              name_of_security_seventh: processData && processData.name_of_security_seventh ? processData.name_of_security_seventh : "",
              quantity_seventh: processData && processData.quantity_seventh ? processData.quantity_seventh : "",

              name_of_security_eighth: processData && processData.name_of_security_eighth ? processData.name_of_security_eighth : "",
              quantity_eighth: processData && processData.quantity_eighth ? processData.quantity_eighth : "",

              name_of_security_ninth: processData && processData.name_of_security_ninth ? processData.name_of_security_ninth : "",
              quantity_ninth: processData && processData.quantity_ninth ? processData.quantity_ninth : "",

              name_of_security_ten: processData && processData.name_of_security_ten ? processData.name_of_security_ten : "",
              quantity_ten: processData && processData.quantity_ten ? processData.quantity_ten : "",

              name_of_security_eleven: processData && processData.name_of_security_eleven ? processData.name_of_security_eleven : "",
              quantity_eleven: processData && processData.quantity_eleven ? processData.quantity_eleven : "",

              name_of_security_twelve: processData && processData.name_of_security_twelve ? processData.name_of_security_twelve : "",
              quantity_twelve: processData && processData.quantity_twelve ? processData.quantity_twelve : "",

              name_of_security_thirteen: processData && processData.name_of_security_thirteen ? processData.name_of_security_thirteen : "",
              quantity_thirteen: processData && processData.quantity_thirteen ? processData.quantity_thirteen : "",

              name_of_security_fourteen: processData && processData.name_of_security_fourteen ? processData.name_of_security_fourteen : "",
              quantity_fourteen: processData && processData.quantity_fourteen ? processData.quantity_fourteen : "",

              name_of_security_fifteen: processData && processData.name_of_security_fifteen ? processData.name_of_security_fifteen : "",
              quantity_fifteen: processData && processData.quantity_fifteen ? processData.quantity_fifteen : "",

              name_of_security_sixteen: processData && processData.name_of_security_sixteen ? processData.name_of_security_sixteen : "",
              quantity_sixteen: processData && processData.quantity_sixteen ? processData.quantity_sixteen : "",

              name_of_security_seventeen: processData && processData.name_of_security_seventeen ? processData.name_of_security_seventeen : "",
              quantity_seventeen: processData && processData.quantity_seventeen ? processData.quantity_seventeen : "",

              name_of_security_eighteen: processData && processData.name_of_security_eighteen ? processData.name_of_security_eighteen : "",
              quantity_eighteen: processData && processData.quantity_eighteen ? processData.quantity_eighteen : "",

              name_of_security_ninteen: processData && processData.name_of_security_ninteen ? processData.name_of_security_ninteen : "",
              quantity_ninteen: processData && processData.quantity_ninteen ? processData.quantity_ninteen : "",

              name_of_security_twenty: processData && processData.name_of_security_twenty ? processData.name_of_security_twenty : "",
              quantity_twenty: processData && processData.quantity_twenty ? processData.quantity_twenty : "",

              name_of_security_tone: processData && processData.name_of_security_tone ? processData.name_of_security_tone : "",
              quantity_tone: processData && processData.quantity_tone ? processData.quantity_tone : "",

              name_of_security_ttwo: processData && processData.name_of_security_ttwo ? processData.name_of_security_ttwo : "",
              quantity_ttwo: processData && processData.quantity_ttwo ? processData.quantity_ttwo : "",

              name_of_security_tthree: processData && processData.name_of_security_tthree ? processData.name_of_security_tthree : "",
              quantity_tthree: processData && processData.quantity_tthree ? processData.quantity_tthree : "",

              name_of_security_tfour: processData && processData.name_of_security_tfour ? processData.name_of_security_tfour : "",
              quantity_tfour: processData && processData.quantity_tfour ? processData.quantity_tfour : "",

              name_of_security_tfive: processData && processData.name_of_security_tfive ? processData.name_of_security_tfive : "",
              quantity_tfive: processData && processData.quantity_tfive ? processData.quantity_tfive : "",
            })
          }
          if(!_.isEmpty(this.state.name_of_security_second)){
            this.setState({
              s2:"",
              b1:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_third)){
            this.setState({
              s3:"",
              b2:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fourth)){
            this.setState({
              s4:"",
              b3:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fifth)){
            this.setState({
              s5:"",
              b4:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_sixth)){
            this.setState({
              s6:"",
              b5:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_seventh)){
            this.setState({
              s7:"",
              b6:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_eighth)){
            this.setState({
              s8:"",
              b7:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ninth)){
            this.setState({
              s9:"",
              b8:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ten)){
            this.setState({
              s10:"",
              b9:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_eleven)){
            this.setState({
              s11:"",
              b10:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_twelve)){
            this.setState({
              s12:"",
              b11:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_thirteen)){
            this.setState({
              s13:"",
              b12:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fourteen)){
            this.setState({
              s14:"",
              b13:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fifteen)){
            this.setState({
              s15:"",
              b14:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_sixteen)){
            this.setState({
              s16:"",
              b15:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_seventeen)){
            this.setState({
              s17:"",
              b16:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_eighteen)){
            this.setState({
              s18:"",
              b17:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ninteen)){
            this.setState({
              s19:"",
              b18:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_twenty)){
            this.setState({
              s20:"",
              b19:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tone)){
            this.setState({
              s21:"",
              b20:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ttwo)){
            this.setState({
              s22:"",
              b21:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tthree)){
            this.setState({
              s23:"",
              b22:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tfour)){
            this.setState({
              s24:"",
              b23:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tfive)){
            this.setState({
              s25:"",
              b24:"shide"
            })
          }
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  products() {
    if (!_.isEmpty(this.state.products)) {
      return this.state.products.map((make, index) => {
        return <option key={index} id={index} value={make._id}>{make.title}</option>
      })
    }
  }

  securityDetailsIndividual(){
    return <tbody>
      <tr>
        <td>
          <TextInput type="text" value={this.state.name_of_security_first} onChange={this.handleChange} 
            name="name_of_security_first" id="name_of_security_first" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_first} onChange={this.handleChange} 
            name="quantity_first" id="quantity_first" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} data_type="second-section" className={this.state.b1} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>

      <tr className={"second-section "+this.state.s2 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_second} onChange={this.handleChange} 
            name="name_of_security_second" id="name_of_security_second" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_second} onChange={this.handleChange} 
            name="quantity_second" id="quantity_second" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="third-section" className={this.state.b2}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"third-section "+this.state.s3 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_third} onChange={this.handleChange} 
            name="name_of_security_third" id="name_of_security_third" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_third} onChange={this.handleChange} 
            name="quantity_third" id="quantity_third" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fourth-section" className={this.state.b3}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fourth-section "+this.state.s4 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fourth} onChange={this.handleChange} 
            name="name_of_security_fourth" id="name_of_security_fourth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fourth} onChange={this.handleChange} 
            name="quantity_fourth" id="quantity_fourth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fifth-section" className={this.state.b4}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fifth-section "+this.state.s5 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fifth} onChange={this.handleChange} 
            name="name_of_security_fifth" id="name_of_security_fifth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fifth} onChange={this.handleChange} 
            name="quantity_fifth" id="quantity_fifth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="sixth-section" className={this.state.b5}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"sixth-section "+this.state.s6 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_sixth} onChange={this.handleChange} 
            name="name_of_security_sixth" id="name_of_security_sixth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_sixth} onChange={this.handleChange} 
            name="quantity_sixth" id="quantity_sixth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="seventh-section" className={this.state.b6}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"seventh-section "+this.state.s7 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_seventh} onChange={this.handleChange} 
            name="name_of_security_seventh" id="name_of_security_seventh" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_seventh} onChange={this.handleChange} 
            name="quantity_seventh" id="quantity_seventh" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="eighth-section" className={this.state.b7}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"eighth-section "+this.state.s8 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_eighth} onChange={this.handleChange} 
            name="name_of_security_eighth" id="name_of_security_eighth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_eighth} onChange={this.handleChange} 
            name="quantity_eighth" id="quantity_eighth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ninth-section" className={this.state.b8}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ninth-section "+this.state.s9 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ninth} onChange={this.handleChange} 
            name="name_of_security_ninth" id="name_of_security_ninth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ninth} onChange={this.handleChange} 
            name="quantity_ninth" id="quantity_ninth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ten-section" className={this.state.b9}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ten-section "+this.state.s10 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ten} onChange={this.handleChange} 
            name="name_of_security_ten" id="name_of_security_ten" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ten} onChange={this.handleChange} 
            name="quantity_ten" id="quantity_ten" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="eleven-section" className={this.state.b10}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"eleven-section "+this.state.s11 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_eleven} onChange={this.handleChange} 
            name="name_of_security_eleven" id="name_of_security_eleven" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_eleven} onChange={this.handleChange} 
            name="quantity_eleven" id="quantity_eleven" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="twelve-section" className={this.state.b11}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"twelve-section "+this.state.s12 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_twelve} onChange={this.handleChange} 
            name="name_of_security_twelve" id="name_of_security_twelve" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_twelve} onChange={this.handleChange} 
            name="quantity_twelve" id="quantity_twelve" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="thirteen-section" className={this.state.b12}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"thirteen-section "+this.state.s13 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_thirteen} onChange={this.handleChange} 
            name="name_of_security_thirteen" id="name_of_security_thirteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_thirteen} onChange={this.handleChange} 
            name="quantity_thirteen" id="quantity_thirteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fourteen-section" className={this.state.b13}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fourteen-section "+this.state.s14 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fourteen} onChange={this.handleChange} 
            name="name_of_security_fourteen" id="name_of_security_fourteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fourteen} onChange={this.handleChange} 
            name="quantity_fourteen" id="quantity_fourteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fifteen-section" className={this.state.b14}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fifteen-section "+this.state.s15 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fifteen} onChange={this.handleChange} 
            name="name_of_security_fifteen" id="name_of_security_fifteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fifteen} onChange={this.handleChange} 
            name="quantity_fifteen" id="quantity_fifteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="sixteen-section" className={this.state.b15}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"sixteen-section "+this.state.s16 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_sixteen} onChange={this.handleChange} 
            name="name_of_security_sixteen" id="name_of_security_sixteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_sixteen} onChange={this.handleChange} 
            name="quantity_sixteen" id="quantity_sixteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="seventeen-section" className={this.state.b16}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"seventeen-section "+this.state.s17 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_seventeen} onChange={this.handleChange} 
            name="name_of_security_seventeen" id="name_of_security_seventeen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_seventeen} onChange={this.handleChange} 
            name="quantity_seventeen" id="quantity_seventeen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="eighteen-section" className={this.state.b17}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"eighteen-section "+this.state.s18 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_eighteen} onChange={this.handleChange} 
            name="name_of_security_eighteen" id="name_of_security_eighteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_eighteen} onChange={this.handleChange} 
            name="quantity_eighteen" id="quantity_eighteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ninteen-section" className={this.state.b18}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ninteen-section "+this.state.s19 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ninteen} onChange={this.handleChange} 
            name="name_of_security_ninteen" id="name_of_security_ninteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ninteen} onChange={this.handleChange} 
            name="quantity_ninteen" id="quantity_ninteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="twenty-section" className={this.state.b19}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"twenty-section "+this.state.s20 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_twenty} onChange={this.handleChange} 
            name="name_of_security_twenty" id="name_of_security_twenty" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_twenty} onChange={this.handleChange} 
            name="quantity_twenty" id="quantity_twenty" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tone-section" className={this.state.b20}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tone-section "+this.state.s21 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tone} onChange={this.handleChange} 
            name="name_of_security_tone" id="name_of_security_tone" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tone} onChange={this.handleChange} 
            name="quantity_tone" id="quantity_tone" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ttwo-section" className={this.state.b21}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ttwo-section "+this.state.s22 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ttwo} onChange={this.handleChange} 
            name="name_of_security_ttwo" id="name_of_security_ttwo" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ttwo} onChange={this.handleChange} 
            name="quantity_ttwo" id="quantity_ttwo" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tthree-section" className={this.state.b22}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tthree-section "+this.state.s23 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tthree} onChange={this.handleChange} 
            name="name_of_security_tthree" id="name_of_security_tthree" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tthree} onChange={this.handleChange} 
            name="quantity_tthree" id="quantity_tthree" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tfour-section" className={this.state.b23}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tfour-section "+this.state.s24 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tfour} onChange={this.handleChange} 
            name="name_of_security_tfour" id="name_of_security_tfour" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tfour} onChange={this.handleChange} 
            name="quantity_tfour" id="quantity_tfour" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tfive-section" className={this.state.b24}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tfive-section "+this.state.s25 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tfive} onChange={this.handleChange} 
            name="name_of_security_tfive" id="name_of_security_tfive" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tfive} onChange={this.handleChange} 
            name="quantity_tfive" id="quantity_tfive" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td></td>
      </tr>
    </tbody>
  }

  individual(){
    return <div>
      <div className="individual mt-3">
        <div className="mt-4 client-category">
            <h5 className="title mb-3 text-left">Please fill in the below details if you wants the redemption in securities </h5>
            
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">Name of Security</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {this.securityDetailsIndividual()}
              </Table>
           
        </div>
      </div>
    </div>
  }

  componentDidMount() {
    this.getProducts();
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/vallum-tracker" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
          <section class="profile-section section-bg padding-top padding-bottom">
            <div class="container container-inner">
             <div className="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
             <div className="row justify-content-center after-login-pages no-gutters">
                  {/*Client On-Boarding Form */}
                  <Sidebar />
                  <div className="col-md-8 col-lg-9">
                    <div className="p-3 p-md-3"> 
                      {this.message()}
                              <h4 className="title mb-3 text-left">Partial Corpus Redemption Form </h4>
                              <h6 className="title mb-3 text-left">Please answer the below Questions to process your partial redemption request </h6>
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter required field." }} >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <Col lg="6">
                                        <h6 className="title text-left">Full Name of Client </h6>
                                       
                                          <FormGroup className="w-100">
                                            <TextInput type="text" value={this.state.holder_name_one} onChange={this.handleChange} name="holder_name_one" id="holder_name_one" placeholder={"Full Name Of Client"}
                                              required
                                              errorMessage={{
                                                required: "Full Name of Client is required"
                                              }}
                                            />
                                          </FormGroup>
                                      
                                      </Col>
                                      <Col lg="6">
                                        <h6 className="title text-left">PMS Account Number</h6>
                                         
                                            <FormGroup className="w-100">
                                              <TextInput type="text" value={this.state.pms_account_number} onChange={this.handleChange} name="pms_account_number" id="pms_account_number" placeholder={"PMS Account Number"}
                                                required readOnly={true}
                                                errorMessage={{
                                                  required: "PMS account number is required"
                                                }}
                                              />
                                            </FormGroup>
                                         
                                      </Col>
                                      <Col lg="6">
                                        <h6 className="title text-left">Strategy in which Capital is to be infused</h6>
                                       
                                          <FormGroup className="w-100">
                                            <SelectGroup name="investment_strategy_product" id="investment_strategy_product" 
                                              onChange={this.handleChange} value={this.state.investment_strategy_product} required errorMessage={{
                                              required: "Please select investment strategy product" }} >
                                              <option value="">Select Investment Strategy Product</option>  
                                              {this.products()}
                                            </SelectGroup>
                                          </FormGroup>
                                        
                                      </Col>
                                      </div>
                                     
                                      
                                        <h6 className="title text-left">Mode of Redemption</h6>
                                        <div className="row">
                                          <Col xs="4">
                                            <FormGroup>
                                              <div className="form-check">
                                                <Input type="radio" value="Funds" onChange={this.handleChange} 
                                                  name="mode_of_redemption" id="mode_of_redemption" required
                                                  checked={this.state.mode_of_redemption == "Funds" ? true : false}
                                                  />{' '}
                                                <label className="form-check-label">Funds</label>
                                              </div>
                                            </FormGroup>
                                            </Col>
                                            <Col xs="4">
                                            <FormGroup>
                                              <div className="form-check">
                                                <Input type="radio" value="Securities" onChange={this.handleChange} 
                                                  name="mode_of_redemption" id="mode_of_redemption" required
                                                  checked={this.state.mode_of_redemption == "Securities" ? true : false}
                                                  />{' '}
                                                <label className="form-check-label">Securities</label>
                                              </div>
                                            </FormGroup>
                                            </Col>
                                            <Col xs="4">
                                            <FormGroup>
                                              <div className="form-check">
                                                  <Input type="radio" value="Both" onChange={this.handleChange} required
                                                  name="mode_of_redemption" id="mode_of_redemption" checked={this.state.mode_of_redemption == "Both" ? true : false}
                                                  />{' '}
                                                <label className="form-check-label">Both</label>
                                              </div>
                                            </FormGroup>
                                            </Col>
                                        </div>
                                     
                                  
                                    {this.state.mode_of_redemption != "" && this.state.mode_of_redemption != "Securities" ? <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <h6 className="title text-left">Amount of  Capital Redemption(INR): 
                                            </h6>
                                          
                                              <FormGroup className="w-100">
                                                <TextInput type="text" value={this.state.amount_of_capital_redemption_inr} onChange={this.handleChange} name="amount_of_capital_redemption_inr" id="amount_of_capital_redemption_inr" placeholder={"Funds in INR*"}
                                                  minLength={6}
                                                  validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                                                  errorMessage={{
                                                    validator: "Amount of additional capital must be numeric."
                                                  }}
                                                />
                                              </FormGroup>
                                              <p className="text-left small-text">(Minimum INR 1,00,000 and in Multiples thereof) </p>
                                          
                                          </div>
                                        </div>
                                      </div>
                                    </div> : null }
                                    {this.state.mode_of_redemption != "" && this.state.mode_of_redemption != 'Funds' ? <div className="client-category">{this.individual()}</div> : ""}

                                    {this.state.partilWithdrawalStatus == '' || this.state.partilWithdrawalStatus == 'form-filled' || this.state.partilWithdrawalStatus == 'document-uploaded' || this.state.partilWithdrawalStatus == 'under-document-verification' ?<div className="row">
                                      <div className="col-md-12"><button type="submit">Submit</button></div>
                                    </div> : null }
                                  
                                  </div>
                                </div>
                              </ValidationForm>
                            </div>
                         </div>
                    </div>
                  {/*Client On Boarding Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PartialWithdrawalForm);