import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Table, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import no_image from '../../assets/img/brand/no_image.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const moment = require('moment');

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};


class CompleteRedemptionForm extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.admin)
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      occupation: this.props.admin ? this.props.admin.occupation : "",
      holder_name_one: this.props.admin && this.props.admin.first_name ? this.props.admin.first_name+' '+this.props.admin.last_name : "",
      pms_account_number: this.props.admin && this.props.admin.client_code ? this.props.admin.client_code : "",
      investment_strategy_product: "604085283f1e5e227853d121",
      mode_of_redemption: "",
      amount_of_capital_redemption_inr: "",
      reason_for_closure: "",
      completeRedemptionStatus : "",

      id: this.props.admin._id,
      profileDataForStore: {},
      clientCategory: ""
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.completeRedemption(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async completeRedemption(postData) {
    let path = apiPath.completeRedemption;
    this.setState({ loading: true });
    postData.user_id = this.state.id
    postData.mode_of_redemption = this.state.mode_of_redemption != "" ? this.state.mode_of_redemption : null;
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(postData)
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            this.setState({redirectTo: true});
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProducts() {
    let path = apiPath.products;
    this.setState({ loading: true });
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData);
          this.setState({ products: resultData.result })
          await this.getProcessData('Complete-Redemption');
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProcessData(processName) {
    let path = apiPath.getProcessData;
    this.setState({ loading: true });
    var data = {}
    data.user_id = this.state.id
    data.process = processName
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData.result,'resultData.result');
          let processData = resultData.result;
          if(processData){
            this.setState({  
              investment_strategy_product: processData && processData.investment_strategy_product ? processData.investment_strategy_product : "",
              mode_of_redemption: processData && processData.mode_of_redemption ? processData.mode_of_redemption : "",
              holder_name_one: processData && processData.holder_name_one ? processData.holder_name_one : "",
              pms_account_number: processData && processData.pms_account_number ? processData.pms_account_number : "",
              amount_of_capital_redemption_inr: processData && processData.amount_of_capital_redemption_inr ? processData.amount_of_capital_redemption_inr : "",
              reason_for_closure: processData && processData.reason_for_closure ? processData.reason_for_closure : "",
            })
          }
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  products() {
    if (!_.isEmpty(this.state.products)) {
      return this.state.products.map((make, index) => {
        return <option key={index} id={index} value={make._id}>{make.title}</option>
      })
    }
  }

  componentDidMount() {
    this.getProducts();
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/vallum-tracker" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
        <section class="profile-section section-bg padding-top padding-bottom">
            <div class="container container-inner">
             <div className="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                  {/*Client On-Boarding Form */}
                  <div className="row justify-content-center after-login-pages no-gutters">
                      <Sidebar />
                        <div className="col-md-8 col-lg-9">
                        <div className="p-3 p-md-3"> 
                           {this.message()}
                              <h4 className="title mb-3 text-left">Complete Redemption Form </h4>
                              <h6 className="title mb-3 text-left">Please answer the below Questions to process your complete redemption request </h6>
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter required field." }} >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <Col lg="6">
                                        <h6 className="title text-left">Full Name of Client </h6>
                                       
                                          <FormGroup className="w-100">
                                            <TextInput type="text" value={this.state.holder_name_one} onChange={this.handleChange} name="holder_name_one" id="holder_name_one" placeholder={"Full Name Of Client"}
                                              required
                                              errorMessage={{
                                                required: "Full Name of Client is required"
                                              }}
                                            />
                                          </FormGroup>
                                       
                                      </Col>
                                      <Col lg="6">
                                        <h6 className="title text-left">PMS Account Number</h6>
                                        
                                            <FormGroup className="w-100">
                                              <TextInput type="text" value={this.state.pms_account_number} onChange={this.handleChange} name="pms_account_number" id="pms_account_number" placeholder={"PMS Account Number"}
                                                required readOnly={true}
                                                errorMessage={{
                                                  required: "PMS account number is required"
                                                }}
                                              />
                                            </FormGroup>
                                       
                                      </Col>
                                      <Col lg="6">
                                        <h6 className="title text-left">Strategy in which Capital is to be infused</h6>
                                       
                                          <FormGroup className="w-100">
                                            <SelectGroup name="investment_strategy_product" id="investment_strategy_product" 
                                              onChange={this.handleChange} value={this.state.investment_strategy_product} required errorMessage={{
                                              required: "Please select investment strategy product" }} >
                                              <option value="">Select Investment Strategy Product</option>  
                                              {this.products()}
                                            </SelectGroup>
                                          </FormGroup>
                                      </Col>
                                      </div>

                                      <h6 className="title text-left">Mode of Redemption</h6>
                                      <div className="row">
                                        <Col xs="4">
                                          <FormGroup>
                                            <div className="form-check">
                                              <Input type="radio" value="Funds" onChange={this.handleChange} 
                                                name="mode_of_redemption" id="mode_of_redemption" required
                                                checked={this.state.mode_of_redemption == "Funds" ? true : false}
                                                />{' '}
                                              <label className="form-check-label">Funds</label>
                                            </div>
                                          </FormGroup>
                                          </Col>
                                          <Col xs="4">
                                          <FormGroup>
                                            <div className="form-check">
                                              <Input type="radio" value="Securities" onChange={this.handleChange} 
                                                name="mode_of_redemption" id="mode_of_redemption" required
                                                checked={this.state.mode_of_redemption == "Securities" ? true : false}
                                                />{' '}
                                              <label className="form-check-label">Securities</label>
                                            </div>
                                          </FormGroup>
                                          </Col>
                                          <Col xs="4">
                                          <FormGroup>
                                            <div className="form-check">
                                                <Input type="radio" value="Both" onChange={this.handleChange} required
                                                name="mode_of_redemption" id="mode_of_redemption" checked={this.state.mode_of_redemption == "Both" ? true : false}
                                                />{' '}
                                              <label className="form-check-label">Both</label>
                                            </div>
                                          </FormGroup>
                                          </Col>
                                        </div>
                                     
                                  
                                    {this.state.mode_of_redemption != "" && this.state.mode_of_redemption != "Securities" ? <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <h6 className="title text-left">Amount of  Capital Redemption(INR): 
                                            </h6>
                                            
                                              <FormGroup className="w-100">
                                                <TextInput type="text" value={this.state.amount_of_capital_redemption_inr} onChange={this.handleChange} name="amount_of_capital_redemption_inr" id="amount_of_capital_redemption_inr" placeholder={"Funds in INR"}
                                                  minLength={6}
                                                  validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                                                  errorMessage={{
                                                    validator: "Amount of additional capital must be numeric."
                                                  }}
                                                />
                                              </FormGroup>
                                           
                                          </div>
                                        </div>
                                      </div>
                                    </div> : null }
                                    {this.state.mode_of_redemption != "" && this.state.mode_of_redemption != 'Funds' ? <div className="client-category">
                                      <h6>Please upload a copy of the client master of the demat account in which you want the securities to be transferred, in the document section</h6>
                                    </div> : ""}
                                    <div className="row">
                                      <Col lg="6">
                                        <h5 className="title text-left">Reason For Closure</h5>
                                        
                                            <FormGroup className="w-100">
                                              <TextInput type="text" value={this.state.reason_for_closure} onChange={this.handleChange} name="reason_for_closure" id="reason_for_closure" placeholder={"Reason For Closure"}
                                                required
                                                errorMessage={{
                                                  required: "Reason for closure is required"
                                                }}
                                              />
                                            </FormGroup>
                                         
                                      </Col>
                                    </div>
                                    {this.state.completeRedemptionStatus == '' || this.state.completeRedemptionStatus == 'form-filled' || this.state.completeRedemptionStatus == 'document-uploaded' || this.state.completeRedemptionStatus == 'under-document-verification' ?<div className="row">
                                      <div className="col-md-12"><button type="submit">Submit</button></div>
                                    </div> : null }
                                    <div className="text-left mt-10">
                                      <p> Note: All redemptions are subject to exit load and  all other fees as agreed. </p>
                                      <p>The Redemption transactions will be executed within T+3 working days, where T is the date on which we receive the request.</p> 
                                      <p>The powers enjoyed by Vallum Capital Advisors Pvt. Ltd. under the PMS agreement shall get revoked on successful closure of the PMS Account.</p>
                                    </div>
                                  </div>
                                </div>
                              </ValidationForm>
                            </div>
                         </div>
                    </div>
                  {/*Client On Boarding Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompleteRedemptionForm);