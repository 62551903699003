import React, {Component} from 'react';
import Moment from 'react-moment';
const moment = require('moment');
class DateFormat extends Component {
  render() {
  	if(this.props.date != ''){
	    return (
	      <p>{moment.utc(this.props.date).format('YYYY-MM-DD H:mm')}</p>
	    );
	}else{
		return ''
	}
  }
}

export default DateFormat;
