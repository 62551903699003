import React, { Component } from "react";
import {
  Label,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  CardHeader,
  FormGroup,
  CardFooter,
} from "reactstrap";
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link } from "react-router-dom";
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";

import validator from "validator";
import PropTypes from "prop-types";
import { adminLogin } from "../../actions/index";
import logo from "../../assets/img/brand/login_logo.png";
import { connect } from "react-redux";
import Modal from "react-modal";

const mapStateToProps = (state) => {
  return { admin: state.admin };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminLogin: (admin) => dispatch(adminLogin(admin)),
  };
};
const customStylesModel = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
  },
};

const propTypes = {
  children: PropTypes.node,
};
const defaultProps = {};

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      settingData: {},
      subscribeEmail: "",
      oneTimeModalOpen:
        localStorage.getItem("oneTimeModelOpen") === "open" ? false : false,
      profileDataForStore: {},
    };
    this.openOneTimeModal = this.openOneTimeModal.bind(this);
    this.closeOneTimeModal = this.closeOneTimeModal.bind(this);
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: new Date().getTime(),
      type: type,
      headline: "",
      message: this.state.message,
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert],
    });
  }

  clearAlerts() {
    this.setState({
      alerts: [],
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)],
      });
    }
  }

  openOneTimeModal() {
    console.log(
      localStorage.getItem("oneTimeModelOpen"),
      "oneTimeModelOpen",
      this.state.oneTimeModalOpen
    );
    localStorage.setItem("oneTimeModelOpen", "open");
    if (localStorage.getItem("oneTimeModelOpen") === "open") {
      this.setState({ oneTimeModalOpen: false });
    } else {
      this.setState({ oneTimeModalOpen: true });
    }
  }

  closeOneTimeModal() {
    localStorage.setItem("oneTimeModelOpen", "open");
    this.setState({ oneTimeModalOpen: false });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.sendSubscriptionRequest(formData);
  };

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  };

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  };

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      );
    }
  }

  async getSettingData() {
    let local_support_email = localStorage.getItem("local_support_email");
    if (
      local_support_email == undefined ||
      local_support_email == "" ||
      local_support_email == null
    ) {
      let path = apiPath.settings;
      this.setState({ loading: true });
      let options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let result = await Http(path, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {
          let resultJson = await result.json();
          if (resultJson.success) {
            let resultUsersJson = resultJson.result;
            this.setState({
              settingData: resultUsersJson,
            });
            if (
              resultUsersJson.footer_data !== "" &&
              this.state.oneTimeModalOpen === true
            ) {
              var popupTableDataHeading =
                resultUsersJson.footer_data.split(":");
              if (
                popupTableDataHeading[0] !== "" &&
                popupTableDataHeading[0] !== undefined
              ) {
                this.setState({
                  popUpHeading: popupTableDataHeading[0],
                });
              }
              var popupTableData = resultUsersJson.footer_data.split("|");
              if (!_.isEmpty(popupTableData)) {
                for (var i = 0; i < popupTableData.length; i++) {
                  if (i === 0) {
                    var firstColData = popupTableData[0].split(":");
                    if (
                      firstColData[1] !== "" &&
                      firstColData[1] !== undefined &&
                      firstColData[2] !== "" &&
                      firstColData[2] !== undefined
                    ) {
                      this.setState({
                        firstColName: firstColData[1],
                        firstColValue: firstColData[2],
                      });
                    }
                  } else {
                    var otherColData = popupTableData[i].split(":");
                    if (
                      otherColData[0] !== "" &&
                      otherColData[0] !== undefined &&
                      otherColData[1] !== "" &&
                      otherColData[1] !== undefined
                    ) {
                      if (i === 1) {
                        this.setState({
                          secondColName: otherColData[0],
                          secondColValue: otherColData[1],
                        });
                      }
                      if (i === 2) {
                        this.setState({
                          thirdColName: otherColData[0],
                          thirdColValue: otherColData[1],
                        });
                      }
                      if (i === 3) {
                        this.setState({
                          fourthColName: otherColData[0],
                          fourthColValue: otherColData[1],
                        });
                      }
                      if (i === 4) {
                        this.setState({
                          fifthColName: otherColData[0],
                          fifthColValue: otherColData[1],
                        });
                      }
                    }
                  }
                }
              }
            }
            this.generate("success");
          } else {
            this.generate("danger");
            this.setState({ message: resultJson.msg });
            setTimeout(() => {
              this.setState({ message: null });
            }, 5000);
          }
        }
      } else {
        this.generate("danger");
        this.setState({ message: result.statusText });
        setTimeout(() => {
          this.setState({ message: null });
        }, 5000);
      }
    } else {
      let settingDa = {};
      settingDa.support_email = local_support_email;
      settingDa.support_mobile = localStorage.getItem("local_support_mobile");
      settingDa.whatsapp_mobile = localStorage.getItem("local_whatsapp_mobile");
      settingDa.copyright = localStorage.getItem("local_copyright");
      settingDa.web_url = localStorage.getItem("local_web_url");
      settingDa.twitter_url = localStorage.getItem("local_twitter_url");
      settingDa.fb_url = localStorage.getItem("local_fb_url");
      settingDa.linkedin_url = localStorage.getItem("local_linkedin_url");
      settingDa.address = localStorage.getItem("local_address");
      settingDa.bank_details = localStorage.getItem("local_bank_details");
      settingDa.logo = localStorage.getItem("local_logo");
      settingDa.footer_data = localStorage.getItem("local_footer_data");
      this.setState({
        settingData: settingDa,
      });
      if (
        settingDa.footer_data !== "" &&
        this.state.oneTimeModalOpen === true
      ) {
        var popupTableDataHeading = settingDa.footer_data.split(":");
        if (
          popupTableDataHeading[0] !== "" &&
          popupTableDataHeading[0] !== undefined
        ) {
          this.setState({
            popUpHeading: popupTableDataHeading[0],
          });
        }
        var popupTableData = settingDa.footer_data.split("|");
        if (!_.isEmpty(popupTableData)) {
          for (var i = 0; i < popupTableData.length; i++) {
            if (i === 0) {
              var firstColData = popupTableData[0].split(":");
              if (
                firstColData[1] !== "" &&
                firstColData[1] !== undefined &&
                firstColData[2] !== "" &&
                firstColData[2] !== undefined
              ) {
                this.setState({
                  firstColName: firstColData[1],
                  firstColValue: firstColData[2],
                });
              }
            } else {
              var otherColData = popupTableData[i].split(":");
              if (
                otherColData[0] !== "" &&
                otherColData[0] !== undefined &&
                otherColData[1] !== "" &&
                otherColData[1] !== undefined
              ) {
                if (i === 1) {
                  this.setState({
                    secondColName: otherColData[0],
                    secondColValue: otherColData[1],
                  });
                }
                if (i === 2) {
                  this.setState({
                    thirdColName: otherColData[0],
                    thirdColValue: otherColData[1],
                  });
                }
                if (i === 3) {
                  this.setState({
                    fourthColName: otherColData[0],
                    fourthColValue: otherColData[1],
                  });
                }
                if (i === 4) {
                  this.setState({
                    fifthColName: otherColData[0],
                    fifthColValue: otherColData[1],
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  async sendSubscriptionRequest(postData) {
    let path = apiPath.sendSubscription;
    this.setState({ loading: true });
    let options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    };
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ subscribeModalOpen: false, subscribeEmail: "" });
          this.setState({ message: resultJson.msg });
          this.generate("success");
          /*setTimeout(() => {
            this.setState({ redirectTo: true });
          }, 1500);*/
        } else {
          this.setState({ message: resultJson.msg });
          this.generate("danger");
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate("danger");
    }
  }

  componentDidMount() {
    this.getSettingData();
    if (localStorage.getItem("oneTimeModelOpen") !== "open") {
      localStorage.setItem("oneTimeModelOpen", "open");
    }
  }

  render() {
    const { children, ...attributes } = this.props;
    Modal.setAppElement("#root");
    return (
      <div>
        <footer className="footer-section pt-4 ">
          <div className="footer-top pb-4">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="footer-col aos-item" data-aos="fade-up">
                    <div className="logo pb-3">
                      <a href="/">
                        <img src={logo} alt="logo" />
                      </a>
                    </div>
                    <div className="contact-info">
                      <p>
                        <i className="fa fa-map-marker mr-2"></i>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.state.settingData.address
                              ? this.state.settingData.address
                              : "36, Kalanagar, Near MHADA office, Bandra <br/> (East), Mumbai 400 051, India",
                          }}
                        ></span>
                      </p>
                      <br />
                      <p>
                        <i className="fa fa-phone mr-2"></i>
                        {this.state.settingData.support_mobile
                          ? this.state.settingData.support_mobile
                          : "+91-22-26590841/42/44/46"}
                      </p>
                      <p>
                        <i className="fa fa-envelope mr-2"></i>
                        <a
                          href={
                            this.state.settingData.support_email
                              ? "mailto:" + this.state.settingData.support_email
                              : "mailto:connect.vallum@vallum.in"
                          }
                        >
                          {this.state.settingData.support_email
                            ? this.state.settingData.support_email
                            : "connect.vallum@vallum.in"}
                        </a>
                      </p>
                      <p>
                        <i className="fa fa-laptop mr-2"></i>
                        {this.state.settingData.web_url
                          ? this.state.settingData.web_url
                          : "www.vallum.in"}
                      </p>
                      {/*<p><i className="fa fa-whatsapp mr-2"></i>{this.state.settingData.whatsapp_mobile ? this.state.settingData.whatsapp_mobile :  "+91-9876543210"}</p>*/}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div
                    className="footer-col footer-link mt-2 aos-item"
                    data-aos="fade-up"
                  >
                    <h5>INFORMATION</h5>
                    <div className="row">
                      <ul className="col-md-6">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/pms">Our Products</a>
                        </li>
                        <li>
                          <a href="/our-team">Our Team</a>
                        </li>
                        <li>
                          <a href="/insight-shareholders-letters">Readings</a>
                        </li>
                      </ul>
                      <ul className="col-md-6">
                        <li>
                          <a href="/get-in-touch">Get In Touch</a>
                        </li>
                        <li>
                          <a href="/career">Careers</a>
                        </li>
                        <li>
                          <a href="/faqs">FAQ's</a>
                        </li>
                        {this.props.admin && this.props.admin.email ? (
                          <li>
                            <a href="/my-account">My Account</a>
                          </li>
                        ) : (
                          <li>
                            <a href="/login">Login</a>
                          </li>
                        )}
                        {this.props.admin && this.props.admin.email ? (
                          ""
                        ) : (
                          <li>
                            <a href="/sign-up">Sign Up</a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div
                    className="footer-col footer-newslater-area aos-item"
                    data-aos="fade-up"
                  >
                    <div className="footer-newslater-content mt-2 text-md-center text-lg-left">
                      {this.message()}
                      <h5 className="title">VALLUM READINGS</h5>
                      <span className="cate">Subscribe Now</span>
                      <ValidationForm
                        onSubmit={this.handleSubmit}
                        onErrorSubmit={this.handleErrorSubmit}
                        ref={this.formRef}
                        className="footer-newslater-form mt-3"
                        immediate={this.state.immediate}
                        setFocusOnError={this.state.setFocusOnError}
                        defaultErrorMessage={{
                          required: "Please enter something.",
                        }}
                      >
                        <TextInput
                          name="subscribeEmail"
                          id="subscribeEmail"
                          type="email"
                          placeholder="Please enter your email id*"
                          validator={(value) =>
                            validator.isEmail(value) &&
                            !validator.contains(value, "+")
                          }
                          className=""
                          maxLength={100}
                          errorMessage={{
                            validator: "Please enter a valid email",
                          }}
                          value={this.state.subscribeEmail}
                          onChange={this.handleChange}
                        />
                        <button className="mt-2" type="submit">
                          Subscribe
                        </button>
                      </ValidationForm>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-12 mt-4">
                  <p className="mb-2">
                    <b>For Grievances</b> -{" "}
                    <a href="mailto:pms.grievances@vallum.in">
                      pms.grievances@vallum.in
                    </a>
                  </p>
                  <p className="mb-2">
                    In case the grievance is not redressed the investor can take
                    up the matter with SEBI through SCORES (SEBI COMPLAINTS
                    REDRESS SYSTEM):{" "}
                    <a target="_blank" href="https://scores.sebi.gov.in/">
                      https://scores.sebi.gov.in/
                    </a>
                  </p>
                  <p>
                    <b>For online dispute resolution platform -</b>{" "}
                    <a target="_blank" href="https://smartodr.in/login">
                      https://smartodr.in/login{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="footer-bottom-area d-flex flex-wrap justify-content-between align-items-center">
                <div className="left">
                  <p>
                    {this.state.settingData.copyright
                      ? this.state.settingData.copyright
                      : "Copyright © 2021 Vallum Capital Advisors."}
                  </p>
                </div>
                <div className="top-social-icon">
                  <div className="d-flex align-item-center flex-wrap">
                    <ul className="mr-4">
                      {/*<li className="list-inline-item"><a href="/terms-and-conditions">Terms & Conditions</a> | </li>
                      <li className="list-inline-item"><a href="/privacy-policy">Privacy Policy</a></li>*/}
                    </ul>
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a
                          href={
                            this.state.settingData.fb_url
                              ? this.state.settingData.fb_url
                              : "https://www.facebook.com/VallumCapitalAdvisors"
                          }
                          target="_blank"
                        >
                          <i className="fa fa-lg fa-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={
                            this.state.settingData.twitter_url
                              ? this.state.settingData.twitter_url
                              : "https://twitter.com/VallumConnect"
                          }
                          target="_blank"
                        >
                          <i className="fa fa-lg fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={
                            this.state.settingData.linkedin_url
                              ? this.state.settingData.linkedin_url
                              : "https://www.linkedin.com/company/vallum-capital-ad"
                          }
                          target="_blank"
                        >
                          <i className="fa fa-lg fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="footerfixedPart">
          {this.state.settingData.footer_data
            ? this.state.settingData.footer_data
            : ""}
        </div>

        {this.state.oneTimeModalOpen ? (
          <Modal
            isOpen={this.state.oneTimeModalOpen}
            onRequestClose={this.closeOneTimeModal}
            style={customStylesModel}
            contentLabel="Investment Advisory - Number of Complaints"
          >
            <div className="modal-content">
              <div className="bg-shape bg_img">
                <a
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.closeOneTimeModal}
                >
                  <i className="fa fa-plus-circle"></i>
                </a>
                <div className="modal-body">
                  <div className="d-flex justify-content-center pt-4 oneTimePopup">
                    <div class="col-md-12">
                      <div className="text-center pb-4">
                        <h5
                          className="modal-title mb-3"
                          id="exampleModalLongTitle text-uppercase"
                        >
                          {this.state.popUpHeading}
                        </h5>
                        <table className="border" width="100%">
                          <tbody>
                            <tr>
                              <th>{this.state.firstColName}</th>
                              <td>{this.state.firstColValue}</td>
                            </tr>
                            <tr>
                              <th>{this.state.secondColName}</th>
                              <td>{this.state.secondColValue}</td>
                            </tr>
                            <tr>
                              <th>{this.state.thirdColName}</th>
                              <td>{this.state.thirdColValue}</td>
                            </tr>
                            <tr>
                              <th>{this.state.fourthColName}</th>
                              <td>{this.state.fourthColValue}</td>
                            </tr>
                            <tr>
                              <th>{this.state.fifthColName}</th>
                              <td>{this.state.fifthColValue}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
