import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import no_image from '../../assets/img/brand/no_image.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from 'react-tooltip';
const moment = require('moment');

const mapStateToProps = state => {
  return { admin: state.admin }; 
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class VallumTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      max_date: new Date(),
      id: this.props.admin._id,
      password: '',
      token: this.props.admin.token ? this.props.admin.token : '',
      first_name: this.props.admin.first_name ? this.props.admin.first_name : '',
      last_name: this.props.admin.last_name ? this.props.admin.last_name : '',
      email: this.props.admin.email ? this.props.admin.email : '',
      mobile: this.props.admin.mobile ? this.props.admin.mobile : '',
      process : localStorage.getItem('tracker_process') ? localStorage.getItem('tracker_process') : "",
      strategy : "604085283f1e5e227853d121",
      processData : "",
      uploadDocumentLink : "#",
      downloadFormForSignature : "#",
      uploadSignaturedFormLink : "#",
      downloadFormForSignatureAdditionalCorpus : "#",
      uploadSignaturedFormLinkAdditionalCorpus : "#",
      uploadDocumentLinkPartialWithdrawal : "#",
      downloadFormForSignaturePartialWithdrawal : "#",
      uploadSignaturedFormLinkPartialWithdrawal : "#",
      uploadDocumentLinkCompleteClosure : "#",
      downloadFormForSignatureCompleteClosure : "#",
      uploadSignaturedFormLinkCompleteClosure : "#",
      active1 : "",
      active2 : "",
      active3 : "",
      active4 : "",
      active5 : "",
      active6 : "",
      active7 : "",
      localAddress : localStorage.getItem('local_address') ? localStorage.getItem('local_address') : '',
      localBankDetails : localStorage.getItem('local_bank_details') ? localStorage.getItem('local_bank_details') : '',
      active7 : "",
      products : [],
      profileDataForStore: {}
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
    if(e.target.name === "process"){
      localStorage.setItem('tracker_process',e.target.value);
      this.getProcessData(e.target.value)
    }
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async getProducts() {
    let path = apiPath.products;
    this.setState({ loading: true });
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData);
          this.setState({ products: resultData.result })
        }
        if(this.state.process != '' && this.state.process != null && this.state.process != undefined){
          this.getProcessData(this.state.process)
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProcessData(processName) {
    let path = apiPath.getProcessData;
    this.setState({ loading: true });
    var data = {}
    data.user_id = this.state.id
    data.process = processName
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          let pData = resultData.result
          this.setState({ processData: resultData.result })
          if(pData && pData.client_category && pData.client_category == 'Individual'){
            this.setState({
              uploadDocumentLink : "/client-on-boarding-document-individual"
            })
          }
          if(pData && pData.client_category && pData.client_category == 'Non-Individual'){
            this.setState({
              uploadDocumentLink : "/client-on-boarding-document-non-individual"
            })
          }
          if(pData && pData.form_uploaded_for_signature && pData.form_uploaded_for_signature != '' && pData.form_uploaded_for_signature.indexOf('u/user') < 1 && this.state.process == 'Client-On-Boarding'){
            this.setState({
              downloadFormForSignature : pData.form_uploaded_for_signature,
              uploadSignaturedFormLink : "/client-on-boarding-upload-signatured-document"
            })
          }
          if(pData && pData.form_uploaded_for_signature && pData.form_uploaded_for_signature != '' && pData.form_uploaded_for_signature.indexOf('u/user') < 1 && this.state.process == 'Additional-Corpus'){
            this.setState({
              downloadFormForSignatureAdditionalCorpus : pData.form_uploaded_for_signature,
              uploadSignaturedFormLinkAdditionalCorpus : "/additional-corpus-upload-signature-form"
            })
          }
          if(pData && pData.mode_of_redemption && pData.mode_of_redemption != '' && this.state.process == 'Partial-Withdrawal'){
            this.setState({
              uploadDocumentLinkPartialWithdrawal : "/partial-withdrawal-document"
            })
          }
          if(pData && pData.mode_of_redemption && pData.mode_of_redemption != '' && this.state.process == 'Complete-Redemption'){
            this.setState({
              uploadDocumentLinkCompleteClosure : "/complete-redemption-document"
            })
          }
          if(pData && pData.form_uploaded_for_signature && pData.form_uploaded_for_signature != '' && pData.form_uploaded_for_signature.indexOf('u/user') < 1 && this.state.process == 'Partial-Withdrawal'){
            this.setState({
              downloadFormForSignaturePartialWithdrawal : pData.form_uploaded_for_signature,
              uploadSignaturedFormLinkPartialWithdrawal : "/partial-withdrawal-upload-signature-form"
            })
          }
          if(pData && pData.form_uploaded_for_signature && pData.form_uploaded_for_signature != '' && pData.form_uploaded_for_signature.indexOf('u/user') < 1 && this.state.process == 'Complete-Redemption'){
            this.setState({
              downloadFormForSignatureCompleteClosure : pData.form_uploaded_for_signature,
              uploadSignaturedFormLinkCompleteClosure : "/complete-redemption-upload-signature-form"
            })
          }
          if(this.state.process == 'Client-On-Boarding'){
            this.setState({
              active1 : "",
              active2 : "",
              active3 : "",
              active4 : "",
              active5 : "",
              active6 : "",
              active7 : ""
            })
            if(pData && pData.status == 'form-filled'){
              this.setState({
                active1 : "active-tracker"
              })
            }
            if(pData && pData.status == 'document-uploaded'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker"
              })
            }
            if(pData && pData.status == 'under-document-verification'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker"
              })
            }
            if(pData && pData.status == 'form-uploaded-for-signature'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker"
              })
            }
            if(pData && pData.status == 'waiting-for-signatured-form-approval'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker"
              })
            }
            if(pData && pData.status == 'form-approved'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker",
                active5 : "active-tracker"
              })
            }
            if(pData && pData.status == 'client-on-boarded'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker",
                active5 : "active-tracker",
                active6 : "active-tracker"
              })
            }
            if(pData && pData.status == 'payment-received'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker",
                active5 : "active-tracker",
                active6 : "active-tracker",
                active7 : "active-tracker"
              })
            }
          }
          if(this.state.process == 'Additional-Corpus'){
            this.setState({
              active1 : "",
              active2 : "",
              active3 : "",
              active4 : "",
              active5 : "",
              active6 : "",
              active7 : ""
            })
            if(pData && pData.status == 'form-filled'){
              this.setState({
                active1 : "active-tracker"
              })
            }
            if(pData && pData.status == 'under-document-verification'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker"
              })
            }
            if(pData && pData.status == 'form-uploaded-for-signature'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker"
              })
            }
            if(pData && pData.status == 'waiting-for-signatured-form-approval'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker"
              })
            }
            if(pData && pData.status == 'form-approved'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker"
              })
            }
            if(pData && pData.status == 'additional-corpus-received'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker",
                active5 : "active-tracker"
              })
            }
          }
          if(this.state.process == 'Partial-Withdrawal'){
            this.setState({
              active1 : "",
              active2 : "",
              active3 : "",
              active4 : "",
              active5 : "",
              active6 : "",
              active7 : ""
            })
            if(pData && pData.status == 'form-filled'){
              this.setState({
                active1 : "active-tracker"
              })
            }
            if(pData && pData.status == 'document-uploaded'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker"
              })
            }
            if(pData && pData.status == 'under-document-verification'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker"
              })
            }
            if(pData && pData.status == 'form-uploaded-for-signature'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker"
              })
            }
            if(pData && pData.status == 'waiting-for-signatured-form-approval'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker"
              })
            }
            if(pData && pData.status == 'form-approved'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker",
                active5 : "active-tracker"
              })
            }
          }
          if(this.state.process == 'Complete-Redemption'){
            this.setState({
              active1 : "",
              active2 : "",
              active3 : "",
              active4 : "",
              active5 : "",
              active6 : "",
              active7 : ""
            })
            if(pData && pData.status == 'form-filled'){
              this.setState({
                active1 : "active-tracker"
              })
            }
            if(pData && pData.status == 'document-uploaded'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker"
              })
            }
            if(pData && pData.status == 'under-document-verification'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker"
              })
            }
            if(pData && pData.status == 'form-uploaded-for-signature'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker"
              })
            }
            if(pData && pData.status == 'waiting-for-signatured-form-approval'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker",
                active5 : "active-tracker"
              })
            }
            if(pData && pData.status == 'form-approved'){
              this.setState({
                active1 : "active-tracker",
                active2 : "active-tracker",
                active3 : "active-tracker",
                active4 : "active-tracker",
                active5 : "active-tracker",
                active6 : "active-tracker"
              })
            }
          }
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentWillUnmount() {
    if (!isEmpty(this.state.profileDataForStore)) {
      this.props.adminLogin(this.state.profileDataForStore);
    }
  }

  componentDidMount() {
    this.getProducts();
  }

  products() {
    if (!_.isEmpty(this.state.products)) {
      return this.state.products.map((make, index) => {
        return <option key={index} id={index} value={make._id}>{make.title}</option>
      })
    }
  }

  clientOnBoarding(){
    return <div className="vallum-tracker">
      <Row className={"tracker-list align-items-center "+this.state.active1}>
        <div className="col-3 status-label text-right">Fill Form</div>
        <div className="status-number"><div className="text-center steps-step">1</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please <a href="/client-on-boarding-form" target="_blank">fill in the form</a> to process your request. </div>
      </Row>
      <Row  className={"tracker-list align-items-center "+this.state.active2}>
        <div className="col-3 text-right">File Upload</div>
        <div className="status-number"><div className="text-center steps-step">2</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please <a href={this.state.uploadDocumentLink} target="_blank">upload all</a> necessary documents. </div>
      </Row>
      <Row  className={"tracker-list align-items-center "+this.state.active3}>
        <div className="col-3 text-right">Under Process</div>
        <div className="status-number"><div className="text-center steps-step">3</div></div>
        <div className="col-md-7 col-6 text-left status-description">We have received your request, we will be sharing the filled PMS forms for signature. Your details have been shared with the Axis Bank Demat Team, they will assist you with the demat opening process. </div>
      </Row>
      <Row  className={"tracker-list align-items-center "+this.state.active4}>
        <div className="col-3 text-right">Form uploaded for signature</div>
        <div className="status-number"><div className="text-center steps-step">4</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please find <a href={this.state.downloadFormForSignature} target="_blank">attached form</a> for opening the PMS account, request you to take a print, put in the necessary signatures and <a href={this.state.uploadSignaturedFormLink} target="_blank">share the scanned copy with us</a> to proceed. </div>
      </Row>
      <Row  className={"tracker-list align-items-center "+this.state.active5}>
        <div className="col-3 text-right">Form with signature approved</div>
        <div className="status-number"><div className="text-center steps-step">5</div></div>
        <div className="col-md-7 col-6 text-left status-description">Your forms have been approved, Request you to share physical copy of forms and documents via courier <a data-tip={this.state.localAddress}><i className="fa fa-info-circle"></i></a><ReactTooltip place="bottom" type="dark" effect="float" html={true}/>.</div>
      </Row>
      <Row  className={"tracker-list align-items-center "+this.state.active6}>
        <div className="col-3 text-right">Client On boarded, ask to make payment</div>
        <div className="status-number"><div className="text-center steps-step">6</div></div>
        <div className="col-md-7 col-6 text-left status-description">You have been onboarded, request you to make the payment and upload the receipt ( bank details for Residents) <a data-tip={this.state.localBankDetails}><i className="fa fa-info-circle"></i></a>.</div>
      </Row>
      <Row  className={"tracker-list align-items-center "+this.state.active7}>
        <div className="col-3 text-right">Payment received</div>
        <div className="status-number"><div className="text-center steps-step">7</div></div>
        <div className="col-md-7 col-6 text-left status-description">We have received your payment, Welcome to the Vallum Family.</div>
      </Row>
    </div>
  }

  additionalCorpus(){
    return <div className="vallum-tracker">
      <Row className={"tracker-list align-items-center "+this.state.active1}>
        <div className="col-3 status-label text-right">Fill Form</div>
        <div className="status-number"><div className="text-center steps-step">1</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please <a href="/additional-corpus-form" target="_blank">fill in the form</a> to process your request. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active2}>
        <div className="col-3 status-label text-right">Under Process</div>
        <div className="status-number"><div className="text-center steps-step">2</div></div>
        <div className="col-md-7 col-6 text-left status-description">We have received your request, the filled additional request form will be shared soon for signature. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active3}>
        <div className="col-3 status-label text-right">Form uploaded for signature</div>
        <div className="status-number"><div className="text-center steps-step">3</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please find <a href={this.state.downloadFormForSignatureAdditionalCorpus} target="_blank">attached form</a>, request you to take a print, put in the necessary signatures and <a href={this.state.uploadSignaturedFormLinkAdditionalCorpus} target="_blank">share the scanned copy</a> with us to proceed. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active4}>
        <div className="col-3 status-label text-right">Form with signature approved</div>
        <div className="status-number"><div className="text-center steps-step">4</div></div>
        <div className="col-md-7 col-6 text-left status-description">Your forms have been approved, kindly courier the form and request you to make the payment/transfer the securities and upload the proof.
          <p>Note: We would also need the photo of the cheque if you are depositing the same in the bank or screen shot which shows the bank account number of both payee and payer for online transfer or bank statement confirming the transfer of funds which will enable us to confirm the bank account number from which the funds are transferred.</p>
        </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active5}>
        <div className="col-3 status-label text-right">Additional corpus received</div>
        <div className="status-number"><div className="text-center steps-step">5</div></div>
        <div className="col-md-7 col-6 text-left status-description">We have received your additional corpus. Thankyou!</div>
      </Row>
    </div>
  }

  partialWithdrawal(){
    return <div className="vallum-tracker">
      <Row className={"tracker-list align-items-center "+this.state.active1}>
        <div className="col-3 status-label text-right">Fill Form</div>
        <div className="status-number"><div className="text-center steps-step">1</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please <a href="/partial-withdrawal-form" target="_blank">fill in the form</a> to process your request. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active2}>
        <div className="col-3 status-label text-right">File Upload</div>
        <div className="status-number"><div className="text-center steps-step">2</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please <a href={this.state.uploadDocumentLinkPartialWithdrawal} target="_blank">upload all</a> necessary documents. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active3}>
        <div className="col-3 status-label text-right">Under Process</div>
        <div className="status-number"><div className="text-center steps-step">3</div></div>
        <div className="col-md-7 col-6 text-left status-description">We have received your request, the filled partial redemption form will be shared soon for signature. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active4}>
        <div className="col-3 status-label text-right">Form uploaded for signature</div>
        <div className="status-number"><div className="text-center steps-step">4</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please find <a href={this.state.downloadFormForSignaturePartialWithdrawal} target="_blank">attached form</a>, request you to take a print, put in the necessary signatures and share the <a href={this.state.uploadSignaturedFormLinkPartialWithdrawal} target="_blank">scanned copy with us </a> to proceed. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active5}>
        <div className="col-3 status-label text-right">Form with signature approved</div>
        <div className="status-number"><div className="text-center steps-step">5</div></div>
        <div className="col-md-7 col-6 text-left status-description">We have received your request, kindly courier the form and your request shall be processed as soon as possible.Thankyou!.</div>
      </Row>
    </div>
  }

  completeRedemption(){
    return <div className="vallum-tracker">
      <Row className={"tracker-list align-items-center "+this.state.active1}>
        <div className="col-3 status-label text-right">Fill Form</div>
        <div className="status-number"><div className="text-center steps-step">1</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please <a href="/complete-redemption-form" target="_blank">fill in the form</a> to process your request. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active2}>
        <div className="col-3 status-label text-right">File Upload</div>
        <div className="status-number"><div className="text-center steps-step">2</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please <a href={this.state.uploadDocumentLinkCompleteClosure} target="_blank">upload all</a> necessary documents. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active3}>
        <div className="col-3 status-label text-right">Under Process</div>
        <div className="status-number"><div className="text-center steps-step">3</div></div>
        <div className="col-md-7 col-6 text-left status-description">We have received your request, you would be soon given the filled complete redemption form for signature. </div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active4}>
        <div className="col-3 status-label text-right">Form uploaded for signature</div>
        <div className="status-number"><div className="text-center steps-step">4</div></div>
        <div className="col-md-7 col-6 text-left status-description">Please find <a href={this.state.downloadFormForSignatureCompleteClosure} target="_blank">attached form</a>, request you to take a print, put in the necessary signatures and share the <a href={this.state.uploadSignaturedFormLinkCompleteClosure} target="_blank">scanned copy with us </a> to proceed.</div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active5}>
        <div className="col-3 status-label text-right">Under Final Process</div>
        <div className="status-number"><div className="text-center steps-step">5</div></div>
        <div className="col-md-7 col-6 text-left status-description">We have received your request, kindly courier all necessary closure forms. The portfolio will be redeemed within T + 3 working days and you shall receive the funds/securities within 7-10 working days.</div>
      </Row>
      <Row className={"tracker-list align-items-center "+this.state.active6}>
        <div className="col-3 status-label text-right">Form with signature approved</div>
        <div className="status-number"><div className="text-center steps-step">6</div></div>
        <div className="col-md-7 col-6 text-left status-description">On receipt of all original closure forms we will close the accounts accordingly.Thankyou!</div>
      </Row>
    </div>
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
          <section class="profile-section section-bg padding-top padding-bottom">
            <div class="container container-inner">
            {this.message()}
              <div class="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                  {/*Vallum Tracker */}
                    <div class="row justify-content-center after-login-pages no-gutters">
                        <Sidebar />
                        <div className="col-md-8 col-lg-9">
                           <div className="p-3 p-md-3">
                              <h4 className="title mb-4 text-left">Vallum Tracker</h4>
                              <p className="">A digital step by step client on boarding solution to open you PMS account</p>
                              <div className="status-select-bar">
                                <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter something." }} >
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <FormGroup className="d-flex align-items-center flex-wrap mb-1 mt-1">
                                        <Label className="pr-3 mb-1" htmlFor="name">Select Strategy</Label>
                                        <SelectGroup className="custom-select" name="strategy" id="strategy" onChange={this.handleChange} required errorMessage="Please select strategy" value={this.state.strategy}>
                                          <option value="">Select Strategy</option>  
                                          {this.products()}
                                        </SelectGroup>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-6">
                                      <FormGroup className="d-flex align-items-center flex-wrap mb-1 mt-1">
                                        <Label className="pr-3 mb-1" htmlFor="name">Select Process</Label>
                                        <SelectGroup className="custom-select" name="process" id="process" onChange={this.handleChange} required errorMessage="Please select process" value={this.state.process}>
                                          <option value="">Select Process</option>  
                                          <option value="Client-On-Boarding">Client On Boarding</option>  
                                          <option value="Additional-Corpus">Additional Corpus</option>  
                                          <option value="Partial-Withdrawal">Partial Withdrawal</option>  
                                          <option value="Complete-Redemption">Complete Redemption</option>  
                                        </SelectGroup>
                                      </FormGroup>
                                    </div>
                                  </div>
                                </ValidationForm>
                              </div>
                              {this.state.process == 'Client-On-Boarding' ? this.clientOnBoarding() : ""}
                              {this.state.process == 'Additional-Corpus' ? this.additionalCorpus() : ""}
                              {this.state.process == 'Partial-Withdrawal' ? this.partialWithdrawal() : ""}
                              {this.state.process == 'Complete-Redemption' ? this.completeRedemption() : ""}
                              <p>For any other queries feel free to contact us at <a href="mailto:connect.vallum@vallum.in"><u>connect.vallum@vallum.in</u></a></p>
                            </div>
                        </div>
                    
                    </div>
                  {/*Vallum Tracker */}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    ); 
  }
} 
export default connect(mapStateToProps, mapDispatchToProps)(VallumTracker);