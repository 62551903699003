import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Table, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import "react-datepicker/dist/react-datepicker.css";

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class AdditionalCorpusUploadSignatureForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      
      occupation: this.props.admin ? this.props.admin.occupation : "",
      id: this.props.admin._id,
      profileDataForStore: {},
      onBoardingId: "",
      signatured_from: "",
      signatured_fromName: "",
      signatured_fromUploadedName: ""
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangeImg = (name, e) => {
    if(name=="signatured_from"){
      this.setState({signatured_fromName : e.target.files[0].name})
    }
    helper.getBase64(e.target.files[0], (result) => {
      this.setState({
        [name]: result,
      });
    });
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.additionalCorpusUploadSignaturedForm(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async additionalCorpusUploadSignaturedForm(postData) {
    let path = apiPath.additionalCorpusUploadSignaturedForm;
    this.setState({ loading: true });
    let data = new FormData();
    data.append('signatured_from', postData.signatured_from);
    postData.user_id = this.state.id
    postData.onBoardingId = this.state.onBoardingId
    data.append('onBoardingDocumentData', JSON.stringify(postData));
    let options = {
      method: 'PUT',
      headers: {},
      body: data
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            this.setState({redirectTo: true});
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProcessData(processName) {
    let path = apiPath.getProcessData;
    this.setState({ loading: true });
    var data = {}
    data.user_id = this.state.id
    data.process = processName
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData.result,'resultData.result');
          let processData = resultData.result;
          this.setState({  
            onBoardingId: processData && processData._id ? processData._id : "",
            signatured_from: processData && processData.signatured_from ? processData.signatured_from : "",
            signatured_fromUploadedName: processData && processData.signatured_from && processData.signatured_from.indexOf('u/user') < 1 ? processData.signatured_from : "",
          })
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentDidMount() {
    this.getProcessData('Additional-Corpus');
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/vallum-tracker" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
        <section class="profile-section section-bg padding-top padding-bottom">
            <div class="container container-inner">
             <div className="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
               <div className="row justify-content-center after-login-pages no-gutters">
                  {/*Client On-Boarding Documents (Individual) */}
                      <Sidebar />
                   <div className="col-md-8 col-lg-9">
                    <div className="p-3 p-md-3"> 
                      {this.message()}
                     
                        
                              <h4 className="title mb-4 text-left">Additional Corpus Upload Signatured Form </h4>
                              <h6 className="title mb-4 text-left">Please Upload the below documents to complete your additional corpus process </h6>
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter required fields." }} >
                                <h6 className="title mt-4 text-left"> Upload Signatured Form</h6>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div>
                                      { this.state.signatured_fromName != '' ? this.state.signatured_fromName :null}
                                    </div>
                                    <div className="custom-file" style={{ "marginTop": "5px" }}>
                                      <FileInput className={"custom-file-input"} name="signatured_from" id="signatured_from"
                                        errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                                        fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'signatured_from')} />
                                      <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Signatured Form</label>
                                      { this.state.signatured_fromUploadedName != '' ? <a href={this.state.signatured_fromUploadedName} target="_blank">Preview Uploaded File</a> :null}
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-lg-6"><button type="submit">Submit</button></div>
                                </div>
                              </ValidationForm>
                              {this.state.clientCategory}
                            </div>
                          </div>
                       
                      </div>
                   
                  {/*Client On Boarding Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCorpusUploadSignatureForm);
