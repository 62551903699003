// src/js/reducers/index.js
import { ADMIN_LOGIN } from "../constants/action-types";
const initialState = {
    admin: {}
};
const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_LOGIN:
            return { ...state, admin: action.payload };
        default:
            return state;
    }
};
export default rootReducer;
