import React, { Component } from 'react';
import {Label,Card,CardBody,Col,Row,Button, CardHeader, FormGroup, CardFooter} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

class InformationRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      name : "",
      email : "",
      mobile : "",
      city: "",
      state: "",
      country: "",
      work_profile: "",
      nature_of_business: "",
      requestMessage : ""
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.sendInformationRequest(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async sendInformationRequest(postData) {
    let path = apiPath.sendInformationRequest;
    this.setState({ loading: true });
    postData.phone = helper.mobileWithCode(postData.phone);
    let options = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();

        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  render() {
    return (
      <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
        ref={this.formRef}
        immediate={this.state.immediate}
        setFocusOnError={this.state.setFocusOnError}
        defaultErrorMessage={{ required: "Please enter something." }} >
        <div className="newslater-area p-4 p-md-4">
          {this.message()}
          <div className="text-center">
            <h3 className="title mb-4">INFORMATION REQUEST</h3>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 col-xl-5">
              <div className="form-group">
                <TextInput value={this.state.name} onChange={this.handleChange} name="name" id="name" placeholder={"Name*"} required className=""
                  maxLength={15}
                  validator={(value) => validator.isAlpha(validator.trim(value)) && validator.isLength(validator.trim(value), { min: 3 })}
                  errorMessage={{
                    required: "Please enter name",
                    validator: "Name must be atleast 3 characters long containing alphabets only."
                  }}
                />
              </div>
              </div>
              <div className="col-md-6 col-xl-5">
              <div className="form-group">
                <TextInput name="email" id="email" type="email" placeholder="Email*"
                  validator={(value) => validator.isEmail(value) && !validator.contains(value, '+')} className=""
                  maxLength={100}
                  errorMessage={{ validator: "Please enter a valid email" }}
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              </div>
              <div className="col-md-6 col-xl-5">
              <div className="form-group">
                <TextInput
                  name="mobile" id="mobile" placeholder={"Contact Number"} 
                  onChange={this.handleChange}
                  value={this.state.mobile} className=""
                  maxLength={10}
                  validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                  errorMessage={{
                    validator: "Contact number must be a numeric only."
                  }}
                />
              </div>
            </div>
            
            <div className="col-md-6 col-xl-5">
                <FormGroup>
                  <TextInput value={this.state.nature_of_business} onChange={this.handleChange} name="nature_of_business" id="nature_of_business" placeholder={"Nature of Business*"} 
                    maxLength={200}
                    required
                    errorMessage={{
                      required: "Please enter nature of business"
                    }}
                  />
                </FormGroup>
            </div>
            <div className="col-md-6 col-xl-5">
                <FormGroup>
                  <SelectGroup name="work_profile" id="work_profile" onChange={this.handleChange} value={this.state.work_profile} required errorMessage={{
                      required: "Please enter work profile" }} >
                    <option value="">Select Work Profile</option>  
                    <option value="Business">Business</option>
                    <option value="Working-Professional">Working Professional</option>
                  </SelectGroup>
                </FormGroup>
            </div>
            <div className="col-md-6 col-xl-5">
              <div className="form-group">
                <TextInput value={this.state.city} onChange={this.handleChange} name="city" id="city" placeholder={"City*"} required 
                  maxLength={50}
                  errorMessage={{
                    required: "Please enter city"
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 col-xl-5">
              <div className="form-group">
                <TextInput value={this.state.state} onChange={this.handleChange} name="state" id="state" placeholder={"State*"} required 
                  maxLength={50}
                  errorMessage={{
                    required: "Please enter state"
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 col-xl-5">
              <div className="form-group">
                <TextInput value={this.state.country} onChange={this.handleChange} name="country" id="country" placeholder={"Country*"} required
                  maxLength={15}
                  errorMessage={{
                    required: "Please enter country"
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 col-xl-10">
              <div className="form-group">
                <TextInput
                  name="requestMessage" id="requestMessage" placeholder={"Message"}
                  multiline rows="5" value={this.state.requestMessage}
                  onChange={this.handleChange}
                  maxLength={1000} className=""
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-xl-10"><button type="submit">Submit</button></div>
          </div>  
        </div>
      </ValidationForm>
    );
  }
}
export default InformationRequest;
