import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import _ from "lodash";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Http from '../../../services/Http';
import apiPath from '../../../constants/apiPath';
import { adminLogin } from "../../../actions/index";
import { connect } from "react-redux";
import validator from "validator";
import logo from '../../../assets/img/brand/login_logo.png';
import Header from '../../Elements/Header';
import Footer from '../../Elements/Footer';
import GetInTouch from '../../Elements/GetInTouch';
import bannerImage from '../../../assets/images/banner/banner-inner.png';

const mapStateToProps = state => { 
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    // If you want to use the reset state function, you need to have a reference to the ValidationForm component
    //If your React < 16.3, check https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      email: '',
      isMailSent: false
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.sendForgotPassLink(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {

  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  async sendForgotPassLink(postData) {
    let path = apiPath.forgotPassword;

    if (!_.isEmpty(postData)) {
      this.setState({ loading: true });
      let options = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {

          let resultData = await result.json();
          if (resultData.success) {

            this.setState({ submitFormSuccess: resultData.msg, email: '', submitFormError: null });
            setTimeout(() => {
              this.setState({ submitFormSuccess: null });
            }, 5000);
            this.resetForm();
          } else {
            this.setState({ submitFormError: resultData.msg, submitFormSuccess: null });
            setTimeout(() => {
              this.setState({ submitFormError: null });
            }, 5000);
          }
        }
      } else {
        this.setState({ submitFormError: result.statusText, submitFormSuccess: null });
        setTimeout(() => {
          this.setState({ submitFormError: null });
        }, 5000);
      }
    }
  }

  responseError() {
    if (this.state.submitFormError) {
      return <Alert color="danger">
        {this.state.submitFormError}
      </Alert>
    } else {
      return null;
    }
  }

  responseSuccess() {
    if (this.state.submitFormSuccess) {
      return <Alert color="success">
        {this.state.submitFormSuccess}
      </Alert>
    } else {
      return null;
    }
  }

  showLoading() {
    if (this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }

  render() {

    if (!_.isEmpty(this.props.admin)) {
      return <Redirect to='/' />
    }

    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
          <section class="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div class="container">
                  <div class="hero-content">
                      <h1 class="title text-white">Forgot Password</h1>
                      <ul class="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Forgot Password
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        {/* Middle Section*/}
          <section class="feature-section padding-top padding-bottom" id="feature">
            <div class="container">
                <div class="row justify-content-center feature-wrapper">
                  {/*Login Form*/}
                    <div class="col-md-7">
                      {this.responseError()} {this.responseSuccess()}
                      <div class="box-block bg_img white-bg box-shadow bg-shape aos-item aos-init aos-animate" data-aos="fade-up">
                        <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                          ref={this.formRef}
                          immediate={this.state.immediate}
                          setFocusOnError={this.state.setFocusOnError}
                          defaultErrorMessage={{ required: "Please enter something."}} >
                          <div className="row justify-content-center pt-5 pb-5">
                            <div className="col-lg-7">
                            <div className="text-center">
                              <h4 className="title mb-4 text-uppercase">Vallum Client Forgot Password</h4>
                            </div>
                            <div className="justify-content-center">
                              <div className="col-md-12">
                                <InputGroup className="mb-3">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-user"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <TextInput name="email" id="email" type="email"
                                    validator={validator.isEmail}
                                    errorMessage={{ validator: "Please enter a valid email" }}
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    placeholder={"Email"}
                                  />
                                </InputGroup>
                              </div>
                              <div className="col-md-12 mb-10">
                                <Button color="primary" type={"submit"} className="px-4">Submit {this.showLoading()}</Button>
                              </div>
                              <div className="col-md-12">
                                <div className="text-center mt-2"><Link to="/login" className="custom-button white btn-sm"><i className="fa fa-angle-left mr-2 pl-0"></i>Back To Login</Link></div>
                              </div>
                            </div>
                            </div>
                          </div>
                        </ValidationForm>
                      </div>
                    </div>
                  {/*Login Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
