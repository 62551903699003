import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Table, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import "react-datepicker/dist/react-datepicker.css";
import closureDocuments from '../../assets/images/ClosureDocuments.zip';

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class CompleteRedemptionDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      
      occupation: this.props.admin ? this.props.admin.occupation : "",
      id: this.props.admin._id,
      profileDataForStore: {},

      onBoardingId: "",
      mode_of_redemption: "",
      bank_account_detail : "",
      bank_account_detail_proof : "",
      bank_account_detail_proofName : "",
      bank_account_detail_proofUploadedName : "",
      client_master_of_demat_account : "",
      client_master_of_demat_accountName : "",
      client_master_of_demat_accountUploadedName : "",
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangeImg = (name, e) => {
    if(name=="bank_account_detail_proof"){
      this.setState({bank_account_detail_proofName : e.target.files[0].name})
    }
    if(name=="client_master_of_demat_account"){
      this.setState({client_master_of_demat_accountName : e.target.files[0].name})
    }
    helper.getBase64(e.target.files[0], (result) => {
      this.setState({
        [name]: result,
      });
    });
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.completeRedemptionDocument(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async completeRedemptionDocument(postData) {
    let path = apiPath.completeRedemptionDocument;
    this.setState({ loading: true });
    let data = new FormData();
    data.append('bank_account_detail_proof', postData.bank_account_detail_proof);
    data.append('client_master_of_demat_account', postData.client_master_of_demat_account);
    postData.user_id = this.state.id
    postData.onBoardingId = this.state.onBoardingId
    data.append('onBoardingDocumentData', JSON.stringify(postData));
    let options = {
      method: 'PUT',
      headers: {},
      body: data
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            this.setState({redirectTo: true});
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  bankAccountDetails(){
    return <div>
      <div className="row ">
        <h6 className="title mt-2 text-left col-lg-6">Bank Account Details</h6>
      </div>
      <div className="row ">
          <div className="col-lg-6">
            <FormGroup>
              <SelectGroup name="bank_account_detail" id="bank_account_detail" 
                  onChange={this.handleChange} value={this.state.bank_account_detail}  errorMessage={{
                  required: "Please select Bank Account Proof" }} >
                  <option value="">Select Bank Account Details</option>
                  <option value="Cancelled-Cheque">Cancelled Cheque</option>
                  <option value="Bank-statement-Passbook (Not more than 3 months old)">Bank statement/Passbook (Not more than 3 months old)</option>
                  <option value="Letter-issued-by-the-Bank-giving-the-account-details.">Letter issued by the Bank giving the account details.</option>
              </SelectGroup>
            </FormGroup>
          </div>

          <div className="col-lg-6">
            <div>
              { this.state.bank_account_detail_proofName != '' ? this.state.bank_account_detail_proofName :null}
            </div>
            <div className="custom-file" style={{ "marginTop": "0px" }}>
              <FileInput className={"custom-file-input"} name="bank_account_detail_proof" id="bank_account_detail_proof"
                errorMessage={{ required: "Please upload a bank account detail.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'bank_account_detail_proof')} />
              <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Bank Account Details</label>
              { this.state.bank_account_detail_proofUploadedName != '' ? <a href={this.state.bank_account_detail_proofUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
      </div>
    </div>
  }

  clientMaster(){
    return <div>
      
        <h6 className="title mt-2 text-left">Client Master of demat account in which securities are to be transferred</h6>
    
      <div className="row ">
          <div className="col-lg-6">
            <div>
              { this.state.client_master_of_demat_accountName != '' ? this.state.client_master_of_demat_accountName :null}
            </div>
            <div className="custom-file" style={{ "marginTop": "0px" }}>
              <FileInput className={"custom-file-input"} name="client_master_of_demat_account" id="client_master_of_demat_account"
                errorMessage={{ required: "Please upload a bank account detail.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'client_master_of_demat_account')} />
              <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Demat Account</label>
              { this.state.client_master_of_demat_accountUploadedName != '' ? <a href={this.state.client_master_of_demat_accountUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
      </div>
    </div>
  }

  async getProcessData(processName) {
    let path = apiPath.getProcessData;
    this.setState({ loading: true });
    var data = {}
    data.user_id = this.state.id
    data.process = processName
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData.result,'resultData.result');
          let processData = resultData.result;
          this.setState({  
            onBoardingId: processData && processData._id ? processData._id : "",
            ownership_status: processData && processData.ownership_status ? processData.ownership_status : "",
            mode_of_redemption: processData && processData.mode_of_redemption ? processData.mode_of_redemption : "",
            bank_account_detail: processData && processData.bank_account_detail ? processData.bank_account_detail : "",
            bank_account_detail_proof: processData && processData.bank_account_detail_proof ? processData.bank_account_detail_proof : "",
            bank_account_detail_proofUploadedName: processData && processData.bank_account_detail_proof && processData.bank_account_detail_proof.indexOf('u/user') < 1 ? processData.bank_account_detail_proof : "",
            client_master_of_demat_account: processData && processData.client_master_of_demat_account ? processData.client_master_of_demat_account : "",
            client_master_of_demat_accountUploadedName: processData && processData.client_master_of_demat_account && processData.client_master_of_demat_account.indexOf('u/user') < 1 ? processData.client_master_of_demat_account : "",
          })
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentDidMount() {
    this.getProcessData('Complete-Redemption');
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/vallum-tracker" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
        <section className="profile-section section-bg padding-top padding-bottom">
            <div className="container container-inner">
             <div className="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                <div className="row justify-content-center after-login-pages no-gutters">
                  {/*Client On-Boarding Documents (Individual) */}
                  <Sidebar />
                     
                      <div class="col-md-8 col-lg-9">
                        <div className="p-3 p-md-3"> 
                        {this.message()}
                          
                              <h4 className="title mb-3 text-left">Complete Redemption Documents </h4>
                              <h6 className="title mb-3 text-left">Please upload the relevant documents to process your redemtion request </h6>
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter required fields." }} >
                                <div className="row">
                                  <div className="col-md-12">
                                    {this.state.mode_of_redemption != "Securities" ? this.bankAccountDetails() : null}
                                    {this.state.mode_of_redemption != "Funds" ? this.clientMaster() : null}
                                    <div className="row mt-3">
                                      <div className="col-lg-6">
                                        <a href={closureDocuments} target="_blank" download className="custom-button">Download Closure Documents</a>
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-md-12"><button type="submit">Submit</button></div>
                                    </div>
                                    <div className="mt-2 text-left">
                                      <p>In addition to the above details the below forms need to be printed and filled. The filled forms need to be sent to our office address for further processing.</p>
                                    </div>
                                  </div>
                                </div>
                              </ValidationForm>
                              {this.state.clientCategory}
                            </div>
                          </div>
                       
                    </div>
                  {/*Client On Boarding Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompleteRedemptionDocuments);
