import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from "validator";
import _ from "lodash";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Http from '../../../services/Http';
import apiPath from '../../../constants/apiPath';
import { adminLogin } from "../../../actions/index";
import { connect } from "react-redux";
import queryString from 'query-string';
import Header from '../../Elements/Header';
import Footer from '../../Elements/Footer';
import GetInTouch from '../../Elements/GetInTouch';
import bannerImage from '../../../assets/images/banner/banner-inner.png';

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => { 
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class UserChangePassword extends Component {
  constructor(props) {
    super(props);
    // If you want to use the reset state function, you need to have a reference to the ValidationForm component
    //If your React < 16.3, check https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      password: '',
      confirm_password: '',
      token: queryString.parse(this.props.location.search).token
    }


  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    console.log(formData);
    this.userResetPassword(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    console.log(e, formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  async userResetPassword(postData) {
    let path = apiPath.userResetPassword;

    if (!_.isEmpty(postData)) {
      this.setState({ loading: true });
      let options = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {

          let resultData = await result.json();
          if (resultData.success) {

            this.setState({ submitFormSuccess: resultData.msg,password : '' });
            this.resetForm();
          } else {
            this.setState({ submitFormError: resultData.msg });
            setTimeout(() => {
              this.setState({ submitFormError: null });
            }, 5000);
          }
        }
      } else {
        console.log(result);
        this.setState({ submitFormError: result.statusText });
        setTimeout(() => {
          this.setState({ submitFormError: null });
        }, 5000);
      }
    } else {
      console.log('No Response');
    }
  }

  responseError() {
    if (this.state.submitFormError) {
      return <Alert color="danger">
        {this.state.submitFormError}
      </Alert>
    }
  }

  responseSuccess() {
    if (this.state.submitFormSuccess) {
      return <Alert color="success">
        {this.state.submitFormSuccess}
      </Alert>
    }
  }

  showLoading() {
    if (this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }

  render() {
    if (_.isEmpty(this.state.token)) {
      return <Redirect to='/login' />
    }

    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
          <section class="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div class="container">
                  <div class="hero-content">
                      <h1 class="title text-white">Reset Password</h1>
                      <ul class="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Reset Password
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        {/* Middle Section*/}
          <section class="feature-section padding-top padding-bottom" id="feature">
            <div class="container">
                <div class="row justify-content-center feature-wrapper">
                  {/*Login Form*/}
                    <div class="col-md-5 col-sm-5 col-lg-5">

                      {this.responseError()} {this.responseSuccess()}
                      <div class="box-block bg_img white-bg box-shadow bg-shape aos-item aos-init aos-animate" data-aos="fade-up">
                        <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                          ref={this.formRef}
                          immediate={this.state.immediate}
                          setFocusOnError={this.state.setFocusOnError}
                          defaultErrorMessage={{ required: "Please enter something."}} >
                          <div className="newslater-area p-4 p-md-5">
                            <div className="text-center">
                            <h4 className="title mb-4 text-uppercase">Vallum Client Reset Password</h4>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-md-12">
                                <InputGroup className="mb-3">
                                  <TextInput type={"hidden"} value={this.state.token} onChange={this.handleChange} name="token" id="token" required />
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-lock"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <TextInput
                                    type={"password"} value={this.state.password} onChange={this.handleChange} name="password" id="password"
                                    placeholder={"New Password"}
                                    required
                                    minLength={6}
                                    errorMessage={{ required: 'Please enter your new password.'}}
                                  />
                                </InputGroup>
                              </div>
                              <div className="col-md-12">
                                <InputGroup className="mb-3">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-lock"></i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <TextInput
                                    type={"password"} value={this.state.confirm_password} onChange={this.handleChange} name="confirm_password" id="confirm_password"
                                    placeholder={"Confirm Password"}
                                    validator={(value) => validator.equals(value, this.state.password)}
                                    required
                                    minLength={6}
                                    errorMessage={{ required: "Please enter confirm password.", validator: "Password and confirm password does not match." }}
                                    errorMessage={{ required: "Please enter confirm password.", validator: "Password and confirm password does not match." }}
                                  />
                                </InputGroup>
                              </div>
                              <div className="col-md-12 mb-10">
                                <Button color="primary" type={"submit"} className="px-4">Submit {this.showLoading()}</Button>
                              </div>
                            </div>
                          </div>
                        </ValidationForm>
                      </div>
                    </div>
                  {/*Login Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserChangePassword);
                       
