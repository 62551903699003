import React, { Component } from 'react';
import { Card, CardBody, Col, Row, Table, Button, CardFooter } from 'reactstrap';
import { Link,Redirect } from 'react-router-dom';
import _ from "lodash";
import { AlertList } from "react-bs-notifier";

import GetInTouch from '../Elements/GetInTouch';
import bannerImage from '../../assets/images/banner/banner-inner.png';

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import DateFormatMonthName from "../../constants/dateformatmonthname";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

class OurTeam extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      position: "top-right",
      alerts: [],
      // timeout: 5000,
      message: "",
      redirectTo: false,
      title: this.props.location.productData ? this.props.location.productData.title : '',
      photo: this.props.location.productData ? this.props.location.productData.photo : '',
      content: this.props.location.productData ? this.props.location.productData.content : '',
      performance: this.props.location.productData ? this.props.location.productData.performance : '',
      performance_photo: this.props.location.productData ? this.props.location.productData.performance_photo : '',
      pdf: this.props.location.productData ? this.props.location.productData.category_id.pdf: '',
      fee_structure: this.props.location.productData ? this.props.location.productData.fee_structure : '',
      fee_structure_photo: this.props.location.productData ? this.props.location.productData.fee_structure_photo : '',
      createdAt: this.props.location.productData ? this.props.location.productData.createdAt : '',

      catTitle: this.props.location.productData ? this.props.location.productData.category_id.title : '',
      catContent: this.props.location.productData ? this.props.location.productData.category_id.content : '',
      catPhoto: this.props.location.productData ? this.props.location.productData.category_id.photo : '',

      teamName: this.props.location.productData ? this.props.location.productData.team_id.name : '',
      teamPhoto: this.props.location.productData ? this.props.location.productData.team_id.photo : '',
      teamTitle: this.props.location.productData ? this.props.location.productData.team_id.title : '',
      teamDesignation: this.props.location.productData ? this.props.location.productData.team_id.designation : '',
      teamDescription: this.props.location.productData ? this.props.location.productData.team_id.description : '',
      teamFbUrl: this.props.location.productData ? this.props.location.productData.team_id.fb_url : '',
      teamTwiterUrl: this.props.location.productData ? this.props.location.productData.team_id.twitter_url : '',
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to="/pms" />
    }

    if (!this.props.location.productData) {
      return <Redirect to={{
        pathname: '/pms'
      }} />
    }

    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
          <section class="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div class="container">
                  <div class="hero-content">
                      <h1 class="title text-white">PMS View</h1>
                      <ul class="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              PMS View
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}
        
        <section className="pms-detail-section padding-top padding-bottom">
            <div className="container container-inner">
               <div className="box-shadow box-block p-3 p-md-4 aos-item product-list-card" data-aos="fade-up">
                              <div className="pt-3 pb-3 row">
                                <div className="col-md-4">
                                  <div className="pb-3"><img className="product-image" src={this.state.photo} alt="image"></img></div>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="title">{this.state.title}</h4>
                                    <p><span dangerouslySetInnerHTML={{__html : `${window.atob(this.state.content)}`}}></span></p>

                                    {/* <div className="d-flex justify-content-md-start" style={{ marginBottom : '10px'}}>
                                      <a className="view-more custom-button" href={this.state.pdf} target="_blank">Download fact Sheet</a>
                                    </div> */}
                                    
                                 </div>   
                                </div>
                                {/*<div className="pt-3 pb-3 row">
                                  <div className="col-md-4">
                                  <div className="pb-3"><img className="product-image" src={this.state.catPhoto} alt="image"></img></div>
                                  </div>
                                  <div className="col-md-8">
                                    <h4 className="title">Category</h4>
                                    <p><span dangerouslySetInnerHTML={{__html : `${window.atob(this.state.catContent)}`}}></span></p>
                                  </div>
                                </div>*/}
                                {/*<div className="pt-3 pb-3 row">
                                  <div className="col-md-4">
                                    <div className="pb-3">
                                      <img className="product-image" src={this.state.performance_photo} alt="image"></img>
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <h4 className="title">Performance</h4>
                                    <p>
                                      <span dangerouslySetInnerHTML={{__html : `${window.atob(this.state.performance)}`}}></span>
                                    </p>
                                  </div>
                                </div>*/}
                                {/*<div className="pt-3 pb-3 row">
                                  <div className="col-md-4">
                                    <div className="pb-3"> <img className="product-image" src={this.state.fee_structure_photo} alt="image"></img></div>
                                  </div>
                                  <div className="col-md-8">
                                    <h4 className="title">Fee Structure</h4>
                                    <p><span dangerouslySetInnerHTML={{__html : `${window.atob(this.state.fee_structure)}`}}></span></p>
                                    </div>
                                </div>*/}
                                <div className="pt-3 pb-3">
                                    <h4 className="title mb-4">Team Member Information</h4>
                                    <div className="row">
                                    <div className="col-md-3">
                                      <div><img className="team-image float-left" src={this.state.teamPhoto} alt="image"></img></div>
                                    </div>
                                    <div className="col-md-9">
                                    <div className="pt-3 clear-both">
                                      <h5 className="team-name">{this.state.teamName}</h5>
                                      <a href={this.state.teamFbUrl ? this.state.teamFbUrl : ""} target="_blank"><i className="fa fa-facebook circle"></i></a>
                                      <a href={this.state.teamTwiterUrl ? this.state.teamTwiterUrl : ""} target="_blank"><i className="fa fa-twitter circle"></i></a>                 
                                      </div>
                                    <p className="pt-3">{this.state.teamTitle}, {this.state.teamDesignation}</p>
                                    </div></div>
                                </div>
                           
                </div>
            </div>
        </section>

        {/*GetInTouch*/}
        <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default OurTeam;
