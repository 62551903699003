import React, {Component} from 'react';
import Moment from 'react-moment';
const moment = require('moment');
class DateFormatMonthName extends Component {
  render() {
  	if(this.props.date != ''){
    	var offset = '+0530';
	    return (
	      <p>{moment.utc(this.props.date).utcOffset(offset).format('MMMM Do YYYY')}</p>
	    );
	}else{
		return ''
	}
  }
}

export default DateFormatMonthName;
