import React, { Component } from 'react';
import {CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";


import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import bannerImage from '../../assets/images/banner/banner-inner.png';

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
class OurClientCentricApproach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      ourClientCentricApproach: "",
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async getStaticPage(){
    let path = apiPath.getStaticPage;
    this.setState({ loading: true });
    let postData = {};
    postData.slug = "our_client centric approach";
    let options = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let ourClientCentricApproach = resultJson.result;
          this.setState({ ourClientCentricApproach: ourClientCentricApproach});
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentDidMount(){
    this.getStaticPage();
  }

  render() {
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
        <section class="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div class="container">
                  <div class="hero-content">
                      <h1 class="title text-white">Our Client Centric Approach</h1>
                      <ul class="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                          Our Client Centric Approach
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        <section className="investment-section padding-top padding-bottom">
            <div className="container container-inner">
               <div className="box-shadow box-block p-3 p-md-4 aos-item product-list-card aos-init aos-animate" data-aos="fade-up">
                        <h2 className="title">{this.state.ourClientCentricApproach.title ? this.state.ourClientCentricApproach.title : "Our Client Centric Approach"}</h2>
                        <p><span dangerouslySetInnerHTML={{__html : this.state.ourClientCentricApproach.content ? this.state.ourClientCentricApproach.content : ""}}></span></p>
                        {this.state.ourClientCentricApproach.photo ? <CardImg top width="50%" src={this.state.ourClientCentricApproach.photo} alt="" /> : ""}
                </div>    
            </div>
        </section>

        {/*GetInTouch*/}
        <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default OurClientCentricApproach;
