import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Table, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import no_image from '../../assets/img/brand/no_image.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const moment = require('moment');

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};


class AdditionalCorpusForm extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.admin)
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      occupation: this.props.admin ? this.props.admin.occupation : "",
      holder_name_one: this.props.admin && this.props.admin.first_name ? this.props.admin.first_name+' '+this.props.admin.last_name : "",
      holder_name_two: "",
      holder_name_three: "",
      pms_account_number: this.props.admin && this.props.admin.client_code ? this.props.admin.client_code : "",
      investment_strategy_product: "604085283f1e5e227853d121",
      mode_of_infusion: "",
      amount_of_additional_capital_inr: "",
      mode_of_capital_infusion: "",
      bank_account_number: "",
      date: "",
      ref_number: "",

      name_of_security_first: "",
      isin_code_first: "",
      quantity_first: "",
      original_date_of_aquisition_first: "",
      original_per_share_rate_of_aquisition_first: "",

      name_of_security_second: "",
      isin_code_second: "",
      quantity_second: "",
      original_date_of_aquisition_second: "",
      original_per_share_rate_of_aquisition_second: "",

      name_of_security_third: "",
      isin_code_third: "",
      quantity_third: "",
      original_date_of_aquisition_third: "",
      original_per_share_rate_of_aquisition_third: "",

      name_of_security_fourth: "",
      isin_code_fourth: "",
      quantity_fourth: "",
      original_date_of_aquisition_fourth: "",
      original_per_share_rate_of_aquisition_fourth: "",

      name_of_security_fifth: "",
      isin_code_fifth: "",
      quantity_fifth: "",
      original_date_of_aquisition_fifth: "",
      original_per_share_rate_of_aquisition_fifth: "",

      name_of_security_sixth: "",
      isin_code_sixth: "",
      quantity_sixth: "",
      original_date_of_aquisition_sixth: "",
      original_per_share_rate_of_aquisition_sixth: "",

      name_of_security_seventh: "",
      isin_code_seventh: "",
      quantity_seventh: "",
      original_date_of_aquisition_seventh: "",
      original_per_share_rate_of_aquisition_seventh: "",

      name_of_security_eighth: "",
      isin_code_eighth: "",
      quantity_eighth: "",
      original_date_of_aquisition_eighth: "",
      original_per_share_rate_of_aquisition_eighth: "",

      name_of_security_ninth: "",
      isin_code_ninth: "",
      quantity_ninth: "",
      original_date_of_aquisition_ninth: "",
      original_per_share_rate_of_aquisition_ninth: "",

      name_of_security_ten: "",
      isin_code_ten: "",
      quantity_ten: "",
      original_date_of_aquisition_ten: "",
      original_per_share_rate_of_aquisition_ten: "",

      name_of_security_eleven: "",
      isin_code_eleven: "",
      quantity_eleven: "",
      original_date_of_aquisition_eleven: "",
      original_per_share_rate_of_aquisition_eleven: "",

      name_of_security_twelve: "",
      isin_code_twelve: "",
      quantity_twelve: "",
      original_date_of_aquisition_twelve: "",
      original_per_share_rate_of_aquisition_twelve: "",

      name_of_security_thirteen: "",
      isin_code_thirteen: "",
      quantity_thirteen: "",
      original_date_of_aquisition_thirteen: "",
      original_per_share_rate_of_aquisition_thirteen: "",

      name_of_security_fourteen: "",
      isin_code_fourteen: "",
      quantity_fourteen: "",
      original_date_of_aquisition_fourteen: "",
      original_per_share_rate_of_aquisition_fourteen: "",

      name_of_security_fifteen: "",
      isin_code_fifteen: "",
      quantity_fifteen: "",
      original_date_of_aquisition_fifteen: "",
      original_per_share_rate_of_aquisition_fifteen: "",

      name_of_security_sixteen: "",
      isin_code_sixteen: "",
      quantity_sixteen: "",
      original_date_of_aquisition_sixteen: "",
      original_per_share_rate_of_aquisition_sixteen: "",

      name_of_security_seventeen: "",
      isin_code_seventeen: "",
      quantity_seventeen: "",
      original_date_of_aquisition_seventeen: "",
      original_per_share_rate_of_aquisition_seventeen: "",

      name_of_security_eighteen: "",
      isin_code_eighteen: "",
      quantity_eighteen: "",
      original_date_of_aquisition_eighteen: "",
      original_per_share_rate_of_aquisition_eighteen: "",

      name_of_security_ninteen: "",
      isin_code_ninteen: "",
      quantity_ninteen: "",
      original_date_of_aquisition_ninteen: "",
      original_per_share_rate_of_aquisition_ninteen: "",

      name_of_security_twenty: "",
      isin_code_twenty: "",
      quantity_twenty: "",
      original_date_of_aquisition_twenty: "",
      original_per_share_rate_of_aquisition_twenty: "",

      name_of_security_tone: "",
      isin_code_tone: "",
      quantity_tone: "",
      original_date_of_aquisition_tone: "",
      original_per_share_rate_of_aquisition_tone: "",

      name_of_security_ttwo: "",
      isin_code_ttwo: "",
      quantity_ttwo: "",
      original_date_of_aquisition_ttwo: "",
      original_per_share_rate_of_aquisition_ttwo: "",

      name_of_security_tthree: "",
      isin_code_tthree: "",
      quantity_tthree: "",
      original_date_of_aquisition_tthree: "",
      original_per_share_rate_of_aquisition_tthree: "",

      name_of_security_tfour: "",
      isin_code_tfour: "",
      quantity_tfour: "",
      original_date_of_aquisition_tfour: "",
      original_per_share_rate_of_aquisition_tfour: "",

      name_of_security_tfive: "",
      isin_code_tfive: "",
      quantity_tfive: "",
      original_date_of_aquisition_tfive: "",
      original_per_share_rate_of_aquisition_tfive: "",
      additiionalCorpusStatus : "",

      id: this.props.admin._id,
      profileDataForStore: {},
      clientCategory: "",
      s2 : "shide",
      s3 : "shide",
      s4 : "shide",
      s5 : "shide",
      s6 : "shide",
      s7 : "shide",
      s8 : "shide",
      s9 : "shide",
      s10 : "shide",
      s11 : "shide",
      s12 : "shide",
      s13 : "shide",
      s14 : "shide",
      s15 : "shide",
      s16 : "shide",
      s17 : "shide",
      s18 : "shide",
      s19 : "shide",
      s20 : "shide",
      s21 : "shide",
      s22 : "shide",
      s23 : "shide",
      s24 : "shide",
      s25 : "shide",
      b1 : "bshow",
      b2 : "bshow",
      b3 : "bshow",
      b4 : "bshow",
      b5 : "bshow",
      b6 : "bshow",
      b7 : "bshow",
      b8 : "bshow",
      b9 : "bshow",
      b10 : "bshow",
      b11 : "bshow",
      b12 : "bshow",
      b13 : "bshow",
      b14 : "bshow",
      b15 : "bshow",
      b16 : "bshow",
      b17 : "bshow",
      b18 : "bshow",
      b19 : "bshow",
      b20 : "bshow",
      b21 : "bshow",
      b22 : "bshow",
      b23 : "bshow",
      b24 : "bshow",
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.additionalCorpus(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  handleButtonChange= (e) =>{
    let stat = e.target.getAttribute('data_type');
    var ele = document.getElementsByClassName(stat);
    ele[0].classList.remove("shide");
    e.target.classList.remove("bshow");
    e.target.classList.add("shide");
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async additionalCorpus(postData) {
    let path = apiPath.additionalCorpus;
    this.setState({ loading: true });
    postData.user_id = this.state.id
    postData.mode_of_infusion = this.state.mode_of_infusion != "" ? this.state.mode_of_infusion : null;
    postData.mode_of_capital_infusion = this.state.mode_of_capital_infusion != "" ? this.state.mode_of_capital_infusion : null;
    postData.date = this.state.date != "" ? moment(this.state.date).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_first = this.state.original_date_of_aquisition_first != "" ? moment(this.state.original_date_of_aquisition_first).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_second = this.state.original_date_of_aquisition_second != "" ? moment(this.state.original_date_of_aquisition_second).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_third = this.state.original_date_of_aquisition_third != "" ? moment(this.state.original_date_of_aquisition_third).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_fourth = this.state.original_date_of_aquisition_fourth != "" ? moment(this.state.original_date_of_aquisition_fourth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_fifth = this.state.original_date_of_aquisition_fifth != "" ? moment(this.state.original_date_of_aquisition_fifth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_sixth = this.state.original_date_of_aquisition_sixth != "" ? moment(this.state.original_date_of_aquisition_sixth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_seventh = this.state.original_date_of_aquisition_seventh != "" ? moment(this.state.original_date_of_aquisition_seventh).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_eighth = this.state.original_date_of_aquisition_eighth != "" ? moment(this.state.original_date_of_aquisition_eighth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_ninth = this.state.original_date_of_aquisition_ninth != "" ? moment(this.state.original_date_of_aquisition_ninth).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_ten = this.state.original_date_of_aquisition_ten != "" ? moment(this.state.original_date_of_aquisition_ten).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_eleven = this.state.original_date_of_aquisition_eleven != "" ? moment(this.state.original_date_of_aquisition_eleven).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_twelve = this.state.original_date_of_aquisition_twelve != "" ? moment(this.state.original_date_of_aquisition_twelve).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_thirteen = this.state.original_date_of_aquisition_thirteen != "" ? moment(this.state.original_date_of_aquisition_thirteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_fourteen = this.state.original_date_of_aquisition_fourteen != "" ? moment(this.state.original_date_of_aquisition_fourteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_fifteen = this.state.original_date_of_aquisition_fifteen != "" ? moment(this.state.original_date_of_aquisition_fifteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_sixteen = this.state.original_date_of_aquisition_sixteen != "" ? moment(this.state.original_date_of_aquisition_sixteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_seventeen = this.state.original_date_of_aquisition_seventeen != "" ? moment(this.state.original_date_of_aquisition_seventeen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_eighteen = this.state.original_date_of_aquisition_eighteen != "" ? moment(this.state.original_date_of_aquisition_eighteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_ninteen = this.state.original_date_of_aquisition_ninteen != "" ? moment(this.state.original_date_of_aquisition_ninteen).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_twenty = this.state.original_date_of_aquisition_twenty != "" ? moment(this.state.original_date_of_aquisition_twenty).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_tone = this.state.original_date_of_aquisition_tone != "" ? moment(this.state.original_date_of_aquisition_tone).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_ttwo = this.state.original_date_of_aquisition_ttwo != "" ? moment(this.state.original_date_of_aquisition_ttwo).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_tthree = this.state.original_date_of_aquisition_tthree != "" ? moment(this.state.original_date_of_aquisition_tthree).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_tfour = this.state.original_date_of_aquisition_tfour != "" ? moment(this.state.original_date_of_aquisition_tfour).format('YYYY-MM-DD') : null;
    postData.original_date_of_aquisition_tfive = this.state.original_date_of_aquisition_tfive != "" ? moment(this.state.original_date_of_aquisition_tfive).format('YYYY-MM-DD') : null;
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(postData)
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            this.setState({redirectTo: true});
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProducts() {
    let path = apiPath.products;
    this.setState({ loading: true });
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData);
          this.setState({ products: resultData.result })
          await this.getProcessData('Additional-Corpus');
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  async getProcessData(processName) {
    let path = apiPath.getProcessData;
    this.setState({ loading: true });
    var data = {}
    data.user_id = this.state.id
    data.process = processName
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData.result,'resultData.result');
          let processData = resultData.result;
          if(processData){
            this.setState({  
              investment_strategy_product: processData && processData.investment_strategy_product ? processData.investment_strategy_product : "",
              mode_of_infusion: processData && processData.mode_of_infusion ? processData.mode_of_infusion : "",
              holder_name_one: processData && processData.holder_name_one ? processData.holder_name_one : "",
              holder_name_two: processData && processData.holder_name_two ? processData.holder_name_two : "",
              holder_name_three: processData && processData.holder_name_three ? processData.holder_name_three : "",
              pms_account_number: processData && processData.pms_account_number ? processData.pms_account_number : "",
              amount_of_additional_capital_inr: processData && processData.amount_of_additional_capital_inr ? processData.amount_of_additional_capital_inr : "",
              mode_of_capital_infusion: processData && processData.mode_of_capital_infusion ? processData.mode_of_capital_infusion : "",
              bank_account_number: processData && processData.bank_account_number ? processData.bank_account_number : "",
              date: processData && processData.date ? new Date(moment(processData.date)) : "",
              ref_number: processData && processData.ref_number ? processData.ref_number : "",

              name_of_security_first: processData && processData.name_of_security_first ? processData.name_of_security_first : "",
              isin_code_first: processData && processData.isin_code_first ? processData.isin_code_first : "",
              quantity_first: processData && processData.quantity_first ? processData.quantity_first : "",
              original_date_of_aquisition_first: processData && processData.original_date_of_aquisition_first && processData.original_date_of_aquisition_first != '' ? new Date(moment(processData.original_date_of_aquisition_first)) : "",
              original_per_share_rate_of_aquisition_first: processData && processData.original_per_share_rate_of_aquisition_first ? processData.original_per_share_rate_of_aquisition_first : "",

              name_of_security_second: processData && processData.name_of_security_second ? processData.name_of_security_second : "",
              isin_code_second: processData && processData.isin_code_second ? processData.isin_code_second : "",
              quantity_second: processData && processData.quantity_second ? processData.quantity_second : "",
              original_date_of_aquisition_second: processData && processData.original_date_of_aquisition_second && processData.original_date_of_aquisition_second != '' ? new Date(moment(processData.original_date_of_aquisition_second)) : "",
              original_per_share_rate_of_aquisition_second: processData && processData.original_per_share_rate_of_aquisition_second ? processData.original_per_share_rate_of_aquisition_second : "",

              name_of_security_third: processData && processData.name_of_security_third ? processData.name_of_security_third : "",
              isin_code_third: processData && processData.isin_code_third ? processData.isin_code_third : "",
              quantity_third: processData && processData.quantity_third ? processData.quantity_third : "",
              original_date_of_aquisition_third: processData && processData.original_date_of_aquisition_third && processData.original_date_of_aquisition_third != '' ? new Date(moment(processData.original_date_of_aquisition_third)) : "",
              original_per_share_rate_of_aquisition_third: processData && processData.original_per_share_rate_of_aquisition_third ? processData.original_per_share_rate_of_aquisition_third : "",

              name_of_security_fourth: processData && processData.name_of_security_fourth ? processData.name_of_security_fourth : "",
              isin_code_fourth: processData && processData.isin_code_fourth ? processData.isin_code_fourth : "",
              quantity_fourth: processData && processData.quantity_fourth ? processData.quantity_fourth : "",
              original_date_of_aquisition_fourth: processData && processData.original_date_of_aquisition_fourth && processData.original_date_of_aquisition_fourth != '' ? new Date(moment(processData.original_date_of_aquisition_fourth)) : "",
              original_per_share_rate_of_aquisition_fourth: processData && processData.original_per_share_rate_of_aquisition_fourth ? processData.original_per_share_rate_of_aquisition_fourth : "",

              name_of_security_fifth: processData && processData.name_of_security_fifth ? processData.name_of_security_fifth : "",
              isin_code_fifth: processData && processData.isin_code_fifth ? processData.isin_code_fifth : "",
              quantity_fifth: processData && processData.quantity_fifth ? processData.quantity_fifth : "",
              original_date_of_aquisition_fifth: processData && processData.original_date_of_aquisition_fifth && processData.original_date_of_aquisition_fifth != '' ? new Date(moment(processData.original_date_of_aquisition_fifth)) : "",
              original_per_share_rate_of_aquisition_fifth: processData && processData.original_per_share_rate_of_aquisition_fifth ? processData.original_per_share_rate_of_aquisition_fifth : "",

              name_of_security_sixth: processData && processData.name_of_security_sixth ? processData.name_of_security_sixth : "",
              isin_code_sixth: processData && processData.isin_code_sixth ? processData.isin_code_sixth : "",
              quantity_sixth: processData && processData.quantity_sixth ? processData.quantity_sixth : "",
              original_date_of_aquisition_sixth: processData && processData.original_date_of_aquisition_sixth && processData.original_date_of_aquisition_sixth != '' ? new Date(moment(processData.original_date_of_aquisition_sixth)) : "",
              original_per_share_rate_of_aquisition_sixth: processData && processData.original_per_share_rate_of_aquisition_sixth ? processData.original_per_share_rate_of_aquisition_sixth : "",

              name_of_security_seventh: processData && processData.name_of_security_seventh ? processData.name_of_security_seventh : "",
              isin_code_seventh: processData && processData.isin_code_seventh ? processData.isin_code_seventh : "",
              quantity_seventh: processData && processData.quantity_seventh ? processData.quantity_seventh : "",
              original_date_of_aquisition_seventh: processData && processData.original_date_of_aquisition_seventh && processData.original_date_of_aquisition_seventh != '' ? new Date(moment(processData.original_date_of_aquisition_seventh)) : "",
              original_per_share_rate_of_aquisition_seventh: processData && processData.original_per_share_rate_of_aquisition_seventh ? processData.original_per_share_rate_of_aquisition_seventh : "",

              name_of_security_eighth: processData && processData.name_of_security_eighth ? processData.name_of_security_eighth : "",
              isin_code_eighth: processData && processData.isin_code_eighth ? processData.isin_code_eighth : "",
              quantity_eighth: processData && processData.quantity_eighth ? processData.quantity_eighth : "",
              original_date_of_aquisition_eighth: processData && processData.original_date_of_aquisition_eighth && processData.original_date_of_aquisition_eighth != '' ? new Date(moment(processData.original_date_of_aquisition_eighth)) : "",
              original_per_share_rate_of_aquisition_eighth: processData && processData.original_per_share_rate_of_aquisition_eighth ? processData.original_per_share_rate_of_aquisition_eighth : "",

              name_of_security_ninth: processData && processData.name_of_security_ninth ? processData.name_of_security_ninth : "",
              isin_code_ninth: processData && processData.isin_code_ninth ? processData.isin_code_ninth : "",
              quantity_ninth: processData && processData.quantity_ninth ? processData.quantity_ninth : "",
              original_date_of_aquisition_ninth: processData && processData.original_date_of_aquisition_ninth && processData.original_date_of_aquisition_ninth != '' ? new Date(moment(processData.original_date_of_aquisition_ninth)) : "",
              original_per_share_rate_of_aquisition_ninth: processData && processData.original_per_share_rate_of_aquisition_ninth ? processData.original_per_share_rate_of_aquisition_ninth : "",

              name_of_security_ten: processData && processData.name_of_security_ten ? processData.name_of_security_ten : "",
              isin_code_ten: processData && processData.isin_code_ten ? processData.isin_code_ten : "",
              quantity_ten: processData && processData.quantity_ten ? processData.quantity_ten : "",
              original_date_of_aquisition_ten: processData && processData.original_date_of_aquisition_ten && processData.original_date_of_aquisition_ten != '' ? new Date(moment(processData.original_date_of_aquisition_ten)) : "",
              original_per_share_rate_of_aquisition_ten: processData && processData.original_per_share_rate_of_aquisition_ten ? processData.original_per_share_rate_of_aquisition_ten : "",

              name_of_security_eleven: processData && processData.name_of_security_eleven ? processData.name_of_security_eleven : "",
              isin_code_eleven: processData && processData.isin_code_eleven ? processData.isin_code_eleven : "",
              quantity_eleven: processData && processData.quantity_eleven ? processData.quantity_eleven : "",
              original_date_of_aquisition_eleven: processData && processData.original_date_of_aquisition_eleven && processData.original_date_of_aquisition_eleven != '' ? new Date(moment(processData.original_date_of_aquisition_eleven)) : "",
              original_per_share_rate_of_aquisition_eleven: processData && processData.original_per_share_rate_of_aquisition_eleven ? processData.original_per_share_rate_of_aquisition_eleven : "",

              name_of_security_twelve: processData && processData.name_of_security_twelve ? processData.name_of_security_twelve : "",
              isin_code_twelve: processData && processData.isin_code_twelve ? processData.isin_code_twelve : "",
              quantity_twelve: processData && processData.quantity_twelve ? processData.quantity_twelve : "",
              original_date_of_aquisition_twelve: processData && processData.original_date_of_aquisition_twelve && processData.original_date_of_aquisition_twelve != '' ? new Date(moment(processData.original_date_of_aquisition_twelve)) : "",
              original_per_share_rate_of_aquisition_twelve: processData && processData.original_per_share_rate_of_aquisition_twelve ? processData.original_per_share_rate_of_aquisition_twelve : "",

              name_of_security_thirteen: processData && processData.name_of_security_thirteen ? processData.name_of_security_thirteen : "",
              isin_code_thirteen: processData && processData.isin_code_thirteen ? processData.isin_code_thirteen : "",
              quantity_thirteen: processData && processData.quantity_thirteen ? processData.quantity_thirteen : "",
              original_date_of_aquisition_thirteen: processData && processData.original_date_of_aquisition_thirteen && processData.original_date_of_aquisition_thirteen != '' ? new Date(moment(processData.original_date_of_aquisition_thirteen)) : "",
              original_per_share_rate_of_aquisition_thirteen: processData && processData.original_per_share_rate_of_aquisition_thirteen ? processData.original_per_share_rate_of_aquisition_thirteen : "",

              name_of_security_fourteen: processData && processData.name_of_security_fourteen ? processData.name_of_security_fourteen : "",
              isin_code_fourteen: processData && processData.isin_code_fourteen ? processData.isin_code_fourteen : "",
              quantity_fourteen: processData && processData.quantity_fourteen ? processData.quantity_fourteen : "",
              original_date_of_aquisition_fourteen: processData && processData.original_date_of_aquisition_fourteen && processData.original_date_of_aquisition_fourteen != '' ? new Date(moment(processData.original_date_of_aquisition_fourteen)) : "",
              original_per_share_rate_of_aquisition_fourteen: processData && processData.original_per_share_rate_of_aquisition_fourteen ? processData.original_per_share_rate_of_aquisition_fourteen : "",

              name_of_security_fifteen: processData && processData.name_of_security_fifteen ? processData.name_of_security_fifteen : "",
              isin_code_fifteen: processData && processData.isin_code_fifteen ? processData.isin_code_fifteen : "",
              quantity_fifteen: processData && processData.quantity_fifteen ? processData.quantity_fifteen : "",
              original_date_of_aquisition_fifteen: processData && processData.original_date_of_aquisition_fifteen && processData.original_date_of_aquisition_fifteen != '' ? new Date(moment(processData.original_date_of_aquisition_fifteen)) : "",
              original_per_share_rate_of_aquisition_fifteen: processData && processData.original_per_share_rate_of_aquisition_fifteen ? processData.original_per_share_rate_of_aquisition_fifteen : "",

              name_of_security_sixteen: processData && processData.name_of_security_sixteen ? processData.name_of_security_sixteen : "",
              isin_code_sixteen: processData && processData.isin_code_sixteen ? processData.isin_code_sixteen : "",
              quantity_sixteen: processData && processData.quantity_sixteen ? processData.quantity_sixteen : "",
              original_date_of_aquisition_sixteen: processData && processData.original_date_of_aquisition_sixteen && processData.original_date_of_aquisition_sixteen != '' ? new Date(moment(processData.original_date_of_aquisition_sixteen)) : "",
              original_per_share_rate_of_aquisition_sixteen: processData && processData.original_per_share_rate_of_aquisition_sixteen ? processData.original_per_share_rate_of_aquisition_sixteen : "",

              name_of_security_seventeen: processData && processData.name_of_security_seventeen ? processData.name_of_security_seventeen : "",
              isin_code_seventeen: processData && processData.isin_code_seventeen ? processData.isin_code_seventeen : "",
              quantity_seventeen: processData && processData.quantity_seventeen ? processData.quantity_seventeen : "",
              original_date_of_aquisition_seventeen: processData && processData.original_date_of_aquisition_seventeen && processData.original_date_of_aquisition_seventeen != '' ? new Date(moment(processData.original_date_of_aquisition_seventeen)) : "",
              original_per_share_rate_of_aquisition_seventeen: processData && processData.original_per_share_rate_of_aquisition_seventeen ? processData.original_per_share_rate_of_aquisition_seventeen : "",

              name_of_security_eighteen: processData && processData.name_of_security_eighteen ? processData.name_of_security_eighteen : "",
              isin_code_eighteen: processData && processData.isin_code_eighteen ? processData.isin_code_eighteen : "",
              quantity_eighteen: processData && processData.quantity_eighteen ? processData.quantity_eighteen : "",
              original_date_of_aquisition_eighteen: processData && processData.original_date_of_aquisition_eighteen && processData.original_date_of_aquisition_eighteen != '' ? new Date(moment(processData.original_date_of_aquisition_eighteen)) : "",
              original_per_share_rate_of_aquisition_eighteen: processData && processData.original_per_share_rate_of_aquisition_eighteen ? processData.original_per_share_rate_of_aquisition_eighteen : "",

              name_of_security_ninteen: processData && processData.name_of_security_ninteen ? processData.name_of_security_ninteen : "",
              isin_code_ninteen: processData && processData.isin_code_ninteen ? processData.isin_code_ninteen : "",
              quantity_ninteen: processData && processData.quantity_ninteen ? processData.quantity_ninteen : "",
              original_date_of_aquisition_ninteen: processData && processData.original_date_of_aquisition_ninteen && processData.original_date_of_aquisition_ninteen != '' ? new Date(moment(processData.original_date_of_aquisition_ninteen)) : "",
              original_per_share_rate_of_aquisition_ninteen: processData && processData.original_per_share_rate_of_aquisition_ninteen ? processData.original_per_share_rate_of_aquisition_ninteen : "",

              name_of_security_twenty: processData && processData.name_of_security_twenty ? processData.name_of_security_twenty : "",
              isin_code_twenty: processData && processData.isin_code_twenty ? processData.isin_code_twenty : "",
              quantity_twenty: processData && processData.quantity_twenty ? processData.quantity_twenty : "",
              original_date_of_aquisition_twenty: processData && processData.original_date_of_aquisition_twenty && processData.original_date_of_aquisition_twenty != '' ? new Date(moment(processData.original_date_of_aquisition_twenty)) : "",
              original_per_share_rate_of_aquisition_twenty: processData && processData.original_per_share_rate_of_aquisition_twenty ? processData.original_per_share_rate_of_aquisition_twenty : "",

              name_of_security_tone: processData && processData.name_of_security_tone ? processData.name_of_security_tone : "",
              isin_code_tone: processData && processData.isin_code_tone ? processData.isin_code_tone : "",
              quantity_tone: processData && processData.quantity_tone ? processData.quantity_tone : "",
              original_date_of_aquisition_tone: processData && processData.original_date_of_aquisition_tone && processData.original_date_of_aquisition_tone != '' ? new Date(moment(processData.original_date_of_aquisition_tone)) : "",
              original_per_share_rate_of_aquisition_tone: processData && processData.original_per_share_rate_of_aquisition_tone ? processData.original_per_share_rate_of_aquisition_tone : "",

              name_of_security_ttwo: processData && processData.name_of_security_ttwo ? processData.name_of_security_ttwo : "",
              isin_code_ttwo: processData && processData.isin_code_ttwo ? processData.isin_code_ttwo : "",
              quantity_ttwo: processData && processData.quantity_ttwo ? processData.quantity_ttwo : "",
              original_date_of_aquisition_ttwo: processData && processData.original_date_of_aquisition_ttwo && processData.original_date_of_aquisition_ttwo != '' ? new Date(moment(processData.original_date_of_aquisition_ttwo)) : "",
              original_per_share_rate_of_aquisition_ttwo: processData && processData.original_per_share_rate_of_aquisition_ttwo ? processData.original_per_share_rate_of_aquisition_ttwo : "",

              name_of_security_tthree: processData && processData.name_of_security_tthree ? processData.name_of_security_tthree : "",
              isin_code_tthree: processData && processData.isin_code_tthree ? processData.isin_code_tthree : "",
              quantity_tthree: processData && processData.quantity_tthree ? processData.quantity_tthree : "",
              original_date_of_aquisition_tthree: processData && processData.original_date_of_aquisition_tthree && processData.original_date_of_aquisition_tthree != '' ? new Date(moment(processData.original_date_of_aquisition_tthree)) : "",
              original_per_share_rate_of_aquisition_tthree: processData && processData.original_per_share_rate_of_aquisition_tthree ? processData.original_per_share_rate_of_aquisition_tthree : "",

              name_of_security_tfour: processData && processData.name_of_security_tfour ? processData.name_of_security_tfour : "",
              isin_code_tfour: processData && processData.isin_code_tfour ? processData.isin_code_tfour : "",
              quantity_tfour: processData && processData.quantity_tfour ? processData.quantity_tfour : "",
              original_date_of_aquisition_tfour: processData && processData.original_date_of_aquisition_tfour && processData.original_date_of_aquisition_tfour != '' ? new Date(moment(processData.original_date_of_aquisition_tfour)) : "",
              original_per_share_rate_of_aquisition_tfour: processData && processData.original_per_share_rate_of_aquisition_tfour ? processData.original_per_share_rate_of_aquisition_tfour : "",

              name_of_security_tfive: processData && processData.name_of_security_tfive ? processData.name_of_security_tfive : "",
              isin_code_tfive: processData && processData.isin_code_tfive ? processData.isin_code_tfive : "",
              quantity_tfive: processData && processData.quantity_tfive ? processData.quantity_tfive : "",
              original_date_of_aquisition_tfive: processData && processData.original_date_of_aquisition_tfive && processData.original_date_of_aquisition_tfive != '' ? new Date(moment(processData.original_date_of_aquisition_tfive)) : "",
              original_per_share_rate_of_aquisition_tfive: processData && processData.original_per_share_rate_of_aquisition_tfive ? processData.original_per_share_rate_of_aquisition_tfive : "",
              additiionalCorpusStatus: processData && processData.status ? processData.status : "",
            })
          }
          if(!_.isEmpty(this.state.name_of_security_second)){
            this.setState({
              s2:"",
              b1:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_third)){
            this.setState({
              s3:"",
              b2:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fourth)){
            this.setState({
              s4:"",
              b3:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fifth)){
            this.setState({
              s5:"",
              b4:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_sixth)){
            this.setState({
              s6:"",
              b5:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_seventh)){
            this.setState({
              s7:"",
              b6:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_eighth)){
            this.setState({
              s8:"",
              b7:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ninth)){
            this.setState({
              s9:"",
              b8:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ten)){
            this.setState({
              s10:"",
              b9:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_eleven)){
            this.setState({
              s11:"",
              b10:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_twelve)){
            this.setState({
              s12:"",
              b11:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_thirteen)){
            this.setState({
              s13:"",
              b12:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fourteen)){
            this.setState({
              s14:"",
              b13:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_fifteen)){
            this.setState({
              s15:"",
              b14:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_sixteen)){
            this.setState({
              s16:"",
              b15:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_seventeen)){
            this.setState({
              s17:"",
              b16:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_eighteen)){
            this.setState({
              s18:"",
              b17:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ninteen)){
            this.setState({
              s19:"",
              b18:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_twenty)){
            this.setState({
              s20:"",
              b19:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tone)){
            this.setState({
              s21:"",
              b20:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_ttwo)){
            this.setState({
              s22:"",
              b21:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tthree)){
            this.setState({
              s23:"",
              b22:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tfour)){
            this.setState({
              s24:"",
              b23:"shide"
            })
          }
          if(!_.isEmpty(this.state.name_of_security_tfive)){
            this.setState({
              s25:"",
              b24:"shide"
            })
          }
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  products() {
    if (!_.isEmpty(this.state.products)) {
      return this.state.products.map((make, index) => {
        return <option key={index} id={index} value={make._id}>{make.title}</option>
      })
    }
  }

  securityDetailsIndividual(){
    return <tbody>
      <tr>
        <td>
          <TextInput type="text" value={this.state.name_of_security_first} onChange={this.handleChange} 
            name="name_of_security_first" id="name_of_security_first" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_first} onChange={this.handleChange} 
            name="isin_code_first" id="isin_code_first" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_first} onChange={this.handleChange} 
            name="quantity_first" id="quantity_first" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_first}
              name="original_date_of_aquisition_first"
              required={this.state.name_of_security_first != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate1}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_first} onChange={this.handleChange} required={this.state.name_of_security_first != "" ? true : false}
            name="original_per_share_rate_of_aquisition_first" id="original_per_share_rate_of_aquisition_first"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} data_type="second-section" className={this.state.b1} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>

      <tr className={"second-section "+this.state.s2 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_second} onChange={this.handleChange} 
            name="name_of_security_second" id="name_of_security_second" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_second} onChange={this.handleChange} 
            name="isin_code_second" id="isin_code_second" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_second} onChange={this.handleChange} 
            name="quantity_second" id="quantity_second" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_second}
              name="original_date_of_aquisition_second"
              yearDropdownItemNumber={10}
              required={this.state.name_of_security_second != "" ? true : false}
              showYearDropdown
              onChange={this.handleChangeOriginalDate2}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_second} onChange={this.handleChange} 
            required={this.state.name_of_security_second != "" ? true : false}
            name="original_per_share_rate_of_aquisition_second" id="original_per_share_rate_of_aquisition_second"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="third-section" className={this.state.b2}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"third-section "+this.state.s3 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_third} onChange={this.handleChange} 
            name="name_of_security_third" id="name_of_security_third" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_third} onChange={this.handleChange} 
            name="isin_code_third" id="isin_code_third" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_third} onChange={this.handleChange} 
            name="quantity_third" id="quantity_third" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_third}
              name="original_date_of_aquisition_third"
              showYearDropdown
              required={this.state.name_of_security_third != "" ? true : false}
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate3}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_third} onChange={this.handleChange} 
            required={this.state.name_of_security_third != "" ? true : false}
            name="original_per_share_rate_of_aquisition_third" id="original_per_share_rate_of_aquisition_third"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fourth-section" className={this.state.b3}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fourth-section "+this.state.s4 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fourth} onChange={this.handleChange} 
            name="name_of_security_fourth" id="name_of_security_fourth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_fourth} onChange={this.handleChange} 
            name="isin_code_fourth" id="isin_code_fourth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fourth} onChange={this.handleChange} 
            name="quantity_fourth" id="quantity_fourth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_fourth}
              name="original_date_of_aquisition_fourth"
              showYearDropdown
              required={this.state.name_of_security_fourth != "" ? true : false}
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate4}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_fourth} onChange={this.handleChange} 
            required={this.state.name_of_security_fourth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_fourth" id="original_per_share_rate_of_aquisition_fourth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fifth-section" className={this.state.b4}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fifth-section "+this.state.s5 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fifth} onChange={this.handleChange} 
            name="name_of_security_fifth" id="name_of_security_fifth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_fifth} onChange={this.handleChange} 
            name="isin_code_fifth" id="isin_code_fifth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fifth} onChange={this.handleChange} 
            name="quantity_fifth" id="quantity_fifth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_fifth}
              name="original_date_of_aquisition_fifth"
              showYearDropdown
              required={this.state.name_of_security_fifth != "" ? true : false}
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate5}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_fifth} onChange={this.handleChange} 
            required={this.state.name_of_security_fifth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_fifth" id="original_per_share_rate_of_aquisition_fifth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="sixth-section" className={this.state.b5}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"sixth-section "+this.state.s6 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_sixth} onChange={this.handleChange} 
            name="name_of_security_sixth" id="name_of_security_sixth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_sixth} onChange={this.handleChange} 
            name="isin_code_sixth" id="isin_code_sixth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_sixth} onChange={this.handleChange} 
            name="quantity_sixth" id="quantity_sixth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_sixth}
              name="original_date_of_aquisition_sixth"
              required={this.state.name_of_security_sixth != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate6}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_sixth} onChange={this.handleChange} 
            required={this.state.name_of_security_sixth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_sixth" id="original_per_share_rate_of_aquisition_sixth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="seventh-section" className={this.state.b6}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"seventh-section "+this.state.s7 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_seventh} onChange={this.handleChange} 
            name="name_of_security_seventh" id="name_of_security_seventh" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_seventh} onChange={this.handleChange} 
            name="isin_code_seventh" id="isin_code_seventh" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_seventh} onChange={this.handleChange} 
            name="quantity_seventh" id="quantity_seventh" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_seventh}
              name="original_date_of_aquisition_seventh"
              required={this.state.name_of_security_seventh != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate7}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_seventh} onChange={this.handleChange} 
            required={this.state.name_of_security_seventh != "" ? true : false}
            name="original_per_share_rate_of_aquisition_seventh" id="original_per_share_rate_of_aquisition_seventh"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="eighth-section" className={this.state.b7}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"eighth-section "+this.state.s8 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_eighth} onChange={this.handleChange} 
            name="name_of_security_eighth" id="name_of_security_eighth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_eighth} onChange={this.handleChange} 
            name="isin_code_eighth" id="isin_code_eighth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_eighth} onChange={this.handleChange} 
            name="quantity_eighth" id="quantity_eighth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_eighth}
              name="original_date_of_aquisition_eighth"
              required={this.state.name_of_security_eighth != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate8}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_eighth} onChange={this.handleChange} 
            required={this.state.name_of_security_eighth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_eighth" id="original_per_share_rate_of_aquisition_eighth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ninth-section" className={this.state.b8}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ninth-section "+this.state.s9 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ninth} onChange={this.handleChange} 
            name="name_of_security_ninth" id="name_of_security_ninth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_ninth} onChange={this.handleChange} 
            name="isin_code_ninth" id="isin_code_ninth" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ninth} onChange={this.handleChange} 
            name="quantity_ninth" id="quantity_ninth" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_ninth}
              name="original_date_of_aquisition_ninth"
              required={this.state.name_of_security_ninth != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate9}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_ninth} onChange={this.handleChange} 
            required={this.state.name_of_security_ninth != "" ? true : false}
            name="original_per_share_rate_of_aquisition_ninth" id="original_per_share_rate_of_aquisition_ninth"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ten-section" className={this.state.b9}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ten-section "+this.state.s10 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ten} onChange={this.handleChange} 
            name="name_of_security_ten" id="name_of_security_ten" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_ten} onChange={this.handleChange} 
            name="isin_code_ten" id="isin_code_ten" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ten} onChange={this.handleChange} 
            name="quantity_ten" id="quantity_ten" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_ten}
              name="original_date_of_aquisition_ten"
              required={this.state.name_of_security_ten != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate10}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_ten} onChange={this.handleChange} 
            required={this.state.name_of_security_ten != "" ? true : false}
            name="original_per_share_rate_of_aquisition_ten" id="original_per_share_rate_of_aquisition_ten"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="eleven-section" className={this.state.b10}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"eleven-section "+this.state.s11 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_eleven} onChange={this.handleChange} 
            name="name_of_security_eleven" id="name_of_security_eleven" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_eleven} onChange={this.handleChange} 
            name="isin_code_eleven" id="isin_code_eleven" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_eleven} onChange={this.handleChange} 
            name="quantity_eleven" id="quantity_eleven" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_eleven}
              name="original_date_of_aquisition_eleven"
              required={this.state.name_of_security_eleven != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate11}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_eleven} onChange={this.handleChange} 
            required={this.state.name_of_security_eleven != "" ? true : false}
            name="original_per_share_rate_of_aquisition_eleven" id="original_per_share_rate_of_aquisition_eleven"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="twelve-section" className={this.state.b11}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"twelve-section "+this.state.s12 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_twelve} onChange={this.handleChange} 
            name="name_of_security_twelve" id="name_of_security_twelve" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_twelve} onChange={this.handleChange} 
            name="isin_code_twelve" id="isin_code_twelve" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_twelve} onChange={this.handleChange} 
            name="quantity_twelve" id="quantity_twelve" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_twelve}
              name="original_date_of_aquisition_twelve"
              required={this.state.name_of_security_twelve != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate12}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_twelve} onChange={this.handleChange} 
            required={this.state.name_of_security_twelve != "" ? true : false}
            name="original_per_share_rate_of_aquisition_twelve" id="original_per_share_rate_of_aquisition_twelve"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="thirteen-section" className={this.state.b12}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"thirteen-section "+this.state.s13 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_thirteen} onChange={this.handleChange} 
            name="name_of_security_thirteen" id="name_of_security_thirteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_thirteen} onChange={this.handleChange} 
            name="isin_code_thirteen" id="isin_code_thirteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_thirteen} onChange={this.handleChange} 
            name="quantity_thirteen" id="quantity_thirteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_thirteen}
              name="original_date_of_aquisition_thirteen"
              required={this.state.name_of_security_thirteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate13}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_thirteen} onChange={this.handleChange} 
            required={this.state.name_of_security_thirteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_thirteen" id="original_per_share_rate_of_aquisition_thirteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fourteen-section" className={this.state.b13}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fourteen-section "+this.state.s14 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fourteen} onChange={this.handleChange} 
            name="name_of_security_fourteen" id="name_of_security_fourteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_fourteen} onChange={this.handleChange} 
            name="isin_code_fourteen" id="isin_code_fourteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fourteen} onChange={this.handleChange} 
            name="quantity_fourteen" id="quantity_fourteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_fourteen}
              name="original_date_of_aquisition_fourteen"
              required={this.state.name_of_security_fourteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate14}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_fourteen} onChange={this.handleChange} 
            required={this.state.name_of_security_fourteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_fourteen" id="original_per_share_rate_of_aquisition_fourteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="fifteen-section" className={this.state.b14}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"fifteen-section "+this.state.s15 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_fifteen} onChange={this.handleChange} 
            name="name_of_security_fifteen" id="name_of_security_fifteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_fifteen} onChange={this.handleChange} 
            name="isin_code_fifteen" id="isin_code_fifteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_fifteen} onChange={this.handleChange} 
            name="quantity_fifteen" id="quantity_fifteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_fifteen}
              name="original_date_of_aquisition_fifteen"
              required={this.state.name_of_security_fifteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate15}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_fifteen} onChange={this.handleChange} 
            required={this.state.name_of_security_fifteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_fifteen" id="original_per_share_rate_of_aquisition_fifteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="sixteen-section" className={this.state.b15}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"sixteen-section "+this.state.s16 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_sixteen} onChange={this.handleChange} 
            name="name_of_security_sixteen" id="name_of_security_sixteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_sixteen} onChange={this.handleChange} 
            name="isin_code_sixteen" id="isin_code_sixteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_sixteen} onChange={this.handleChange} 
            name="quantity_sixteen" id="quantity_sixteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_sixteen}
              name="original_date_of_aquisition_sixteen"
              required={this.state.name_of_security_sixteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate16}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_sixteen} onChange={this.handleChange} 
            required={this.state.name_of_security_sixteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_sixteen" id="original_per_share_rate_of_aquisition_sixteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="seventeen-section" className={this.state.b16}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"seventeen-section "+this.state.s17 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_seventeen} onChange={this.handleChange} 
            name="name_of_security_seventeen" id="name_of_security_seventeen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_seventeen} onChange={this.handleChange} 
            name="isin_code_seventeen" id="isin_code_seventeen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_seventeen} onChange={this.handleChange} 
            name="quantity_seventeen" id="quantity_seventeen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_seventeen}
              name="original_date_of_aquisition_seventeen"
              required={this.state.name_of_security_seventeen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate17}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_seventeen} onChange={this.handleChange} 
            required={this.state.name_of_security_seventeen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_seventeen" id="original_per_share_rate_of_aquisition_seventeen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="eighteen-section" className={this.state.b17}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"eighteen-section "+this.state.s18 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_eighteen} onChange={this.handleChange} 
            name="name_of_security_eighteen" id="name_of_security_eighteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_eighteen} onChange={this.handleChange} 
            name="isin_code_eighteen" id="isin_code_eighteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_eighteen} onChange={this.handleChange} 
            name="quantity_eighteen" id="quantity_eighteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_eighteen}
              name="original_date_of_aquisition_eighteen"
              required={this.state.name_of_security_eighteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate18}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_eighteen} onChange={this.handleChange} 
            required={this.state.name_of_security_eighteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_eighteen" id="original_per_share_rate_of_aquisition_eighteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ninteen-section" className={this.state.b18}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ninteen-section "+this.state.s19 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ninteen} onChange={this.handleChange} 
            name="name_of_security_ninteen" id="name_of_security_ninteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_ninteen} onChange={this.handleChange} 
            name="isin_code_ninteen" id="isin_code_ninteen" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ninteen} onChange={this.handleChange} 
            name="quantity_ninteen" id="quantity_ninteen" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_ninteen}
              name="original_date_of_aquisition_ninteen"
              required={this.state.name_of_security_ninteen != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate19}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_ninteen} onChange={this.handleChange} 
            required={this.state.name_of_security_ninteen != "" ? true : false}
            name="original_per_share_rate_of_aquisition_ninteen" id="original_per_share_rate_of_aquisition_ninteen"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="twenty-section" className={this.state.b19}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"twenty-section "+this.state.s20 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_twenty} onChange={this.handleChange} 
            name="name_of_security_twenty" id="name_of_security_twenty" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_twenty} onChange={this.handleChange} 
            name="isin_code_twenty" id="isin_code_twenty" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_twenty} onChange={this.handleChange} 
            name="quantity_twenty" id="quantity_twenty" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_twenty}
              name="original_date_of_aquisition_twenty"
              required={this.state.name_of_security_twenty != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate20}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_twenty} onChange={this.handleChange} 
            required={this.state.name_of_security_twenty != "" ? true : false}
            name="original_per_share_rate_of_aquisition_twenty" id="original_per_share_rate_of_aquisition_twenty"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tone-section" className={this.state.b20}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tone-section "+this.state.s21 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tone} onChange={this.handleChange} 
            name="name_of_security_tone" id="name_of_security_tone" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_tone} onChange={this.handleChange} 
            name="isin_code_tone" id="isin_code_tone" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tone} onChange={this.handleChange} 
            name="quantity_tone" id="quantity_tone" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_tone}
              name="original_date_of_aquisition_tone"
              required={this.state.name_of_security_tone != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate21}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_tone} onChange={this.handleChange} 
            required={this.state.name_of_security_tone != "" ? true : false}
            name="original_per_share_rate_of_aquisition_tone" id="original_per_share_rate_of_aquisition_tone"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="ttwo-section" className={this.state.b21}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"ttwo-section "+this.state.s22 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_ttwo} onChange={this.handleChange} 
            name="name_of_security_ttwo" id="name_of_security_ttwo" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_ttwo} onChange={this.handleChange} 
            name="isin_code_ttwo" id="isin_code_ttwo" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_ttwo} onChange={this.handleChange} 
            name="quantity_ttwo" id="quantity_ttwo" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_ttwo}
              name="original_date_of_aquisition_ttwo"
              required={this.state.name_of_security_ttwo != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate22}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_ttwo} onChange={this.handleChange} 
            required={this.state.name_of_security_ttwo != "" ? true : false}
            name="original_per_share_rate_of_aquisition_ttwo" id="original_per_share_rate_of_aquisition_ttwo"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tthree-section" className={this.state.b22}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tthree-section "+this.state.s23 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tthree} onChange={this.handleChange} 
            name="name_of_security_tthree" id="name_of_security_tthree" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_tthree} onChange={this.handleChange} 
            name="isin_code_tthree" id="isin_code_tthree" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tthree} onChange={this.handleChange} 
            name="quantity_tthree" id="quantity_tthree" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_tthree}
              name="original_date_of_aquisition_tthree"
              required={this.state.name_of_security_tthree != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate23}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_tthree} onChange={this.handleChange} 
            required={this.state.name_of_security_tthree != "" ? true : false}
            name="original_per_share_rate_of_aquisition_tthree" id="original_per_share_rate_of_aquisition_tthree"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tfour-section" className={this.state.b23}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tfour-section "+this.state.s24 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tfour} onChange={this.handleChange} 
            name="name_of_security_tfour" id="name_of_security_tfour" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_tfour} onChange={this.handleChange} 
            name="isin_code_tfour" id="isin_code_tfour" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tfour} onChange={this.handleChange} 
            name="quantity_tfour" id="quantity_tfour" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_tfour}
              name="original_date_of_aquisition_tfour"
              required={this.state.name_of_security_tfour != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate24}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_tfour} onChange={this.handleChange} 
            required={this.state.name_of_security_tfour != "" ? true : false}
            name="original_per_share_rate_of_aquisition_tfour" id="original_per_share_rate_of_aquisition_tfour"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td><button onClick={this.handleButtonChange} type="button" data_type="tfive-section" className={this.state.b24}><i class="fa fa-plus" aria-hidden="true"></i></button></td>
      </tr>
      
      <tr className={"tfive-section "+this.state.s25 }>
        <td>
          <TextInput type="text" value={this.state.name_of_security_tfive} onChange={this.handleChange} 
            name="name_of_security_tfive" id="name_of_security_tfive" />
        </td>
        <td>
          <TextInput type="text" value={this.state.isin_code_tfive} onChange={this.handleChange} 
            name="isin_code_tfive" id="isin_code_tfive" />
        </td>
        <td>
          <TextInput type="text" value={this.state.quantity_tfive} onChange={this.handleChange} 
            name="quantity_tfive" id="quantity_tfive" 
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Quantity must be numeric."
            }} />
        </td>
        <td>
          <FormGroup className="datepic">
            <DatePicker
              selected={this.state.original_date_of_aquisition_tfive}
              name="original_date_of_aquisition_tfive"
              required={this.state.name_of_security_tfive != "" ? true : false}
              showYearDropdown
              yearDropdownItemNumber={10}
              onChange={this.handleChangeOriginalDate25}
              disabledKeyboardNavigation
              maxDate={this.state.max_date}
              className="form-control"
              selectsStart
              dateFormat="yyyy-MM-dd"
            />
          </FormGroup>
        </td>
        <td>
          <TextInput type="text" value={this.state.original_per_share_rate_of_aquisition_tfive} onChange={this.handleChange} 
            required={this.state.name_of_security_tfive != "" ? true : false}
            name="original_per_share_rate_of_aquisition_tfive" id="original_per_share_rate_of_aquisition_tfive"
            validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
            errorMessage={{
              validator: "Value must be numeric."
            }} />
        </td>
        <td></td>
      </tr>
    </tbody>
  }

  individual(){
    return <div>
      <div className="individual mt-3">
        <div className="mt-4 client-category">
            <h5 className="title mb-3 text-left">Please fill in the below details if you would be making an Investment by transfering securities </h5>
          
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">Name of Security</th>
                    <th scope="col">ISIN Code</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Original Date of Acquisition</th>
                    <th scope="col">Original Per Share Rate of Acquisition</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {this.securityDetailsIndividual()}
              </Table>
           
        </div>
      </div>
    </div>
  }

  componentDidMount() {
    this.getProducts();
  }

  // Date change functions
    handleChangedDate = date => {
      this.setState({
        date: date
      })
    }

    handleChangeOriginalDate1 = date => {
      this.setState({
        original_date_of_aquisition_first: date
      })
    }

    handleChangeOriginalDate2 = date => {
      this.setState({
        original_date_of_aquisition_second: date
      })
    }

    handleChangeOriginalDate3 = date => {
      this.setState({
        original_date_of_aquisition_third: date
      })
    }

    handleChangeOriginalDate4 = date => {
      this.setState({
        original_date_of_aquisition_fourth: date
      })
    }

    handleChangeOriginalDate5 = date => {
      this.setState({
        original_date_of_aquisition_fifth: date
      })
    }

    handleChangeOriginalDate6 = date => {
      this.setState({
        original_date_of_aquisition_sixth: date
      })
    }

    handleChangeOriginalDate7 = date => {
      this.setState({
        original_date_of_aquisition_seventh: date
      })
    }

    handleChangeOriginalDate8 = date => {
      this.setState({
        original_date_of_aquisition_eighth: date
      })
    }

    handleChangeOriginalDate9 = date => {
      this.setState({
        original_date_of_aquisition_ninth: date
      })
    }

    handleChangeOriginalDate10 = date => {
      this.setState({
        original_date_of_aquisition_ten: date
      })
    }

    handleChangeOriginalDate11 = date => {
      this.setState({
        original_date_of_aquisition_eleven: date
      })
    }

    handleChangeOriginalDate12 = date => {
      this.setState({
        original_date_of_aquisition_twelve: date
      })
    }

    handleChangeOriginalDate13 = date => {
      this.setState({
        original_date_of_aquisition_thirteen: date
      })
    }

    handleChangeOriginalDate14 = date => {
      this.setState({
        original_date_of_aquisition_fourteen: date
      })
    }

    handleChangeOriginalDate15 = date => {
      this.setState({
        original_date_of_aquisition_fifteen: date
      })
    }

    handleChangeOriginalDate16 = date => {
      this.setState({
        original_date_of_aquisition_sixteen: date
      })
    }

    handleChangeOriginalDate17 = date => {
      this.setState({
        original_date_of_aquisition_seventeen: date
      })
    }

    handleChangeOriginalDate18 = date => {
      this.setState({
        original_date_of_aquisition_eighteen: date
      })
    }

    handleChangeOriginalDate19 = date => {
      this.setState({
        original_date_of_aquisition_ninteen: date
      })
    }

    handleChangeOriginalDate20 = date => {
      this.setState({
        original_date_of_aquisition_twenty: date
      })
    }

    handleChangeOriginalDate21 = date => {
      this.setState({
        original_date_of_aquisition_tone: date
      })
    }

    handleChangeOriginalDate22 = date => {
      this.setState({
        original_date_of_aquisition_ttwo: date
      })
    }

    handleChangeOriginalDate23 = date => {
      this.setState({
        original_date_of_aquisition_tthree: date
      })
    }

    handleChangeOriginalDate24 = date => {
      this.setState({
        original_date_of_aquisition_tfour: date
      })
    }

    handleChangeOriginalDate25 = date => {
      this.setState({
        original_date_of_aquisition_tfive: date
      })
    }

  // Date change functions

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/vallum-tracker" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
        <section class="profile-section section-bg padding-top padding-bottom">
            <div class="container container-inner">
             <div className="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                  {/*Client On-Boarding Form */}
                  <div className="row justify-content-center after-login-pages no-gutters">
                  <Sidebar />
                   <div className="col-md-8 col-lg-9">
                    <div className="p-3 p-md-3"> 
                      {this.message()}
                         <h4 className="title mb-3 text-left">Additional Corpus Investment Form </h4>
                              <h6 className="title mb-3 text-left">Please answer the below Questions to process your additional corpus request </h6>
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter required field." }} >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <Col lg="6">
                                        <h6 className="title text-left">Full Name of Client </h6>
                                        
                                          <FormGroup className="w-100">
                                            <TextInput type="text" value={this.state.holder_name_one} onChange={this.handleChange} name="holder_name_one" id="holder_name_one" placeholder={"Full Name Of Client"}
                                              required
                                              errorMessage={{
                                                required: "Full Name of Client is required"
                                              }}
                                            />
                                          </FormGroup>
                                       
                                      </Col>
                                      <Col lg="6">
                                        <h6 className="title text-left">PMS Account Number</h6>
                                        
                                            <FormGroup className="w-100">
                                              <TextInput type="text" value={this.state.pms_account_number} onChange={this.handleChange} name="pms_account_number" id="pms_account_number" placeholder={"PMS Account Number"}
                                                required readOnly={true}
                                                errorMessage={{
                                                  required: "PMS account number is required"
                                                }}
                                              />
                                            </FormGroup>
                                         
                                      </Col>
                                      </div>
                                      <div className="row">
                                      <Col lg="6">
                                        <h6 className="title text-left">Strategy in which Capital is to be infused</h6>
                                       
                                          <FormGroup className="w-100">
                                            <SelectGroup name="investment_strategy_product" id="investment_strategy_product" 
                                              onChange={this.handleChange} value={this.state.investment_strategy_product} required errorMessage={{
                                              required: "Please select investment strategy product" }} >
                                              <option value="">Select Investment Strategy Product</option>  
                                              {this.products()}
                                            </SelectGroup>
                                          </FormGroup>
                                       
                                      </Col>
                                      </div>
                                      <h6 className="title text-left">Mode of infusion</h6>
                                      <div className="row">
                                     
                                        <Col xs="4">
                                          <FormGroup>
                                            <div className="form-check">
                                              <Input type="radio" value="Funds" onChange={this.handleChange} 
                                                name="mode_of_infusion" id="mode_of_infusion" required
                                                checked={this.state.mode_of_infusion == "Funds" ? true : false}
                                                />{' '}
                                              <label className="form-check-label">Funds</label>
                                            </div>
                                          </FormGroup>
                                          </Col>
                                          <Col xs="4">
                                          <FormGroup>
                                            <div className="form-check">
                                              <Input type="radio" value="Securities" onChange={this.handleChange} 
                                                name="mode_of_infusion" id="mode_of_infusion" required
                                                checked={this.state.mode_of_infusion == "Securities" ? true : false}
                                                />{' '}
                                              <label className="form-check-label">Securities</label>
                                            </div>
                                          </FormGroup>
                                          </Col>
                                          <Col xs="4">
                                          <FormGroup>
                                            <div className="form-check">
                                                <Input type="radio" value="Both" onChange={this.handleChange} required
                                                name="mode_of_infusion" id="mode_of_infusion" checked={this.state.mode_of_infusion == "Both" ? true : false}
                                                />{' '}
                                              <label className="form-check-label">Both</label>
                                            </div>
                                          </FormGroup>
                                    
                                      </Col>
                                    </div>
                                    {this.state.mode_of_infusion != "" && this.state.mode_of_infusion != "Securities" ? 
                                      <div className="">
                                        <div className="">
                                          <div className="row">
                                            <div className="col-lg-6">
                                              <h6 className="title text-left">Amount of Additional Capital (INR)</h6>
                                              
                                              <FormGroup className="w-100">
                                                <TextInput type="text" value={this.state.amount_of_additional_capital_inr} onChange={this.handleChange} name="amount_of_additional_capital_inr" id="amount_of_additional_capital_inr" placeholder={"Funds in INR*"}
                                                  minLength={6}
                                                  validator={(value) => validator.trim(value) != '' ? validator.isNumeric(validator.trim(value)) : true}
                                                  errorMessage={{
                                                    validator: "Amount of additional capital must be numeric."
                                                  }}
                                                />
                                              </FormGroup>
                                              <p className="text-left small-text">(Minimum INR 1,00,000 and in Multiples thereof) </p>
                                              </div>
                                            </div>
                                          </div>
                                        
                                          <h6 className="title text-left">Mode of Capital Infusion</h6>

                                      <div className="row">
                                          <Col xs="4">
                                              <FormGroup>
                                                <div className="form-check">
                                                  <Input type="radio" value="Cheque" onChange={this.handleChange} 
                                                    name="mode_of_capital_infusion" id="mode_of_capital_infusion" required
                                                    checked={this.state.mode_of_capital_infusion == "Cheque" ? true : false}
                                                    />{' '}
                                                  <label className="form-check-label">Cheque</label>
                                                </div>
                                              </FormGroup>
                                              </Col>
                                              <Col xs="4">
                                              
                                              <FormGroup>
                                                <div className="form-check">
                                                  <Input type="radio" value="NEFT" onChange={this.handleChange} 
                                                    name="mode_of_capital_infusion" id="mode_of_capital_infusion" required
                                                    checked={this.state.mode_of_capital_infusion == "NEFT" ? true : false}
                                                    />{' '}
                                                  <label className="form-check-label">NEFT</label>
                                                </div>
                                              </FormGroup>
                                              
                                              </Col>
                                              <Col xs="4">
                                                <FormGroup>
                                                  <div className="form-check">
                                                      <Input type="radio" value="RTGS" onChange={this.handleChange} required
                                                      name="mode_of_capital_infusion" id="mode_of_capital_infusion" checked={this.state.mode_of_capital_infusion == "RTGS" ? true : false}
                                                      />{' '}
                                                    <label className="form-check-label">RTGS</label>
                                                  </div>
                                                </FormGroup>
                                              </Col>
                                        </div>
                                        
                                       
                                        <div className="row">
                                          <div className="col-md-12">
                                            <h6 className="title text-left">Bank Account no from which funds will be transferred</h6>
                                              <FormGroup className="w-100">
                                                <TextInput type="text" value={this.state.bank_account_number} onChange={this.handleChange} name="bank_account_number" id="bank_account_number" placeholder={"Bank Account Number*"}
                                                />
                                              </FormGroup>
                                          </div>
                                          <div className="col-lg-6">
                                            <h6 className="title text-left">Date </h6>
                                              <FormGroup className="datepic w-100">
                                                <DatePicker
                                                  selected={this.state.date}
                                                  name="date"
                                                  yearDropdownItemNumber={10}
                                                  showYearDropdown
                                                  onChange={this.handleChangedDate}
                                                  disabledKeyboardNavigation
                                                  maxDate={this.state.max_date}
                                                  className="form-control"
                                                  selectsStart
                                                  dateFormat="yyyy-MM-dd"
                                                />
                                              </FormGroup>
                                          </div>
                                       
                                       
                                          <div className="col-lg-6">
                                            <h6 className="title text-left">Ref No</h6>
                                              <FormGroup className="w-100">
                                                <TextInput type="text" value={this.state.ref_number} onChange={this.handleChange} name="ref_number" id="ref_number" placeholder={"Ref Number*"}
                                                />
                                              </FormGroup>
                                          </div>
                                        </div>
                                   
                                    </div> : null }
                                    {this.state.mode_of_infusion != "" && this.state.mode_of_infusion != 'Funds' ? <div className="client-category">{this.individual()}</div> : ""}

                                    {this.state.additiionalCorpusStatus == '' || this.state.additiionalCorpusStatus == 'form-filled' || this.state.additiionalCorpusStatus == 'under-document-verification' ?<div className="row mt-5">
                                      <div className="col-md-12"><button type="submit">Submit</button></div>
                                    </div> : null }
                                  
                                  </div>
                                </div>
                              </ValidationForm>
                       </div>
                       </div>
                    </div>
                  {/*Client On Boarding Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCorpusForm);
