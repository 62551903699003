import React, { Component } from 'react';
import {Label,Card,CardBody,Col,Row,Button, CardHeader, FormGroup, CardFooter} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { NavLink  } from 'react-router-dom';
import PropTypes from 'prop-types';
import { adminLogin } from "../../actions/index";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: ""
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <div className="col-md-4 col-lg-3 text-left">
        <ul className="sidebar sidemenu">
          <li>
            <NavLink to="/my-account" activeClassName="active"><i className="fa fa-user"></i> My Account</NavLink>
          </li>
          <li>
            <NavLink to="/change-password" activeClassName="active"><i className="fa fa-lock"></i> Change Password</NavLink>
          </li>
          <li>
            <NavLink to="/change-email" activeClassName="active"><i className="fa fa-envelope"></i> Change Email</NavLink>
          </li>
          <li>
            <NavLink to="/change-mobile" activeClassName="active"><i className="fa fa-phone"></i> Change Mobile Number</NavLink>
          </li>
          <li>
            <a href="https://webfa.axisbank.co.in/wealthspectrum/app/loginWith" activeClassName="active" target="_blank"><i className="fa fa-folder"></i> All Reports</a>
          </li>
          <li>
            <NavLink to="/talk-to-the-team" activeClassName="active"><i className="fa fa-comments"></i> Talk To The Team</NavLink>
          </li>
          <li>
            <NavLink to="/documents"><i className="fa fa-file-pdf-o" activeClassName="active"></i> Documentation</NavLink>
          </li>
          <li>
            <NavLink to="/vallum-tracker" activeClassName="active"><i className="fa fa-clock-o"></i> Vallum Tracker</NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default Sidebar
