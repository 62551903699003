import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, CardGroup, Col, Container, Alert, Row} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import no_image from '../../assets/img/brand/no_image.png';

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      id: this.props.admin._id,
      full_name: this.props.admin.first_name ? this.props.admin.first_name+' '+this.props.admin.last_name : '',
      first_name: this.props.admin.first_name ? this.props.admin.first_name : '',
      last_name: this.props.admin.last_name ? this.props.admin.last_name : '',
      prism_username: this.props.admin.prism_username ? this.props.admin.prism_username : '',
      prism_password: this.props.admin.prism_password ? this.props.admin.prism_password : '',
      email: this.props.admin.email ? this.props.admin.email : '',
      mobile: this.props.admin.mobile ? this.props.admin.mobile : '',
      profileDataForStore: {}
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async checkUserOnPrism() {
    var uname = this.state.prism_username ? this.state.prism_username : 'ajay'
    var pwd = this.state.prism_password ? this.state.prism_password : "ajay"
    if(uname != '' && pwd != ''){
      var payload = {uname:uname,pwd:pwd,iat:Date.now()}
      var base64 = btoa(JSON.stringify(payload))
      let path = "https://stage.prismportfolio.com/cgi-bin/oauth.cgi?oauth=Basic "+base64;
      this.setState({loading: true});
      let headers = new Headers();
      headers.append('Access-Control-Allow-Origin', '*');
      let options = {
        credentials: 'omit',
        mode: 'cors',
        method: 'GET',
        /*headers : {'Access-Control-Allow-Origin':"*","Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept","Access-Control-Allow-Credentials":true}*/
      }
      try {
        let result = await fetch(path, options)
        /*console.log(result,'result');
        return false;*/
        this.setState({loading: false});
        if (result.ok) {
          if (result.status === 200) {
            let resultJson = await result.json();
            if (resultJson.success) {
              var pp2 = "https://stage.prismportfolio.com/cgi-bin/clogin.cgi?token="+resultJson.token;
              window.open(pp2);
              setTimeout(() => {
                this.setState({redirectTo : true})
              }, 1500);
              this.generate('success');
            } else {
              this.generate('danger');
              this.setState({message: resultJson.msg});
              setTimeout(() => {
                this.setState({message: null});
              }, 5000);
            }
          }
        } else {
          this.generate('danger');
          this.setState({message: result.statusText});
          setTimeout(() => {
            this.setState({message: null});
          }, 5000);
        }
      }catch(error){
        console.log(error,'error')
      }
    }else{
      this.generate('danger');
      this.setState({message: "Please contact admin for Prism Credentials."});
      setTimeout(() => {
        this.setState({message: null});
      }, 5000);
    }
  }

  componentDidMount(){
    this.checkUserOnPrism();
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/my-account" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
         <section class="profile-section section-bg padding-top padding-bottom" id="my-profile">
            <div class="container container-inner">
              {this.message()}
              <div class="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                <div className="row justify-content-center after-login-pages no-gutters">
                      <Sidebar />
                      <div className="col-md-8 col-lg-9">
                        <div className="p-3 p-md-3">
                        <h4 className="title mb-4 text-left">Reports</h4>
                        
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
