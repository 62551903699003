import React, { Component } from 'react';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";
import Pagination from "react-js-pagination";
import { Link,Redirect } from 'react-router-dom';
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import bannerImage from '../../assets/images/banner/banner-inner.png';
import DateFormatMonthName from "../../constants/dateformatmonthname";
import helper from "../../constants/helper";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const MAX_LENGTH = 160;
class Valuenomics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      activePage: 1,
      pageSize: 10,
      hasNextPage: false,
      hasPrevPage: false,
      limit: "",
      nextPage: false,
      prevPage: false,
      page: 0,
      prevPage: "",
      totalDocs: 0,
      totalPages: 0,
      insightData: {
        docs: []
      },
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  View(detailPage) {
    this.setState({ detailView: detailPage })
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }
  
  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    let params = {
      page: pageNumber,
      // search: { "name": this.state.name, "email" : this.state.email, "mobile" : this.state.mobile}
    };
    this.getInsightPage(helper.serialize(params));
  }

  async getInsightPage(params = "page=1"){
    let path = apiPath.insights;
    this.setState({ loading: true }); this.setState({ insightData: { docs: [] } })
    let postData = {};
    postData.slug = "valuenomics";
    let options = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    }
    // let result = await Http(path, options);
    let result = await Http(path + "?" + params, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let valuenomics = resultJson.result;
          this.setState({ valuenomics: valuenomics.docs, pageSize: valuenomics.limit, insightData: valuenomics, totalDocs: resultJson.result.totalDocs, totalPages: resultJson.result.totalPages, activePage: valuenomics.page || 1 });
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentDidMount(){
    this.getInsightPage();
  }

  insightList(){
    if(!_.isEmpty(this.state.valuenomics)){
      return this.state.valuenomics.map((value,index)=>{
        return <div className="col-md-6" key={index}>
          <div className="box-grey mb-4">
            <div className="p-3">
              <div className="grid-item-content">
                {/*<span className="modal-title published-date" id="exampleModalLongTitle">
                            <p className="float-left">Published On :   </p><DateFormatMonthName date={value.createdAt} />
                        </span>*/}
                <h5 className="view-more modal-title title" onClick={this.View.bind(this, value)} outline color="info" value={JSON.stringify(value)} dangerouslySetInnerHTML={{__html : value.title}}></h5>
                {/*value.content != '' && value.content != null && value.content != undefined ?<div className="description" dangerouslySetInnerHTML={{__html : `${window.atob(value.content) ? window.atob(value.content).substring(0, MAX_LENGTH) : ''}...`}}></div>: null*/}
              </div>
              <div className="grid-item-footer">
                <hr/>
                {value.pdf != '' && value.pdf.indexOf('u/user') < 1 ? 
                  <a href={value.pdf} target="_blank" className="view-more custom-button btn-sm">View More</a> 
                  : value.external_url && value.external_url.indexOf('u/user') != '' ? 
                  <a href={value.external_url} target="_blank" className="view-more custom-button btn-sm">View More</a> 
                  : <h6 className="view-more custom-button btn-sm mb-0" onClick={this.View.bind(this, value)} outline color="info" value={JSON.stringify(value)}>View More</h6>
                }
              </div>
            </div>
          </div>
        </div>
      })
    }
  }

  loadPagination() {
    if (this.state.valuenomics) {
      return (
        <div>
          <Pagination
            activeClass={"page-item active"}
            activeLinkClass={"page-link"}
            itemClass={"page-item"}
            linkClass={"page-link"}
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageSize}
            totalItemsCount={this.state.totalDocs}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange}
          />
          {/* Total Records: {this.state.totalDocs || 0} */}
        </div>
      )
    }
  }

  render() {
    if (!_.isEmpty(this.state.detailView)) {
      return <Redirect to={{
        pathname: '/blog-view',
        insightData: this.state.detailView
      }} />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
        <section class="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div class="container">
                  <div class="hero-content">
                      <h1 class="title text-white">Blog</h1>
                      <ul class="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Blog
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        <section className="insights-vallum-section padding-top padding-bottom">
            <div className="container container-inner">
               
                   <div className="box-shadow box-block p-3 p-md-4 aos-item aos-init aos-animate" data-aos="fade-up">
                      <div className="row justify-content-center">
                                {this.insightList()}
                            </div>
                        </div>
                  
                <div className="row justify-content-center insight-pagination">
                  {this.loadPagination()}
                </div>
            </div>
            
        </section>

        {/*GetInTouch*/}
        <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default Valuenomics;
