import React, { Component } from 'react';
import { Card, CardBody, Col, Row, Table, Button, FormGroup } from 'reactstrap';
import { transform, isString, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Pagination from "react-js-pagination";
import Http from "../../services/Http";
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import DocumentRow from './DocumentRow';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
const moment = require('moment');

const mapStateToProps = state => {
  return { admin: state.admin };
};
const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class Documents extends Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      keyword: '',
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: true,
      loading: false,
      activePage: 1,
      totalDocs: 0,
      totalPages: 0,
      pageSize: 10,      
      id: this.props.admin._id,
      name: '',
      title: '',
      csvData: [],
      dataList: {},
      headers:[],
      documents: {
        docs: []
      }
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.pleaseSort = this.pleaseSort.bind(this);
  }

  handleChange = (e) => {
    console.log(e.target);
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    formData = transform(formData, function (result, value, key) {
      result[key] = isString(value) ? value.trim() : value;
    }, {});
    let params = {
      search: formData
    };
    console.log(params);
    this.getDocument(helper.serialize(params));
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    console.log(e, formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
    this.setState({ keyword: '',name:'', activePage: 1 }, () => {
      let params = {
        page: this.state.activePage,
        search: {}
      };
      this.getDocument(helper.serialize(params));
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    let params = {
      page: pageNumber,
      search: { "name": this.state.name}
    };
    this.getDocument(helper.serialize(params));
  }

  async getDocument(params = "page=1") {
    let path = apiPath.getDocument;
    this.setState({ loading: true }); this.setState({ documents: { docs: [] } })
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path + "?user_id="+this.state.id+"&" + params, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result || {};
          this.setState({ pageSize: resultUsersJson.limit, documents: resultUsersJson, totalDocs: resultJson.result.totalDocs, totalPages: resultJson.result.totalPages, activePage: resultUsersJson.page || 1 });
        } else {
          this.setState({ error: resultJson.msg });
          setTimeout(() => {
            this.setState({ error: null });
          }, 5000);
        }
      }
    } else {
      this.setState({ error: result.statusText });
      setTimeout(() => {
        this.setState({ error: null });
      }, 5000);
    }
  }

  componentDidMount() {
    this.getDocument("page=1");
  }

  noDataFound() {
    if (!this.state.documents.docs.length) {
      return (
        <tr className={"text-center"}>
          <th colSpan={8} scope="row">No record(s) found</th>
        </tr>
      )
    }
  }

  pleaseSort(event) {

    let sortdata = event.currentTarget.dataset.sortdata;
    let sortdataArrya = sortdata.split(":");
    let selected = document.getElementById(sortdataArrya[0]);
    let firstEle = sortdataArrya[0];
    let secondEle = (sortdataArrya[1] === 'asc') ? 'desc' : 'asc';
    selected.dataset.sortdata = firstEle + ":" + secondEle;
    selected.children[0].className = (sortdataArrya[1] === 'asc') ? 'fa fa-sort-up' : 'fa fa-sort-down';

    let params = {
      page: this.state.activePage,
      sort: { [sortdataArrya[0]]: sortdataArrya[1] },
      search: { "keyword": this.state.keyword }
    };
    this.getDocument(helper.serialize(params));
  }

  loading() {
    if (this.state.loading) {
      return (
        <tr><td className={"text-center"} colSpan="8"><i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
          <span className="sr-only" > Loading</span></td></tr>
      )
    }
  }

  loadPagination() {
    if (this.state.documents.docs.length) {
      return (
        <div>
          <Pagination
            activeClass={"page-item active"}
            activeLinkClass={"page-link"}
            itemClass={"page-item"}
            linkClass={"page-link"}
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageSize}
            totalItemsCount={this.state.totalDocs}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange}
          />
        </div>
      )
    }
  }

  render() {
    const userList = this.state.documents.docs;
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
          <section class="profile-section section-bg padding-top padding-bottom" id="my-profile">
            <div class="container container-inner">
              <div class="box-shadow box-block aos-item aos-init aos-animate section-white overflow-hidden" data-aos="fade-up">
                <div className="row justify-content-center after-login-pages no-gutters">
                  <Sidebar />
                  <div className="col-md-8 col-lg-9">
                    <div className="p-3 p-md-3">
                      <h4 className="title mb-4 text-left">Documents</h4>
                      <Table responsive hover>
                        <thead>
                          <tr>
                            {/*<th scope="col">#</th>*/}
                            <th scope="col">
                              Title
                            </th>
                            <th scope="col">
                              Document
                            </th>
                            <th scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList.map((user, index) =>
                            <DocumentRow key={index} id={((this.state.activePage - 1) * this.state.pageSize) + index} user={user} />
                          )}
                          {this.noDataFound()}
                          {this.loading()}
                        </tbody>
                      </Table>
                      {this.loadPagination()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents);