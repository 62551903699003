import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Table, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import "react-datepicker/dist/react-datepicker.css";

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class ClientOnBoardingDocumentsNonIndividual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      
      occupation: this.props.admin ? this.props.admin.occupation : "",
      id: this.props.admin._id,
      profileDataForStore: {},
      clientCategory: "",

      onBoardingId: "",
      non_individual_type: "",

      pan_card: "",
      pan_cardName: "",
      pan_cardUploadedName: "",
      pan_card_of_karta: "",
      pan_card_of_kartaName: "",
      pan_card_of_kartaUploadedName: "",
      pan_card_of_others: "",
      pan_card_of_othersName: "",
      pan_card_of_othersUploadedName: "",
      pan_card_of_others2: "",
      pan_card_of_others2Name: "",
      pan_card_of_others2UploadedName: "",
      pan_card_of_others3: "",
      pan_card_of_others3Name: "",
      pan_card_of_others3UploadedName: "",
      pan_card_of_others4: "",
      pan_card_of_others4Name: "",
      pan_card_of_others4UploadedName: "",
      pan_card_of_others5: "",
      pan_card_of_others5Name: "",
      pan_card_of_others5UploadedName: "",
      address_proof_name: "",
      address_proof: "",
      address_proofName: "",
      address_proofUploadedName: "",
      co_parceners_on_company_letter_head: "",
      co_parceners_on_company_letter_headName: "",
      co_parceners_on_company_letter_headUploadedName: "",
      bank_account_detail: "",
      bank_account_detail_document: "",
      bank_account_detail_documentName: "",
      bank_account_detail_documentUploadedName: "",
      other_document: "",
      other_documentName: "",
      other_documentUploadedName: "",
      other_document2: "",
      other_document2Name: "",
      other_document2UploadedName: "",
      other_document3: "",
      other_document3Name: "",
      other_document3UploadedName: "",

      directors_with_din_no_company_letter_head: "",
      directors_with_din_no_company_letter_headName: "",
      directors_with_din_no_company_letter_headUploadedName: "",
      memorandum_of_association: "",
      memorandum_of_associationName: "",
      memorandum_of_associationUploadedName: "",
      articles_of_association: "",
      articles_of_associationName: "",
      articles_of_associationUploadedName: "",
      bank_account_detail_document_of_corporate: "",
      bank_account_detail_document_of_corporateName: "",
      bank_account_detail_document_of_corporateUploadedName: "",
      resolution: "",
      resolutionName: "",
      resolutionUploadedName: "",
      certificate_of_incorporation: "",
      certificate_of_incorporationName: "",
      certificate_of_incorporationUploadedName: "",
      authorized_signatory_with_specimen_signature: "",
      authorized_signatory_with_specimen_signatureName: "",
      authorized_signatory_with_specimen_signatureUploadedName: "",
      balance_sheet_year_1: "",
      balance_sheet_year_1Name: "",
      balance_sheet_year_1UploadedName: "",
      balance_sheet_year_2: "",
      balance_sheet_year_2Name: "",
      balance_sheet_year_2UploadedName: "",
      latest_share_holding_pattern: "",
      latest_share_holding_patternName: "",
      latest_share_holding_patternUploadedName: "",
      certificate_of_registration : "",
      certificate_of_registrationName : "",
      certificate_of_registrationUploadedName : "",
      trust_deed : "",
      trust_deedName : "",
      trust_deedUploadedName : "",
      list_of_trustees : "",
      list_of_trusteesName : "",
      list_of_trusteesUploadedName : "",
      societyRule : "",
      societyRuleName : "",
      societyRuleUploadedName : "",
      partnership_deed : "",
      partnership_deedName : "",
      partnership_deedUploadedName : "",
      proof_of_existence : "",
      proof_of_existenceName : "",
      proof_of_existenceUploadedName : "",
      ODS2 : "shide",
      ODS3 : "shide",
      ODB1 : "bshow",
      ODB2 : "bshow",
      PS2 : "shide",
      PS3 : "shide",
      PS4 : "shide",
      PS5 : "shide",
      PB1 : "bshow",
      PB2 : "bshow",
      PB3 : "bshow",
      PB4 : "bshow",
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleButtonChange= (e) =>{
    let stat = e.target.getAttribute('data_type');
    var ele = document.getElementsByClassName(stat);
    ele[0].classList.remove("shide");
    e.target.classList.remove("bshow");
    e.target.classList.add("shide");
  }

  handleChangeImg = (name, e) => {
    if(name=="pan_card"){
      this.setState({pan_cardName : e.target.files[0].name})
    }
    if(name=="pan_card_of_karta"){
      this.setState({pan_card_of_kartaName : e.target.files[0].name})
    }
    if(name=="pan_card_of_others"){
      this.setState({pan_card_of_othersName : e.target.files[0].name})
    }
    if(name=="pan_card_of_others2"){
      this.setState({pan_card_of_others2Name : e.target.files[0].name})
    }
    if(name=="pan_card_of_others3"){
      this.setState({pan_card_of_others3Name : e.target.files[0].name})
    }
    if(name=="pan_card_of_others4"){
      this.setState({pan_card_of_others4Name : e.target.files[0].name})
    }
    if(name=="pan_card_of_others5"){
      this.setState({pan_card_of_others5Name : e.target.files[0].name})
    }
    if(name=="address_proof"){
      this.setState({address_proofName : e.target.files[0].name})
    }
    if(name=="co_parceners_on_company_letter_head"){
      this.setState({co_parceners_on_company_letter_headName : e.target.files[0].name})
    }
    if(name=="bank_account_detail_document"){
      this.setState({bank_account_detail_documentName : e.target.files[0].name})
    }
    if(name=="other_document"){
      this.setState({other_documentName : e.target.files[0].name})
    }
    if(name=="other_document2"){
      this.setState({other_document2Name : e.target.files[0].name})
    }
    if(name=="other_document3"){
      this.setState({other_document3Name : e.target.files[0].name})
    }
    if(name=="directors_with_din_no_company_letter_head"){
      this.setState({directors_with_din_no_company_letter_headName : e.target.files[0].name})
    }
    if(name=="memorandum_of_association"){
      this.setState({memorandum_of_associationName : e.target.files[0].name})
    }
    if(name=="articles_of_association"){
      this.setState({articles_of_associationName : e.target.files[0].name})
    }
    if(name=="bank_account_detail_document_of_corporate"){
      this.setState({bank_account_detail_document_of_corporateName : e.target.files[0].name})
    }
    if(name=="resolution"){
      this.setState({resolutionName : e.target.files[0].name})
    }
    if(name=="certificate_of_incorporation"){
      this.setState({certificate_of_incorporationName : e.target.files[0].name})
    }
    if(name=="authorized_signatory_with_specimen_signature"){
      this.setState({authorized_signatory_with_specimen_signatureName : e.target.files[0].name})
    }
    if(name=="balance_sheet_year_1"){
      this.setState({balance_sheet_year_1Name : e.target.files[0].name})
    }
    if(name=="balance_sheet_year_2"){
      this.setState({balance_sheet_year_2Name : e.target.files[0].name})
    }
    if(name=="latest_share_holding_pattern"){
      this.setState({latest_share_holding_patternName : e.target.files[0].name})
    }
    if(name=="certificate_of_registration"){
      this.setState({certificate_of_registrationName : e.target.files[0].name})
    }
    if(name=="trust_deed"){
      this.setState({trust_deedName : e.target.files[0].name})
    }
    if(name=="list_of_trustees"){
      this.setState({list_of_trusteesName : e.target.files[0].name})
    }
    if(name=="societyRule"){
      this.setState({societyRuleName : e.target.files[0].name})
    }
    if(name=="partnership_deed"){
      this.setState({partnership_deedName : e.target.files[0].name})
    }
    if(name=="proof_of_existence"){
      this.setState({proof_of_existenceName : e.target.files[0].name})
    }
    helper.getBase64(e.target.files[0], (result) => {
      this.setState({
        [name]: result
      });
    });
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.clientOnBoardingDocumentsNonIndividual(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async clientOnBoardingDocumentsNonIndividual(postData) {
    let path = apiPath.clientOnBoardingDocumentsNonIndividual;
    this.setState({ loading: true });
    let data = new FormData();
    data.append('pan_card', postData.pan_card);
    data.append('pan_card_of_karta', postData.pan_card_of_karta);
    data.append('pan_card_of_others', postData.pan_card_of_others);
    data.append('pan_card_of_others2', postData.pan_card_of_others2);
    data.append('pan_card_of_others3', postData.pan_card_of_others3);
    data.append('pan_card_of_others4', postData.pan_card_of_others4);
    data.append('pan_card_of_others5', postData.pan_card_of_others5);
    data.append('address_proof', postData.address_proof);
    data.append('co_parceners_on_company_letter_head', postData.co_parceners_on_company_letter_head);
    data.append('bank_account_detail_document', postData.bank_account_detail_document);
    data.append('other_document', postData.other_document);
    data.append('other_document2', postData.other_document2);
    data.append('other_document3', postData.other_document3);
    data.append('directors_with_din_no_company_letter_head', postData.directors_with_din_no_company_letter_head);
    data.append('memorandum_of_association', postData.memorandum_of_association);
    data.append('articles_of_association', postData.articles_of_association);
    data.append('bank_account_detail_document_of_corporate', postData.bank_account_detail_document_of_corporate);
    data.append('resolution', postData.resolution);
    data.append('certificate_of_incorporation', postData.certificate_of_incorporation);
    data.append('authorized_signatory_with_specimen_signature', postData.authorized_signatory_with_specimen_signature);
    data.append('balance_sheet_year_1', postData.balance_sheet_year_1);
    data.append('balance_sheet_year_2', postData.balance_sheet_year_2);
    data.append('latest_share_holding_pattern', postData.latest_share_holding_pattern);
    data.append('certificate_of_registration', postData.certificate_of_registration);
    data.append('trust_deed', postData.trust_deed);
    data.append('list_of_trustees', postData.list_of_trustees);
    data.append('societyRule', postData.societyRule);
    data.append('partnership_deed', postData.partnership_deed);
    data.append('proof_of_existence', postData.proof_of_existence);
    postData.user_id = this.state.id
    postData.onBoardingId = this.state.onBoardingId
    postData.non_individual_type = this.state.non_individual_type
    data.append('onBoardingDocumentData', JSON.stringify(postData));
    let options = {
      method: 'PUT',
      headers: {},
      body: data
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            this.setState({redirectTo: true});
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  panCardField(){
    return <div className="row">
      <div className="col-md-6">
        <div>
          { this.state.pan_cardName != '' ? this.state.pan_cardName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="pan_card" id="pan_card"
            errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
          { this.state.pan_cardUploadedName != '' ? <a href={this.state.pan_cardUploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  addressProofField(){
    return <div className="row">
      <div className="col-md-6">
        <FormGroup>
          <SelectGroup name="address_proof_name" id="address_proof_name" 
            onChange={this.handleChange} value={this.state.address_proof_name} required errorMessage={{
            required: "Please select Address Proof" }} >
            <option value="">Select Address Proof</option>
            <option value="Electricity-Bill">Electricity Bill (Not more than 3 months old)</option>
            <option value="Telephone-Bill-Landline">Telephone Bill -Landline (Not more than 3 months old)</option>
            <option value="Gas-Bill">Gas Bill (Not more than 3 months old)</option>
            <option value="Ration-Card">Ration Card</option>
            <option value="Passport">Passport</option>
            <option value="Aadhaar-Card">Aadhaar Card</option>
            <option value="Registered-Lease-or-Sale-Agreement-of-Residence">Registered Lease or Sale Agreement of Residence</option>
            <option value="Driving-License">Driving License</option>
            <option value="Flat-Maintenance-bill">Flat Maintenance bill</option>
            <option value="Insurance-Copy">Insurance Copy</option>
            <option value="Voter-ID-card">Voter's ID card</option>
            <option value="Other-Address-Proof">Other Address Proof</option>
          </SelectGroup>
        </FormGroup>
      </div>
      <div className="col-md-6">
        <div>
          { this.state.address_proofName != '' ? this.state.address_proofName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="address_proof" id="address_proof"
            errorMessage={{ required: "Please upload an address proof document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'address_proof')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Address Proof</label>
          { this.state.address_proofUploadedName != '' ? <a href={this.state.address_proofUploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  bankDetailFields(){
    return <div className="row">
      <div className="col-md-6">
        <FormGroup>
          <SelectGroup name="bank_account_detail" id="bank_account_detail" 
              onChange={this.handleChange} value={this.state.bank_account_detail} required errorMessage={{
              required: "Please select Bank Proof" }} >
              <option value="">Select Bank Account Details</option>
              <option value="Cancelled-Cheque">Cancelled Cheque</option>
              <option value="Bank-statement-Passbook (Not more than 3 months old)">Bank statement/Passbook (Not more than 3 months old)</option>
              <option value="Letter-issued-by-the-Bank-giving-the-account-details.">Letter issued by the Bank giving the account details.</option>
          </SelectGroup>
        </FormGroup>
      </div>
      <div className="col-md-6">
        <div>
          { this.state.bank_account_detail_documentName != '' ? this.state.bank_account_detail_documentName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="bank_account_detail_document" id="bank_account_detail_document"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'bank_account_detail_document')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Bank Account Document</label>
          { this.state.bank_account_detail_documentUploadedName != '' ? <a href={this.state.bank_account_detail_documentUploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  otherDocumentField(){
    return <div className="row">
      <div className="col-md-6">
        <div>
          { this.state.other_documentName != '' ? this.state.other_documentName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document" id="other_document"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_documentUploadedName != '' ? <a href={this.state.other_documentUploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="second-section" className={this.state.ODB1} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"second-section col-md-6 "+this.state.ODS2 }>
        <div>
          { this.state.other_document2Name != '' ? this.state.other_document2Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document2" id="other_document2"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document2')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document2UploadedName != '' ? <a href={this.state.other_document2UploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="third-section" className={this.state.ODB2} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"third-section col-md-6 "+this.state.ODS3 }>
        <div>
          { this.state.other_document3Name != '' ? this.state.other_document3Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document3" id="other_document3"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document3')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document3UploadedName != '' ? <a href={this.state.other_document3UploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  otherPanCardField(){
    return <div className="row">
      <div className="col-md-6">
        <div>
          { this.state.pan_card_of_othersName != '' ? this.state.pan_card_of_othersName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="pan_card_of_others" id="pan_card_of_others"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_of_others')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
          { this.state.pan_card_of_othersUploadedName != '' ? <a href={this.state.pan_card_of_othersUploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="pan-section" className={this.state.PB1} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"pan-section col-md-6 "+this.state.PS2 }>
        <div>
          { this.state.pan_card_of_others2Name != '' ? this.state.pan_card_of_others2Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="pan_card_of_others2" id="pan_card_of_others2"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_of_others2')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
          { this.state.pan_card_of_others2UploadedName != '' ? <a href={this.state.pan_card_of_others2UploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="pan-section2" className={this.state.PB2} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"pan-section2 col-md-6 "+this.state.PS3 }>
        <div>
          { this.state.pan_card_of_others3Name != '' ? this.state.pan_card_of_others3Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="pan_card_of_others3" id="pan_card_of_others3"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_of_others3')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
          { this.state.pan_card_of_others3UploadedName != '' ? <a href={this.state.pan_card_of_others3UploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="pan-section3" className={this.state.PB3} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"pan-section3 col-md-6 "+this.state.PS4 }>
        <div>
          { this.state.pan_card_of_others4Name != '' ? this.state.pan_card_of_others4Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="pan_card_of_others4" id="pan_card_of_others4"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_of_others4')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
          { this.state.pan_card_of_others4UploadedName != '' ? <a href={this.state.pan_card_of_others4UploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="pan-section4" className={this.state.PB4} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"pan-section4 col-md-6 "+this.state.PS5 }>
        <div>
          { this.state.pan_card_of_others5Name != '' ? this.state.pan_card_of_others5Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="pan_card_of_others5" id="pan_card_of_others5"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_of_others5')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
          { this.state.pan_card_of_others5UploadedName != '' ? <a href={this.state.pan_card_of_others5UploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  balanceSheetFields(){
    return <div className="row">
      <div className="col-md-6">
        <div>
          { this.state.balance_sheet_year_1Name != '' ? this.state.balance_sheet_year_1Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="balance_sheet_year_1" id="balance_sheet_year_1"
            errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'balance_sheet_year_1')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Balance sheet 1</label>
          { this.state.balance_sheet_year_1UploadedName != '' ? <a href={this.state.balance_sheet_year_1UploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
      <div className="col-md-6">
        <div>
          { this.state.balance_sheet_year_2Name != '' ? this.state.balance_sheet_year_2Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="balance_sheet_year_2" id="balance_sheet_year_2"
            errorMessage={{ required: "Please upload an address proof document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'balance_sheet_year_2')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Balance sheet 2</label>
          { this.state.balance_sheet_year_2UploadedName != '' ? <a href={this.state.balance_sheet_year_2UploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  resolutionDocument(){
    return <div>
      <div>
        { this.state.resolutionName != '' ? this.state.resolutionName :null}
      </div>
      <div className="custom-file" style={{ "marginTop": "5px" }}>
        <FileInput className={"custom-file-input"} name="resolution" id="resolution"
          errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
          fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'resolution')} />
        <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Resolution Document</label>
        { this.state.resolutionUploadedName != '' ? <a href={this.state.resolutionUploadedName} target="_blank">Preview Uploaded File</a> :null}
      </div>
    </div>
  }

  specimenSignatureDocument(){
    return <div className="row">
      <div className="col-md-6">
        <div>
          { this.state.authorized_signatory_with_specimen_signatureName != '' ? this.state.authorized_signatory_with_specimen_signatureName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="authorized_signatory_with_specimen_signature" id="authorized_signatory_with_specimen_signature"
            errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'authorized_signatory_with_specimen_signature')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload specimen signature</label>
          { this.state.authorized_signatory_with_specimen_signatureUploadedName != '' ? <a href={this.state.authorized_signatory_with_specimen_signatureUploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  certificateOfRegistration(){
    return <div className="row">
      <div className="col-md-6">
        <div>
          { this.state.certificate_of_registrationName != '' ? this.state.certificate_of_registrationName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="certificate_of_registration" id="certificate_of_registration"
            errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'certificate_of_registration')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Certificate of Registration</label>
          { this.state.certificate_of_registrationUploadedName != '' ? <a href={this.state.certificate_of_registrationUploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  HUF(){
    return <div>
      <div className="huf">
        
        {/* <div className="col-md-6 text-left"></div> */}
        <h6 className="title mt-2 text-left">1. Pan Card of HUF</h6>
        {this.panCardField()}

        <h6 className="title mt-2 text-left">2. Pan Card of Karta</h6>
        <div className="row">
          <div className="col-md-6">
            <div>
              { this.state.pan_card_of_kartaName != '' ? this.state.pan_card_of_kartaName :null}
            </div>
            <div className="custom-file" style={{ "marginTop": "5px" }}>
              <FileInput className={"custom-file-input"} name="pan_card_of_karta" id="pan_card_of_karta"
                errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_of_karta')} />
              <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
              { this.state.pan_card_of_kartaUploadedName != '' ? <a href={this.state.pan_card_of_kartaUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
        </div>

        <h6 className="title mt-2 text-left">3. Pan Card of Co-Parceners</h6>
        {this.otherPanCardField()}
    
        <h6 className="title mt-2 text-left">4. Address proof of the HUF</h6>
        {this.addressProofField()}
        
        <h6 className="title mt-2 text-left">5. List Co Parceners on company letter head</h6>
        <div className="row">
          <div className="col-md-6">
            <div>
              { this.state.co_parceners_on_company_letter_headName != '' ? this.state.co_parceners_on_company_letter_headName :null}
            </div>
            <div className="custom-file" style={{ "marginTop": "5px" }}>
              <FileInput className={"custom-file-input"} name="co_parceners_on_company_letter_head" id="co_parceners_on_company_letter_head"
                errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'co_parceners_on_company_letter_head')} />
              <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
              { this.state.co_parceners_on_company_letter_headUploadedName != '' ? <a href={this.state.co_parceners_on_company_letter_headUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
        </div>

        <h6 className="title mt-2 text-left">6. Bank Account Details of HUF</h6>
        {this.bankDetailFields()}
        
        <h6 className="title mt-2 text-left">7. Any Other Document</h6>
        {this.otherDocumentField()}

      </div>
    </div>
  }

  Corporate(){
    return <div>
      <div className="corporate">
        <div className="form-group">
          <h6 className="title mt-2 text-left">1. Pan Card of Corporate</h6>
          {this.panCardField()}
        </div>
        <div className="form-group">
          <h6 className="title mt-2 text-left">2. Address proof of the Corporate</h6>
          {this.addressProofField()}
          </div>
          <div className="form-group">
          <h6 className="title mt-2 text-left">3. List of all directors with DIN no on company letter head</h6>
          <div className="row">
            <div className="col-md-6">
              <div>
                { this.state.directors_with_din_no_company_letter_headName != '' ? this.state.directors_with_din_no_company_letter_headName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="directors_with_din_no_company_letter_head" id="directors_with_din_no_company_letter_head"
                  errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'directors_with_din_no_company_letter_head')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload DIN no on company letter head</label>
                { this.state.directors_with_din_no_company_letter_headUploadedName != '' ? <a href={this.state.directors_with_din_no_company_letter_headUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>
</div>
<div className="form-group">
          <h6 className="title mt-2 text-left">4. Pan Card of all Directors</h6>
          {this.otherPanCardField()}
</div>
<div className="form-group">
          <h6 className="title mt-2 text-left">5. Bank Account Details of Corporate</h6>
          {this.bankDetailFields()}
 </div>  
 <div className="form-group">     
          <h6 className="title mt-2 text-left">6. Board Resolution on company letter head</h6>
          <div className="row">
            <div className="col-md-6">
              Dowload Format of Board Resolution <a href="#" target="_blank"><i className="fa fa-download"></i></a>
              {this.resolutionDocument()}
            </div>
          </div>
</div>
<div className="form-group">
          <h6 className="title mt-2 text-left">7. Memorandum of Association</h6>
          <div className="row">
            <div className="col-md-6">
              <div>
                { this.state.memorandum_of_associationName != '' ? this.state.memorandum_of_associationName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="memorandum_of_association" id="memorandum_of_association"
                  errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'memorandum_of_association')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Memorandum of Association</label>
                { this.state.memorandum_of_associationUploadedName != '' ? <a href={this.state.memorandum_of_associationUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>
</div> 
<div className="form-group">
          <h6 className="title mt-2 text-left">8. Articles of Association</h6>
          <div className="row">
            <div className="col-md-6">
              <div>
                { this.state.articles_of_associationName != '' ? this.state.articles_of_associationName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="articles_of_association" id="articles_of_association"
                  errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'articles_of_association')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Articles of Association</label>
                { this.state.articles_of_associationUploadedName != '' ? <a href={this.state.articles_of_associationUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>
</div>
<div className="form-group">
          <h6 className="title mt-2 text-left">9. Certificate of Incorporation</h6>
          <div className="row">
            <div className="col-md-6">
              <div>
                { this.state.certificate_of_incorporationName != '' ? this.state.certificate_of_incorporationName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="certificate_of_incorporation" id="certificate_of_incorporation"
                  errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'certificate_of_incorporation')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Certificate of Incorporation</label>
                { this.state.certificate_of_incorporationUploadedName != '' ? <a href={this.state.certificate_of_incorporationUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>
</div>
<div className="form-group">
          <h6 className="title mt-2 text-left">10. List of Authorized Signatory with specimen signature</h6>
          {this.specimenSignatureDocument()}
</div> 
<div className="form-group">
          <h6 className="title mt-2 text-left">11. Balance sheet of the last 2 Financial Years</h6>
          {this.balanceSheetFields()}
     </div>
     <div className="form-group">   
          <h6 className="title mt-2 text-left">12. Latest Share Holding Pattern</h6>
          <div className="row">
            <div className="col-md-6">
              <div>
                { this.state.latest_share_holding_patternName != '' ? this.state.latest_share_holding_patternName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="latest_share_holding_pattern" id="latest_share_holding_pattern"
                  errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'latest_share_holding_pattern')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Latest Share Holding Pattern</label>
                { this.state.latest_share_holding_patternUploadedName != '' ? <a href={this.state.latest_share_holding_patternUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>
</div>
<div className="form-group">
          <h6 className="title mt-2 text-left">13. Any Other Document</h6>
          {this.otherDocumentField()}
</div>
      </div>
    </div>
  }

  trustOrRegisteredSociety(){
    return <div>
      <div className="corporate">
          <h6 className="title mt-2 text-left">1. Pan Card of Trust/Society</h6>
          {this.panCardField()}

          <h6 className="title mt-2 text-left">2. Address proof of the Trust/Society</h6>
          {this.addressProofField()}

          <h6 className="title mt-2 text-left">3. Certificate of Registeration (for registered trust only)</h6>
          {this.certificateOfRegistration()}

          <h6 className="title mt-2 text-left">4. Trust Deed</h6>
          <div className="row">
            <div className="col-md-6">
              <div>
                { this.state.trust_deedName != '' ? this.state.trust_deedName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="trust_deed" id="trust_deed"
                  errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'trust_deed')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Trust Deed</label>
                { this.state.trust_deedUploadedName != '' ? <a href={this.state.trust_deedUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>

          <h6 className="title mt-2 text-left">5. List of trustees certified by managing trustees/CA or Managing Committee members</h6>
          <div className="row">
            <div className="col-md-6">
              <div>
                { this.state.list_of_trusteesName != '' ? this.state.list_of_trusteesName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="list_of_trustees" id="list_of_trustees"
                  errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'list_of_trustees')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload List of trustees</label>
                { this.state.list_of_trusteesUploadedName != '' ? <a href={this.state.list_of_trusteesUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>
        
          <h6 className="title mt-2 text-left">6. Pan Card of all Trustees</h6>
          {this.otherPanCardField()}

          <h6 className="title mt-2 text-left">7. Bank Account Details of Trust/Society</h6>
          {this.bankDetailFields()}

          <h6 className="title mt-2 text-left">8. Resolution for Investment in Securities</h6>
          <div className="row">
            <div className="col-md-6">
              Dowload Format of Resolution <a href="#" target="_blank"><i className="fa fa-download"></i></a>
              {this.resolutionDocument()}
            </div>
          </div>

          <h6 className="title mt-2 text-left">9. Balance sheet of the last 2 Financial Years</h6>
          {this.balanceSheetFields()}

          <h6 className="title mt-2 text-left">10. Committee resolution for persons authorized to act as authorized signatories with specimen signatures.</h6>
          {this.specimenSignatureDocument()}

          <h6 className="title mt-2 text-left">11. True copy of Society Rules and Bye Laws certified by the Chairman/Secretary</h6>
          <div className="row">
            <div className="col-md-6">
              <div>
                { this.state.societyRuleName != '' ? this.state.societyRuleName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="societyRule" id="societyRule"
                  errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'societyRule')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Society Rule</label>
                { this.state.societyRuleUploadedName != '' ? <a href={this.state.societyRuleUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>

          <h6 className="title mt-2 text-left">12. Any Other Document</h6>
          {this.otherDocumentField()}

      </div>
    </div>
  }

  Partnership(){
    return <div>
      <div className="corporate">
          <h6 className="title mt-2 text-left">1. Pan Card of Partnership Firm</h6>
          {this.panCardField()}

          <h6 className="title mt-2 text-left">2. Address proof of Partnership Firm</h6>
          {this.addressProofField()}

          <h6 className="title mt-2 text-left">3. Pan Card of all Partners</h6>
          {this.otherPanCardField()}

          <h6 className="title mt-2 text-left">4. Bank Account Details of Trust/Society</h6>
          {this.bankDetailFields()}

          <h6 className="title mt-2 text-left">5. Partnership Deed</h6>
          <div className="row">
            <div className="col-md-6">
              <div>
                { this.state.partnership_deedName != '' ? this.state.partnership_deedName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="partnership_deed" id="partnership_deed"
                  errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'partnership_deed')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Partnership Deed</label>
                { this.state.partnership_deedUploadedName != '' ? <a href={this.state.partnership_deedUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>
        
          <h6 className="title mt-2 text-left">6. Certificate of Registeration</h6>
          {this.certificateOfRegistration()}

          <h6 className="title mt-2 text-left">7. List of Authorized Signatory with specimen signature</h6>
          <div className="row">
            <div className="col-md-6">
              Dowload Format of Resolution <a href="#" target="_blank"><i className="fa fa-download"></i></a>
              <div>
                { this.state.authorized_signatory_with_specimen_signatureName != '' ? this.state.authorized_signatory_with_specimen_signatureName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="authorized_signatory_with_specimen_signature" id="authorized_signatory_with_specimen_signature"
                  errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'authorized_signatory_with_specimen_signature')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload specimen signature</label>
                { this.state.authorized_signatory_with_specimen_signatureUploadedName != '' ? <a href={this.state.authorized_signatory_with_specimen_signatureUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>

          <h6 className="title mt-2 text-left">8. Balance sheet of the last 2 Financial Years</h6>
          {this.balanceSheetFields()}

          <h6 className="title mt-2 text-left">9. Any Other Document</h6>
          {this.otherDocumentField()}

      </div>
    </div>
  }

  unincorporatedAssociationOrBodyIndividuals(){
    return <div>
      <div className="corporate">
       <formGroup>
         <h6 className="title mt-2 text-left">1. Pan Card</h6>
        {this.panCardField()}
        </formGroup> 
        <h6 className="title mt-2 text-left">2. Address proof</h6>
        {this.addressProofField()}

        <h6 className="title mt-2 text-left">3. Bank Account Details</h6>
        {this.bankDetailFields()}

        <h6 className="title mt-2 text-left">4. Proof of existence/Costitution document</h6>
        <div className="row">
          <div className="col-md-6">
            <div>
              { this.state.proof_of_existenceName != '' ? this.state.proof_of_existenceName :null}
            </div>
            <div className="custom-file" style={{ "marginTop": "5px" }}>
              <FileInput className={"custom-file-input"} name="proof_of_existence" id="proof_of_existence"
                errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'proof_of_existence')} />
              <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Proof</label>
              { this.state.proof_of_existenceUploadedName != '' ? <a href={this.state.proof_of_existenceUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
        </div>
      
        <h6 className="title mt-2 text-left">5. Resolution of the managing body & Power of Attorney granted to transact business on its behalf</h6>
        <div className="row">
          <div className="col-md-6">
            {this.resolutionDocument()}
          </div>
        </div>

        <h6 className="title mt-2 text-left">6. List of Authorized signatories with specimen signatures</h6>
        <div className="row">
          <div className="col-md-6">
            Download Format of Authorized Signatory <a href="#" target="_blank"><i className="fa fa-download"></i></a>
            <div>
              { this.state.authorized_signatory_with_specimen_signatureName != '' ? this.state.authorized_signatory_with_specimen_signatureName :null}
            </div>
            <div className="custom-file" style={{ "marginTop": "5px" }}>
              <FileInput className={"custom-file-input"} name="authorized_signatory_with_specimen_signature" id="authorized_signatory_with_specimen_signature"
                errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'authorized_signatory_with_specimen_signature')} />
              <label className="custom-file-label" htmlFor="validatedCustomFile">Upload specimen signature</label>
              { this.state.authorized_signatory_with_specimen_signatureUploadedName != '' ? <a href={this.state.authorized_signatory_with_specimen_signatureUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
        </div>

        <h6 className="title mt-2 text-left">7. Any Other Document</h6>
        {this.otherDocumentField()}

      </div>
    </div>
  }

  bankInstitutionalInvestor(){
    return <div>
      <div className="corporate">
          <h6 className="title mt-2 text-left">1. Pan Card</h6>
          {this.panCardField()}

          <h6 className="title mt-2 text-left">2. Address proof</h6>
          {this.addressProofField()}

          <h6 className="title mt-2 text-left">3. Bank Account Details of Trust/Society</h6>
          {this.bankDetailFields()}

          <h6 className="title mt-2 text-left">4. Certificate of Registeration Copy</h6>
          {this.certificateOfRegistration()}
        
          <h6 className="title mt-2 text-left">5. Balance sheet of the last 2 Financial Years</h6>
          {this.balanceSheetFields()}

          <h6 className="title mt-2 text-left">6. List of Authorized signatories with specimen signatures.</h6>
          <div className="row">
            <div className="col-md-6">
              Dowload Format of Resolution <a href="#" target="_blank"><i className="fa fa-download"></i></a>
              <div>
                { this.state.authorized_signatory_with_specimen_signatureName != '' ? this.state.authorized_signatory_with_specimen_signatureName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="authorized_signatory_with_specimen_signature" id="authorized_signatory_with_specimen_signature"
                  errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'authorized_signatory_with_specimen_signature')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload specimen signature</label>
                { this.state.authorized_signatory_with_specimen_signatureUploadedName != '' ? <a href={this.state.authorized_signatory_with_specimen_signatureUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>

          <h6 className="title mt-2 text-left">7. Any Other Document</h6>
          {this.otherDocumentField()}

      </div>
    </div>
  }

  foreignInstitutionalInvestors(){
    return <div>
      <div className="corporate">
        <h6 className="title mt-2 text-left">1. Pan Card</h6>
        {this.panCardField()}

        <h6 className="title mt-2 text-left">2. Address proof</h6>
        {this.addressProofField()}

        <h6 className="title mt-2 text-left">3. Bank Account Details of Trust/Society</h6>
        {this.bankDetailFields()}

        <h6 className="title mt-2 text-left">4. SEBI Registeration Certificate</h6>
        {this.certificateOfRegistration()}

        <h6 className="title mt-2 text-left">5. List of Authorized signatories with specimen signatures</h6>
        <div className="row">
          <div className="col-md-6">
            Dowload Format of Resolution <a href="#" target="_blank"><i className="fa fa-download"></i></a>
            <div>
              { this.state.authorized_signatory_with_specimen_signatureName != '' ? this.state.authorized_signatory_with_specimen_signatureName :null}
            </div>
            <div className="custom-file" style={{ "marginTop": "5px" }}>
              <FileInput className={"custom-file-input"} name="authorized_signatory_with_specimen_signature" id="authorized_signatory_with_specimen_signature"
                errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'authorized_signatory_with_specimen_signature')} />
              <label className="custom-file-label" htmlFor="validatedCustomFile">Upload specimen signature</label>
              { this.state.authorized_signatory_with_specimen_signatureUploadedName != '' ? <a href={this.state.authorized_signatory_with_specimen_signatureUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
        </div>

        <h6 className="title mt-2 text-left">6. Any Other Document</h6>
        {this.otherDocumentField()}

      </div>
    </div>
  }

  armyGovernmentBodies(){
    return <div>
      <div className="corporate">
        <h6 className="title mt-2 text-left">1. Pan Card</h6>
        {this.panCardField()}

        <h6 className="title mt-2 text-left">2. Address proof</h6>
        {this.addressProofField()}

        <h6 className="title mt-2 text-left">3. Bank Account Details of Trust/Society</h6>
        {this.bankDetailFields()}

        <h6 className="title mt-2 text-left">4. Self Certification on Letterhead</h6>
        {this.certificateOfRegistration()}

        <h6 className="title mt-2 text-left">5. List of Authorized signatories with specimen signatures</h6>
        <div className="row">
          <div className="col-md-6">
            Dowload Format of Resolution <a href="#" target="_blank"><i className="fa fa-download"></i></a>
            <div>
              { this.state.authorized_signatory_with_specimen_signatureName != '' ? this.state.authorized_signatory_with_specimen_signatureName :null}
            </div>
            <div className="custom-file" style={{ "marginTop": "5px" }}>
              <FileInput className={"custom-file-input"} name="authorized_signatory_with_specimen_signature" id="authorized_signatory_with_specimen_signature"
                errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'authorized_signatory_with_specimen_signature')} />
              <label className="custom-file-label" htmlFor="validatedCustomFile">Upload specimen signature</label>
              { this.state.authorized_signatory_with_specimen_signatureUploadedName != '' ? <a href={this.state.authorized_signatory_with_specimen_signatureUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
        </div>

        <h6 className="title mt-2 text-left">6. Any Other Document</h6>
        {this.otherDocumentField()}

      </div>
    </div>
  }

  async getProcessData(processName) {
    let path = apiPath.getProcessData;
    this.setState({ loading: true });
    var data = {}
    data.user_id = this.state.id
    data.process = processName
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData.result,'resultData.result');
          let processData = resultData.result;
          this.setState({  
            onBoardingId: processData && processData._id ? processData._id : "",
            non_individual_type: processData && processData.non_individual_type ? processData.non_individual_type : "",

            pan_card: processData && processData.pan_card ? processData.pan_card : "",
            pan_cardUploadedName: processData && processData.pan_card && processData.pan_card.indexOf('u/user') < 1 ? processData.pan_card : "",
            pan_card_of_karta: processData && processData.pan_card_of_karta ? processData.pan_card_of_karta : "",
            pan_card_of_kartaUploadedName: processData && processData.pan_card_of_karta && processData.pan_card_of_karta.indexOf('u/user') < 1 ? processData.pan_card_of_karta : "",
            pan_card_of_others: processData && processData.pan_card_of_others ? processData.pan_card_of_others : "",
            pan_card_of_othersUploadedName: processData && processData.pan_card_of_others && processData.pan_card_of_others.indexOf('u/user') < 1 ? processData.pan_card_of_others : "",
            pan_card_of_others2: processData && processData.pan_card_of_others2 ? processData.pan_card_of_others2 : "",
            pan_card_of_others2UploadedName: processData && processData.pan_card_of_others2 && processData.pan_card_of_others2.indexOf('u/user') < 1 ? processData.pan_card_of_others2 : "",
            pan_card_of_others3: processData && processData.pan_card_of_others3 ? processData.pan_card_of_others3 : "",
            pan_card_of_others3UploadedName: processData && processData.pan_card_of_others3 && processData.pan_card_of_others3.indexOf('u/user') < 1 ? processData.pan_card_of_others3 : "",
            pan_card_of_others4: processData && processData.pan_card_of_others4 ? processData.pan_card_of_others4 : "",
            pan_card_of_others4UploadedName: processData && processData.pan_card_of_others4 && processData.pan_card_of_others4.indexOf('u/user') < 1 ? processData.pan_card_of_others4 : "",
            pan_card_of_others5: processData && processData.pan_card_of_others5 ? processData.pan_card_of_others5 : "",
            pan_card_of_others5UploadedName: processData && processData.pan_card_of_others5 && processData.pan_card_of_others5.indexOf('u/user') < 1 ? processData.pan_card_of_others5 : "",
            address_proof_name: processData && processData.address_proof_name ? processData.address_proof_name : "",
            address_proof: processData && processData.address_proof ? processData.address_proof : "",
            address_proofUploadedName: processData && processData.address_proof && processData.address_proof.indexOf('u/user') < 1 ? processData.address_proof : "",
            co_parceners_on_company_letter_head: processData && processData.co_parceners_on_company_letter_head ? processData.co_parceners_on_company_letter_head : "",
            co_parceners_on_company_letter_headUploadedName: processData && processData.co_parceners_on_company_letter_head && processData.co_parceners_on_company_letter_head.indexOf('u/user') < 1 ? processData.co_parceners_on_company_letter_head : "",
            bank_account_detail: processData && processData.bank_account_detail ? processData.bank_account_detail : "",
            bank_account_detail_document: processData && processData.bank_account_detail_document ? processData.bank_account_detail_document : "",
            bank_account_detail_documentUploadedName: processData && processData.bank_account_detail_document && processData.bank_account_detail_document.indexOf('u/user') < 1 ? processData.bank_account_detail_document : "",
            other_document: processData && processData.other_document ? processData.other_document : "",
            other_documentUploadedName: processData && processData.other_document && processData.other_document.indexOf('u/user') < 1 ? processData.other_document : "",
            other_document2: processData && processData.other_document2 ? processData.other_document2 : "",
            other_document2UploadedName: processData && processData.other_document2 && processData.other_document2.indexOf('u/user') < 1 ? processData.other_document2 : "",
            other_document3: processData && processData.other_document3 ? processData.other_document3 : "",
            other_document3UploadedName: processData && processData.other_document3 && processData.other_document3.indexOf('u/user') < 1 ? processData.other_document3 : "",
            directors_with_din_no_company_letter_head: processData && processData.directors_with_din_no_company_letter_head ? processData.directors_with_din_no_company_letter_head : "",
            directors_with_din_no_company_letter_headUploadedName: processData && processData.directors_with_din_no_company_letter_head && processData.directors_with_din_no_company_letter_head.indexOf('u/user') < 1 ? processData.directors_with_din_no_company_letter_head : "",
            memorandum_of_association: processData && processData.memorandum_of_association ? processData.memorandum_of_association : "",
            memorandum_of_associationUploadedName: processData && processData.memorandum_of_association && processData.memorandum_of_association.indexOf('u/user') < 1 ? processData.memorandum_of_association : "",
            articles_of_association: processData && processData.articles_of_association ? processData.articles_of_association : "",
            articles_of_associationUploadedName: processData && processData.articles_of_association && processData.articles_of_association.indexOf('u/user') < 1 ? processData.articles_of_association : "",
            bank_account_detail_document_of_corporate: processData && processData.bank_account_detail_document_of_corporate ? processData.bank_account_detail_document_of_corporate : "",
            bank_account_detail_document_of_corporateUploadedName: processData && processData.bank_account_detail_document_of_corporate && processData.bank_account_detail_document_of_corporate.indexOf('u/user') < 1 ? processData.bank_account_detail_document_of_corporate : "",
            resolution: processData && processData.resolution ? processData.resolution : "",
            resolutionUploadedName: processData && processData.resolution && processData.resolution.indexOf('u/user') < 1 ? processData.resolution : "",
            certificate_of_incorporation: processData && processData.certificate_of_incorporation ? processData.certificate_of_incorporation : "",
            certificate_of_incorporationUploadedName: processData && processData.certificate_of_incorporation && processData.certificate_of_incorporation.indexOf('u/user') < 1 ? processData.certificate_of_incorporation : "",
            authorized_signatory_with_specimen_signature: processData && processData.authorized_signatory_with_specimen_signature ? processData.authorized_signatory_with_specimen_signature : "",
            authorized_signatory_with_specimen_signatureUploadedName: processData && processData.authorized_signatory_with_specimen_signature && processData.authorized_signatory_with_specimen_signature.indexOf('u/user') < 1 ? processData.authorized_signatory_with_specimen_signature : "",
            balance_sheet_year_1: processData && processData.balance_sheet_year_1 ? processData.balance_sheet_year_1 : "",
            balance_sheet_year_1UploadedName: processData && processData.balance_sheet_year_1 && processData.balance_sheet_year_1.indexOf('u/user') < 1 ? processData.balance_sheet_year_1 : "",
            balance_sheet_year_2: processData && processData.balance_sheet_year_2 ? processData.balance_sheet_year_2 : "",
            balance_sheet_year_2UploadedName: processData && processData.balance_sheet_year_2 && processData.balance_sheet_year_2.indexOf('u/user') < 1 ? processData.balance_sheet_year_2 : "",
            latest_share_holding_pattern: processData && processData.latest_share_holding_pattern ? processData.latest_share_holding_pattern : "",
            latest_share_holding_patternUploadedName: processData && processData.latest_share_holding_pattern && processData.latest_share_holding_pattern.indexOf('u/user') < 1 ? processData.latest_share_holding_pattern : "",
            certificate_of_registration: processData && processData.certificate_of_registration ? processData.certificate_of_registration : "",
            certificate_of_registrationUploadedName: processData && processData.certificate_of_registration && processData.certificate_of_registration.indexOf('u/user') < 1 ? processData.certificate_of_registration : "",
            trust_deed: processData && processData.trust_deed ? processData.trust_deed : "",
            trust_deedUploadedName: processData && processData.trust_deed && processData.trust_deed.indexOf('u/user') < 1 ? processData.trust_deed : "",
            list_of_trustees: processData && processData.list_of_trustees ? processData.list_of_trustees : "",
            list_of_trusteesUploadedName: processData && processData.list_of_trustees && processData.list_of_trustees.indexOf('u/user') < 1 ? processData.list_of_trustees : "",
            societyRule: processData && processData.societyRule ? processData.societyRule : "",
            societyRuleUploadedName: processData && processData.societyRule && processData.societyRule.indexOf('u/user') < 1 ? processData.societyRule : "",
            partnership_deed: processData && processData.partnership_deed ? processData.partnership_deed : "",
            partnership_deedUploadedName: processData && processData.partnership_deed && processData.partnership_deed.indexOf('u/user') < 1 ? processData.partnership_deed : "",
            proof_of_existence: processData && processData.proof_of_existence ? processData.proof_of_existence : "",
            proof_of_existenceUploadedName: processData && processData.proof_of_existence && processData.proof_of_existence.indexOf('u/user') < 1 ? processData.proof_of_existence : "",
          })
          if(!_.isEmpty(this.state.other_document2) && processData.other_document2.indexOf('u/user') < 1){
            this.setState({
              ODS2:"",
              ODB1:"shide"
            })
          }
          if(!_.isEmpty(this.state.other_document3) && processData.other_document3.indexOf('u/user') < 1){
            this.setState({
              ODS3:"",
              ODB2:"shide"
            })
          }
          if(!_.isEmpty(this.state.pan_card_of_others2) && processData.pan_card_of_others2.indexOf('u/user') < 1){
            this.setState({
              PS2:"",
              PB1:"shide"
            })
          }
          if(!_.isEmpty(this.state.pan_card_of_others3) && processData.pan_card_of_others3.indexOf('u/user') < 1){
            this.setState({
              PS3:"",
              PB2:"shide"
            })
          }
          if(!_.isEmpty(this.state.pan_card_of_others4) && processData.pan_card_of_others4.indexOf('u/user') < 1){
            this.setState({
              PS4:"",
              PB3:"shide"
            })
          }
          if(!_.isEmpty(this.state.pan_card_of_others5) && processData.pan_card_of_others5.indexOf('u/user') < 1){
            this.setState({
              PS5:"",
              PB4:"shide"
            })
          }
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentDidMount() {
    this.getProcessData('Client-On-Boarding');
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/vallum-tracker" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
        <section className="profile-section section-bg padding-top padding-bottom">
            <div className="container container-inner">
             <div className="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                <div className="row justify-content-center after-login-pages no-gutters">
                  {/*Client On-Boarding Documents (Non-Individual) */}
                   
                  <Sidebar />
                      <div class="col-md-8 col-lg-9">
                        <div className="p-3 p-md-3"> 
                        {this.message()}
                          
                              <h4 className="title mb-3 text-left">Client On-Boarding Documents (Non-Individual) </h4>
                              <h6 className="title mb-3 text-left">Please Upload the below documents to begin your on-boarding process </h6>
                              
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter something." }} >
                                <div className="row">

                                  <div className="col-md-12">

                                    <div className="row">
                                      <Col lg="6">
                                        <FormGroup>
                                          <SelectGroup name="non_individual_type" id="non_individual_type" disabled={true}
                                              onChange={this.handleChange} value={this.state.non_individual_type} required errorMessage={{
                                              required: "Please select Category" }} >
                                              <option value="">Select Category</option>
                                              <option value="HUF">HUF</option>
                                              <option value="Corporate">Corporate</option>
                                              <option value="Trust">Trust or Registered Society</option>
                                              <option value="Partnership">Partnership </option>
                                              <option value="Unincorporated-Association-or-Body-of-Individuals">Unincorporated Association or Body of Individuals</option>
                                              <option value="Bank-Institutional-Investor">Bank/ Institutional Investor</option>
                                              <option value="Foreign-Institutional-Investors">Foreign Institutional Investors</option>
                                              <option value="Army-Government-Bodies">Army/Government Bodies</option>
                                          </SelectGroup>
                                        </FormGroup>
                                      </Col>
                                    </div>

                                    {this.state.non_individual_type == 'HUF' ? this.HUF() : ""}
                                    {this.state.non_individual_type == 'Corporate' ? this.Corporate() : ""}
                                    {this.state.non_individual_type == 'Trust' ? this.trustOrRegisteredSociety() : ""}
                                    {this.state.non_individual_type == 'Partnership' ? this.Partnership() : ""}
                                    {this.state.non_individual_type == 'Unincorporated-Association-or-Body-of-Individuals' ? this.unincorporatedAssociationOrBodyIndividuals() : ""}
                                    {this.state.non_individual_type == 'Bank-Institutional-Investor' ? this.bankInstitutionalInvestor() : ""}
                                    {this.state.non_individual_type == 'Foreign-Institutional-Investors' ? this.foreignInstitutionalInvestors() : ""}
                                    {this.state.non_individual_type == 'Army-Government-Bodies' ? this.armyGovernmentBodies() : ""}

                                    <div className="row mt-3">
                                      <div className="col-md-12"><button type="submit">Submit</button></div>
                                    </div>
                                  
                                  </div>

                                </div>
                              </ValidationForm>
                            </div>
                          </div>
                      
                    </div>
                  {/*Client On Boarding Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientOnBoardingDocumentsNonIndividual);
