import React, { Component } from 'react';
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import _ from "lodash";
import { AlertList } from "react-bs-notifier";

import { Link,Redirect } from 'react-router-dom';
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import bannerImage from '../../assets/images/banner/banner-inner.png';
import DateFormatMonthName from "../../constants/dateformatmonthname";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const MAX_LENGTH = 160;
class WhatVallumReadsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo: false,
      id: this.props.match.params.id,
      title: '',
      content: '',
      photo: '',
      pdf: '',
      youtube_url: '',
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  message() {
    if (!_.isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async getInsightDetails(id){
    let path = apiPath.getInsightDetails;
    this.setState({loading: true});
    let options = {
      method: 'GET',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({loading: false});
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({
            title: resultUsersJson.title ? resultUsersJson.title : '',
            content: resultUsersJson.content ? resultUsersJson.content : '',
            photo: resultUsersJson && resultUsersJson.photo !== '' && resultUsersJson.photo.indexOf('u/user') < 1 ? resultUsersJson.photo : '',
            pdf: resultUsersJson && resultUsersJson.pdf !== '' && resultUsersJson.pdf.indexOf('u/user') < 1  ? resultUsersJson.pdf : '',
            youtube_url: resultUsersJson.youtube_url ? resultUsersJson.youtube_url : '',
          });
          this.generate('success');
        } else {
          this.generate('danger');
          this.setState({message: resultJson.msg});
          setTimeout(() => {
            this.setState({message: null,redirectTo:true});
          }, 5000);
        }
      }
    } else {
      this.generate('danger');
      this.setState({message: result.statusText});
      setTimeout(() => {
        this.setState({message: null});
      }, 5000);
    }
  }

  componentDidMount(){
    this.getInsightDetails(this.props.match.params.id);
  }
  
  render() {
    if (this.state.redirectTo) {
      return <Redirect to="/" />
    }

    /*if (!this.props.location.insightData) {
      return <Redirect to={{
        pathname: '/insight-what-vallum-reads'
      }} />
    }*/

    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Banner Section */}
        <section class="hero-section bg_img"  style={{backgroundImage: `url(${bannerImage})`}}>
              <div class="container">
                  <div class="hero-content">
                      <h1 class="title text-white">Vallum's Weekend Reading View</h1>
                      <ul class="breadcrumb text-white">
                          <li>
                              <a href="/">Home</a>
                          </li>
                          <li>
                              Vallum's Weekend Reading View
                          </li>
                      </ul>
                  </div>
              </div>
          </section>
        {/* Banner Section */}

        <section className="vallum-reads-section padding-top padding-bottom">
            <div className="container container-inner">
             
                        <div className="box-shadow box-block p-3 p-md-4 aos-item aos-init aos-animate" data-aos="fade-up">
                            <div className="feature-content product-detail">
                                <h3 className="title">{this.state.title}</h3>
                                {this.state.content != '' && this.state.content != null && this.state.content != undefined ? <p><span dangerouslySetInnerHTML={{__html : `${window.atob(this.state.content)}`}}></span></p> : null}
                                {this.state.photo != '' ?<img className="product-image" src={this.state.photo} alt="image"></img> : null}
                                {this.state.pdf != '' ? <a href={this.state.pdf} target="_blank" className="view-pdf">View Pdf <i className="fa fa-eye" aria-hidden="true"></i></a> : null}

                                {this.state.youtube_url != '' ? <div>
                                <iframe src={this.state.youtube_url}
                                    frameborder='0' width="560" height="315"
                                    allow='autoplay; encrypted-media'
                                    allowfullscreen
                                    title='video'
                                />
                                </div> : null}
                            </div>
                </div>
            </div>
        </section>

        {/*GetInTouch*/}
        <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default WhatVallumReadsDetail;
