import React, { Component } from "react";
import {
  Label,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  CardHeader,
  FormGroup,
  CardFooter,
} from "reactstrap";
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";

import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link } from "react-router-dom";
import { Chrono } from "react-chrono";
import timelineHomeImage from "../../assets/images/timeline-bg.jpg";

class TimelineHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: new Date().getTime(),
      type: type,
      headline: "",
      message: this.state.message,
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert],
    });
  }

  clearAlerts() {
    this.setState({
      alerts: [],
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)],
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
  };

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  };

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  };

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      );
    }
  }

  title1() {
    return (
      <div>
        <h5>Newsletter – Compulsory Dividends For Equity</h5>
        <p>
          Championed the fight for compulsory dividend in India, this will align
          the cash flow generation with reporting accounting and save the
          banking system. 
        </p>
        <a
          href="/assets/images/2012.pdf#page=1"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }

  title2() {
    return (
      <div>
        <h5>Newsletter – End Game Of Indian Real Estate </h5>
        <p>
          Divestment from Indian real estate, Indian real estate is fuelled by
          price bubbles and when bubble bursts the aftermath will have long term
          negative impact on the economy. A generation will wonder on how a
          nation was making its way to prosperity by speculating on a piece of
          land and eventually lost a fortune.{" "}
        </p>
        <a
          href="/assets/images/2013.pdf#page=1"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }

  title3() {
    return (
      <div>
        <h5>Shareholder Letter - Reiteration On Indian Real Estate</h5>
        <p>
          Reiteration of the extreme bearishness on real estate prices in India.
          If real estate prices do not correct as envisaged, India will face
          serious problem of competitiveness v/s other emerging and developed
          nations.
        </p>
        <a
          href="/assets/images/2014-Real-estate.pdf#page=1"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
        <br />
        <h5>Shareholder Letter - Dislocation in PSU Banking Industry</h5>
        <p>
          Many a times “value trap is a governance trap.” The valuation
          difference between public sector unit banks v/s private sector banks
          or NBFCs demonstrates the same.
        </p>
        <a
          href="/assets/images/2014-PSU.pdf#page=2"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }

  title5() {
    return (
      <div>
        <h5>Shareholder Letter - PSU Is A Value Trap</h5>
        <p>
          Strong believer of the fact that PSU is a value trap and we still
          remain switched off from public sector banks and believe “value trap
          is a governance trap”.
        </p>
        <a
          href="/assets/images/2015.pdf#page=3"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }

  title6() {
    return (
      <div>
        <h5>Shareholder Letter - Benefit To Indian Chemical Industry</h5>
        <p>
          The cost dynamics of Chinese chemical industry is going to go up
          significantly due to the renewed pledge to curb pollution. This is
          going to benefit Indian chemical industry which is well prepared to
          handle the shift of business.
        </p>
        <a
          href="/assets/images/2016-Chemical.pdf#page=3"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
        <br />
        <h5>Shareholder Letter - No Immediate Revival For PSU's </h5>
        <p>
          Do not foresee immediate revival of the banking sector due to
          shrinking capital requirements. The reoccurrence of the banking crises
          highlights the need for strong self-induced check & balances in
          institutional mechanisms..
        </p>
        <a
          href="/assets/images/2016-PSU.pdf#page=3"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }

  title7() {
    return (
      <div>
        <h5>Shareholder Letter - Economic Outlook</h5>
        <p>
          Let me also abreast you some of the headwinds faced by Indian
          equities. Sounding a note of caution that Indian economic growth and
          its reflection in the markets is masked more by economic value shifts
          rather sustained value creation.
        </p>
        <a
          href="/assets/images/2018.pdf#page=4"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }

  title8() {
    return (
      <div>
        <h5>Shareholder Letter - Dollar Depreciation </h5>
        <p>
          Invested in theme of depreciation of USD, US Dollar is entering its
          bearish eight-year cycle, a trend observed over a long time. Emerging
          markets, Gold, Oil are going to be the beneficiary of this dollar
          cycle.
        </p>
        <a
          href="/assets/images/2019.pdf#page=7"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
        <h5>Shareholder Letter - Rise Of Gold Prices</h5>
        <p>
          A decade of under investment in gold mining is leading to huge supply
          side contraction. Having read the tea leaves, we loaded up investment
          in the largest gold financing company.
        </p>
        <a
          href="/assets/images/2019.pdf#page=7"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }

  title9() {
    return (
      <div>
        <h5>Shareholder Letter - Recovery In The Indian Economy</h5>

        <p>
          The year 2020 will be fraught with a challenge on multiple fronts, but
          the base for long term economic growth is set. We see a meaningful
          domestic sector led recovery by 2021.
        </p>
        <a
          href="/assets/images/2020-Economy.pdf#page=12"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
        <h5>Shareholder Letter - Pharmaceuticals</h5>
        <p>
          It is most under owned sector with improving regulatory dynamics,
          favouring growth in business momentum.
        </p>
        <a
          href="/assets/images/2020-Pharma.pdf#page=5"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
        <h5>Shareholder Letter - Oil Prices</h5>
        <p>Recovery of Oil Prices is Imminent</p>
        <a
          href="/assets/images/2020-Oil.pdf#page=8"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
        <h5>Shareholder Letter - Gold</h5>
        <p>
          The global economic situation and steep decline of interest rates in
          the U.S. have reinforced the case for investing in favour of gold, and
          the best is likely yet to come.
        </p>
        <a
          href="/assets/images/2020-Gold.pdf#page=4"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }

  title10() {
    return (
      <div>
        <h5>Depreciation of the US dollar</h5>
        <p>
          Strong belief in depreciation of the US dollar, its positive
          implication on inflows into the emerging markets.
        </p>
        <a
          href="/assets/images/2021-postcovid.pdf#page=1"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
        <h5>Physical Asset and Infrastructure Sector</h5>
        <p>
          Low interest rates for corporations and mortgages, with pent-up
          consumer demand and the government’s commitment to infrastructure is
          resulting in a revival of growth.
        </p>
        <a
          href="/assets/images/2021-postcovid.pdf#page=3"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
        <h5>The Post Covid World</h5>
        <p>
          The excess money in the global monetary system is creating asset
          inflations, with its associated domino effect.
        </p>
        <a
          href="/assets/images/2021-postcovid.pdf#page=8"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }
  title11() {
    return (
      <div>
        <p>Capex Recovery, Buy Capital goods </p>

        <a
          href="https://vallum.in/api/img/insights/202410230902240877.pdf"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }
  title12() {
    return (
      <div>
        <p>Invest in Mid & Small cap opportunities</p>

        <a
          href="https://vallum.in/api/img/insights/202410230840501805.pdf"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }
  title13() {
    return (
      <div>
        <p className="mb-1">- History of Financial Bubbles in the country</p>
        <p className="mb-1">- Railway PSU is a bubble.</p>
        <p className="mb-4">- Likely appreciation of rupee in the long term</p>
        <a
          href="https://vallum.in/api/img/insights/202410230839096510.pdf"
          target="_blank"
          className="custom-button btn-sm"
        >
          Read More
        </a>
      </div>
    );
  }

  render() {
    return (
      <section
        className="overlay-bg custom-timeline-section pt-5 pb-5 bg_img"
        style={{ backgroundImage: `url(${timelineHomeImage})` }}
      >
        <div className="container container-inner relative">
          <h2 className="white-text title text-uppercase text-center">
            Our 10 Year Investment Guidance
          </h2>
          <div className="timeline-content-block">
            <Chrono
              items={[
                { title: "2012", cardDetailedText: this.title1() },
                { title: "2013", cardDetailedText: this.title2() },
                { title: "2014", cardDetailedText: this.title3() },
                { title: "2015", cardDetailedText: this.title5() },
                { title: "2016", cardDetailedText: this.title6() },
                { title: "2018", cardDetailedText: this.title7() },
                { title: "2019", cardDetailedText: this.title8() },
                { title: "2020", cardDetailedText: this.title9() },
                { title: "2021", cardDetailedText: this.title10() },
                { title: "2022", cardDetailedText: this.title11() },
                { title: "2023", cardDetailedText: this.title12() },
                { title: "2024", cardDetailedText: this.title13() },
              ]}
              mode="HORIZONTAL"
              scrollable={{ scrollbar: true }}
              slideShow={true}
              slideItemDuration={4500}
              useReadMore={false}
            />
          </div>
        </div>
      </section>
    );
  }
}
export default TimelineHome;
