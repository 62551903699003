import React, { Component } from 'react';
import { Button, Card, Label, Input, FormGroup, CardBody, Checkbox, CardGroup, Col, Table, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row,CardImg} from 'reactstrap';
import apiPath from "../../constants/apiPath";
import helper from "../../constants/helper";
import Http from "../../services/Http";
import _ from "lodash";
import { isEmpty } from "lodash";
import { AlertList } from "react-bs-notifier";
import { Link,Redirect } from 'react-router-dom';
import { TextInput, ValidationForm,SelectGroup,FileInput } from "react-bootstrap4-form-validation";

import validator from "validator";

import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import GetInTouch from '../Elements/GetInTouch';
import Sidebar from '../Elements/Sidebar';
import "react-datepicker/dist/react-datepicker.css";

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class ClientOnBoardingDocumentsIndividual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      position: "top-right",
      alerts: [],
      timeout: 5000,
      message: "",
      redirectTo : false,
      
      occupation: this.props.admin ? this.props.admin.occupation : "",
      id: this.props.admin._id,
      profileDataForStore: {},
      clientCategory: "",

      onBoardingId: "",
      ownership_status: "",
      ownerStatus: "",
      pms_account_holder: "",

      pan_card: "",
      pan_cardName: "",
      pan_cardUploadedName: "",
      address_proof_name: "",
      address_proof: "",
      address_proofName: "",
      address_proofUploadedName: "",
      correspondence_permanent_address_same: "",
      correspondence_permanent_address: "",
      correspondence_permanent_addressName: "",
      correspondence_permanent_addressUploadedName: "",
      bank_account_detail: "",
      bank_account_detail_file: "",
      bank_account_detail_fileName: "",
      bank_account_detail_fileUploadedName: "",
      other_document: "",
      other_documentName: "",
      other_documentUploadedName: "",
      other_document2: "",
      other_document2Name: "",
      other_document2UploadedName: "",
      other_document3: "",
      other_document3Name: "",
      other_document3UploadedName: "",

      pan_card_two: "",
      pan_card_twoName: "",
      pan_card_twoUploadedName: "",
      address_proof_name_two: "",
      address_proof_two: "",
      address_proof_twoName: "",
      address_proof_twoUploadedName: "",
      correspondence_permanent_address_same_two: "",
      correspondence_permanent_address_two: "",
      correspondence_permanent_address_twoName: "",
      correspondence_permanent_address_twoUploadedName: "",
      bank_account_detail_two: "",
      bank_account_detail_file_two: "",
      bank_account_detail_file_twoName: "",
      bank_account_detail_file_twoUploadedName: "",
      other_document_two: "",
      other_document_twoName: "",
      other_document_twoUploadedName: "",
      other_document_two2: "",
      other_document_two2Name: "",
      other_document_two2UploadedName: "",
      other_document_two3: "",
      other_document_two3Name: "",
      other_document_two3UploadedName: "",

      pan_card_three: "",
      pan_card_threeName: "",
      pan_card_threeUploadedName: "",
      address_proof_name_three: "",
      address_proof_three: "",
      address_proof_threeName: "",
      address_proof_threeUploadedName: "",
      correspondence_permanent_address_same_three: "",
      correspondence_permanent_address_three: "",
      correspondence_permanent_address_threeName: "",
      correspondence_permanent_address_threeUploadedName: "",
      bank_account_detail_three: "",
      bank_account_detail_file_three: "",
      bank_account_detail_file_threeName: "",
      bank_account_detail_file_threeUploadedName: "",
      other_document_three: "",
      other_document_threeName: "",
      other_document_threeUploadedName: "",
      other_document_three2: "",
      other_document_three2Name: "",
      other_document_three2UploadedName: "",
      other_document_three3: "",
      other_document_three3Name: "",
      other_document_three3UploadedName: "",

      passport: "",
      passportName: "",
      passportUploadedName: "",
      tin: "",
      tinName: "",
      tinUploadedName: "",
      visa: "",
      visaName: "",
      visaUploadedName: "",

      passport_two: "",
      passport_twoName: "",
      passport_twoUploadedName: "",
      tin_two: "",
      tin_twoName: "",
      tin_twoUploadedName: "",
      visa_two: "",
      visa_twoName: "",
      visa_twoUploadedName: "",

      passport_three: "",
      passport_threeName: "",
      passport_threeUploadedName: "",
      tin_three: "",
      tin_threeName: "",
      tin_threeUploadedName: "",
      visa_three: "",
      visa_threeName: "",
      visa_threeUploadedName: "",
      ODS2 : "shide",
      ODS3 : "shide",
      ODB1 : "bshow",
      ODB2 : "bshow",
      OD2S2 : "shide",
      OD2S3 : "shide",
      OD2B1 : "bshow",
      OD2B2 : "bshow",
      OD3S2 : "shide",
      OD3S3 : "shide",
      OD3B1 : "bshow",
      OD3B2 : "bshow",
    };
  }

  generate(type) {
    this.clearAlerts();
    const newAlert = {
      id: (new Date()).getTime(),
      type: type,
      headline: '',
      message: this.state.message
    };

    this.setState({
      alerts: [...this.state.alerts, newAlert]
    });
  }

  clearAlerts() {
    this.setState({
      alerts: []
    });
  }

  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangeImg = (name, e) => {
    if(name=="pan_card"){
      this.setState({pan_cardName : e.target.files[0].name})
    }
    if(name=="pan_card_two"){
      this.setState({pan_card_twoName : e.target.files[0].name})
    }
    if(name=="pan_card_three"){
      this.setState({pan_card_threeName : e.target.files[0].name})
    }
    if(name=="address_proof"){
      this.setState({address_proofName : e.target.files[0].name})
    }
    if(name=="address_proof_two"){
      this.setState({address_proof_twoName : e.target.files[0].name})
    }
    if(name=="address_proof_three"){
      this.setState({address_proof_threeName : e.target.files[0].name})
    }
    if(name=="correspondence_permanent_address"){
      this.setState({correspondence_permanent_addressName : e.target.files[0].name})
    }
    if(name=="correspondence_permanent_address_two"){
      this.setState({correspondence_permanent_address_twoName : e.target.files[0].name})
    }
    if(name=="correspondence_permanent_address_three"){
      this.setState({correspondence_permanent_address_threeName : e.target.files[0].name})
    }
    if(name=="bank_account_detail_file"){
      this.setState({bank_account_detail_fileName : e.target.files[0].name})
    }
    if(name=="bank_account_detail_file_two"){
      this.setState({bank_account_detail_file_twoName : e.target.files[0].name})
    }
    if(name=="bank_account_detail_file_three"){
      this.setState({bank_account_detail_file_threeName : e.target.files[0].name})
    }
    if(name=="other_document"){
      this.setState({other_documentName : e.target.files[0].name})
    }
    if(name=="other_document_two"){
      this.setState({other_document_twoName : e.target.files[0].name})
    }
    if(name=="other_document_three"){
      this.setState({other_document_threeName : e.target.files[0].name})
    }
    if(name=="other_document2"){
      this.setState({other_document2Name : e.target.files[0].name})
    }
    if(name=="other_document_two2"){
      this.setState({other_document_two2Name : e.target.files[0].name})
    }
    if(name=="other_document_three2"){
      this.setState({other_document_three2Name : e.target.files[0].name})
    }
    if(name=="other_document3"){
      this.setState({other_document3Name : e.target.files[0].name})
    }
    if(name=="other_document_two3"){
      this.setState({other_document_two3Name : e.target.files[0].name})
    }
    if(name=="other_document_three3"){
      this.setState({other_document_three3Name : e.target.files[0].name})
    }
    if(name=="passport"){
      this.setState({passportName : e.target.files[0].name})
    }
    if(name=="passport_two"){
      this.setState({passport_twoName : e.target.files[0].name})
    }
    if(name=="passport_three"){
      this.setState({passport_threeName : e.target.files[0].name})
    }
    if(name=="visa"){
      this.setState({visaName : e.target.files[0].name})
    }
    if(name=="visa_two"){
      this.setState({passport_twoName : e.target.files[0].name})
    }
    if(name=="visa_three"){
      this.setState({visa_threeName : e.target.files[0].name})
    }
    if(name=="tin"){
      this.setState({tinName : e.target.files[0].name})
    }
    if(name=="tin_two"){
      this.setState({tin_twoName : e.target.files[0].name})
    }
    if(name=="tin_three"){
      this.setState({tin_threeName : e.target.files[0].name})
    }
    helper.getBase64(e.target.files[0], (result) => {
      this.setState({
        [name]: result,
      });
    });
  }

  handleButtonChange= (e) =>{
    let stat = e.target.getAttribute('data_type');
    var ele = document.getElementsByClassName(stat);
    ele[0].classList.remove("shide");
    e.target.classList.remove("bshow");
    e.target.classList.add("shide");
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    this.clientOnBoarding(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    //console.log(e,formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  message() {
    if (!isEmpty(this.state.message)) {
      return (
        <AlertList
          position={this.state.position}
          alerts={this.state.alerts}
          timeout={this.state.timeout}
          dismissTitle="Begone!"
          onDismiss={this.onAlertDismissed.bind(this)}
        />
      )
    }
  }

  async clientOnBoarding(postData) {
    let path = apiPath.clientOnBoardingDocument;
    this.setState({ loading: true });
    let data = new FormData();
    data.append('pan_card', postData.pan_card);
    data.append('address_proof', postData.address_proof);
    data.append('correspondence_permanent_address', postData.correspondence_permanent_address);
    data.append('bank_account_detail_file', postData.bank_account_detail_file);
    data.append('other_document', postData.other_document);
    data.append('other_document2', postData.other_document2);
    data.append('other_document3', postData.other_document3);
    data.append('pan_card_two', postData.pan_card_two);
    data.append('address_proof_two', postData.address_proof_two);
    data.append('correspondence_permanent_address_two', postData.correspondence_permanent_address_two);
    data.append('bank_account_detail_file_two', postData.bank_account_detail_file_two);
    data.append('other_document_two', postData.other_document_two);
    data.append('other_document_two2', postData.other_document_two2);
    data.append('other_document_two3', postData.other_document_two3);
    data.append('pan_card_three', postData.pan_card_three);
    data.append('address_proof_three', postData.address_proof_three);
    data.append('correspondence_permanent_address_three', postData.correspondence_permanent_address_three);
    data.append('bank_account_detail_file_three', postData.bank_account_detail_file_three);
    data.append('other_document_three2', postData.other_document_three2);
    data.append('other_document_three3', postData.other_document_three3);
    data.append('tin', postData.tin);
    data.append('tin_two', postData.tin_two);
    data.append('tin_three', postData.tin_three);
    data.append('visa_two', postData.visa_two);
    data.append('visa_three', postData.visa_three);
    data.append('visa', postData.visa);
    data.append('passport', postData.passport);
    data.append('passport_two', postData.passport_two);
    data.append('passport_three', postData.passport_three);
    postData.user_id = this.state.id
    postData.onBoardingId = this.state.onBoardingId
    postData.ownership_status = this.state.ownership_status
    postData.correspondence_permanent_address_same = this.state.correspondence_permanent_address_same
    postData.correspondence_permanent_address_same_three = this.state.correspondence_permanent_address_same_three
    postData.correspondence_permanent_address_same_two = this.state.correspondence_permanent_address_same_two
    data.append('onBoardingDocumentData', JSON.stringify(postData));
    let options = {
      method: 'PUT',
      headers: {},
      body: data
    }
    let result = await Http(path + "?id=" + this.state.id, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        if (resultJson.success) {
          let resultUsersJson = resultJson.result;
          this.setState({ message: resultJson.msg});
          this.generate('success');
          setTimeout(() => {
            this.setState({redirectTo: true});
          }, 1500);
        }
        else {
          this.setState({ message: resultJson.msg });
          this.generate('danger');
        }
      }
    } else {
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  indian(){
    return <div>
      {this.state.pms_account_holder == '1' ? this.indianOne() : ""}
      {this.state.pms_account_holder == '2' ? this.indianTwo() : ""}
      {this.state.pms_account_holder == '3' ? this.indianThree() : ""}
    </div>
  }

  indianOne(){
    return <div>
      <div className="indian mt-3">
      <h6 className="title text-left pt-1 holder">HOLDER 1</h6>
        <div className="">
            {/* <div className="col-md-6 text-left"></div> */}
            <h6 className="title mt-3 text-left">1. Upload Pan Card</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.pan_cardName != '' ? this.state.pan_cardName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="pan_card" id="pan_card"
                    errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
                  { this.state.pan_cardUploadedName != '' ? <a href={this.state.pan_cardUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="row ">
          <h6 className="title mt-3 text-left col-md-6">2. Address Proof</h6>
        </div>
        <div className="row ">
            <div className="col-md-6">
              <FormGroup>
                <SelectGroup name="address_proof_name" id="address_proof_name" 
                    onChange={this.handleChange} value={this.state.address_proof_name} errorMessage={{
                    required: "Please select Address Proof" }} >
                    <option value="">Select Address Proof</option>
                    <option value="Electricity-Bill">Electricity Bill (Not more than 3 months old)</option>
                    <option value="Telephone-Bill-Landline">Telephone Bill -Landline (Not more than 3 months old)</option>
                    <option value="Gas-Bill">Gas Bill (Not more than 3 months old)</option>
                    <option value="Ration-Card">Ration Card</option>
                    <option value="Aadhaar-Card">Aadhaar Card</option>
                    <option value="Passport">Passport</option>
                    <option value="Registered-Lease-or-Sale-Agreement-of-Residence">Registered Lease or Sale Agreement of Residence</option>
                    <option value="Driving-License">Driving License</option>
                    <option value="Flat-Maintenance-bill">Flat Maintenance bill</option>
                    <option value="Insurance-Copy">Insurance Copy</option>
                    <option value="Voter-ID-card">Voter's ID card</option>
                    <option value="Other-Address-Proof">Other Address Proof</option>
                </SelectGroup>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <div>
                { this.state.address_proofName != '' ? this.state.address_proofName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="address_proof" id="address_proof"
                  errorMessage={{ required: "Please upload an address proof document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'address_proof')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Address Proof</label>
                { this.state.address_proofUploadedName != '' ? <a href={this.state.address_proofUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
          </div>
        </div>

        <div className="">
          <h6 className="title mt-2 text-left">3. Is your correspondence & permanent address same?</h6>
          <div className="row">
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same" id="exampleRadios1" value="Yes" 
              checked={this.state.correspondence_permanent_address_same == "Yes" ? true : false} />
              <label className="form-check-label">
                Yes
              </label>
            </div>
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same" id="exampleRadios2" value="No" 
              checked={this.state.correspondence_permanent_address_same == "No" ? true : false} />
              <label className="form-check-label">
                No
              </label>
            </div>
          </div>

          {this.state.correspondence_permanent_address_same == 'No' ? this.correnpondencePermanentAddSame() : ""}
          <p>Note: Proof of Address in the name of the Spouse can also be submitted</p>
        </div>

        <div className="row ">
        <h6 className="title mt-2 text-left col-md-6">4. Bank Account Details</h6>
        </div>
        <div className="row ">
            <div className="col-md-6">
              <FormGroup>
                <SelectGroup name="bank_account_detail" id="bank_account_detail" 
                    onChange={this.handleChange} value={this.state.bank_account_detail}  errorMessage={{
                    required: "Please select Address Proof" }} >
                    <option value="">Select Bank Account Details</option>
                    <option value="Cancelled-Cheque">Cancelled Cheque</option>
                    <option value="Bank-statement-Passbook (Not more than 3 months old)">Bank statement/Passbook (Not more than 3 months old)</option>
                    <option value="Letter-issued-by-the-Bank-giving-the-account-details.">Letter issued by the Bank giving the account details.</option>
                </SelectGroup>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <div>
                { this.state.bank_account_detail_fileName != '' ? this.state.bank_account_detail_fileName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="bank_account_detail_file" id="bank_account_detail_file"
                  errorMessage={{ required: "Please upload a bank account detail.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'bank_account_detail_file')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Bank Account Details</label>
                { this.state.bank_account_detail_fileUploadedName != '' ? <a href={this.state.bank_account_detail_fileUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
        </div>

        <div className="">
          <h6 className="title mt-2 text-left">5. Any Other Document</h6>
          {this.otherDocument()}
        </div>

      </div>
    </div>
  }

  indianTwo(){
    return <div>
      {this.indianOne()}
      <div className="indian mt-3">
      <h6 className="title text-left pt-1 holder">HOLDER 2</h6>
        
        <div className="">
            <h6 className="title mt-3 text-left">1. Upload Pan Card</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.pan_card_twoName != '' ? this.state.pan_card_twoName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="pan_card_two" id="pan_card_two"
                    errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_two')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
                  { this.state.pan_card_twoUploadedName != '' ? <a href={this.state.pan_card_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="row ">
          <h6 className="title mt-3 text-left col-md-6">2. Address Proof</h6>
        </div>
        <div className="row ">
            <div className="col-md-6">
              <FormGroup>
                <SelectGroup name="address_proof_name_two" id="address_proof_name_two" 
                    onChange={this.handleChange} value={this.state.address_proof_name_two}  errorMessage={{
                    required: "Please select Address Proof" }} >
                    <option value="">Select Address Proof</option>
                    <option value="Electricity-Bill">Electricity Bill (Not more than 3 months old)</option>
                    <option value="Telephone-Bill-Landline">Telephone Bill -Landline (Not more than 3 months old)</option>
                    <option value="Gas-Bill">Gas Bill (Not more than 3 months old)</option>
                    <option value="Ration-Card">Ration Card</option>
                    <option value="Passport">Passport</option>
                    <option value="Registered-Lease-or-Sale-Agreement-of-Residence">Registered Lease or Sale Agreement of Residence</option>
                    <option value="Driving-License">Driving License</option>
                    <option value="Flat-Maintenance-bill">Flat Maintenance bill</option>
                    <option value="Insurance-Copy">Insurance Copy</option>
                    <option value="Voter-ID-card">Voter's ID card</option>
                    <option value="Other-Address-Proof">Other Address Proof</option>
                </SelectGroup>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <div>
                { this.state.address_proof_twoName != '' ? this.state.address_proof_twoName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="address_proof_two" id="address_proof_two"
                  errorMessage={{ required: "Please upload an address proof document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'address_proof_two')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Address Proof</label>
                { this.state.address_proof_twoUploadedName != '' ? <a href={this.state.address_proof_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
        </div>

        <div className="">
          <h6 className="title mt-2 text-left">3. Is your correspondence & permanent address same?</h6>
          <div className="row">
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same_two" id="exampleRadios1" value="Yes" 
              checked={this.state.correspondence_permanent_address_same_two == "Yes" ? true : false} />
              <label className="form-check-label">
                Yes
              </label>
            </div>
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same_two" id="exampleRadios2" value="No" 
              checked={this.state.correspondence_permanent_address_same_two == "No" ? true : false} />
              <label className="form-check-label">
                No
              </label>
            </div>
          </div>

          {this.state.correspondence_permanent_address_same_two == 'No' ? this.correnpondencePermanentAddSameTwo() : ""}
        </div>

        <div className="row ">
        <h6 className="title mt-2 text-left col-md-6">4. Bank Account Details</h6>
        </div>
        <div className="row ">
            <div className="col-md-6">
              <FormGroup>
                <SelectGroup name="bank_account_detail_two" id="bank_account_detail_two" 
                    onChange={this.handleChange} value={this.state.bank_account_detail_two}  errorMessage={{
                    required: "Please select Address Proof" }} >
                    <option value="">Select Bank Account Details</option>
                    <option value="Cancelled-Cheque">Cancelled Cheque</option>
                    <option value="Bank-statement-Passbook (Not more than 3 months old)">Bank statement/Passbook (Not more than 3 months old)</option>
                    <option value="Letter-issued-by-the-Bank-giving-the-account-details.">Letter issued by the Bank giving the account details.</option>
                </SelectGroup>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <div>
                { this.state.bank_account_detail_file_twoName != '' ? this.state.bank_account_detail_file_twoName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="bank_account_detail_file_two" id="bank_account_detail_file_two"
                  errorMessage={{ required: "Please upload a bank account detail.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'bank_account_detail_file_two')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Bank Account Details</label>
                { this.state.bank_account_detail_file_twoUploadedName != '' ? <a href={this.state.bank_account_detail_file_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">5. Any Other Document</h6>
            {this.otherDocument2()}
        </div>

      </div>
    </div>
  }

  indianThree(){
    return <div>
      {this.indianTwo()}
      <div className="indian mt-3">
      <h6 className="title text-left pt-1 holder">HOLDER 3</h6>
        
        <div className="">
            {/* <div className="col-md-6 text-left"></div> */}
            <h6 className="title mt-3 text-left">1. Upload Pan Card</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.pan_card_threeName != '' ? this.state.pan_card_threeName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="pan_card_three" id="pan_card_three"
                    errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_three')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
                  { this.state.pan_card_threeUploadedName != '' ? <a href={this.state.pan_card_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="row ">
          <h6 className="title mt-3 text-left col-md-6">2. Address Proof</h6>
        </div>
        <div className="row ">
            <div className="col-md-6">
              <FormGroup>
                <SelectGroup name="address_proof_name_three" id="address_proof_name_three" 
                    onChange={this.handleChange} value={this.state.address_proof_name_three}  errorMessage={{
                    required: "Please select Address Proof" }} >
                    <option value="">Select Address Proof</option>
                    <option value="Electricity-Bill">Electricity Bill (Not more than 3 months old)</option>
                    <option value="Telephone-Bill-Landline">Telephone Bill -Landline (Not more than 3 months old)</option>
                    <option value="Gas-Bill">Gas Bill (Not more than 3 months old)</option>
                    <option value="Ration-Card">Ration Card</option>
                    <option value="Passport">Passport</option>
                    <option value="Registered-Lease-or-Sale-Agreement-of-Residence">Registered Lease or Sale Agreement of Residence</option>
                    <option value="Driving-License">Driving License</option>
                    <option value="Flat-Maintenance-bill">Flat Maintenance bill</option>
                    <option value="Insurance-Copy">Insurance Copy</option>
                    <option value="Voter-ID-card">Voter's ID card</option>
                    <option value="Other-Address-Proof">Other Address Proof</option>
                </SelectGroup>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <div>
                { this.state.address_proof_threeName != '' ? this.state.address_proof_threeName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="address_proof_three" id="address_proof_three"
                  errorMessage={{ required: "Please upload an address proof document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'address_proof_three')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Address Proof</label>
                { this.state.address_proof_threeUploadedName != '' ? <a href={this.state.address_proof_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
          </div>
        </div>

        <div className="">
          <h6 className="title mt-2 text-left">3. Is your correspondence & permanent address same?</h6>
          <div className="row">
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same_three" id="exampleRadios1" value="Yes" 
              checked={this.state.correspondence_permanent_address_same_three == "Yes" ? true : false} />
              <label className="form-check-label">
                Yes
              </label>
            </div>
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same_three" id="exampleRadios2" value="No" 
              checked={this.state.correspondence_permanent_address_same_three == "No" ? true : false} />
              <label className="form-check-label">
                No
              </label>
            </div>
          </div>

          {this.state.correspondence_permanent_address_same_three == 'No' ? this.correnpondencePermanentAddSameThree() : ""}
        </div>

        <div className="row ">
          <h6 className="title mt-2 text-left col-md-6">4. Bank Account Details</h6>
        </div>
        <div className="row ">
            <div className="col-md-6">
              <FormGroup>
                <SelectGroup name="bank_account_detail_three" id="bank_account_detail_three" 
                    onChange={this.handleChange} value={this.state.bank_account_detail_three}  errorMessage={{
                    required: "Please select Address Proof" }} >
                    <option value="">Select Bank Account Details</option>
                    <option value="Cancelled-Cheque">Cancelled Cheque</option>
                    <option value="Bank-statement-Passbook (Not more than 3 months old)">Bank statement/Passbook (Not more than 3 months old)</option>
                    <option value="Letter-issued-by-the-Bank-giving-the-account-details.">Letter issued by the Bank giving the account details.</option>
                </SelectGroup>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <div>
                { this.state.bank_account_detail_file_threeName != '' ? this.state.bank_account_detail_file_threeName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="bank_account_detail_file_three" id="bank_account_detail_file_three"
                  errorMessage={{ required: "Please upload a bank account detail.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'bank_account_detail_file_three')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Bank Account Details</label>
                { this.state.bank_account_detail_file_threeUploadedName != '' ? <a href={this.state.bank_account_detail_file_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>  
          </div>
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">5. Any Other Document</h6>
            {this.otherDocument3()}
        </div>

      </div>
    </div>
  }

  correnpondencePermanentAddSame(){
    return <div>
      <div className="row col mt-3">
        <div className="col-md-6">
          <div>
            { this.state.correspondence_permanent_addressName != '' ? this.state.correspondence_permanent_addressName :null}
          </div>
          <div className="custom-file" style={{ "marginTop": "5px" }}>
            <FileInput className={"custom-file-input"} name="correspondence_permanent_address" id="correspondence_permanent_address"
              errorMessage={{ required: "Please upload an correspondence permanent document address.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
              fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'correspondence_permanent_address')} />
            <label className="custom-file-label" htmlFor="validatedCustomFile">Upload an Correspondence Permanent Address</label>
            { this.state.correspondence_permanent_addressUploadedName != '' ? <a href={this.state.correspondence_permanent_addressUploadedName} target="_blank">Preview Uploaded File</a> :null}
          </div>
        </div>
      </div>
      <h6 className="title mt-3 text-left">Note: Proof of Address in the name of the Spouse can also be submitted</h6>
    </div>
  }

  correnpondencePermanentAddSameTwo(){
    return <div>
      <div className="row col mt-3">
        <div className="col-md-6">
          <div>
            { this.state.correspondence_permanent_address_twoName != '' ? this.state.correspondence_permanent_address_twoName :null}
          </div>
          <div className="custom-file" style={{ "marginTop": "5px" }}>
            <FileInput className={"custom-file-input"} name="correspondence_permanent_address_two" id="correspondence_permanent_address_two"
              errorMessage={{ required: "Please upload an correspondence permanent document address.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
              fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'correspondence_permanent_address_two')} />
            <label className="custom-file-label" htmlFor="validatedCustomFile">Upload an Correspondence Permanent Address</label>
            { this.state.correspondence_permanent_address_twoUploadedName != '' ? <a href={this.state.correspondence_permanent_address_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
          </div>
        </div>
      </div>
      <h6 className="title mt-3 text-left">Note: Proof of Address in the name of the Spouse can also be submitted</h6>
    </div>
  }

  correnpondencePermanentAddSameThree(){
    return <div>
      <div className="row col mt-3">
        <div className="col-md-6">
          <div>
            { this.state.correspondence_permanent_address_threeName != '' ? this.state.correspondence_permanent_address_threeName :null}
          </div>
          <div className="custom-file" style={{ "marginTop": "5px" }}>
            <FileInput className={"custom-file-input"} name="correspondence_permanent_address_three" id="correspondence_permanent_address_three"
              errorMessage={{ required: "Please upload an correspondence permanent document address.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
              fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'correspondence_permanent_address_three')} />
            <label className="custom-file-label" htmlFor="validatedCustomFile">Upload an Correspondence Permanent Address</label>
            { this.state.correspondence_permanent_address_threeUploadedName != '' ? <a href={this.state.correspondence_permanent_address_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
          </div>
        </div>
      </div>
      <h6 className="title mt-3 text-left">Note: Proof of Address in the name of the Spouse can also be submitted</h6>
    </div>
  }

  otherDocument(){
    return <div className="row col">
      <div className="col-md-6">
        <div>
          { this.state.other_documentName != '' ? this.state.other_documentName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document" id="other_document"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_documentUploadedName != '' ? <a href={this.state.other_documentUploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="second-section" className={this.state.ODB1} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"second-section col-md-6 "+this.state.ODS2 }>
        <div>
          { this.state.other_document2Name != '' ? this.state.other_document2Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document2" id="other_document2"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document2')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document2UploadedName != '' ? <a href={this.state.other_document2UploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="third-section" className={this.state.ODB2} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"third-section col-md-6 "+this.state.ODS3 }>
        <div>
          { this.state.other_document3Name != '' ? this.state.other_document3Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document3" id="other_document3"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document3')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document3UploadedName != '' ? <a href={this.state.other_document3UploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  otherDocument2(){
    return <div className="row col">
      <div className="col-md-6">
        <div>
          { this.state.other_document_twoName != '' ? this.state.other_document_twoName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document_two" id="other_document_two"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document_two')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document_twoUploadedName != '' ? <a href={this.state.other_document_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="second-section2" className={this.state.OD2B1} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"second-section2 col-md-6 "+this.state.OD2S2 }>
        <div>
          { this.state.other_document_two2Name != '' ? this.state.other_document_two2Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document_two2" id="other_document_two2"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document_two2')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document_two2UploadedName != '' ? <a href={this.state.other_document_two2UploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="third-section2" className={this.state.OD2B2} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"third-section2 col-md-6 "+this.state.OD2S3 }>
        <div>
          { this.state.other_document_two3Name != '' ? this.state.other_document_two3Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document_two3" id="other_document_two3"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document_two3')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document_two3UploadedName != '' ? <a href={this.state.other_document_two3UploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  otherDocument3(){
    return <div className="row col">
      <div className="col-md-6">
        <div>
          { this.state.other_document_threeName != '' ? this.state.other_document_threeName :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document_three" id="other_document_three"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document_three')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document_threeUploadedName != '' ? <a href={this.state.other_document_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="second-section3" className={this.state.OD3B1} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"second-section3 col-md-6 "+this.state.OD3S2 }>
        <div>
          { this.state.other_document_three2Name != '' ? this.state.other_document_three2Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document_three2" id="other_document_three2"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document_three2')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document_three2UploadedName != '' ? <a href={this.state.other_document_three2UploadedName} target="_blank">Preview Uploaded File</a> :null}
          <div><button onClick={this.handleButtonChange} data_type="third-section3" className={this.state.OD3B2} type="button"><i class="fa fa-plus" aria-hidden="true"></i></button></div>
        </div>
      </div>
      <div className={"third-section3 col-md-6 "+this.state.OD3S3 }>
        <div>
          { this.state.other_document_three3Name != '' ? this.state.other_document_three3Name :null}
        </div>
        <div className="custom-file" style={{ "marginTop": "5px" }}>
          <FileInput className={"custom-file-input"} name="other_document_three3" id="other_document_three3"
            errorMessage={{ required: "Please upload an other document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
            fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'other_document_three3')} />
          <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Other Document</label>
          { this.state.other_document_three3UploadedName != '' ? <a href={this.state.other_document_three3UploadedName} target="_blank">Preview Uploaded File</a> :null}
        </div>
      </div>
    </div>
  }

  nonResidentIndian(){
    return <div>
      {this.state.pms_account_holder == '1' ? this.nonResidentIndianOne() : ""}
      {this.state.pms_account_holder == '2' ? this.nonResidentIndianTwo() : ""}
      {this.state.pms_account_holder == '3' ? this.nonResidentIndianThree() : ""}
    </div>
  }

  nonResidentIndianOne(){
    return <div>
      <div className="non-resident-indian mt-3">
      <h6 className="title text-left pt-1 holder">HOLDER 1</h6>
        <div className="">
          <h6 className="title mt-3 text-left">1. Passport </h6>
          <div className="row col">
            <div className="col-md-6">
              <div>
                { this.state.passportName != '' ? this.state.passportName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="passport" id="passport"
                  errorMessage={{ required: "Please upload an passport document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'passport')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Passport</label>
                { this.state.passportUploadedName != '' ? <a href={this.state.passportUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
        <h6 className="title mt-3 text-left col-md-6">2. Address Proof</h6>
        </div>
        <div className="row ">
            <div className="col-md-6">
              <FormGroup>
                <SelectGroup name="address_proof_name" id="address_proof_name" 
                    onChange={this.handleChange} value={this.state.address_proof_name}  errorMessage={{
                    required: "Please select Address Proof" }} >
                    <option value="">Select Address Proof</option>
                    <option value="Electricity-Bill">Electricity Bill (Not more than 3 months old)</option>
                    <option value="Telephone-Bill-Landline">Telephone Bill -Landline (Not more than 3 months old)</option>
                    <option value="Gas-Bill">Gas Bill (Not more than 3 months old)</option>
                    <option value="Ration-Card">Ration Card</option>
                    <option value="Passport">Passport</option>
                    <option value="Registered-Lease-or-Sale-Agreement-of-Residence">Registered Lease or Sale Agreement of Residence</option>
                    <option value="Driving-License">Driving License</option>
                    <option value="Flat-Maintenance-bill">Flat Maintenance bill</option>
                    <option value="Insurance-Copy">Insurance Copy</option>
                    <option value="Voter-ID-card">Voter's ID card</option>
                    <option value="Other-Address-Proof">Other Address Proof</option>
                </SelectGroup>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <div>
                { this.state.address_proofName != '' ? this.state.address_proofName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="address_proof" id="address_proof"
                  errorMessage={{ required: "Please upload an address proof document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'address_proof')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Address Proof</label>
                { this.state.address_proofUploadedName != '' ? <a href={this.state.address_proofUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
            </div>
        </div>

        <div className="">

          <h6 className="title mt-2 text-left">Is your correspondence & permanent address same?</h6>
          <div className="row">
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same" id="exampleRadios1" value="Yes" 
              checked={this.state.correspondence_permanent_address_same == "Yes" ? true : false} />
              <label className="form-check-label">
                Yes
              </label>
            </div>
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same" id="exampleRadios2" value="No" 
              checked={this.state.correspondence_permanent_address_same == "No" ? true : false} />
              <label className="form-check-label">
                No
              </label>
            </div>
          </div>

          {this.state.correspondence_permanent_address_same == 'No' ? this.correnpondencePermanentAddSame() : ""}
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">3. Upload Pan Card</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.pan_cardName != '' ? this.state.pan_cardName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="pan_card" id="pan_card"
                    errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
                  { this.state.pan_cardUploadedName != '' ? <a href={this.state.pan_cardUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-3 text-left">4. Tax Identification No</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.tinName != '' ? this.state.tinName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="tin" id="tin"
                    errorMessage={{ required: "Please upload an tax identification no document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'tin')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Tax Identification No</label>
                  { this.state.tinUploadedName != '' ? <a href={this.state.tinUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">5. Visa</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.visaName != '' ? this.state.visaName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="visa" id="visa"
                    errorMessage={{ required: "Please upload an visa document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'visa')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Visa</label>
                  { this.state.visaUploadedName != '' ? <a href={this.state.visaUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">6. Any Other Document</h6>
            {this.otherDocument()}
        </div>

      </div>
    </div>
  }

  nonResidentIndianTwo(){
    return <div>
      {this.nonResidentIndianOne()}
      <div className="non-resident-indian mt-3">
        <h6 className="title text-left pt-1 holder">HOLDER 2</h6>
        <div className="">
            <h6 className="title mt-3 text-left">1. Passport </h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.passport_twoName != '' ? this.state.passport_twoName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="passport_two" id="passport_two"
                    errorMessage={{ required: "Please upload an passport document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'passport_two')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Passport</label>
                  { this.state.passport_twoUploadedName != '' ? <a href={this.state.passport_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="row ">
          <h6 className="title mt-3 text-left col-md-6">2. Address Proof</h6>
        </div>
        <div className="row ">
            <div className="col-md-6">
              <FormGroup>
                <SelectGroup name="address_proof_name_two" id="address_proof_name_two" 
                    onChange={this.handleChange} value={this.state.address_proof_name_two}  errorMessage={{
                    required: "Please select Address Proof" }} >
                    <option value="">Select Address Proof</option>
                    <option value="Electricity-Bill">Electricity Bill (Not more than 3 months old)</option>
                    <option value="Telephone-Bill-Landline">Telephone Bill -Landline (Not more than 3 months old)</option>
                    <option value="Gas-Bill">Gas Bill (Not more than 3 months old)</option>
                    <option value="Ration-Card">Ration Card</option>
                    <option value="Passport">Passport</option>
                    <option value="Registered-Lease-or-Sale-Agreement-of-Residence">Registered Lease or Sale Agreement of Residence</option>
                    <option value="Driving-License">Driving License</option>
                    <option value="Flat-Maintenance-bill">Flat Maintenance bill</option>
                    <option value="Insurance-Copy">Insurance Copy</option>
                    <option value="Voter-ID-card">Voter's ID card</option>
                    <option value="Other-Address-Proof">Other Address Proof</option>
                </SelectGroup>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <div>
                { this.state.address_proof_twoName != '' ? this.state.address_proof_twoName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="address_proof_two" id="address_proof_two"
                  errorMessage={{ required: "Please upload an address proof document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'address_proof_two')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Address Proof</label>
                { this.state.address_proof_twoUploadedName != '' ? <a href={this.state.address_proof_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
            </div>
          </div>
        </div>

        <div className="">

          <h6 className="title mt-2 text-left">Is your correspondence & permanent address same?</h6>
          <div className="row">
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same_two" id="exampleRadios1" value="Yes" 
              checked={this.state.correspondence_permanent_address_same_two == "Yes" ? true : false} />
              <label className="form-check-label">
                Yes
              </label>
            </div>
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same_two" id="exampleRadios2" value="No" 
              checked={this.state.correspondence_permanent_address_same_two == "No" ? true : false} />
              <label className="form-check-label">
                No
              </label>
            </div>
          </div>

          {this.state.correspondence_permanent_address_same_two == 'No' ? this.correnpondencePermanentAddSameTwo() : ""}
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">3. Upload Pan Card</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.pan_card_twoName != '' ? this.state.pan_card_twoName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="pan_card_two" id="pan_card_two"
                    errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_two')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
                  { this.state.pan_card_twoUploadedName != '' ? <a href={this.state.pan_card_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-3 text-left">4. Tax Identification No</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.tin_twoName != '' ? this.state.tin_twoName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="tin_two" id="tin_two"
                    errorMessage={{ required: "Please upload an tax identification no document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'tin_two')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Tax Identification No</label>
                  { this.state.tin_twoUploadedName != '' ? <a href={this.state.tin_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">5. Visa</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.visa_twoName != '' ? this.state.visa_twoName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="visa_two" id="visa_two"
                    errorMessage={{ required: "Please upload an visa document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'visa_two')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Visa</label>
                  { this.state.visa_twoUploadedName != '' ? <a href={this.state.visa_twoUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">6. Any Other Document</h6>
            {this.otherDocument2()}
        </div>

      </div>
    </div>
  }

  nonResidentIndianThree(){
    return <div>
      {this.nonResidentIndianTwo()}
      <div className="non-resident-indian mt-3">
      <h6 className="title text-left pt-1 holder">HOLDER 3</h6>
        <div className="">
            <h6 className="title mt-3 text-left">1. Passport </h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.passport_threeName != '' ? this.state.passport_threeName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="passport_three" id="passport_three"
                    errorMessage={{ required: "Please upload an passport document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'passport_three')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Passport</label>
                  { this.state.passport_threeUploadedName != '' ? <a href={this.state.passport_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="row ">
          <h6 className="title mt-3 text-left col-md-6">2. Address Proof</h6>
        </div>
        <div className="row ">
            <div className="col-md-6">
              <FormGroup>
                <SelectGroup name="address_proof_name_three" id="address_proof_name_three" 
                    onChange={this.handleChange} value={this.state.address_proof_name_three}  errorMessage={{
                    required: "Please select Address Proof" }} >
                    <option value="">Select Address Proof</option>
                    <option value="Electricity-Bill">Electricity Bill (Not more than 3 months old)</option>
                    <option value="Telephone-Bill-Landline">Telephone Bill -Landline (Not more than 3 months old)</option>
                    <option value="Gas-Bill">Gas Bill (Not more than 3 months old)</option>
                    <option value="Ration-Card">Ration Card</option>
                    <option value="Passport">Passport</option>
                    <option value="Registered-Lease-or-Sale-Agreement-of-Residence">Registered Lease or Sale Agreement of Residence</option>
                    <option value="Driving-License">Driving License</option>
                    <option value="Flat-Maintenance-bill">Flat Maintenance bill</option>
                    <option value="Insurance-Copy">Insurance Copy</option>
                    <option value="Voter-ID-card">Voter's ID card</option>
                    <option value="Other-Address-Proof">Other Address Proof</option>
                </SelectGroup>
              </FormGroup>
            </div>

            <div className="col-md-6">
              <div>
                { this.state.address_proof_threeName != '' ? this.state.address_proof_threeName :null}
              </div>
              <div className="custom-file" style={{ "marginTop": "5px" }}>
                <FileInput className={"custom-file-input"} name="address_proof_three" id="address_proof_three"
                  errorMessage={{ required: "Please upload an address proof document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                  fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'address_proof_three')} />
                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Address Proof</label>
                { this.state.address_proof_threeUploadedName != '' ? <a href={this.state.address_proof_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
              </div>
          </div>
        </div>

        <div className="">

          <h6 className="title mt-2 text-left">Is your correspondence & permanent address same?</h6>
          <div className="row">
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same_three" id="exampleRadios1" value="Yes" 
              checked={this.state.correspondence_permanent_address_same_three == "Yes" ? true : false} />
              <label className="form-check-label">
                Yes
              </label>
            </div>
            <div className="form-check col-lg-6">
              <input className="form-check-input financial-radio" type="radio"
              onChange={this.handleChange} name="correspondence_permanent_address_same_three" id="exampleRadios2" value="No" 
              checked={this.state.correspondence_permanent_address_same_three == "No" ? true : false} />
              <label className="form-check-label">
                No
              </label>
            </div>
          </div>

          {this.state.correspondence_permanent_address_same_three == 'No' ? this.correnpondencePermanentAddSameThree() : ""}
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">3. Upload Pan Card</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.pan_card_threeName != '' ? this.state.pan_card_threeName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="pan_card_three" id="pan_card_three"
                    errorMessage={{ required: "Please upload an pan card document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'pan_card_three')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Pan Card</label>
                  { this.state.pan_card_threeUploadedName != '' ? <a href={this.state.pan_card_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-3 text-left">4. Tax Identification No</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.tin_threeName != '' ? this.state.tin_threeName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="tin_three" id="tin_three"
                    errorMessage={{ required: "Please upload an tax identification no document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'tin_three')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Tax Identification No</label>
                  { this.state.tin_threeUploadedName != '' ? <a href={this.state.tin_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">5. Visa</h6>
            <div className="row col">
              <div className="col-md-6">
                <div>
                  { this.state.visa_threeName != '' ? this.state.visa_threeName :null}
                </div>
                <div className="custom-file" style={{ "marginTop": "5px" }}>
                  <FileInput className={"custom-file-input"} name="visa_three" id="visa_three"
                    errorMessage={{ required: "Please upload an visa document.", fileType: 'only Image, Pdf, Doc files are allowed.', maxFileSize: "File size must be less than 5 mb." }}
                    fileType={["jpeg", "png", "jpg", "pdf", "doc", "docx"]} maxFileSize="5120 kb" onChange={this.handleChangeImg.bind(this, 'visa_three')} />
                  <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Visa</label>
                  { this.state.visa_threeUploadedName != '' ? <a href={this.state.visa_threeUploadedName} target="_blank">Preview Uploaded File</a> :null}
                </div>
              </div>
            </div>
        </div>

        <div className="">
            <h6 className="title mt-2 text-left">6. Any Other Document</h6>
            {this.otherDocument3()}
        </div>

      </div>
    </div>
  }

  async getProcessData(processName) {
    let path = apiPath.getProcessData;
    this.setState({ loading: true });
    var data = {}
    data.user_id = this.state.id
    data.process = processName
    let options = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }
    let result = await Http(path, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultData = await result.json();
        if (resultData.success) {
          console.log(resultData.result,'resultData.result');
          let processData = resultData.result;
          this.setState({  
            onBoardingId: processData && processData._id ? processData._id : "",
            ownership_status: processData && processData.ownership_status ? processData.ownership_status : "",
            ownerStatus: processData && processData.ownership_status ? processData.ownership_status : "",
            pms_account_holder: processData && processData.pms_account_holder ? processData.pms_account_holder : "",

            pan_card: processData && processData.pan_card ? processData.pan_card : "",
            pan_cardUploadedName: processData && processData.pan_card && processData.pan_card.indexOf('u/user') < 1 ? processData.pan_card : "",
            address_proof_name: processData && processData.address_proof_name ? processData.address_proof_name : "",
            address_proof: processData && processData.address_proof ? processData.address_proof : "",
            address_proofUploadedName: processData && processData.address_proof && processData.address_proof.indexOf('u/user') < 1 ? processData.address_proof : "",
            correspondence_permanent_address_same: processData && processData.correspondence_permanent_address_same ? processData.correspondence_permanent_address_same : "",
            correspondence_permanent_address: processData && processData.correspondence_permanent_address ? processData.correspondence_permanent_address : "",
            correspondence_permanent_addressUploadedName: processData && processData.correspondence_permanent_address && processData.correspondence_permanent_address.indexOf('u/user') < 1 ? processData.correspondence_permanent_address : "",
            bank_account_detail: processData && processData.bank_account_detail ? processData.bank_account_detail : "",
            bank_account_detail_file: processData && processData.bank_account_detail_file ? processData.bank_account_detail_file : "",
            bank_account_detail_fileUploadedName: processData && processData.bank_account_detail_file && processData.bank_account_detail_file.indexOf('u/user') < 1 ? processData.bank_account_detail_file : "",
            other_document: processData && processData.other_document ? processData.other_document : "",
            other_documentUploadedName: processData && processData.other_document && processData.other_document.indexOf('u/user') < 1 ? processData.other_document : "",
            other_document2: processData && processData.other_document2 ? processData.other_document2 : "",
            other_document2UploadedName: processData && processData.other_document2 && processData.other_document2.indexOf('u/user') < 1 ? processData.other_document2 : "",
            other_document3: processData && processData.other_document3 ? processData.other_document3 : "",
            other_document3UploadedName: processData && processData.other_document3 && processData.other_document3.indexOf('u/user') < 1 ? processData.other_document3 : "",

            pan_card_two: processData && processData.pan_card_two ? processData.pan_card_two : "",
            pan_card_twoUploadedName: processData && processData.pan_card_two && processData.pan_card_two.indexOf('u/user') < 1 ? processData.pan_card_two : "",
            address_proof_name_two: processData && processData.address_proof_name_two ? processData.address_proof_name_two : "",
            address_proof_two: processData && processData.address_proof_two ? processData.address_proof_two : "",
            address_proof_twoUploadedName: processData && processData.address_proof_two && processData.address_proof_two.indexOf('u/user') < 1 ? processData.address_proof_two : "",
            correspondence_permanent_address_same_two: processData && processData.correspondence_permanent_address_same_two ? processData.correspondence_permanent_address_same_two : "",
            correspondence_permanent_address_two: processData && processData.correspondence_permanent_address_two ? processData.correspondence_permanent_address_two : "",
            correspondence_permanent_address_twoUploadedName: processData && processData.correspondence_permanent_address_two && processData.correspondence_permanent_address_two.indexOf('u/user') < 1 ? processData.correspondence_permanent_address_two : "",
            bank_account_detail_two: processData && processData.bank_account_detail_two ? processData.bank_account_detail_two : "",
            bank_account_detail_file_two: processData && processData.bank_account_detail_file_two ? processData.bank_account_detail_file_two : "",
            bank_account_detail_file_twoUploadedName: processData && processData.bank_account_detail_file_two && processData.bank_account_detail_file_two.indexOf('u/user') < 1 ? processData.bank_account_detail_file_two : "",
            other_document_two: processData && processData.other_document_two ? processData.other_document_two : "",
            other_document_twoUploadedName: processData && processData.other_document_two && processData.other_document_two.indexOf('u/user') < 1 ? processData.other_document_two : "",
            other_document_two2: processData && processData.other_document_two2 ? processData.other_document_two2 : "",
            other_document_two2UploadedName: processData && processData.other_document_two2 && processData.other_document_two2.indexOf('u/user') < 1 ? processData.other_document_two2 : "",
            other_document_two3: processData && processData.other_document_two3 ? processData.other_document_two3 : "",
            other_document_two3UploadedName: processData && processData.other_document_two3 && processData.other_document_two3.indexOf('u/user') < 1 ? processData.other_document_two3 : "",

            pan_card_three: processData && processData.pan_card_three ? processData.pan_card_three : "",
            pan_card_threeUploadedName: processData && processData.pan_card_three && processData.pan_card_three.indexOf('u/user') < 1 ? processData.pan_card_three : "",
            address_proof_name_three: processData && processData.address_proof_name_three ? processData.address_proof_name_three : "",
            address_proof_three: processData && processData.address_proof_three ? processData.address_proof_three : "",
            address_proof_threeUploadedName: processData && processData.address_proof_three && processData.address_proof_three.indexOf('u/user') < 1 ? processData.address_proof_three : "",
            correspondence_permanent_address_same_three: processData && processData.correspondence_permanent_address_same_three ? processData.correspondence_permanent_address_same_three : "",
            correspondence_permanent_address_three: processData && processData.correspondence_permanent_address_three ? processData.correspondence_permanent_address_three : "",
            correspondence_permanent_address_threeUploadedName: processData && processData.correspondence_permanent_address_three && processData.correspondence_permanent_address_three.indexOf('u/user') < 1 ? processData.correspondence_permanent_address_three : "",
            bank_account_detail_three: processData && processData.bank_account_detail_three ? processData.bank_account_detail_three : "",
            bank_account_detail_file_three: processData && processData.bank_account_detail_file_three ? processData.bank_account_detail_file_three : "",
            bank_account_detail_file_threeUploadedName: processData && processData.bank_account_detail_file_three && processData.bank_account_detail_file_three.indexOf('u/user') < 1 ? processData.bank_account_detail_file_three : "",
            other_document_three: processData && processData.other_document_three ? processData.other_document_three : "",
            other_document_threeUploadedName: processData && processData.other_document_three && processData.other_document_three.indexOf('u/user') < 1 ? processData.other_document_three : "",
            other_document_three2: processData && processData.other_document_three2 ? processData.other_document_three2 : "",
            other_document_three2UploadedName: processData && processData.other_document_three2 && processData.other_document_three2.indexOf('u/user') < 1 ? processData.other_document_three2 : "",
            other_document_three3: processData && processData.other_document_three3 ? processData.other_document_three3 : "",
            other_document_three3UploadedName: processData && processData.other_document_three3 && processData.other_document_three3.indexOf('u/user') < 1 ? processData.other_document_three3 : "",

            passport: processData && processData.passport ? processData.passport : "",
            passportUploadedName: processData && processData.passport && processData.passport.indexOf('u/user') < 1 ? processData.passport : "",
            tin: processData && processData.tin ? processData.tin : "",
            tinUploadedName: processData && processData.tin && processData.tin.indexOf('u/user') < 1 ? processData.tin : "",
            visa: processData && processData.visa ? processData.visa : "",
            visaUploadedName: processData && processData.visa && processData.visa.indexOf('u/user') < 1 ? processData.visa : "",

            passport_two: processData && processData.passport_two ? processData.passport_two : "",
            passport_twoUploadedName: processData && processData.passport_two && processData.passport_two.indexOf('u/user') < 1 ? processData.passport_two : "",
            tin_two: processData && processData.tin_two ? processData.tin_two : "",
            tin_twoUploadedName: processData && processData.tin_two && processData.tin_two.indexOf('u/user') < 1 ? processData.tin_two : "",
            visa_two: processData && processData.visa_two ? processData.visa_two : "",
            visa_twoUploadedName: processData && processData.visa_two && processData.visa_two.indexOf('u/user') < 1 ? processData.visa_two : "",

            passport_three: processData && processData.passport_three ? processData.passport_three : "",
            passport_threeUploadedName: processData && processData.passport_three && processData.passport_three.indexOf('u/user') < 1 ? processData.passport_three : "",
            tin_three: processData && processData.tin_three ? processData.tin_three : "",
            tin_threeUploadedName: processData && processData.tin_three && processData.tin_three.indexOf('u/user') < 1 ? processData.tin_three : "",
            visa_three: processData && processData.visa_three ? processData.visa_three : "",
            visa_threeUploadedName: processData && processData.visa_three && processData.visa_three.indexOf('u/user') < 1 ? processData.visa_three : "",
          })
          if(!_.isEmpty(this.state.other_document2) && processData.other_document2.indexOf('u/user') < 1){
            this.setState({
              ODS2:"",
              ODB1:"shide"
            })
          }
          if(!_.isEmpty(this.state.other_document3) && processData.other_document3.indexOf('u/user') < 1){
            this.setState({
              ODS3:"",
              ODB2:"shide"
            })
          }
          if(!_.isEmpty(this.state.other_document_two2) && processData.other_document_two2.indexOf('u/user') < 1){
            this.setState({
              OD2S2:"",
              OD2B1:"shide"
            })
          }
          if(!_.isEmpty(this.state.other_document_two3) && processData.other_document_two3.indexOf('u/user') < 1){
            this.setState({
              OD2S3:"",
              OD2B2:"shide"
            })
          }
          if(!_.isEmpty(this.state.other_document_three2) && processData.other_document_three2.indexOf('u/user') < 1){
            this.setState({
              OD3S2:"",
              OD3B1:"shide"
            })
          }
          if(!_.isEmpty(this.state.other_document_three3) && processData.other_document_three3.indexOf('u/user') < 1){
            this.setState({
              OD3S3:"",
              OD3B2:"shide"
            })
          }
        }
      }
    } else {
      console.log(result);
      this.setState({ message: result.statusText });
      this.generate('danger');
    }
  }

  componentDidMount() {
    this.getProcessData('Client-On-Boarding');
  }

  render() {
    if(_.isEmpty(this.props.admin)){
      return <Redirect to="/" />
    }
    if(this.state.redirectTo){
      return <Redirect to="/vallum-tracker" />
    }
    return (
      <div className="inner-page">
        {/* Header */}
          <Header />
        {/* Header */}

        {/* Middle Section*/}
        <section className="profile-section section-bg padding-top padding-bottom">
            <div className="container container-inner">
             <div className="box-shadow box-block aos-item aos-init aos-animate section-white" data-aos="fade-up">
                <div className="row justify-content-center after-login-pages no-gutters">
                  {/*Client On-Boarding Documents (Individual) */}
                  <Sidebar />
                    
                      <div class="col-md-8 col-lg-9">
                       <div className="p-3 p-md-3"> 
                       {this.message()}
                         
                              <h4 className="title mb-4 text-left">Client On-Boarding Documents (Individual) </h4>
                              <h6 className="title mb-4 text-left">Please Upload the below documents to begin your on-boarding process </h6>
                              <h5 className="title mb-4 text-left">Please select number of Holders for this PMS Account </h5>
                              <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                                  ref={this.formRef}
                                  immediate={this.state.immediate}
                                  setFocusOnError={this.state.setFocusOnError}
                                  defaultErrorMessage={{ required: "Please enter required fields." }} >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <Col lg="6">
                                        <FormGroup>
                                          <SelectGroup name="pms_account_holder" id="pms_account_holder" onChange={this.handleChange} value={this.state.pms_account_holder} required errorMessage={{required: "Please select PMS Account Holder" }} >
                                            <option value="">Select PMS Account Holder</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                          </SelectGroup>
                                        </FormGroup>
                                      </Col>
                                    </div>

                                    <div className="pt-1">
                                      <h6 className="title text-left">Please answer the question below and upload the document for each individual holder respectively </h6>
                                      
                                      <h5 className="title mb-3 text-left pt-1">Ownership Status: </h5>
                                      <div className="row">
                                        <div className="form-check col-lg-6">
                                          <input className="form-check-input" type="radio" name="ownership_status" id="radios1" 
                                            onChange={this.handleChange}
                                            value="Indian" checked={this.state.ownership_status == "Indian" ? true : false} disabled={this.state.ownerStatus == "Non-Resident-Indian" ? true : false} />
                                          <label className="form-check-label">
                                            Indian
                                          </label>
                                        </div>
                                        <div className="form-check col-lg-6">
                                          <input className="form-check-input" type="radio" name="ownership_status" id="radios2" 
                                            onChange={this.handleChange}
                                            value="Non-Resident-Indian" checked={this.state.ownership_status == "Non-Resident-Indian" ? true : false}  disabled={this.state.ownerStatus == "Indian" ? true : false} />
                                          <label className="form-check-label">
                                            Non resident Indian(NRI)
                                          </label>
                                        </div>
                                      </div>
                                      {this.state.ownership_status == 'Indian' ? this.indian() : ""}
                                      {this.state.ownership_status == 'Non-Resident-Indian' ? this.nonResidentIndian() : ""}
                                    </div>
                                    <div className="row mt-5">
                                      <div className="col-md-6"><button type="submit">Submit</button></div>
                                    </div>
                                  </div>
                                </div>
                              </ValidationForm>
                              {this.state.clientCategory}
                            </div>
                          </div>
                       
                    </div>
                  {/*Client On Boarding Form*/}
                </div>
            </div>
          </section>
        {/* Middle Section*/}

        {/*GetInTouch*/}
          <GetInTouch />
        {/*GetInTouch*/}

        {/*Footer*/}
          <Footer />
        {/*Footer*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientOnBoardingDocumentsIndividual);
